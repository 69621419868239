.pubblica-articolo-page
    margin-bottom: 9.2rem    
    &__help
        margin-top: 2rem        
        margin-left: auto
        margin-right: auto
        margin-bottom: 10rem
        &__title
            color: $color-secondary-dark
            margin-bottom: 2.2rem
            text-align: center
            line-height: 4.8rem
            font-size: 4rem
            font-family: $font-primary
        &__text
            text-align: center
            margin-left: auto
            margin-right: auto
            font-size: 2rem      
        
    &__form-box
        margin-left: auto
        margin-right: auto

.div-input-file    
    height: 17.2rem
    position: relative    
    text-align: center
    border: dashed 0.3rem $color-grey-16

.div-input-file-dropzone 
    height: 17.2rem
    position: relative       

.input-file
    opacity: 0.0
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    position: absolute
    &__text
        font-size: 2rem
        color: $color-grey-16
        padding: 2rem

.button-submit-richiesta
    text-align: center

.privacy-richiesta
    margin-left: auto
    margin-right: auto
    .registrazione-page__privacy-text
        font-size: 1.6rem
        font-weight: $font-bold
        line-height: 1.9rem
        margin-bottom: 2.2rem
        margin-top: 2.4rem

.ruolo-container
    margin-left: auto
    margin-right: auto
    text-align: center
    margin-bottom: 4.8rem
    &__text
        display: inline-block
        margin-right: 1.8rem
    &__div-dropdown
        display: inline-block
        width: 45rem
        
.submit
    margin-bottom: 5rem