@import public/sass/abstracts

.box-abbonamento
  position: relative
  background: $color-bg
  width: 100%
  max-width: 632px
  padding: 30px
  +border-radius(16px)
  +res-sm-max
    padding-top: 44px
  &__tag
    position: absolute
    top: 0
    right: 0
    +font(16, 24, 700)
    color: #fff
    text-transform: uppercase
    padding: 6px $wcont-padding
    +border-radius(0 16px)
  &__title
    +font(24, 30, 800)
    margin-bottom: 16px
    max-width: 340px
  &__middle
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: 24px
  &__fullprice
    text-decoration: line-through
    +font(24, 28, 400)
    color: $grey-3
    margin-right: 10px
    +res-sm-max
      margin-bottom: 16px
  &__price
    +font(24, 28, 700)
    margin-right: 30px
    +res-sm-max
      margin-bottom: 16px
    &--promo
      +font(36, 43, 800)
      color: $color-primary
  &__trigger
    margin-bottom: 20px
    +res-md-min
      display: none
  &__text
    +font(14, 20, 400)
    color: $grey-3
    +res-sm-max
      display: none
      &--open
        display: block
