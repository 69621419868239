.bibliography-box
  padding: 3rem 2.5rem
  border: 3px solid $color-teal-light
  +border-radius(.6rem)
  &__title
    font-size: 2rem
    font-family: $font-primary
    font-weight: $font-bold
    line-height: 3rem
    text-transform: uppercase
    margin-bottom: 1.5rem
    color: $color-teal-dark
  &__text
    @extend .ext-custom-text
    font-size: 1.8rem
    line-height: 3.2rem