@import public/sass/abstracts

.area-personale-raccolta-edit-form
  position: relative
  padding-bottom: 57px
  +res-md-min
    display: flex
  &__sidebar
    width: 25%
    max-width: 216px
    margin-right: 16px
    +res-sm-max
      display: none
  &__image-box
    border-radius: 20px
    overflow: hidden
    height: 216px
    width: 216px
  &__main
    flex: 1
    max-width: 748px
    margin: 0 auto
  &__title
    +typoH3
    margin-bottom: 24px
    +res-sm-max
      +typoH4
  &__actions
    +res-md-min
      text-align: center
    +res-sm-max
      display: flex
      flex-direction: column
      align-items: center
      > * 
        margin-top: 24px
  &__action
    &--cancel
      +res-md-min
        display: none
    &--delete
      +res-md-min
        position: absolute
        top: -52px
        right: 0