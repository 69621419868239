.raccolta-page
  &__header
    margin-bottom: 2rem
    &__summary
      margin: .5rem auto
      .intro
        font-size: 1.6rem
        margin-bottom: 0
        text-transform: uppercase
      .title
        font-size: 4rem
        font-weight: $font-bold
        line-height: 4.8rem
        color: $color-text
        margin-bottom: 1rem
      .tags
        margin-bottom: 1.5rem
      .author
        margin-bottom: 1.5rem
        font-size: 1.6rem
        .icon
          display: inline-block
          margin: 0 .3rem
          vertical-align: sub
          +fill-icon($color-primary)
          &--community
            width: 2.75rem
            height: 1.75rem
          &--logosymbol
            width: 2.4rem
            height: 2.4rem
        .from-community,
        .redazionale
          color: $color-primary
      .abstract
        font-family: $font-secondary
        font-size: 2rem
        line-height: 3.2rem
        margin-bottom: 2.5rem
    &__image-box
      margin: 2rem 0
      max-width: 34rem
      position: relative
      +res-md-max
        margin: 2rem auto
      &::before
        position: absolute
        content: ""
        width: 100%
        height: 100%
        left: 1.5rem
        top: -1.5rem
        background: $color-primary-light
        +border-radius(.6rem)
        z-index: -1
  .elements
    margin-bottom: 3rem
    .switch
      margin-bottom: 3rem
      display: none
      +res-md-min
        display: flex
    .grid
      &__item
        margin-bottom: 3rem
        +res-lg-min
          &:nth-child(2n) // gli elementi di destra li metto allineati a destra
            & > *
              margin-left: auto
        +res-md-max
          & > *
            margin-left: auto
            margin-right: auto
        
            
  .pills
    margin-bottom: 5rem
  .community
    margin-top: 5rem