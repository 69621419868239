@import public/sass/abstracts

.correlati-card-item
    margin-bottom: 24px
    &:last-child
        margin-bottom: 0
    +res-md-max
        margin-bottom: 20px
    &__tags
        +font(12, 16, 900)
        text-transform: uppercase
        color: $color-secondary-dark
        margin-bottom: 4px
    &__title
        +font(16, 22, 800)
        text-decoration: underline