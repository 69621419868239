.autore-summary
  position: relative
  max-width: $wcont-sportelli-width
  margin-left: auto
  margin-right: auto
  margin-top: 5rem
  &__main
    display: flex
    justify-content: center
    +res-xs-max
      flex-direction: column
  &__content
    display: flex
    flex-direction: column
    padding: 1rem 0
    +res-xs-max
      align-items: center
      text-align: center
  &__fullname
    font-size: 3rem
    font-weight: $font-bold
    line-height: 3.6rem
    margin-bottom: 1rem
  &__type
    font-size: 2rem
    font-family: $font-secondary
    line-height: 2.4rem
    margin-bottom: 2.7rem
    +res-xs-max
      margin-bottom: 2rem
  &__community
    font-size: 1.6rem
    font-weight: $font-bold
    line-height: 2.4rem
    .icon
      vertical-align: bottom
      &--community
        fill: $color-primary
        width: 30px
        height: 30px
        margin-right: 1rem    
  &__links
    display: flex 
    justify-content: center
    margin-top: 5rem
    +res-sm-max
      margin-top: 2rem
      padding-bottom: 2rem
    +res-xs-max
      display: block
      text-align: center
  &__link
    display: inline-block
    color: $color-secondary-dark
    font-size: 2rem
    line-height: 2.4rem
    font-weight: $font-bold
    margin-left: 1.5rem
    margin-right: 1.5rem
    text-decoration: underline 
    cursor: pointer
    &:hover
      color: $color-secondary-dark
      text-decoration: underline
    +res-xs-max
      display: block
      padding: 1rem
  &__content
    span
      color: $color-primary
  &__image-box
    padding-right: 4rem
    +res-xs-max
      text-align: center
      padding-right: 0
      margin-bottom: 1rem
    .icon
      width: 13rem
      height: 13rem
      background: $color-grey-1 
      +fill-icon($color-grey-2)
      +border-radius(1.5rem)
    img
      max-width: 16rem
      max-height: 16rem   
  &--redazionale
    .autore-summary
      &__main
        justify-content: flex-start
      &__content
        align-items: flex-start
        padding: 0
        +res-xs-max
          align-items: center
      &__fullname
        .icon
          width: 2rem
          height: 2rem
          fill: $color-primary
          margin-left: 1rem
      &__image
        width: 16rem
        height: 16rem
        +border-radius(1.5rem)
      &__links
        margin-bottom: 2.5rem
        margin-top: 2rem
        +res-xs-max
          margin-bottom: 0
        a
          &:first-child
            margin-left: 0
      &__bio
        font-family: $font-secondary
        font-size: 1.6rem
        line-height: 2.4rem
        +res-xs-max
          margin-bottom: 2.5rem



