@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap')
@import "./abstracts"
@import "./base/base"
@import "./base/utilities"

.pagebuilder-wrapper
  width: 100%
  height: 100%
  position: relative

  input, textarea, select
    outline: none
    -webkit-appearance: auto !important