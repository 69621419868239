.filter-buttons
  display: flex
  justify-content: center
  &__item
    border: 2px solid $color-grey-14
    font-size: 1.6rem
    line-height: 2.4rem
    color: $color-grey-13
    text-align: center
    padding: .5rem 2rem
    min-width: 14.5rem
    &:first-child
      +border-top-left-radius(2rem)
      +border-bottom-left-radius(2rem)
    &:last-child
      +border-top-right-radius(2rem)
      +border-bottom-right-radius(2rem)
    &:not(:last-child)
      margin-right: -2px