.scarica
  display: block
  background: $color-secondary
  padding: 1rem 0 0 .8rem
  +border-top-left-radius(1.3rem)
  +border-bottom-right-radius(1.6rem)
  width: 4.5rem
  height: 4.5rem
  .icon
    width: 2.6rem
    height: 2.6rem
    fill: #fff!important
