@import public/sass/abstracts

.card-configurazione
  display: flex
  padding: 20px
  padding: 30px 16px
  background: #fff
  +border-radius(12px)
  +res-sm-max
    flex-direction: column
    align-items: center
  &__content
    +res-sm-max
      margin-top: 30px
      text-align: center
    +res-md-min
      margin-left: 30px
      flex: 1
  &__title
    +font(20, 24, 750)
    margin-bottom: 10px
    +res-sm-max
      +font(18, 24, 700)
  &__description
    +font(16, 24, 400)
    margin-bottom: 16px
    +res-sm-max
      margin-bottom: 10px
  &__price
    +font(20, 24, 700)
    color: $tertiary
    +res-sm-max
      margin-bottom: 30px
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none
  &__cta-box
    display: flex
    align-items: center
    justify-content: space-between
    +res-sm-max
      justify-content: center
  &__acquista
    +res-sm-max
      order: 2
  &__dettagli
    +res-sm-max
      order: 1
      margin-right: 20px
  &__modal
    &__content
      padding: 10px 30px 20px 30px
    &__title
      +font(20, 26, 700)
      margin-bottom: 20px
    &__description
      +font(16, 24, 400)
      margin-bottom: 20px