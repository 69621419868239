=typoBase($size, $lineh, $weight: null, $family: $font-primary, $color: null)
  font-size: $size
  line-height: $lineh
  @if $weight
    font-weight: $weight
  @if $family
    font-family: $family
  @if $color
    color: $color

=typoH1
  +typoBase(7.2rem, 8rem, $font-primary-black)

=typoH2
  +typoBase(4rem, 4.8rem, $font-primary-black)
  
=typoH2Mobile
  +typoBase(3.6rem, 4.8rem, $font-primary-black)

=typoH3
  +typoBase(3rem, 4rem, $font-primary-black)

=typoH4
  +typoBase(2.4rem, 3rem, $font-primary-black) 

=typoH5
  +typoBase(2rem, 2.6rem, $font-primary-black) 

=typoTextSm
  +typoBase(1.2rem, 1.4rem) 

=typoTextMd
  +typoBase(1.4rem, 2rem, $font-primary-regular) 

=typoTextLg
  +typoBase(1.6rem, 2.4rem, $font-primary-regular) 

=typoTextXl
  +typoBase(2rem, 2.4rem, $font-primary-regular) 

=typoTextMdSerif
  +typoBase(1.4rem, 2rem, $font-secondary-regular, $font-secondary) 

=typoTextLgSerif
  +typoBase(1.6rem, 2.4rem, $font-secondary-regular, $font-secondary) 

=typoTextXl
  +typoBase(1.8rem, 2.4rem, $font-primary-regular) 

=typoButtonSm
  +typoBase(1.4rem, 2rem, $font-primary-heavy)

=typoButtonMd
  +typoBase(1.6rem, 2.4rem, $font-primary-heavy)