.segui
  display: block
  background: $color-secondary
  padding: 1rem 0 0 .8rem
  +border-top-left-radius(1.3rem)
  +border-bottom-right-radius(1.6rem)
  width: 4.5rem
  height: 4.5rem
  cursor: pointer
  .icon
    width: 2.4rem
    height: 2.4rem
    fill: #fff
  &--follow
    background: #fff
    .icon
      fill: $color-secondary
