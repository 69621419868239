.consigliati-side
  display: flex
  flex-direction: column
  align-items: center
  background: $color-bg
  width: 29rem
  max-width: 100%
  margin: 3rem auto
  padding: 2rem
  +border-radius(.6rem)
  &__title
    font-size: 2rem
    line-height: 3.2rem
    color: $color-secondary-dark
    text-align: center
    margin-bottom: 2rem
  .articolo-card,
  .lezione-card,
    &:not(:last-child)
      margin-bottom: 3rem

@media print
  .consigliati-side
    display: none