.observe-box
  margin: 3rem 0
  &__title
    font-size: 2rem
    font-family: $font-primary
    font-weight: $font-bold
    line-height: 3rem
    text-transform: uppercase
    background: $color-teal-light
    color: $color-teal-dark
    position: relative
    padding: .6rem 2rem .3rem 5rem
    margin-bottom: 2rem
    +border-radius(1.95rem)
    &::before
      content: url(/images/svg/observe.svg)
      position: absolute
      top: .4rem
      left: 1.5rem
  &__text
    @extend .ext-custom-text
    font-family: $font-primary
    font-size: 2rem
    line-height: 3.2rem
    color: $color-teal-dark