@import public/sass/abstracts

.area-personale-contenuti-template
  .area-personale-page
    &__head
      +res-sm-max
        display: flex
        align-items: center
        justify-content: space-between
    &__head-actions
      margin-top: 0
      margin-left: 20px
    &__main
      padding-bottom: 0
      +res-md-min
        display: flex
        flex-direction: column
  &__tabgroup
    margin: 0 -$wcont-padding
    flex: 1
    display: flex
    flex-direction: column
    @media screen and (min-width: 1280px)
      margin-right: calc(624px - 50vw)
    .tab-group
      &__triggers
        @media screen and (min-width: 1280px)
          margin-right: calc(50vw - 624px)
      &__contents
        flex: 1
    .tab
      padding-bottom: 200px
      min-height: 100%
      +res-sm-max
        padding-bottom: 80px
      @media screen and (min-width: 1280px)
        padding-right: calc(50vw - 624px)