@import public/sass/abstracts

.tassonomia
    text-transform: uppercase
    color: $color-secondary-dark
    +font(16, 22, 900)
    margin-bottom: 24px
    &__item
      font-weight: 500
    &__icon
      display: inline-block
      width: 20px
      height: 20px
      margin-right: 12px
      +fill-icon($color-secondary-dark)
      vertical-align: text-bottom