@import public/sass/abstracts

.badgetag
  &__tag
    position: absolute
    top: 0
    right: 0
    background: $tertiary
    color: #FFF
    padding: 6px 12px
    +typoTextMd
    font-weight: $font-primary-heavy
    text-transform: uppercase
    border-radius: 0 12px
    +res-sm-max
      top: -1px
      right: -1px
    &--primary
      background: $color-primary
    &--secondary-dark
      background: $color-secondary-dark
