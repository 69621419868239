@import public/sass/abstracts

.landing-riviste-slider
  color: #FFF
  &__pagination
    margin-top: 63px
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-around
    +res-sm-max
      display: none

  &__pagination-item
    border: 2px solid currentColor
    border-radius: 60px
    padding: 24px 35px
    +typoBase(22px, 30px, 800)
    letter-spacing: 0.07em
    text-transform: uppercase
    cursor: pointer
    color: inherit
    margin: 0 8px 10px
    +res-md-max
      +typoBase(14px, 19px, 800)
      padding: 16px 24px
    &--active
      cursor: default
      background: #fff
      color: #000
      border-color: #FFF

  &__slider
    padding: 72px 0 84px
    margin-top: 28px
    border-radius: 50px
    background: rgba(#FFF, .7)
    @media screen and (min-width: 1440px)
      margin-left: -84px
      margin-right: -84px
    +res-sm-max
      padding: 40px 16px

    .slick-dots
      +res-md-min
        text-align: left
        padding-left: 40px
      +res-sm-max
        padding-left: 0
      @media screen and (min-width: 1440px)
        padding-left: 84px

      > *
        button
          color: inherit
          width: 15px
          height: 15px
          +border-radius(50%)
          cursor: pointer
          &::before
            display: none
          &:not(.u-landing-riviste-bg-default)
            background: $color-primary
        &.slick-active
          button
            background: #000

  &__slide
    &-layout
      +res-md-min
        display: flex
        padding: 0 40px 20px
        > *
          flex: 1 0 0
          &:not(:last-child)
            margin-right: 5%
      +res-sm-max
        padding: 0 16px 20px
      @media screen and (min-width: 1440px)
        padding: 0 84px 20px

    &-content
      color: #000
      > *
        &:not(:last-child)
          margin-bottom: 26px
    &-title
      +typoBase(36px, 55px, 800)
      letter-spacing: -0.01em
      +res-md-max
        +typoBase(28px, 55px, 800)
      +res-sm-max
        text-align: center
    &-description
      +typoBase(22px, 30px, 500)
      +res-md-max
        +typoBase(14px, 19px, 500)
    &-zoom
      display: flex
      align-items: center
      color: #767E84
      +res-sm-max
        display: none
      > *
        &:not(:last-child)
          margin-right: 12px
      &-icon
        svg
          +style-icon(22px, 21px, currentColor)
      &-label
        +typoBase(20px, 27px, 500)
        font-style: italic
        +res-md-max
          +typoBase(14px, 19px, 500)
    &-image-box
      display: block
      cursor: pointer
      +res-sm-max
        width: 100%
      > img
        display: block
        margin: 0 auto
        max-height: 350px
        cursor: pointer!important
      &--no-zoom
        cursor: default
        > img
          cursor: default!important

  &__zoom-modal.wmodal
    max-width: 100%
    img
      display: block
      margin: 0 auto
      max-height: calc(100vh - 65px)