@import public/sass/abstracts
  
.wfocus-box
  padding: 1.5rem 3rem
  border-left: .4rem solid $color-primary
  margin-top: 3rem
  margin-bottom: 3rem
  @extend .ext-custom-text !optional
  font-family: $font-secondary
  font-size: 2.4rem
  font-weight: $font-regular
  line-height: 4rem