@import public/sass/abstracts

.rivista-card-wide-abbonato
    display: flex
    background-color: $grey-0
    +border-radius(16px)
    align-items: center
    justify-content-center
    margin-bottom: 30px
    +res-sm-max
      display: block
    &__content-title-mobile
      display: none
      +res-sm-max
        display: block
        margin-left: 16px
    &__title-numero-mobile
      +typoH5
    &__numero-mobile
      +typoTextMd 
  
   
    &__title
      +typoH3
      margin-bottom: 15px
      +res-sm-max
        +typoH4
    &__cta
      color: #0375f8
      +typoButtonMd

    &__actions
      +typoTextLg
      font-weight: 700
      color: #0375f8
      margin-bottom: 15px
    &__container-scadenza
      padding: 16px
      +border-radius(8px)
      border: 1px solid $grey-1
    &__status
      +typoTextLg
      font-weight: 400
      color: #000000
    &__badges
        margin-bottom: 12px
        .badge 
          margin-right: 10px
    &__member-cta
        margin-top: 12px
    &__container__content-scadenza
      flex: 1
      padding: 23px 25px 23px 30px
      +res-sm-max
        padding: 20px 20px 15px 20px
    
    &__container__content-sfoglia
      display: flex
      padding: 30px 37px 30px 0
      flex: 1
      +res-sm-max
        padding: 20px 20px 30px 20px
        display: inline-block
    &__image-box
        margin-right: 30px
        +res-sm-max
          display: flex
          align-items: center
    &__numero
      +typoTextMd 
      +res-sm-max
        display: none
    &__title-numero
      margin-bottom: 16px
      +typoH5
      +res-sm-max
        display: none
      
    &__descrizione-numero
      +typoTextMd
      margin-bottom: 16px
      +res-sm-max
        margin-top: 16px
    &__content-box
      flex: 1
    &__cta-numero 
      margin-left: 20px
      color: #0375f8
      +typoButtonMd
      

