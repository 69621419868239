@import public/sass/abstracts

.widget-card
  padding: 25px 20px
  background: $grey-0
  border-radius: 20px
  +res-sm-min
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 20px
  &__content
    +res-sm-min
      width: calc(33.33% - 10px)
    +res-xs-max
      margin-bottom: 30px
    > *
      &:not(:last-child)
        margin-bottom: 16px
  &__title
    +typoH5
  &__description
    +typoTextLg
  &__card
    +res-sm-min
      width: calc(66.66% - 10px)
    +res-xs-max
      margin: 0 -20px -25px