.pagebuilder-slider
  display: flex
  justify-content: center
  margin: 4rem 0
  .slick-slider
    max-width: 92%
    .slick-prev
      &::before
        content: url(/images/svg/chevron-sharp-left.svg)  // da cambiare
    .slick-next
      transform: rotate(180deg) translateY(50%)
      &::before
        content: url(/images/svg/chevron-sharp-right.svg)  // da cambiare
    .slick-list
      & > div
        margin-left: 0
      .slick-track
        .slick-slide
          display: inline-block
          max-width: 22rem
          &:not(:last-child)
            margin-right: 2rem
.fake-slider
  display: flex
  overflow-x: auto
  height: 25rem
  max-height: 25rem
  width: 80rem
  max-width: 80rem
  img 
    max-width: 25rem
    max-height: 25rem
    margin-right: 1.5rem
