@import public/sass/abstracts

.checkout-indirizzo-form
  &--boxed
    border: 1px solid $grey-1
    border-radius: 10px
    padding: 20px

  &__title
    +typoTextMd
    font-weight: $font-primary-heavy
    margin-bottom: 20px
  
  &__privacy-field
    flex: 1

  &__required-label
    +typoTextSm
    +res-md-min
      margin-left: 20px
      align-self: center
      text-align: right
    +res-sm-max
      margin-top: 20px

  &__head
    margin-bottom: 20px

  &__actions
    margin-top: 20px
    display: flex
    align-items: center
    justify-content: flex-end
    > *
      &:not(:last-child)
        margin-right: 20px
    +res-sm-max
      justify-content: space-between