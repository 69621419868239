.rivista-page
  &__header
    text-align: center
    &__image
      display: block
      margin-left: auto
      margin-right: auto
      margin-bottom: 1.5rem
    &__title
      +typoTextXl
      margin-bottom: 1.5rem
      color: #000000
      text-transform: uppercase
      +res-sm-max
        +typoTextLg
    &__description
      +typoH2
      margin-bottom: 5rem
      color: #000000
      +res-sm-max 
        margin-bottom: 1rem
  &__listing 
    margin-bottom: 28rem
    +res-sm-max
      margin-bottom: 9.8rem
      

 

      