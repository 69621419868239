#footer
  background: $color-primary
  position: relative
  margin-top: -$footer-negative-margin // per avere il vuoto del bordo del colore del div precedente
  +border-top-left-radius(3rem)
  z-index: 2 // per andar sopra la sidebar dell'area personale che deve andare sopra al main
  +res-md-max
    text-align: center
  .footer-credits
    background: $black
    +border-top-left-radius(3rem)
    +res-lg-min
      position: absolute
      right: 0
      bottom: 0
      height: 8.4rem
      width: 75%
    @media (min-width: 1281px)
      width: calc(50% + 32rem)
    +res-md-max
      width: 100%
    &__content
      width: 100%
      height: 100%
      padding: 2rem 3rem
      @media (min-width: 1281px)
        width: 96rem
      +res-lg-min
        display: flex
        justify-content: space-between
        align-items: center
      +res-md-max
        text-align: center
    &__logos
      +res-lg-min
        display: flex
        justify-content: flex-start
        align-items: center
      +res-md-max
        margin-bottom: 2rem
    &__logos-item
      +res-lg-min
        flex: 1
        &:not(:last-child)
          margin-right: 2rem
      +res-md-max
        padding: .5rem 0
        display: block
        margin: 0 auto
      .icon
        width: 17rem
        height: 3rem
        display: block
        margin: 0 auto
    &__copy
      color: #fff
      font-size: 1rem
      opacity: .8
      +res-lg-min
        padding-left: 1.5rem
    &__links
      display: flex
      margin-left: auto
      +res-md-max
        justify-content: center
        margin-top: 2rem
    &__link
      position: relative
      a 
        display: block        
        padding: 0 1rem
        font-size: 1.4rem
        font-weight: $font-bold
        color: #fff
        text-decoration: none
        cursor: pointer
        +res-lg-max
          font-size: 1.2rem
        +res-md-max
          font-size: 1.4rem
      &:not(:last-child)
        padding-right: 1px
        &::after
          content: ""
          position: absolute
          top: .4rem
          width: 1px
          right: -1px
          height: 1.5rem
          background: $color-primary

.footer
  &__content
    @extend .w-container
  &__logo
    &-link
      display: block
    .icon
      +fill-icon(#fff)
      width: 23.7rem
      height: 5.7rem
      max-width: 100%
      +res-md-max
        width: 16.15rem
        height: 3.9rem
  &-menu
    &__item
      a
        display: block
        font-size: 1.4rem
        line-height: 1.7rem
        color: #fff
        text-transform: uppercase
        padding: 1rem

  +res-md-max
    &__logo
      &-link
        padding: 2rem 0 1rem 0

  +res-lg-min
    &__content
      display: flex
      height: 16.8rem
    &__logo
      flex: 1 0 23%
      align-self: center
      padding-right: 3rem
    &__center
      flex: 1 0 52%
      padding-bottom: 8.4rem
      align-self: center
    &__right
      flex: 1 0 20%
      padding-bottom: 8.4rem
      align-self: center
    &-menu
      display: flex
      align-items: center
      flex-wrap: wrap

  &__social
    display: flex
    justify-content: flex-end
    &-item
      position: relative
      flex: 0 0 3rem
      +border-radius(50%)
      background: #fff
      width: 3rem
      height: 3rem
      padding: 0
      border: 2px solid #fff
      +box-shadow(0 .3rem .6rem rgba(#000, .3))
      &:not(:last-child)
        margin-right: 1rem
      .icon
        position: absolute
        top: -2px
        left: -2px
        right: -2px
        bottom: -2px
        width: 3rem
        height: 3rem
        +fill-icon($color-primary)
        display: block
        &--linkedin
          width: 2rem
          height: 2rem
          top: 3px
          left: 3px
        &--pinterest
          top: 2px
          left: 1px
          width: 2.3rem
          height: 2.3rem
      &:hover
        background: $color-primary
        .icon
          +fill-icon(#fff)

    +res-md-max
      justify-content: center
      padding: 2rem 0

// Quando c'è direttamente l'actionbar prima del footer correggo con hack
.actionbar + #footer 
  margin-top: 0
  position: relative
  &::before
    content: ""
    position: absolute
    left: 0
    right: 0
    top: 0
    height: 3rem
    z-index: -1
    background: $color-secondary

    


@media print
  #footer
    display: none

