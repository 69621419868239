@import public/sass/abstracts

.numero-rivista-card
  width: 200px
  &__data
    margin-top: $wcont-padding
    margin-bottom: 4px
    text-transform: uppercase
    +font(14, 20, 400)
  &__title
    +font(18, 24, 700)