@import public/sass/abstracts

.area-personale-menu-item
  &__trigger
    display: flex
    align-items: center
    cursor: pointer
    width: 100%
    +typoBase(1.4rem, 2.4rem, $font-primary-medium, $font-primary)
    .badge
      margin-left: .8rem
    
  &__icon
    +style-icon(20px, 20px, currentColor)
    margin-right: 12px

  &__badge
    margin-left: 8px

  &__toggle-icon
    margin-left: auto
    svg
      +style-icon(12px, 12px, currentColor)

  &__list
    max-height: 0
    overflow: hidden
    +transition(max-height .5s linear)

  &--level-1
    border-left: 3px solid transparent
    +transition(border-color .5s linear)
    &.area-personale-menu-item--open
      border-left-color: $black
    .area-personale-menu-item
      &__trigger
        padding: 16px

  &--level-2
    .area-personale-menu-item
      &__trigger
        padding: 10px 20px 10px 48px
        
  &--open
    .area-personale-menu-item
      &__list
        max-height: 300px

  &--active
    > .area-personale-menu-item
      &__trigger
        font-weight: $font-primary-black

.area-personale-menu
  &__group
    &:not(:last-child)
      padding-bottom: 6px
      border-bottom: 1px solid $grey-2
      margin-bottom: 8px
    > *
      &:not(:last-child)
        margin-bottom: 8px
  &__section
    +font(12, 16, 500)
    color: $grey-2
    text-transform: uppercase