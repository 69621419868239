@import public/sass/abstracts

.cart-panel-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 100%
  background: rgba(42, 42, 42, 0.1)
  z-index: 9998

.cart-panel
  position: fixed
  right: 0
  width: 100%
  max-width: 376px
  +translateX(100%)
  +transition(transform .3s ease-in-out)
  background: $white
  padding: 64px 20px 20px
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1)
  display: flex
  flex-direction: column
  height: 100%
  z-index: 9999
  &--open
    +translateX(0)
  &__close
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    svg
      +style-icon(24px, 24px, $black)
  &__title
    +typoH4
    margin-bottom: 20px
  &__num
    +typoTextLg
    font-weight: $font-primary-medium-2
    vertical-align: middle
  &__scrollable
    overflow-y: auto
    +scrollbar-style2
    position: relative
    padding-right: 10px
  &__bottom-shadow
    position: relative
    &::before
      content: ""
      position: absolute
      left: 0
      right: 0
      height: 30px
      z-index: 1
      bottom: 0
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
  &__notification-bar
    height: 46px
    font-size: 12px
    margin-bottom: 10px
  &__products
    padding-bottom: 10px
    > *
      &:not(:last-child)
        margin-bottom: 10px
  &__riepilogo
    margin-top: auto
  &__bottom
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 16px
    > *
      flex: 1
      padding-left: 0
      padding-right: 0
      &:not(:last-child)
        margin-right: 10px

body
  &.w-toolbar
    .cart-panel
      top: $toolbar-height
      height: calc( 100% - #{$toolbar-height} )
