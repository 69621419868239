.button
  font-family: $font-primary
  font-size: 1.6rem
  font-weight: $font-bold
  line-height: 2rem
  display: inline-block
  cursor: pointer
  &__arrow
    display: inline-block
    margin-left: 1rem
    width: 1.7rem
    height: .9rem
    +fill-icon(currentColor)
    vertical-align: middle
  &:disabled
    cursor: default
    opacity: .5
  &.disabled
    opacity: .5
  &--primary
    position: relative
    color: #fff
    background: $color-secondary
    padding: 1.4rem 2.5rem
    &.button--inverted
      background: #fff
      color: $color-secondary
    &.button--orange
      background: $color-primary
      &.button--inverted
        background: #fff
        color: $color-primary
    &.button--red
      background: $color-red
      &.button--inverted
        background: #fff
        color: $color-red
    &.button--black
      background: #000
      &.button--inverted
        background: #fff
        color: #000
    &.button--beige
      background: $color-bg
      color: $color-text
    &.button--icon
      padding: .75rem
      width: 3rem
      height: 3rem
      +border-radius(50%)
      .icon
        display: block
        width: 1.5rem
        height: 1.5rem
        +fill-icon($color-secondary)
      &.button--inverted
        .icon
          +fill-icon($color-secondary)
  &--secondary
    padding: 1.2rem 2.8rem
    border: 2px solid $color-secondary
    color: $color-secondary
    &.button--inverted
      border-color: #fff
      color: #fff
    &.button--red
      border-color: $color-red
      color: $color-red
    &.button--black
      border-color: #707070
      color: #707070
    &::after
      top: -2px
      left: -2px
      right: -2px
      bottom: -2px

  &--facebook, &--google, &--zainodigitale
    padding: 1.2rem 3rem
    color: #fff
    border-color: #fff
    +border-radius(2.4rem)
    line-height: 2.4rem
    .icon
      display: inline-block
      margin-right: .5rem
      width: 2.4rem
      height: 2.4rem
      +fill-icon(#fff)
      vertical-align: middle

  &--facebook
    background: $color-facebook

  &--google
    background: $color-secondary

  &--zainodigitale
    background: $color-zainodigitale
    .icon
      max-width: 5.4rem
      max-height: 2.8rem
      width: 100%
      height: auto

  &--primary, &--secondary, &--facebook, &--google, &--zainodigitale
    +border-radius(2.4rem)
    position: relative
    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      +box-shadow(0 .2rem .4rem #cccac6)
      +border-radius(2.4rem)
      opacity: 0
      +transition(opacity .2s)
      mix-blend-mode: multiply
    &:hover:not(:disabled)
      &::after
        opacity: 1
    &.button--inverted
      &:disabled
        opacity: .7

  &--tertiary
    color: $color-secondary
    font-weight: $font-regular
    &:not(:disabled):hover
      font-weight: $font-bold
    .icon
      +fill-icon($color-secondary)
    &.button--inverted
      color: #fff
      .icon
        +fill-icon(#fff)
    &.button--icon
      width: 3rem
      height: 3rem
      +border-radius(50%)
      .icon
        display: block
        width: 1.8rem
        height: 2.4rem
        margin: auto
    &.button--icon-text
      span
        display: block
        font-size: .7rem
        font-weight: $font-bold
        line-height: .9rem
        text-align: center
        text-transform: uppercase
        margin-top: .5rem
      .icon
        display: block
        width: 2.4rem
        height: 2.4rem
        margin-left: auto
        margin-right: auto

  &--10
    margin-top: 10px!important
    margin-bottom: 10px!important
