.video-home2021
  position: relative
  background: $color-bg
  padding: 73px 0
  +res-sm-max
    padding-bottom: 68px
  &__title
    @extend .ext-heading2
    text-align: center
    margin-bottom: 42px
    +res-sm-max
      margin-bottom: 32px
  &__slider
    position: relative
    z-index: 1
    .slick-dots
      bottom: -44px
      font-size: 12px
      line-height: 12px
      li 
        width: 12px 
        height: 12px
        margin: 0 6px
        button
          background: #BBBBBB
          border-radius: 50%
          width: 12px 
          height: 12px
          &::before
            display: none
        &.slick-active
          button
            background: $color-secondary
  &__item
    padding: 0 7.5px
    max-width: 330px
    margin: 0 auto
    &__image-box
      position: relative
      border-radius: 20px
      height: 223px
      margin-bottom: 20px
      overflow: hidden
    &__image
      position: absolute
      top: 0      
      height: 100%
      max-width: none
      left: -9999px
      right: -9999px
      margin: 0 auto
    &__player-icon
      position: absolute
      top: calc(50% - 36px)
      left: 0
      right: 0
      z-index: 1
      cursor: pointer
      svg
        +style-icon(72px, 72px, #fff)
        margin: 0 auto
    &__title
      font-size: 2.4rem
      font-weight: $font-bold
      line-height: 3rem
      margin-bottom: 1rem
      cursor: pointer
    
  &__illustration
    position: absolute
    right: 0
    left: calc(50% + 300px)
    top: 0
    z-index: 0
    +translateY(-50%)
    +res-md-max
      display: none
    img
      display: block
      margin-left: auto
      max-height: 300px
      +res-lg-max
        max-height: 200px

