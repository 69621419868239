@import public/sass/abstracts

.formazione-corsi-listing
  &__list
    max-width: 100vw
    width: 100%
    overflow: hidden
    display: flex
    flex-flow: column
    align-items: center
    > *
      &:not(:last-child)
        margin-bottom: 36px
        +res-sm-max
          margin-bottom: 30px
    &__filters
      display: flex
      justify-content: center
      &--desktop
        +res-sm-max
          display: none
      &--mobile
        +res-md-min
          display: none!important
      &__item
        &:not(:last-child)
          margin-right: 10px
