@import public/sass/landing-riviste/abstracts

.landing-riviste-cartacea
  position: relative
  font-family: $font-primary
  color: #000
  padding: 35px 0 83px
  +res-sm-max 
    padding: 18px 0 33px

  &__bg
    position: absolute
    top: 0
    width: 25%
    left: -1%
    z-index: 0
    +res-sm-max
      display: none
    svg
      +style-icon(100%, 100%, rgba(#fff, .17))
      color: rgba(#fff, .17)

  &__content, &__list
    position: relative
    z-index: 1

  &__title
    display: flex
    justify-content: center
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 16px
  &__title-icon
    svg
      +style-icon(58px, 75px, currentColor)
      +res-md-max
        +size-icon(30px, 39px)
  &__title-label
    +typoH4MaiuscoloLR
    margin-top: 5px
    +res-md-max
      +typoBase(18px, 24px)

  &__description
    text-align: center
    margin-top: 52px
    +typoBase(28px, 38px)
    +res-md-max
      margin-top: 33px
      +typoBase(18px, 24px)