@import public/sass/abstracts

.bundle-panel
  position: fixed
  top: 0
  right: 0
  width: 100%
  +translateX(100%)
  +transition(transform .3s ease-in-out)
  background: $white
  padding: 30px 20px
  display: flex
  flex-direction: column
  height: 100%
  z-index: 9999
  &__close
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    svg
      +style-icon(24px, 24px, $black)
  &__title
    +font(20, 26, 800)
    margin-bottom: 20px
  &__description
    +font(16, 24, 400)
    margin-bottom: 20px
  &__items
    overflow: auto
    flex: 1

body
  &.w-toolbar
    .bundle-panel
      top: $toolbar-height
      height: calc( 100% - #{$toolbar-height} )
