.register-box
  position: relative
  margin-bottom: 4rem
  // margin-top: 4rem
  color: #fff
  background-color: $color-primary
  background-image: url("/images/demo/logo_iopiu.svg")
  background-repeat: no-repeat
  +border-radius(.6rem)
  +print
    page-break-inside: avoid

  &--articolo
    background-position: 109% 140%
    text-align: center
    .register-box
      &__content
        padding: 5rem 1.5rem
        +res-sm-max
          padding: 3rem 1.5rem
      &__title
        margin-bottom: 3rem
      &__description
        margin-bottom: 3rem
      &__cta-box
        justify-content: center
      &__cta
        &--abbonamento
          +res-md-min
            margin-right: 1.6rem
          +res-sm-max
            margin-bottom: .8rem

  &--strumento
    background-position: 101% 122%
    .register-box
      &__content
        display: flex
        justify-content: space-evenly
        align-items: center
        padding: 8rem 1.5rem
        +res-sm-max
          padding: 3rem 1.5rem
          flex-direction: column
          text-align: center
      &__title
        max-width: 50rem
        +res-sm-max
          margin-bottom: 3rem
      &__description
        margin-bottom: 3rem
      &__cta-box
        justify-content: flex-start
        +res-sm-max
          justify-content: center
      &__cta
        &--abbonamento
          +res-md-min
            margin-right: 1.6rem
          +res-sm-max
            margin-bottom: .8rem

  &__content
    width: 100%

  &__title
    font-size: 4rem
    line-height: 4.8rem
    font-weight: $font-bold
    +res-sm-max
      font-size: 3rem
      line-height: 4rem

  &__description
    font-size: 1.8rem
    line-height: 2.4rem

  &__cta-box
    display: flex
    align-items: center
    +res-sm-max
      flex-direction: column
