@import public/sass/abstracts

.badge
  display: inline-block
  background-color: $color-primary
  border-radius: 8px
  padding: 4px 6px
  +typoBase(1rem, 1rem, $font-primary-black, $font-primary, $white)
  text-transform: uppercase

.blue
  background-color: $color-secondary-dark
  text-transform: none

.grey
  background-color: transparent 
  text-transform: none
  color: $grey-3
  border: 1px solid #4F4A41