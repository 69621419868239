.pagination
  display: block
  text-align: center
  padding: 2rem 0
  &__item
    display: inline-block
    vertical-align: middle
    padding: 0 .8rem
    text-align: center
    margin: 0 .5rem
    font-size: 1.4rem
    line-height: 2.4rem
    text-align: center
    font-weight: $font-bold
    color: $color-secondary
    background: $color-secondary-light
    text-decoration: none
    cursor: pointer
    +border-radius(.8rem)
    +res-sm-max
      margin: 0 .3rem
    &:hover, &:active, &:focus, &:visited, &:link
      color: $color-secondary
      text-decoration: none
    &--active, &:active
      color: #fff
      background: $color-secondary
      &:hover
        color: #fff
    &--active, &--separator
      cursor: default
    &--separator
      background: transparent
      &::before
        content: "..."

    &--nomobile
      +res-sm-max
        display: none

  &__nav
    display: inline-block
    vertical-align: middle
    padding: .2rem .5rem
    font-size: 1.6rem
    font-weight: $font-bold
    line-height: 2.4rem
    color: $color-secondary
    cursor: pointer
    &:hover, &:active, &:focus, &:visited, &:link
      color: $color-secondary
    span
      display: inline-block
      line-height: 2.4rem
    +res-sm-max
      padding: .2rem 0
    .icon
      width: 2.4rem
      height: 2.4rem
      padding: .65rem .8rem
      display: inline-block
      +fill-icon($color-secondary)
      vertical-align: bottom
      +res-sm-max
        padding: .65rem 0

    &--prev
      .icon
        +rotate(180deg)
      +res-xs-max
        margin-right: .5rem
    &--next
      +res-xs-max
        margin-left: .5rem

    &--disabled
      opacity: .6
      cursor: default
      pointer-events: none

