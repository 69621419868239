@import public/sass/abstracts

.area-personale-riepilogo-ordine-card
  background: $white
  padding: 30px
  border-radius: 12px
  > *
    &:not(:last-child)
      margin-bottom: 30px

  &__section-title
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
    margin-bottom: 10px

  &__list
    > *
      &:not(:last-child)
        margin-bottom: 10px

  &__rows
    +typoTextSm
    > *
      &:not(:last-child)
        margin-bottom: 20px

  &__row
    display: flex
    align-items: center
    justify-content: space-between
    &--promo
      color: $color-secondary
      font-weight: 700

  &__row-label
    display: flex
    align-items: center
    svg
      +style-icon(20px, 20px, currentColor)
      margin-right: 10px

  &__totale
    margin-top: 20px
    border-top: 1px solid $grey-2
    padding-top: 20px
    display: flex
    align-items: center
    justify-content: space-between
    +typoTextLg
    font-weight: $font-primary-heavy