@import public/sass/abstracts

.formazione-modulo-iscrizione
  width: 100%
  background: white
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
  border-radius: 12px
  &__button
    margin-bottom: 20px
    padding-top: 20px
    padding-left: 20px
    padding-right: 20px
    &--external
      padding-bottom: 20px
    button
      width: 100%
    a
      width: 100%
  &__data
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: $color-text
    padding-bottom: 20px
    padding-left: 20px
    padding-right: 20px
    span
      font-weight: bold
    &--box-guest
      background: $color-grey-12
    a
      color: $color-secondary
      cursor: pointer
      text-decoration: underline
