.hero-home2021
  &__slider
    .slick-dots
      z-index: 10
      li
        button
          background: #fff
          border-radius: 50%
          &::before
            display: none
        &.slick-active
          button
            background: $color-secondary
      +res-md-min
        top: 66px
        bottom: auto
        left: 16px
        right: auto
        width: auto
        @media screen and (min-width: 1014px)
          left: calc(50% - 507px)
        li
          width: 9px
          height: 9px
          button
            width: 9px
            height: 9px
      +res-sm-max
        bottom: 23px
        li
          width: 7px
          height: 7px
          button
            width: 7px
            height: 7px

  &__item
    height: 422px
    position: relative
    overflow: hidden
    +res-sm-max
      height: 351px
      text-align: center
    &__image-box
      position: absolute
      top: 0
      bottom: 0
      left: -9999px
      right: -9999px
      margin: 0 auto
      &--desktop
        width: 1920px
        @media screen and (max-width: 1280px)
          display: none
      &--tablet
        width: 1280px
        @media screen and (min-width: 1281px)
          display: none
        +res-sm-max
          display: none
      &--mobile
        width: 767px
        +res-md-min
          display: none

    &__content
      position: absolute
      top: 116px
      left: 16px
      color: #FFF
      z-index: 5
      @media screen and (min-width: 1014px)
        left: calc(50% - 507px)
      +res-md-min
        right: 50%
      +res-sm-max
        top: auto
        right: 16px
        bottom: 61px

    &__title
      font-size: 4rem
      font-weight: $font-bold
      line-height: 4.8rem
      margin-bottom: 22px
      +res-sm-max
        font-size: 3.2rem
        line-height: 3.9rem
        margin-bottom: 5px

    &__description
      font-size: 1-8rem
      line-height: 2.4rem
      margin-bottom: 46px
      +res-sm-max
        margin-bottom: 55px
