@import public/sass/abstracts

.widget-agente
  border-radius: 20px
  background: $grey-0
  padding: 25px 20px
  +res-sm-min
    display: flex
    align-items: center
  &__content
    +res-sm-min
      width: 60%
      padding-right: 20px
  &__title
    +typoH5
    margin-bottom: 16px
    +res-xs-max
      text-align: center
  &__name
    +typoBase(1.8rem, 2.4rem, $font-primary-black-2)
    margin-bottom: 16px
    +res-xs-max
      text-align: center
      margin-bottom: 32px
  &__contacts
    > *
      &:not(:last-child)
        margin-bottom: 16px
  &__contact
    display: flex
    align-items: center
    &__icon
      margin-right: 10px
      svg
        +style-icon(20px, 20px, currentColor)
    &__value
      +typoTextMd
  &__actions
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    > *
      &:not(:last-child)
        margin-bottom: 16px
    &__wa
      +res-md-min
        display: none
    +res-xs-max
      margin-top: 32px