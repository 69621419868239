@import public/sass/abstracts

.scrollup
  position: fixed
  display: flex
  justify-content: center
  align-items: center
  bottom: 0
  right: 2rem
  width: 56px
  height: 56px
  background: $color-secondary
  z-index: 999
  +border-radius(50%)
  +box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))
  +translateY(100%)
  +transition(300ms)
  +res-md-min
    display: none
  &--visible
    bottom: 2rem
    +translateY(0)
  .icon
    width: 30px
    height: 30px
    fill: #fff