@import public/sass/abstracts

.area-personale-dati-template
  background: $grey-0!important
  &__top-cards
    +res-md-min
      display: flex
      align-items: center
      margin-bottom: 57px
      > *
        width: calc(50% - 10px)
        &:not(:last-child)
          margin-right: 20px
    +res-sm-max
      margin-bottom: 40px
      > *
        &:not(:last-child)
          margin-bottom: 40px