@import public/sass/abstracts

.area-personale-add-scuola-modal
  max-width: 807px
  .wmodal
    &__title
      text-align: center
    &__body
      max-width: 600px
      margin: 0 auto
  .form-layout
    > .form-field
      &--layout-md
        width: 50%