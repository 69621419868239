.formazione-page
  background: black
  &__header
    text-align: center
    &__title
      @extend .ext-heading1
      color: white
      margin-top: 2rem
      margin-bottom: -1rem
  &__content
    &--in-corso
      position: relative
      z-index: 3
      margin-top: -70px
      padding-top: 40px
    &__abstract
      @extend .ext-abstract-medium
      text-align: center
      max-width: 100rem
      margin-left: auto
      margin-right: auto
      a
        color: $color-secondary

  &__tabs
    background: $color-bg
    padding-bottom: 5.5rem
    +border-top-left-radius(9.6rem)
    +res-sm-max
      +border-top-left-radius(2.4rem)
  &__navigation
    margin-top: 8rem
    padding-left: 0
    +res-sm-max
      display: none
    &__item
      display: flex
      justify-content: center
      flex-direction: column
      height: 6.6rem
      padding-right: 3rem
      padding-left: 3rem
      color: $color-secondary-dark
    .active
      background: $color-bg-current
      border-radius: 0 4rem 4rem 0
