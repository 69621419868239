.listing-shop
    &__container
        @extend .w-container
        padding-right: 0 // per "scrollabilità" slider filtri
    &__titolo
        +font(40, 48, 800)
        margin: 25px 0
        color: $color-secondary-dark
        +res-sm-max
            margin: 15px 0 10px 0
    &__descrizione
        margin-bottom: 20px
        line-height: 26px
        font-size: 20px
        font-weight: 400
    &__filtri
        margin-bottom: 50px
        display: flex
        flex-flow: column
        gap: 10px
        +res-sm-max
            margin-bottom: 40px
    &__classi
        margin-bottom: 20px
        +res-sm-max
            margin-bottom: 24px
    &__round-corner
        background: $grey-0
        padding: 50px $wcont-padding 125px $wcont-padding
        overflow: hidden
        +border-top-left-radius($border-radius-big)
        +border-top-right-radius($border-radius-big)
        +res-sm-max
            +border-top-left-radius($border-radius-medium)
            +border-top-right-radius($border-radius-medium)
            padding: 30px 0 125px 0
    &__list
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: $wcont-padding
        margin: 0 auto
        max-width: $wcont-width
        .card-adozionale
            background: #fff
            +res-sm-max
                background: $grey-0
                max-width: 163px
                padding: 0
