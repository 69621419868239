@import public/sass/abstracts

.correlati-card
    padding: 30px
    background: #fff
    +border-radius(12px)
    width: 320px
    margin-right: 27.5px
    &:last-child
        margin-right: 0
    +res-md-max
        width: 100%
        margin-right: 0
        margin-bottom: 20px
    &__title
        +font(20, 24, 800)
        margin-bottom: 24px
        .icon
            width: 30px
            height: 30px
            margin-right: 12px
            vertical-align: middle