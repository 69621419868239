.share-buttons
  display: flex
  justify-content: space-between
  &__button
    margin-right: 1.2rem
    svg
      +box-shadow(0 .2rem .4rem 0 #cccac6)
      +border-radius(3rem)
      &:hover
        +box-shadow(0 .4rem .7rem 0 #cccac6)
        