@import public/sass/abstracts

.area-personale-riviste-template
  &__richiedi
    margin-bottom: 40px
    &__description
      +typoTextLg
      margin-bottom: 16px
  &__inserto
    margin-bottom: 20px
  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(12px)
      > *
        width: 50%
