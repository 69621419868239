@import public/sass/abstracts

.formazione-corso-card
  position: relative
  max-width: 955px
  width: 100%
  margin-bottom: 20px
  +res-md-min
    padding-right: 107px
  +res-sm-max
    background: #fff
    border-radius: 16px
    overflow: hidden
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
  &--image-inside
    max-width: 1062px
    max-height: 280px
    width: 100%
    +res-md-max
      max-height: revert
  &__bg
    +res-md-min
      display: flex
      border-radius: 30px
    +res-sm-max
      background: none!important

  &__image-box
    +res-md-min
      flex: 0 0 294px
      border-radius: 30px 0 0 30px
      overflow: hidden
    +res-sm-max
      height: 230px
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none
    &--image-inside
      margin-right: 26px
      width: 200px
      height: 200px
      flex: 0 0 200px
      img
        max-width: 200px
        max-height: 200px
        width: 100%
        height: 100%
    &--detail
      img
        +border-radius(20px)
    img
      width: 100%
      height: 100%
      display: block
      object-fit: cover

  &__main
    +res-md-min
      margin: 30px -107px 30px 0
      border-radius: 12px
      background: #fff
      padding: 30px
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
      max-width: 660px
    +res-sm-max
      padding: 20px
    > *
      &:not(:last-child)
        margin-bottom: 12px
    &--image-inside
      display: flex
      text-align: left
      +res-md-max
        flex-flow: column
      +res-md-min
        max-width: 1062px
        padding: 40px
        padding-bottom: 28px  // 40-12 assorbe il margin-bottom dell'immagine

  &__tags
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: 12px
    > *
      &:not(:last-child)
        margin-right: 10px

  &__tag
    padding: 4px 8px
    background: $grey-0
    border-radius: 4px
    +typoTextLg
    font-weight: $font-primary-medium-2
    +res-md-min
      margin-bottom: 10px
    &--tipologia
      color: $tertiary
      font-weight: $font-primary-heavy

  &__title
    +typoH4
    margin-bottom: 12px
    &--detail
      +font(40, 48, 800)
      margin-bottom: 16px
      +res-sm-max
        +font(36, 48, 800)

  &__description
    +typoTextLgSerif
    +res-sm-max
      +typoTextMdSerif
    &--detail
      +font(16, 24, 400)
      font-family: $font-primary
    &--listing
      @extend .overflow-ellipsis
      -webkit-line-clamp: 2
      +res-sm-max
        -webkit-line-clamp: unset

  &__cta-box
    margin-top: 12px
    > *
      +res-sm-max
        width: 100%
