.ROOT
  .block-menuitem
    &:not(:last-child)
      margin-bottom: 0
  
.ROOT--editing
  .block-menuitem
    min-height: 20px
    padding: 10px
    &:not(:last-child)
      margin-bottom: 1.2rem