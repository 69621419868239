@import public/sass/abstracts

.box-adozione-corso
  background: $color-discipline-irc
  padding: 60px 0 90px 0
  +res-sm-max
    padding: 40px 0 70px 0
  &__container
    @extend .w-container-inner
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    +res-sm-max
      flex-direction: column
      justify-content: center
      align-items: flex-start
  &__title
    +font(30, 40, 800)
    margin-bottom: 25px
    color: #fff
  &__description
    +font(18, 24, 500)
    color: #fff
  &__content
    max-width: 393px
    margin-right: 30px
    +res-sm-max
      margin-bottom: 30px
  &__card
    display: flex
    align-items: center
    padding: 20px
    background: #fff
    +border-radius(10px)
    &__content
      max-width: 365px
    &__title
      +font(16, 21, 800)
      margin-bottom: 4px
    &__description
      +font(12, 16, 400)
    .icon
      width: 24px
      height: 24px
      margin-right: 20px
      +res-sm-max
        min-width: 32px
        width: 32px
        height: 32px
        margin-right: 16px
      &--phone
        fill: $color-primary