@import public/sass/abstracts

.formazione-modulo-card
  border-radius: 16px
  background: #FFF
  color: $color-text
  padding: 10px 10px 20px
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
  +res-sm-max
    &:not(:last-child)
      margin-bottom: 30px
  &--concluso
    .formazione-modulo-card
      &__head
        background: $grey-0
  &--iscrizione
    .formazione-modulo-card
      &__cta-box
        display: flex
        align-items: center
        margin-top: 20px
        > *
          +res-xs-max
            flex: 1 0 0
            padding-left: 8px
            padding-right: 8px
          &:not(:last-child)
            margin-right: 8px
  &:not(.formazione-modulo-card--iscrizione)
    .formazione-modulo-card
      &__content
        +res-sm-min
          display: flex
          justify-content: space-between
          flex-flow: column
          row-gap: 20px
        > *
          &:not(:last-child)
            +res-sm-min
              margin-right: 20px
            +res-xs-max
              margin-bottom: 20px

  &__head
    position: relative
    border-radius: 12px
    background: $accent-blue-light
    margin-bottom: 20px
    padding: 20px

  &__tag
    position: absolute
    top: 0
    right: 0
    border-radius: 0 12px
    background: $tertiary
    color: #fff
    padding: 6px 12px 3px
    +typoTextMd
    font-weight: $font-primary-heavy
    text-transform: uppercase

  &__intro
    +typoTextMd
    text-transform: uppercase
    margin-bottom: 8px

  &__title
    +typoTextXl
    font-weight: $font-primary-black-2

  &__content
    padding: 0 16px

  &__list
    +typoTextMd
    font-weight: $font-primary-medium-2
    strong
      font-weight: $font-primary-heavy
    > *
      &:not(:last-child)
        margin-bottom: 12px

  &__item
    display: flex
    &-icon
      margin-right: 8px
      svg
        +style-icon(20px, 20px, currentColor)

  &__cta-box
    a
      margin-right: 8px
.icon--check
  display: inline-block
  width: 18px
  height: 13px
  margin-right: 13px
  fill: $color-secondary
