.dropdown-algolia
  min-width: 17.4rem
  position: relative
  div[class$="control"]
    border: 0
    +border-radius(1.2rem)
    min-height: 4.8rem!important
    background: $color-grey-12
  & * 
    cursor: pointer
    font-size: 1.6rem
    line-height: 2.4rem
    text-align: left
  .icon
    height: 1.2rem
    width: 1.2rem
  &__indicators
    .icon
      +fill-icon($color-grey-3)
  &__indicator, &__dropdown-indicator
    padding: 1.2rem!important
  &__indicator-separator
    display: none!important
  &__menu
    z-index: 99999!important
  &__option
    &:hover
      background: $color-grey-12
  &__value-container
    padding: 0 1.5rem!important
    margin-top: 1px
  &__single-value, &__placeholder
    color: $color-grey-10!important
    left: 1.3rem
    right: 0
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  &--is-disabled
    opacity: .4
