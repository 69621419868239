.sidebar
    .tree-node-container
        display: flex
        flex-direction: column
        width: 100%

    .tree-node-header
        display: flex
        justify-content: space-between
        cursor: pointer
        padding: 4px
        &:hover
            background-color: $pb-grey-20
        &__active
            background-color: $pb-grey-20

    .tree-node-header-end
        display: flex
        justify-content: flex-end
        div
            padding-left: 4px

    .tree-node-indent
        display: flex