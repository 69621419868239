@import public/sass/abstracts

.area-personale-breadcrumbs
  display: flex
  align-items: center

.area-personale-breadcrumbs-item
  +typoTextMd
  color: $black

  &--link    
    color: $accent-blue

  &:not(:last-child)
    &::after
      content: "/"
      margin: 0 5px
      color: $black