@keyframes notification-bar-enter
  0%
    transform: translateX(-110%)
    opacity: 0
  100%
    transform: translateX(0)
    opacity: 1

@keyframes notification-bar-exit
  0%
    transform: translateX(0)
    opacity: 1
  100%
    transform: translateX(-110%)
    opacity: 0
  
.pb-notification-bar
  position: fixed
  left: 10px
  top: 10px
  max-width: 400px
  border-radius: 4px
  animation: .5s ease-out notification-bar-enter 
  padding: 12px 50px 12px 16px
  +typoTextMd
  &__close
    position: absolute
    top: 6px
    right: 6px
    color: inherit
    svg
      +style-icon(16px, currentColor)

  &--closing
    animation: .5s ease-out notification-bar-exit 
  
  &--alert
    background: $pb-error-light
    color: $pb-error-dark
    
  &--warning
    background: $pb-warning-light
    color: $pb-warning-dark
    
  &--success
    background: $pb-success-light
    color: $pb-success-dark

    

