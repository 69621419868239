@import public/sass/abstracts

.cart-section
  &__head
    display: flex
    align-items: center
    margin-bottom: 10px
    > *
      &:not(:last-child)
        margin-right: 4px
  &__title
    +typoTextLg
    font-weight: $font-primary-heavy
  &__num
    +typoTextMd
    font-weight: $font-primary-medium
    margin-bottom: 2px // per allinearlo otticamente al title
  &__helper
    svg
      +style-icon(24px, 24px, currentColor)