@import public/sass/abstracts

.popup-generico
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  &__title
    line-height: 35px
    margin-bottom: 10px
  &__image, &__description
    margin-top: 10px
    margin-bottom: 20px
  &__cta
    margin-top: 10px
    margin-bottom: 10px