@import public/sass/abstracts

.bacheca-template
  &__head
    margin-bottom: 44px
    +res-md-min
      display: flex
      align-items: center
      margin-bottom: 20px
    &__row-mobile
      display: flex
      align-items: center
      &:last-child
        +res-md-min
          margin-left: auto
        +res-sm-max
          margin-top: 20px
    
  &__tooltip
    margin-left: 20px
    +res-sm-max
      margin-left: auto

  &__open-widget
    +res-md-min
      margin-left: auto
    +res-sm-max
      margin-right: auto

  &__widget-list
    +res-md-min
      margin-left: -7px
      margin-right: -7px
      display: flex
      flex-wrap: wrap
      > *
        margin: 7px 
    +res-sm-max
      > *
        > *
          margin-bottom: 20px

    &__banner-propaganda
      display: block
      height: 268px
      background-position: center
      background-repeat: no-repeat
      +border-radius(20px)

  &__widget-modal
    top: 10vh
    +translateY(0)
    +res-sm-max
      top: 45px
      bottom: 0
      +border-bottom-left-radius(0)
      +border-bottom-right-radius(0)
      padding-bottom: 68px
    &__form-wrap
      +res-md-min
        overflow-y: auto
        +scrollbar-style
        max-height: calc(80vh - 192px - 88px) // tolta altezza header, altezza footer e 10vh sopra e sotto di margine
        padding-right: 20px
    .wmodal
      &__head
        text-align: center
      &__content
        max-width: 470px
        margin: 0 auto
        +res-sm-max
          overflow-y: auto
          +scrollbar-style
          max-height: calc(100vh - 45px - 56px - 88px)
      &__buttons
        +res-sm-max
          position: absolute
          bottom: 0
          left: 0
          width: 100%
          padding: 10px 0
          background: $white
        .wbutton
          min-width: 113px