.proposta-card
  display: inline-block
  width: 25rem
  height: 33.3rem
  background: #fff
  +border-radius(.6rem)
  +border-top-left-radius(2.5rem)
  &__head
    padding: 2.5rem 2.2rem
    +border-top-left-radius(2.5rem)
    +border-bottom-right-radius(4rem)
    margin-right: 2rem
  &__title
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 2.9rem
    color: #fff
  &__image-box
    +border-radius(50%)
    overflow: hidden
    width: 20.7rem
    height: 20.7rem
    margin-top: 2.4rem
    position: relative
    margin-left: auto
    margin-right: auto
  &__image
    position: absolute
    top: 0
    bottom: 0
    left: -999rem
    right: -999rem
    margin: 0 auto
    max-width: none
    height: 100%
