@import public/sass/abstracts

.secondo-desktop
  display: flex
  align-items: center
  text-transform: uppercase
  color: $tertiary
  // width: 210px
  // width: 200px
  width: 230px
  padding: 5px 0
  +font(13, 14, 700)
  @media (max-width: 1275px)
    width: 185px
  +res-md-max
    +font(16, 20, 700)
    text-transform: none
    margin-bottom: 12px
  // .icon
  //   display: none
  //   margin-left: 16px
  //   width: 24px
  //   height: 24px
  //   fill: $tertiary
  // &:hover
  //   font-weight: 800
  //   padding: 0
  //   .icon
  //     display: block