@import public/sass/abstracts

.typ-io-piu-box
  background: $color-bg
  padding: 50px 0 60px
  text-align: center
  &__title
    +typoH3
    color: $color-primary
    margin-bottom: 30px
    svg
      +style-icon(71px, 55px, currentColor)
      display: inline-block
  &__description
    +typoBase(1.6rem, 2.4rem, $font-secondary-regular, $font-secondary)
    margin-bottom: 30px
  &__list
    +res-md-min
      display: flex
    +res-lg-min
      > *
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 34px
    +res-md-only
      flex-wrap: wrap
      > *
        flex: 0 0 calc(50% - 17px)
        &:nth-child(2n + 1)
          margin-right: 34px
  &__item
    display: flex
    align-items: center
    margin-bottom: 16px
    > *
      &:not(:last-child)
        margin-right: 18px
    &__image-box
      position: relative
      height: 88px
      width: 88px
      background: $color-primary
      +border-radius(50%)
    &__image
      position: absolute
      top: 50%
      +translateY(-50%)
      left: -999px
      right: -999px
      margin: 0 auto
      max-width: 107px
      max-height: 100px

    &__content
      text-align: left
      +typoBase(1.6rem, 2.4rem, $font-primary-black)
      flex: 1

  &__cta
    text-align: center
    margin-top: 14px