.iopiu2-numeri 
  background: linear-gradient(180deg, rgba(247,137,48,1) 0%, rgba(247,140,54,1) 13%, rgba(247,150,71,1) 30%, rgba(249,167,101,1) 51%, rgba(250,190,141,1) 73%, rgba(252,219,192,1) 97%, rgba(253,223,199,1) 100%)
  padding: 44.2px 0 54.4px
  &__content
    max-width: 1065px
    padding: 0 24px
    margin: 0 auto
    text-align: center
  &__title
    font-size: 24px
    font-weight: 900
    line-height: 40px
    margin-bottom: 41.5px
    span
      +res-sm-max
        display: block 
  &__slider
    @media screen and (max-width: 1064px)
      padding-left: 30px
      padding-right: 30px
    .slick-prev, .slick-next
      width: 24px
      height: 24px
      &::before
        height: 100%
    .slick-prev
      &::before
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjEyMSIgaGVpZ2h0PSIxNS40MTQiIHZpZXdCb3g9IjAgMCA5LjEyMSAxNS40MTQiPg0KICA8cGF0aCBpZD0iZnJlY2NpYV9zeCIgZGF0YS1uYW1lPSJmcmVjY2lhIHN4IiBkPSJNMTE5OC4xNjEsMjg2OS42OTRsNyw3LTcsNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIwNi41NzUgMjg4NC40MDEpIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+DQo8L3N2Zz4NCg==")
    .slick-next
      &::before
        height: 14px
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjEyMSIgaGVpZ2h0PSIxNS40MTQiIHZpZXdCb3g9IjAgMCA5LjEyMSAxNS40MTQiPg0KICA8cGF0aCBpZD0iZnJlY2NpYV9keCIgZGF0YS1uYW1lPSJmcmVjY2lhIGR4IiBkPSJNMTE5OC4xNjEsMjg2OS42OTRsNyw3LTcsNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOTcuNDUzIC0yODY4Ljk4NykiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz4NCjwvc3ZnPg0K")
  &__slide
    outline: none
  &__item
    max-width: 182px
    padding: 0 16px
    margin: 0 auto
    +res-sm-max
      max-width: 109px
      padding: 0 12px
    &__numero
      background: #fff
      width: 150px
      height: 150px
      +border-radius(50%)
      font-size: 35.11px
      line-height: 150px
      color: $color-red
      margin: 0 auto 27px
      +res-sm-max
        width: 85px
        height: 85px
        font-size: 20px
        line-height: 85px
        margin-bottom: 14px
    &__label
      font-size: 16px
      font-weight: 900
      line-height: 20px
      color: #000
      +res-sm-max
        font-size: 10px
        line-height: 13px



  