@import public/sass/abstracts

.area-personale-libri-all-template
  background: $grey-0!important
  &__list
    +res-sm-min
      display: flex
      flex-wrap: wrap
    > *
      margin-bottom: 20px
      +res-sm-md-only
        width: calc(50% - 7px)
        &:not(:nth-child(2n))
          margin-right: 14px
      +res-lg-min
        width: calc(33.33% - 9.33px)
        &:not(:nth-child(3n))
          margin-right: 14px