@import public/sass/abstracts

.wcolor-box
  background: $color-bg
  color: $color-text
  padding: 2rem 2.5rem
  +border-radius(.6rem)
  margin: 2rem 0
  &--white
    background: #fff  
  &--no-padding
    padding: 0