@import public/sass/abstracts

.typ-contenuti-box
  &__title
    +typoH2
    text-align: center
    margin-bottom: 10px
  &__description
    +typoBase(1.6rem, 2.4rem, $font-secondary-regular, $font-secondary)
    text-align: center
  &__cta
    text-align: center
    margin-top: 30px
  &__list
    margin-top: 30px
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(15px)
    +res-lg-min
      > *
        width: 33.33%
    +res-md-only
      > *
        width: 50%
  &__item
    margin-bottom: 30px