.accordion-item
  &--opened
    .accordion-item__icon
      transform: rotate(180deg)
        
    .accordion-item__inner
      max-height: 1000rem
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22)
      transition-duration: 0.5s
      transition-property: max-height
    
    .accordion-item__content
      opacity: 1
      transform: translateY(0)
      transition-delay: 0.2s
      transition-timing-function: ease-in-out
      transition-duration: 0.2s
      transition-property: opacity, transform

  &__line
    display: flex
    justify-content: space-between
    align-items: center
    position: relative
    cursor: pointer
  
  &__title
    font-family: $font-secondary
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 3.1rem

  &__icon
    transition: transform 0.3s ease-in-out
    margin-left: 2rem

    .icon
      display: block
      width: 2rem
      height: 1.2rem
      fill: $color-text
  
  &__inner
    max-height: 0
    overflow: hidden
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035)
    transition-duration: 0.5s
    transition-property: max-height
    position: relative
  
  &__content
    opacity: 0
    transform: translateY(-1rem)
    transition-timing-function: linear, ease
    transition-duration: 0.1s
    transition-property: opacity, transform
    transition-delay: 0.5s

  &__paragraph    
    font-family: $font-secondary
    font-size: 2rem
    line-height: 3.2rem
    margin-top: 2.4rem
    // padding-top: 3rem 
    padding-bottom: .8rem 
    +res-sm-max
      padding: 2rem 0 0 0
    a
      text-decoration: underline

