@import public/sass/abstracts

.sezione-rivista
  display: flex
  justify-content: center
  width: 100%
  margin-bottom: 50px
  padding: 0 $wcont-padding
  +res-sm-max
    margin-bottom: 30px
  &__box
    border-bottom: 1px solid $grey-2
    padding-bottom: 50px
    +res-sm-max 
      padding-bottom: 30px