.pb-form-field
  margin-bottom: 8px
  
  &__head
    display: flex
    align-items: center
    margin-bottom: 2px

  &__label
    display: block
    +typoTextXs
    font-weight: 600
    text-transform: uppercase
    color: $pb-grey-100

  &__helper
    margin-left: 4px
    cursor: pointer
    svg
      +style-icon(20px, $pb-grey-100)
