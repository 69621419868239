@import public/sass/abstracts

.tab-corsi-iscritto
  &__list
    display: flex
    flex-wrap: wrap
    +custom-row(16px)
    > *
      width: 50%
    +res-lg-min
      > *
        width: 50%
    +res-md-max
      > *
        width: 100%
    +res-sm-max
      +custom-row(8px)
  &__item
    position: relative
    margin-bottom: 24px
    +res-sm-max
      margin-bottom: 8px
