@import public/sass/abstracts

.area-personale-libri-detail-template
  &__head
    +res-md-min
      display: flex
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 24px
  
  &__title
    +typoH3
    +res-sm-max
      +typoH4
      margin-bottom: 8px

  &__description
    +typoBase(1.8rem, 2.4rem, $font-primary-black)
    +res-sm-max
      +typoBase(1.6rem, 2.4rem, $font-primary-heavy)

  &__main
    margin-top: 22px
    +res-md-min
      display: flex
  &__sidebar
    +res-md-min
      width: 30%
      margin-right: 30px
  &__detail
    +res-md-min
      max-width: 380px
    +res-sm-max
      display: flex
      align-items: center
      margin-bottom: 40px
    &__image-box
      +res-md-min
        margin-bottom: 30px
      +res-sm-max
        margin-right: 10px
        width: 160px

    &__logo
      margin-bottom: 10px
      +res-sm-max
        margin-bottom: 20px
      svg
        width: 90px
        height: 30px
        display: block
    &__actions
      +res-sm-max
        display: flex
        flex-direction: column
        align-items: flex-start
      > *
        &:not(:last-child)
          margin-bottom: 12px
        +res-md-min
          &:not(:last-child)
            margin-right: 24px

  &__list
    +res-sm-min
      flex: 1
      display: flex
      flex-wrap: wrap
      +custom-row(14px)
    > *
      +res-sm-md-only
        width: 50%
      +res-lg-min
        width: 33.33%
  &__item
    margin-bottom: 20px
    > *
      margin: 0 auto