@import public/sass/abstracts

.checkout-header
  background: $white
  box-shadow: $shadow-md

  &__layout
    position: relative
    display: flex
    align-items: center
    justify-content: center
    height: 76px

  &__back
    position: absolute
    left: 0
    top: 50%
    +translateY(-50%)

  &__logo
    svg
      display: block
      +size-icon(143px, 35px)

.toolbar
  ~ .checkout-header
    margin-top: $toolbar-height