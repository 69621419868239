@import public/sass/abstracts

.area-personale-page
  background: $white
  &__back
    margin-top: 30px
  &__main
    padding: 32px $wcont-padding 200px
    +res-sm-max
      padding-bottom: 80px
  &__breadcrumbs
    margin-bottom: 30px
    +res-sm-max
      display: none
  &__head
    margin-bottom: 30px
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
  &__head-actions
    display: flex
    align-items: center
    > * 
      &:not(:last-child)
        margin-right: 10px
    +res-md-min
      margin-left: 20px
    +res-sm-max
      margin-top: 30px
  &__title
    +typoH4
  &__description
    +typoTextXl
    margin-bottom: 30px
    p
      margin-bottom: 20px
    a
      color: $accent-blue
      font-weight: $font-primary-black-2
  &__open-menu
    display: flex
    align-items: center
    width: 100%
    +typoH5
    background: $white
    padding: 16px
    box-shadow: 0 2px 4px rgba(#000, .25)
    position: relative
    z-index: 1
    &__icon
      margin-right: 20px
      width: 24px
      svg
        +style-icon(24px, 24px, $accent-blue)
    &__label
      flex: 1
      text-align: left
    +res-md-min
      display: none
  
  &:not(.area-personale-page--no-sidebar)
    +res-lg-min
      display: flex
    .area-personale-page
      &__main
        +res-lg-min
          width: calc(100% - 326px)
        @media screen and (min-width: $wcont-width)
          padding-right: calc(50vw - #{$wcont-width / 2} )
          width: calc(50vw - #{$wcont-width / 2 - 1008px})
  &--no-sidebar
    .area-personale-page
      &__back
        margin-left: 16px
        margin-right: 16px
      &__main
        max-width: $wcont-width
        margin: 0 auto