@import public/sass/abstracts

.attiva-libro-card
  padding: 20px 16px
  border-radius: 20px
  background: $white
  display: flex
  align-items: center
  &__image-box
    width: 30%
    max-width: 166px
    margin-right: 16px
    overflow: hidden
    +border-radius(10px)
  &__content
    flex: 1
  &__title
    +typoH5
    margin-bottom: 16px
    +res-sm-max
      +typoBase(1.8rem, 2.4rem, $font-primary-black)
  &__description
    +typoBase(1.4rem, 2rem, $font-primary-heavy)
  &__cta
    margin-top: 25px
    +res-sm-max
      margin-top: 16px
  &__activated
    color: $color-green
    .icon
      width: 2rem
      height: 2rem
      +fill-icon($color-green)
  &__modal
    text-align: center