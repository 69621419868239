.iopiu2-vantaggi
  padding: 45.5px 24px 0
  max-width: 1065px
  margin: 0 auto
  +res-sm-max
    padding-bottom: 20.5px 
    padding-bottom: 41.6px 
  &__title
    font-size: 40px
    font-weight: 900
    line-height: 48px
    color: $color-primary
    text-align: center
    margin-bottom: 20.1px 
    +res-sm-max
      font-size: 20px
      line-height: 31px
      margin-bottom: 11.9px
  &__description
    font-size: 24px
    line-height: 28.8px
    color: #000
    text-align: center
    margin-bottom: 39.8px
    +res-sm-max
      font-size: 16px
      line-height: 23px
      margin-bottom: 34.4px
  &__tabs
    +res-md-min
      display: none
    &__trigger
      font-size: 16px
      font-weight: 900
      line-height: 20px
      text-align: center
      text-transform: uppercase
      color: #777777
      padding: 5px 16px 0
      +border-radius(15px)
      border: 2px solid #CCCCCC
      margin-bottom: 9px
      min-height: 60px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      &--active
        background: $color-primary
        border-color: #D56734
        color: #000
  &__tab
    display: none
    margin-top: 32px
    +border-radius(15px)
    border: 2px solid #CCCCCC
    padding: 14.7px 40px 33.9px
    &--active
      display: block
    
    &__title
      font-size: 22px
      font-weight: 900
      line-height: 28px
      text-align: center 
      color: #808080
      margin-bottom: 20.9px
    &__list
      margin-bottom: 22.2px
      max-width: 270px
      margin-left: auto
      margin-right: auto
    &__item
      position: relative
      padding-left: 24px
      svg
        width: 16px
        height: 16px
        display: block
        fill: $color-secondary
        position: absolute
        top: 2px
        left: -2px
      &__label 
    &__price-title
      font-size: 28px
      line-height: 36px
      text-align: center
      margin-bottom: 4.8px
    &__price-description
      font-size: 13px
      line-height: 16px
      text-align: center
      margin-bottom: 20px
    &__cta-box
      text-align: center
    &--orange
      border-color: $color-primary
      .iopiu2-vantaggi
        &__tab
          &__title
            color: $color-primary
    &--red    
      border-color: $color-red
      .iopiu2-vantaggi
        &__tab
          &__title
            color: $color-red

  &__table
    background: $color-bg
    padding: 43.5px 40.6px 100px
    +border-top-left-radius(63.38px)
    +border-top-right-radius(63.38px)
    display: flex
    +res-sm-max
      display: none
    &__col
      position: relative
      flex: 1 0 0 
      padding: 0 29px
      +res-md-max
        padding-left: 10px
        padding-right: 10px
      &:not(.iopiu2-vantaggi__table__col--main)
        background: #fff
        +border-radius(25px)
        border: 2px solid #CCCCCC
        padding-bottom: 25.4px
        margin-left: 12.4px
      &__title
        font-size: 14px
        font-weight: 900
        line-height: 20px
        text-align: center
        color: #808080
        margin-left: -2px
        margin-right: -2px
        width: auto!important
        +res-lg-min
          margin-left: -16px
          margin-right: -16px
      &--orange
        &:not(.iopiu2-vantaggi__table__col--main)
          border-color: $color-primary
        .iopiu2-vantaggi
          &__table
            &__col
              &__title
                color: $color-primary
      &--red
        &:not(.iopiu2-vantaggi__table__col--main)
          border-color: $color-red
        .iopiu2-vantaggi
          &__table
            &__col
              &__title
                color: $color-red
      &--main
        flex: 0 0 290px
        margin-top: 61px
        padding-left: 0
        +res-md-max
          flex-basis: 175px // text max su due righe
          margin-top: 81px
        .iopiu2-vantaggi
          &__table
            &__cell
              text-align: left
              &:first-child
                border-top: .5px solid #B3B3B3
            &__price
              text-align: left
      
    &__cell
      padding-top: 4px
      height: 46px
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center
      font-size: 16px
      line-height: 19px
      border-bottom: .5px solid #B3B3B3
      > *
        width: 100%
      &--head
        height: 60px
        +res-md-max
          height: 80px
      .icon
        width: 24px
        height: 24px
        margin: 0 auto
        fill: $color-secondary

    &__price
      text-align: center
      &__label
        font-size: 20px
        line-height: 24px
        margin-top: 26.4px
      &__title
        font-size: 28px
        line-height: 33.6px
        margin-top: 17.5px
        margin-bottom: 12.8px
      &__description
        font-size: 13px
        line-height: 15.6px
    &__cta-box
      position: absolute
      top: calc(100% + 15px)
      left: 0
      right: 0
      text-align: center
    &__cta
      &.button--beige
        border: 1px solid #CCCCCC
        