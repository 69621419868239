@import public/sass/abstracts

.sticky-steps
    position: sticky
    width: 294px
    top: 20px
    display: flex
    flex-direction: column
    align-self: flex-start
    +res-md-max
        display: none
        position: fixed
        background: #fff
        top: 0
        left: 0
        padding: 12px $wcont-padding
        width: 100vw
        height: 62px
        z-index: 999
        +box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))
        &.sticky-steps--visible
            display: flex
            .sticky-steps-item
                +font(16, 24, 800)
                &:not(.sticky-steps-item--active)
                    display: none
                .icon
                    display: none
        &.sticky-steps--empty
            display: none
    .sticky-steps-item
        &:not(:last-child)
            margin-bottom: 10px

body
    &.w-toolbar
        .sticky-steps
            top: calc( 20px + #{$toolbar-height} )
            +res-md-max
                top: calc( #{$toolbar-height} )