@import public/sass/abstracts

.notification-bar
  display: flex
  align-items: center
  padding: 16px
  border-radius: 10px
  +typoTextMd
  +res-sm-max
    padding: 8px
    border-radius: 8px
  &__icon
    margin-right: 10px
    +style-icon(24px, 24px, currentColor)
  &__close
    margin-left: auto
    +style-icon(18px, 18px, currentColor)
    cursor: pointer
  // Variant  
  &--info
    background: $light-blue-1
    color: $green-dark
  &--info-blue
    background: $color-grey-12
    color: $color-secondary-dark
  &--info-red
    background: $color-red-light
    color: $color-red-dark
