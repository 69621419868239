@import public/sass/landing-riviste/abstracts

.landing-riviste-digitale
  position: relative
  font-family: $font-primary
  color: #000
  padding: 35px 0 83px
  background: $color-primary
  +res-sm-max 
    padding: 18px 0 33px

  &__bg
    position: absolute
    top: 86px
    width: 20%
    left: 5%
    z-index: 0
    +res-sm-max
      display: none
    svg
      +style-icon(100%, 100%, rgba(#fff, .17))

  &__title
    display: flex
    justify-content: center
    align-items: center
    color: $landing-riviste-orange-dark
    > *
      &:not(:last-child)
        margin-right: 16px
  &__title-icon
    svg
      +style-icon(76px, 74px, currentColor)
      +res-md-max
        +size-icon(45px, 43px)
  &__title-label
    +typoH4MaiuscoloLR
    margin-top: 5px
    +res-md-max
      +typoBase(18px, 24px)

  &__description
    text-align: center
    margin-top: 52px
    +typoBase(28px, 38px)
    +res-md-max
      margin-top: 33px
      +typoBase(18px, 24px)

  &__bottom
    margin-top: 33px
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
    +res-sm-max
      margin-top: 14px

  &__box
    +res-md-min
      width: 66.66%
  &__iopiu
    width: 176px
    +res-sm-max
      margin: 37px auto 0
    svg
      +style-icon(176px, 140px, #FFF)