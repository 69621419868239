@import public/sass/abstracts

.formazione-docente-banner
  text-align: left
  display: flex
  width: 230px
  height: 70px
  align-items: center
  background: #FFFFFF
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
  border-radius: 35px
  &__immagine-profilo
    margin: 10px
    height: 50px
    width: 50px
    border-radius: 25px
    img
      +border-radius(25px)

  &__docente-dati
    &__titolo
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: $color-text
    &__nome-cognome
      font-weight: 800
      font-size: 18px
      line-height: 24px
      color: $color-text
