.generic-card
  font-family: $font-primary
  position: relative
  width: 21.5rem
  height: 33.8rem
  +border-radius(1.6rem)
  display: flex
  flex-direction: column
  z-index: 1
  background: $color-grey-12
  +transition(300ms)
  &:hover
    +translateY(-1rem)
  .segui
    position: absolute
    top: 0
    left: 0
    z-index: 3
  &__image-box
    display: flex
    justify-content: center
    align-items: center
    max-height: 100%
    height: 21.5rem
    width: 21.5rem
    background-repeat: no-repeat
    background-size: contain
    background-position: center
    padding: 1.6rem
    +border-radius(1.6rem)
    &__icon
      width: 70%
      background: $color-grey-1
      +border-radius(1.6rem)
    &__title
      font-size: 2.2rem
      font-weight: 700
      line-height: 3rem
      text-align: center
      span
        display: block
        &.white
          color: #fff
  &__content
    padding: 1.6rem 1.2rem 1.8rem 1.2rem
  &__title
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-size: 1.6rem
    line-height: 2.4rem
    font-weight: $font-bold
    margin-bottom: .5rem
    word-break: break-word
  &__abstract
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-family: $font-secondary
    font-size: 1.4rem
    line-height: 2rem
  &__icon-box
    color: $color-primary
    font-size: 1.4rem
    line-height: 2rem
    margin-top: 1.5rem
    .icon
      width: 2.4rem
      height: 2.4rem
      vertical-align: bottom
      +fill-icon($color-primary)
  &--horizontal
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    .generic-card
      &__image-box
        height: 21.6rem
        min-width: 16.4rem
        display: flex
      &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
  +res-sm-max
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    .generic-card
      &__image-box
        height: 21.6rem
        min-width: 16.4rem
        display: flex
      &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center

