.evento-card
  width: 50rem
  height: 36.6rem
  background: #fff
  max-width: 100%
  +res-sm-max
    width: auto
  main
    padding-top: 0
    padding: 2rem
    overflow-y: auto
    height: calc(100% - 6rem)
    & > article
      display: flex
      margin-bottom: 3rem
      section
        display: flex
        flex-direction: column
        justify-content: space-around
        h4
          font-size: 2rem
          line-height: 2.4rem
          font-weight: $font-bold
  header
    display: flex
    align-items: center
    height: 6rem
    background: #024598
    color: #fff
    font-size: 2rem
    line-height: 3.2rem
    padding: 0 1.8rem
    +res-xs-max
      flex-wrap: wrap
  &__title
    font-weight: $font-bold
    margin-right: .6rem
  &__image
    max-width: 6.5rem
    +border-radius(1.5rem)
    &-wrapper
      width: 6.5rem
      height: 6.5rem
      margin-right: 2.5rem
  &__default-image
    width: 6.5rem
    height: 6.5rem
    background: $color-grey-1 
    +fill-icon($color-grey-2)
    +border-radius(1.5rem)

.u-bg-white
  .evento-card
    background: $color-bg