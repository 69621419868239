.masonry
  display: flex
  margin-left: -6rem
  width: auto

  &__item 
    padding-left: 3rem

    & > div
      margin-bottom: 4rem
      +res-md-max
        margin-left: auto
        margin-right: auto

      .icon--remove
        position: absolute
        // top: 2rem
        bottom: 14rem
        right: 1.8rem
        width: 2.5rem
        height: 2.5rem
        cursor: pointer
        z-index: 999
        +fill-icon($color-text)

