@import public/sass/abstracts

.typ-action-box
  padding: 30px 32px 20px
  border-radius: 6px
  display: flex
  flex-direction: column
  background: $color-primary
  color: $white
  &--rounded
    border-radius: 20px
  &__title 
    +typoH4
    margin-bottom: 16px
  &__description
    +typoTextLgSerif
    margin-bottom: 16px
  &__cta
    margin-top: auto