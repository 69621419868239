@import public/sass/abstracts

.rivista-card-wide-noabbonato
  display: flex
  align-items: center
  justify-content: center
  flex: 1
  color: #ffffff
  background: linear-gradient(180deg, #EA8B45 0%, #E95B29 100%)
  +border-radius(16px)
  margin-bottom: 30px
  &__container__content
    padding: 33px 45px
    flex: 1
    
  &__container__content-title
    display: flex
    align-items: center
    flex: 1
    margin-bottom: 16px
    +res-sm-max
      flex-direction: column
      align-items: flex-start
    .icon
      width: 79px
      height: 61px
      fill: #fff
  &__title
    +typoH4
    margin-left: 20px
    +res-sm-max 
      margin-left: 0
      margin-top: 16px
  &__description
    +typoTextLg
    margin-bottom: 20px
  &__cta
    .wbutton
      &:first-child
        margin-right: 10px
        +res-sm-max
          margin-right: 0
          margin-bottom: 16px
     


  &__container__content-image
    flex: 1
    margin-left: 76px
    align-self: flex-start
    +res-sm-max
      display: none