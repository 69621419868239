.riviste-side
  background: $color-bg
  width: 100%
  padding: 30px
  padding-bottom: 10px
  +border-radius(12px)
  &__title
    +font(16, 26, 800)
    margin-bottom: 20px
  &__content
    display: flex
    justify-content: space-between
    +res-md-max
      flex-direction: column
  &__items
    display: flex
    justify-content: space-between
    +res-md-max
      flex-direction: column
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    max-width: 334px
    margin-bottom: 20px
    +font(18, 24, 800)
    text-decoration: underline
    +res-md-max
      max-width: 100%
    &__title
      flex: 1
      margin-left: 20px

@media print
  .riviste-side
    display: none
