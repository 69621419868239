@import public/sass/abstracts

.calendario-avvento-list-step2
    .icon_calendario
        position: absolute
        max-width: 2rem
        bottom: 10%
        left: 70%
        +res-md-max
            max-width: 1.8rem

    .cella-step2--open
        position: relative
        text-align: left
        font-size: 2.4rem
        cursor: pointer
        width: 14.716%
        border: 2px solid #ffffff
        min-height: 98px
        margin-bottom: 12px
        +border-radius(1rem)
        color: #ffffff
        background-color: $text-green-light
        margin: 0 auto 12px
        +res-md-min
            &:not(:nth-child(6n+1))
                margin-left: 15px
        +res-md-max
            width: 85px
            min-height: 85px
            &:not(:nth-child(4n+1))
                margin-left: 1rem
    
    .cella-step2--close
        position: relative
        text-align: left
        font-size: 2.4rem
        cursor: pointer
        width: 14.716%
        border: 1px solid #ffffff
        min-height: 98px
        background-color: #ffffff
        border: 2px solid #238B68
        color: $green-dark
        margin-bottom: 12px
        +border-radius(1rem)
        margin: 0 auto 12px
        +res-md-min
            &:not(:nth-child(6n+1))
                margin-left: 15px
        +res-md-max
            width: 85px
            min-height: 85px
            &:not(:nth-child(4n+1))
                margin-left: 1rem
         
    .cella-step2--future
        position: relative
        text-align: left
        font-size: 2.4rem
        width: 14.716%
        border: 2px solid $green-1
        background-color: $green-0
        min-height: 98px
        margin-bottom: 12px
        display: flex
        justify-content: center
        align-items: center
        align-content: center
        +border-radius(1rem)
        margin: 0 auto 12px
        +res-md-min
            &:not(:nth-child(6n+1))
                margin-left: 15px
        +res-md-max
            width: 85px
            min-height: 85px
            &:not(:nth-child(4n+1))
                margin-left: 1rem
     

        .number
            color: $green-1
       
    .number
        display: inline-block
        padding: 7%