@import public/sass/abstracts

.form-toggle-temi
  &__list
    display: flex
    flex-wrap: wrap
    +custom-row(16px)
    > *
      width: 50%
    +res-sm-min
      > *
        width: 33.33%
    +res-md-min
      > *
        width: 25%
    +res-sm-max
      +custom-row(8px)
  &__item
    position: relative
    margin-bottom: 24px
    +res-sm-max
      margin-bottom: 8px
    .selection-button
      left: 8px
      +res-sm-max
        left: 4px
  &__actions
    display: flex
    flex-direction: column
    align-items: center
    +res-sm-max
      margin-top: 16px
    > *
      &:not(:last-child)
        margin-bottom: 20px
