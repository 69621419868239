.member
  background: rgba($color-primary, .16)
  height: 4.7rem
  position: relative
  padding: 1.8rem 1.5rem 0 6.5rem
  margin-bottom: 2rem
  +border-radius(.6rem)
  .icon
    position: absolute
    top: -.4rem
    left: 2rem
    width: 4.2rem
    height: 6.3rem
    display: block
    +fill-icon($color-primary)
  &__title
    font-size: 1.6rem
    font-weight: $font-bold
    text-transform: uppercase
    color: $color-primary