@import public/sass/abstracts

.list-toggle
  position: relative
  display: inline-flex
  border-radius: 20px
  border: 1px solid $accent-blue
  padding: 4px 8px
  cursor: pointer
  &::after
    content: ""
    position: absolute
    top: -1px
    left: -1px
    height: calc(100% + 2px)
    width: calc(50% + 6px)
    border-radius: 20px
    background: $accent-blue
    +transition(left .2s linear)
    z-index: 0
  &--list
    &::after
      left: calc(50% - 5px)
  &__icon
    z-index: 1
    position: relative
    svg
      +style-icon(24px, 24px, $accent-blue)
      +transition(all .2s linear)
    &--selected
      svg
        +fill-icon($white)
    &:not(:last-child)
      margin-right: 12px