@import public/sass/abstracts

.formazione-webinar-card
  position: relative
  padding: 40px
  border-radius: 20px
  border: 1px solid $grey-1
  background: #FFF
  width: 100%
  max-width: 1063px
  margin-bottom: 30px
  +res-sm-max
    padding: 20px
  +res-md-min
    display: flex
    align-items: center
  > *
    &:not(:last-child)
      +res-md-min
        margin-right: 50px
      +res-sm-max
        margin-bottom: 20px

  &--future
    background: $grey-0
    border-color: $grey-0
    .formazione-webinar-card
      &__date-wrap
        +res-sm-max
          text-align: center
      &__date
        padding: 10px 16px
        border-radius: 40px
        background: #FFF
        display: inline-flex

  &--current
    background: linear-gradient(0deg, rgba(230, 241, 254, 0.3), rgba(230, 241, 254, 0.3)), #FFFFFF
    border-color: $accent-blue

  &__image-box
    max-width: 420px
    border-radius: 16px
    overflow: hidden
    max-height: 256px
    position: relative
    +res-md-min
      position: relative
      flex: 0 0 40%
    +res-sm-max
      margin-left: auto
      margin-right: auto
    img
      display: block
      margin: 0 auto
    &__icon
      position: absolute
      width: 74px
      height: 82px
      left: 0
      right: 0
      top: 0
      bottom: 0
      margin: auto
      cursor: pointer
      z-index: 10
      svg
        width: 74px
        height: 82px

  &__content
    flex: 0 0 50%
    > *
      &:not(:last-child)
        margin-bottom: 16px
    &--future
      flex: 0 0 100%

  &__intro
    +typoTextMd
    font-weight: $font-primary-heavy
    text-transform: uppercase
    margin-bottom: 4px!important
    color: $tertiary

  &__title
    +typoH3

  &__date
    display: flex

  &__date-icon
    margin-right: 8px
    svg
      +style-icon(20px, 20px, currentColor)

  &__date-label
    +typoTextMd

  &__description
    +typoTextLg

  &__cta-box
    +res-sm-max
      text-align: center
