
#CybotCookiebotDialog
  +print
    display: none!important
.articolo-page
  &__breadcrumbs-container
    padding-bottom: 40px
    +res-md-max
      padding-bottom: 10px
    +print
      display: none
  &__title
    +font(40, 51, 700)
    font-family: $font-secondary
    margin-bottom: 20px
    +res-md-max
      margin-bottom: 10px
    +print
      +font(30, 41, 700)
  &__abstract
    +font(22, 28, 400)
    font-family: $font-secondary
    margin-bottom: 20px
    color: $grey-3
    +print
      +font(18, 24, 400)
  &__info
    display: flex
    align-items: center
    +font(14, 19, 400)
    margin-bottom: 30px
    +res-md-max
      flex-wrap: wrap
      margin-bottom: 20px
    &__author
      font-weight: 800
      margin-right: 20px
    &__date
      color: $grey-2
      +res-md-max
        order: 3
    &__readtime
      display: flex
      align-items: center
      margin-left: auto
      +res-md-max
        margin-left: 0
    &__tag
      +res-md-max
      +font(12, 26, 900)
      margin-left: auto
      color: #fff
      background: $color-secondary-dark
      padding: 0 6px
      text-transform: uppercase
      +border-radius(4px)
      +res-md-max
        order: 4
      +res-xs-max
        margin-top: 8px
        margin-left: 0
    .icon
      width: 20px
      height: 20px
      margin-right: 10px
  &__head
    .ioplusbar
      +print
        display: none
  &__head-container
    position: relative
    padding: 0 30px
    margin-left: auto
    margin-right: auto
    +res-md-max
      padding: 0 $wcont-padding
    &--articolo
      max-width: $wcont-little-width
    &--lezione
      max-width: $wcont-sportelli-width
  &__content-container
    position: relative
    display: flex
    padding: 0 30px
    margin-left: auto
    margin-right: auto
    +res-md-max
      padding: 0 $wcont-padding
    &--articolo
      max-width: $wcont-little-width
    &--lezione
      max-width: $wcont-width
  &__sticky-container
    position: sticky
    top: 20px
    z-index: 999 // per le modali
    +print
      display: none
    .sticky-actions
      position: absolute
      top: 0
      right: calc(((100vw - #{$wcont-sportelli-width}) / 2) - 60px)
      +res-lg-max
        right: $wcont-padding
  &__summary
    +font(16, 24, 800)
    color: $color-secondary-dark
    +res-md-max
      margin-top: 0!important
    .icon
      +fill-icon($color-secondary-dark)
  &__content
    margin-left: auto
    margin-right: auto
    max-width: $wcont-little-width
    &__imageContainer
      text-align: right
      margin-bottom: 30px
      +font(12, 24, 400)
      +res-md-max
        margin-bottom: 20px
      &__main-image
        +border-radius(20px)
        +print
          display: none

    @media print
      max-width: 100%
      margin-top: 20px
    .pagebuilder-wrapper, .custom-content
      margin-bottom: 20px
      .strumento-card, .wcolor-box
        @media print
          page-break-inside: avoid
      .heading2-secondary,.heading2
        @media print
          +font(25, 31, 700)
      .wvideo
        +print
          display: none
      .block-image
        img
          +print
            max-width: 70%
    .custom-content
      iframe
        +print
          display: none
      p
        line-height: 28px
        margin-bottom: 10px
    &.anteprima
      .pagebuilder-wrapper
        &:before
          z-index: 1
          content: ""
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          background: linear-gradient(transparent 65%, #fff 100%)
      .custom-content
        &:before
          z-index: 1
          content: ""
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          background: linear-gradient(transparent 65%, #fff 100%)
          +print
            display: none
    hr
      margin-bottom: 30px
    .pills
      margin-bottom: 20px
      &:last-child
        margin-bottom: 50px
      +print
        display: none
    .riviste-side
      margin-bottom: 100px
      +res-md-max
        margin-bottom: 40px
  &--lezione
    .articolo-page
      &__breadcrumbs-container
        background: $light-blue-1
        +print
          background: none
      &__head
        .ioplusbar
          margin-bottom: 20px
          +print
            display: none
      &__wrapper
        background: $light-blue-1
        padding-bottom: 5px
        margin-bottom: 50px
        +res-md-max
          margin-bottom: 30px
        +print
          background: none
          margin-bottom: 0
      &__head-container
        +res-lg-max
          padding: 0 calc( #{$wcont-padding} * 4 ) 0 $wcont-padding
        +res-md-max
          padding: 0 $wcont-padding
      &__content-container
        +res-lg-max
          padding: 0 $wcont-padding
        +res-md-max
          padding: 0 $wcont-padding
      &__content
        padding: 0 30px
        margin: 0
        +res-lg-max
          padding: 0 calc( #{$wcont-padding} * 4 ) 0 $wcont-padding
        +res-md-max
          width: 100%
          padding: 0
        .pb-content
          &.active
            min-width: 800px
      &__info
        &__date
          +res-md-max
            margin-right: $wcont-padding
  &--articolo
    .articolo-page
      &__info
        &__author
          +res-md-max
            flex-basis: 80%
        &__date
          +res-md-max
            margin-left: auto
            margin-top: $wcont-padding
        &__readtime
          +res-md-max
            margin-top: $wcont-padding
  &--pdf
    padding: 4rem 2rem
    .tags__item,
    .pills__item,
    .articolo-page__title,
    .articolo-page__author,
    .info__readtime
      font-family: $font-primary
    .articolo-page__author__redazionale
      .icon
        display: none

body
  &.w-toolbar
    .articolo-page
      &__sticky-container
        top: calc( 20px + #{$toolbar-height} )
    &.w-menu-open
      .articolo-page
        &__sticky-container
          z-index: 9 // per il menu
