@import public/sass/abstracts

.form-select
  &__input
    &__control
      cursor: pointer
      border: 1px solid $grey-0!important
      background: $grey-0!important
      color: $black
      width: 100%
      outline: none!important
      box-shadow: none!important
      min-height: 0!important
      border-radius: 12px!important
      &--is-focused
        border-color: $accent-blue!important

    &__placeholder
      margin-top: 2px
      margin-bottom: 2px
      padding-top: 2px
      padding-bottom: 2px

    &__indicator-separator
      display: none!important

    &__dropdown-indicator
      svg
        display: block
        +fill-icon($black)

    &__value-container
      padding: 0!important

    &__single-value
      margin: 0!important
      color: $black!important
      
    &__menu
      margin: 0!important
      +border-radius(12px!important)
      +box-shadow(none!important)
      overflow: hidden
      border: 1px solid $grey-1!important

    &__menu-list
      padding: 0!important
      +scrollbar-style

    &__option
      font-size: 16px!important
      line-height: 22px!important
      padding: 13px 16px!important
      color: $black!important
      cursor: pointer!important
      background: $grey-0!important
      &:hover
        background: $grey-1!important
        color: $black!important
      &--is-focused
        background: $grey-0!important
        color: $black!important
        outline: none!important
      &--is-selected
        background: $grey-2!important
        color: $black!important

    // Disabled
    &--is-disabled
      opacity: .4
      .form-select
        &__input
          &__single-value
            color: $grey-2!important
          &__dropdown-indicator
            svg
              +fill-icon($grey-3)

    &--searchable
      .form-select
        &__input
          &__value-container
            height: 2.8rem
          &__single-value
            top: 50%!important

  // Status
  &--error
    .form-select
      &__input
        &__control
          border-color: $accent-red!important

  // Size    
  &--md
    .form-select
      &__input
        &__control
          min-height: 4.8rem!important
          +typoBase(1.6rem, 2.4rem, $font-primary-medium-2, $font-primary)
          padding: 10px 16px!important
        &__dropdown-indicator
          svg
            +size-icon(13px, 8px)  
  &--sm
    .form-select
      &__input
        &__control
          min-height: 3.6rem!important
          +typoBase(1.4rem, 2rem, $font-primary-medium-2, $font-primary)
          padding: 8px 12px!important
          +border-radius(40px!important)
        &__dropdown-indicator
          svg
            +size-icon(12px, 6px)

  // ColorVariant
  &--white
    .form-select
      &__input
        &__control
          background: $white!important
        &__option
          background: $white!important
          &:not(:last-child)
            border-bottom: 1px solid #BBB