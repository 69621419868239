.progetti-speciali
  &__header
    text-align: center
  &__title
    @extend .ext-heading1
    color: white
    margin-top: 2rem
    margin-bottom: -1rem
    &--black
      color: $color-text
  &__subtitle
    @extend .ext-heading2
    color: white
    margin-top: 1.7rem
    margin-bottom: 3.6rem
  &__abstract
    @extend .ext-abstract-medium
    margin-bottom: 4rem
    text-align: center
    max-width: 100rem
    margin-left: auto
    margin-right: auto
  &__subintro
    margin-top: 3rem
    &.desktop
      +res-sm-max
        display: none
    &.mobile
      +res-md-min
        display: none
  &__main-section
    background: #fff
    +border-top-left-radius($border-radius-big)
    +border-top-right-radius($border-radius-big)
    +res-sm-max
      +border-top-left-radius($border-radius-medium)
      +border-top-right-radius($border-radius-medium)
      padding-bottom: 3rem
  &__cards
    display: flex
    flex-direction: column
    align-items: center
    +res-sm-max
      display: none
    +res-md-min
      flex-direction: row
      justify-content: center
    &__item
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      width: 26.5rem
      height: 30rem
      background: $color-bg
      border-top-left-radius: 9.6rem
      font-size: 2.4rem
      font-weight: $font-bold
      line-height: 2.9rem
      color: $color-secondary-dark
      text-decoration: underline
      padding: 3.5rem 2.5rem 3.5rem 2.5rem
      text-align: center
      &:not(:last-child)
        +res-sm-max
          margin-bottom: 2rem
        +res-md-min
          margin-right: 7rem
      &.trigger
        background: $color-bg
        +res-md-min
          display: none
      img
        width: 17.4rem
        height: 15.9rem
        margin-bottom: 3.7rem
      .icon
        width: 1.8rem
        fill: $color-secondary-dark
        +transition(all 300ms)
  &__section
    +res-sm-max
      margin-bottom: 1rem
      background-color: $color-bg
      +border-top-left-radius(3.5rem)
      .progetti-speciali__section__header
        display: none
      .trigger
        flex-direction: row
        justify-content: space-around
        padding: 1.5rem 0
        align-items: center
        height: 11.5rem
        width: 100%
      .progetti-speciali__cards__item
        img
          width: 9rem
          height: 8.2rem
          margin-bottom: 0
    &__header
      @extend .w-container-fluidright
      padding-right: 1.5rem
      margin-bottom: 1rem
      +res-md-min
        display: flex
        align-items: flex-end
        margin-bottom: 1.5rem
    &__title
      color: $color-secondary-dark
      +res-md-max
        font-size: 3.2rem
        line-height: 4.4rem