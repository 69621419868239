.sidebar
    width: 220px
    background-color: $pb-white
    border-left: 1px solid $pb-grey-80
    display: flex
    flex-direction: column
    overflow: auto
    +scrollbar-style
    color: $pb-grey-100

    &__tabs
        width: 100%
        display: flex
        flex-wrap: wrap
        background: $pb-grey-50

    &__tab-item
        height: 35px
        padding: 10px
        cursor: pointer
        border-right: 1px solid $pb-grey-80
        border-bottom: 1px solid $pb-grey-80
        flex: 1 1 20%
        display: flex
        align-items: center
        justify-content: center
        > *
            &:not(:last-child)
                margin-right: 5px
        svg
            +style-icon(20px, currentColor)
                    

        span
            +typoCaption
        
        &--active
            background-color: $pb-white
            color: $pb-accent-default
            border-bottom-color: $pb-white
        

    &__tab-content
        flex-grow: 1