.contatta-page
    margin-bottom: 9.2rem
    &__content
        margin-top: 6.8rem        
        margin-left: auto
        margin-right: auto
        &__informazioni
            &__title
                color: $color-secondary-dark    
                margin-bottom: 2.2rem
                text-align: center
                line-height: 4.8rem
                font-size: 4rem
                font-family: $font-primary
            &__text
                text-align: center
                margin-bottom: 10rem
                font-family: $font-secondary
    &__header
        margin-top: 2rem        
        margin-left: auto
        margin-right: auto
        border-bottom: 2px solid $color-rivista
        &__title
            color: $color-secondary-dark
            margin-bottom: 2.2rem
            text-align: center
            line-height: 4.8rem
            font-size: 4rem
            font-family: $font-primary
        &__text
            text-align: center
            margin-bottom: 3.3rem
            font-family: $font-secondary
        &__link
            margin-left: auto
            margin-right: auto
        &__links
            text-align: center
            margin-bottom: 6rem
            max-width: 69rem
            margin-left: auto
            margin-right: auto
    &__form-box
        margin-left: auto
        margin-right: auto

.button-submit-richiesta
    text-align: center

.privacy-richiesta
    margin-left: auto
    margin-right: auto
    .registrazione-page__privacy-text
        font-size: 1.6rem
        font-weight: $font-bold
        line-height: 1.9rem
        margin-bottom: 2.2rem
        margin-top: 2.4rem

.ruolo-container
    text-align: center
    &__text
        display: inline-block
        margin-right: 1.8rem
        vertical-align: top
        margin-top: 2rem
        +res-sm-max
            display: none
    &__div-dropdown
        text-align: left
        display: inline-block
        width: 45rem
        +res-sm-max
            width: 100%
        
.submit
    margin-bottom: 5rem

.link-contatta
    display: inline
    color: $color-secondary

.icon--arrow-right
                height: .8rem
                width: 1.6rem
                margin-left: 1rem
                fill: $color-secondary