@import public/sass/abstracts

.selection-button
  position: absolute
  top: 0
  left: 0
  background: $white
  border-radius: 10px 0 12px 0
  padding: 8px
  z-index: 1
  cursor: pointer
  svg
    +style-icon(24px, 24px, $black)
  &:not(.selection-button--selected)
    svg
      opacity: .6
  &--selected
    svg
      +fill-icon($accent-blue)