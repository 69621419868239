@import public/sass/abstracts

.formazione-modulo-card-profilo
  &__head
    display: flex
    &__image
      max-height: 120px
      max-width: 120px
      height: 100%
      width: 100%
    &__container
      margin-left: 20px
      &__intro
        font-weight: 400
        font-size: 16px
        line-height: 24px
        color: $color-text
        margin-bottom: 8px
        text-transform: uppercase
      &__title
        font-weight: 800
        font-size: 24px
        line-height: 30px
        color: $color-text
  &__content
    margin-top: 20px
    &__cta-box
      width: 100%
      text-align: center
      display: flex
      justify-content: space-between
      +res-md-max
        flex-wrap: wrap
      a
        flex: 0 0 49%
        &:last-child
          padding: 10px 14px
        +res-md-max
          flex: 0 0 100%
          &:first-child
            margin-bottom: 10px
