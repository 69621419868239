@import public/sass/abstracts

.form-metodo-pagamento-radio-option
  &__input-wrap
    position: relative
    display: flex
    align-items: center
    cursor: pointer
    color: $black
    padding: 20px
    border: 1px solid $grey-1
    border-radius: 10px
    > *
      &:not(:last-child)
        margin-right: 10px
    +res-md-max
      flex-wrap: wrap
    +res-lg-min
      min-height: 90px

  &__label    
    display: block
    flex: 1
    +typoTextLg
    > *
      display: block

  &__title
    font-weight: $font-primary-heavy
    &--secondary
      color: $color-secondary

  &__description
    display: none

  &__input 
    position: absolute
    left: -9999px

  &__styledinput
    svg
      +style-icon(24px, 24px, currentColor)

  &__importo
    +typoTextLg
    font-weight: $font-primary-heavy
    white-space: nowrap
    
  &__remove
    +typoTextMd
    font-weight: $font-primary-heavy
    cursor: pointer
    margin-left: 30px
    white-space: nowrap
    +res-md-max
      margin-left: auto
  
  &__remove-label
    &--desktop
      +res-md-max
        display: none
    &--mobile
      +res-lg-min
        display: none

  &__image
    

  &--removable
    .form-metodo-pagamento-radio-option
      &__styledinput
        +res-md-max
          align-self: flex-start
      &__label
        +res-md-max
          flex-basis: calc(100% - 34px)
          margin-bottom: 10px
          margin-right: 0
      &__description
        display: block
      &__importo
        +res-md-max
          padding-left: 34px

  &--checked
    .form-metodo-pagamento-radio-option
      &__input-wrap
        border-color: $color-secondary
      &__styledinput
        svg
          +fill-icon($accent-blue)
      &__description
        display: block

  &--readonly 
    .form-metodo-pagamento-radio-option
      &__styledinput
        svg
          +fill-icon($black)

  &--disabled
    .form-metodo-pagamento-radio-option
      &__input-wrap
        color: $grey-1
        cursor: default
      &__styledinput
        svg
          +fill-icon($grey-1)
      &__label
        color: $grey-1

  // Status
  &--error
    .form-metodo-pagamento-radio-option
      &__styledinput
        color: $accent-red

.form-metodo-pagamento-radio
  &__options
    > *
      &:not(:last-child)
      margin-bottom: 20px
    &__more
      margin: 20px 0
      +typoH5