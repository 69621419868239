.strumento-page
  padding-bottom: 3rem
  .breadcrumbs
    margin-bottom: 40px
  .w-container-inner
    padding-bottom: 20px
  &__header-audio-box
    display: flex
    justify-content: center
    align-items: flex-end
    height: 34.5rem
    +res-sm-max
      height: auto
      margin: 5rem 0
    audio
      height: 4rem
  &__header-image-box
    position: relative
    margin-bottom: 50px
    +res-md-max
      display: flex
      margin-bottom: 30px
    figure
      +res-md-max
        margin-left: auto
        margin-right: auto
    img
      +border-radius(5px)
    .ioplus
      position: absolute
      top: 0
      left: 0
      border-top-left-radius: 5px
      z-index: 0
    .icon
      position: absolute
      bottom: 10px
      right: 5px
      width: 30px
      height: 30px
      z-index: 0
      fill: $color-grey-10
      +print
        display: none
  .sticky-actions
    z-index: 999

body
    &.w-toolbar
      .strumento-page
        .sticky-actions
          top: calc( 20px + #{$toolbar-height} )
    &.w-menu-open
      .strumento-page
        .sticky-actions
          z-index: 9 // per il menu