@import public/sass/abstracts

.abbonamento-card
  display: flex
  flex-direction: row
  flex: 1
  position: relative
  margin: 0 auto 20px auto
  background: #ffffff
  max-width: 840px
  width: -webkit-fill-available
  overflow: hidden
  +box-shadow(0px 4px 10px rgba(#000,0.1))
  +border-radius(6px)
  +res-sm-max
    flex-direction: column
    margin: 10px 16px
  &:last-child
    margin-bottom: 120px
  &__label
    position: absolute
    background: $tertiary
    color: #fff
    padding: 10px 16px
    +font(14, 20, 700)
    border-radius: 6px 0 6px 0
    +res-sm-max
      right: 0
      border-radius: 0 6px 0 6px
  &__info
    width: 100%
  &__content
    flex: 1
    padding: 6rem 3rem 3rem 3rem
    +res-sm-max
      padding: 5rem 2rem 3rem 2rem
  &__image-desktop-no-io
    display: flex
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.10)
    align-self: flex-end
    margin-right: 3rem
    margin-bottom: -120px
    +res-sm-max
      display: none
  &__image-desktop
    +res-sm-max
      display: none
  &__image-mobile
    display: none
    +res-sm-max
      display: block
  &__content-description
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 2rem
    +res-sm-max
      flex-direction: column
      align-items: flex-start
    .icon
      width: 79px
      height: 61px
      fill: $color-primary-dark
      margin-right: 2.4rem
      +res-sm-max
        width: 65px
        height: 50px
        margin-right: 0
  &__title
    +typoH4
    margin-bottom: 10px
    +res-sm-max
      +typoH5
      margin-top: 3rem

  &__price
    margin-bottom: 2rem

  &__full-price
    font-size: 2.4rem
    line-height: 2.9rem
    color: $grey-3
    text-decoration: line-through
    margin-right: 10px
    +res-sm-max
      font-size: 18px
      line-height: 22px
  &__final-price
    font-size: 3.6rem
    line-height: 4.3rem
    color: #2A2A2A
    margin-right: 1rem
    font-weight: $font-primary-heavy
    +res-sm-max
      font-size: 24px
      line-height: 29px

  &__cta
    display: flex
    flex-direction: row
  &__button
    color: #0375F8
    display: block
    cursor: pointer
    display: flex
    align-items: center
    +typoTextLg
    font-weight: 700
    margin-left: 2.5rem
    svg
      +style-icon(20px, 20px, currentColor)
      margin-right: 0.6rem
  &__inserto
    position: absolute
    right: 14px
    top: 12px
    width: 175px
    height: 102px
    z-index: 1
    +res-sm-max
      width: 100%
      height: 100%
      position: relative
    &-desktop
      +res-sm-max
        display: none
    &-mobile
      display: none
      +res-sm-max
        display: block
