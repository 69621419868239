@import public/sass/abstracts

.riepilogo-ordine
  background: $grey-0
  padding: 20px
  border-radius: 10px
  &__title
    +typoBase(1.8rem, 2.4rem, $font-primary-heavy)
    margin-bottom: 30px
  &__rows
    +typoTextMd
    > *
      &:not(:last-child)
        margin-bottom: 20px
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    &--promo
      color: $color-secondary
      font-weight: 700
    &--tertiary
      font-weight: 700
      color: $tertiary
  &__row-label
    display: flex
    align-items: center
    svg
      +style-icon(20px, 20px, currentColor)
      margin-right: 10px
  &__totale
    margin-top: 20px
    border-top: 1px solid $grey-2
    padding-top: 20px
    display: flex
    align-items: center
    justify-content: space-between
    +typoTextLg
    font-weight: $font-primary-heavy