@import public/sass/abstracts

.area-personale-agente-card
  background: $white
  padding: 24px 28px
  border-radius: 20px
  &__head
    +res-sm-min
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 12px
  &__title
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
    +res-xs-max
      margin-bottom: 30px
  &__name
    +typoBase(2rem, 3.2rem, $font-primary-regular)
    +res-xs-max
      margin-bottom: 32px
  &__contacts
    +res-md-min
      display: flex
      flex-wrap: wrap
      align-items: center
      > *
        margin: 8px 0
        &:not(:last-child)
          margin-right: 40px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 20px
          
  &__contact
    display: flex
    align-items: center
    &__icon
      margin-right: 10px
      svg
        +style-icon(20px, 20px, currentColor)
    &__value
      +typoTextMd
  &__actions
    margin-top: 8px
    +res-sm-max
      margin-top: 30px
    &__wa
      display: none
      +res-sm-max
        display: inline-flex
        margin-left: 2rem
      +res-xs-max
        margin-left: 0
        margin-top: 2rem