.toolbar-section
    border-bottom: 1px solid $pb-grey-20

    &__button
        cursor: pointer
        +typoTextMd
        font-weight: 600
        padding: 10px 16px

        .disabled
            color: $pb-grey-20
    
    &__content
        padding: 0 16px 12px
        
    &__item
        &:not(:last-child)
            margin-bottom: 10px
        
        &__header
            +typoTextXs
            margin-bottom: 2px

            &__label
                font-weight: 600
                text-transform: uppercase
                color: $pb-grey-100

            &__description
                color: $pb-grey-80

        &--inline
            display: flex
            align-items: center
            justify-content: space-between
            .toolbar-section
                &__item
                    &__header
                        margin-bottom: 0
                        &__label
                            +typoTextSm
                            font-weight: 400
                            text-transform: none
