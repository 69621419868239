@import public/sass/abstracts

.aggiungi-contenuto-card
  padding: 40px 16px
  border-radius: 20px
  background: $white
  text-align: center
  &__icon
    margin-bottom: 16px
    svg
      +style-icon(30px, 30px, $tertiary)
      margin: 0 auto