.ROOT
  .fine-anteprima-box
    margin: 3rem 0
    text-align: center
    background: linear-gradient(0deg, transparent calc(50% - 1px), $color-primary 50%, transparent calc(50% + 1px))
    &::before
      content: "FINE ANTEPRIMA"
      display: inline-block
      text-transform: uppercase
      color: $color-primary
      font-weight: $font-primary-heavy
      padding: 5px 10px
      background: #FFF