.iopiu-home2021
  margin-bottom: 73px
  padding: 62px 50px 54px
  background: linear-gradient(181.1deg, #F78930 1.03%, #FA4F00 89.73%)
  color: #fff
  border-radius: 6px
  +res-lg-min
    display: flex
  +res-md-max
    padding: 35px 24px 50px
  +res-sm-max
    margin-left: -2rem
    margin-right: -2rem
    border-radius: 0
  @media screen and (min-width: 1254px)
    margin-left: 16px
    margin-right: 16px
  
  &__content
    +res-lg-min
      width: 40%
      margin-right: 5%
      position: relative
    +res-md-max
      margin-bottom: 20px
      text-align: center

  &__icon
    display: block
    +res-md-min
      position: absolute
      top: 0
      left: 0
    +res-sm-max
      margin-bottom: 18px
    svg
      +style-icon(71px, 55px, #fff)
      +res-sm-max
        margin: 0 auto

  &__title
    font-size: 3rem
    font-weight: 700
    line-height: 4rem
    margin-bottom: 30px
    +res-lg-min
      padding-top: 22px
      padding-left: 71px
    +res-md-max
      font-size: 2.8rem
      line-height: 4.3rem
      margin-bottom: 20px

  &__description
    font-family: $font-secondary
    font-size: 1.6rem
    line-height: 2.4rem
    margin-bottom: 20px
    +res-lg-min
      min-height: 90px

  &__cta
    +res-md-max
      width: 250px
      max-width: 100%

  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      flex: 1
      > *
        margin-bottom: 50px
        width: 45%
        &:nth-child(2n + 1)
          margin-right: 10%
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 20px

  &__item
    display: flex
    align-items: center

    &__image-box
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      width: 100px
      height: 100px
      background: $color-bg
      margin-right: 16px
      flex: 0 0 100px
      +res-sm-max
        width: 114px
        height: 114px 
        flex-basis: 114px
        margin-right: 20px

    &__title
      flex: 1
      @extend .ext-heading5