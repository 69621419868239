@import public/sass/abstracts

.box-contenuti-libro
  display: flex
  align-items: center
  justify-content: space-between
  background: $color-bg
  padding: 30px 40px
  +border-radius(6px)
  +res-sm-max
    flex-direction: column
    align-items: center
    padding: 40px 30px
  &__content
    +res-md-min
      flex: 1
      margin-right: 60px
    +res-sm-max
      margin-bottom: 20px
  &__title
    +font(20, 26, 750)
    margin-bottom: 10px
  &__description
    +font(14, 20, 400)
  &__cta
    .wbutton__label
      font-weight: 700