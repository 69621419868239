.toolbar
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  padding: 0 2rem
  width: 100%
  height: $toolbar-height
  background: #fff
  z-index: 10000
  +box-shadow(0px 1px 3px 0px $color-grey-9)
  &__user
    font-weight: $font-bold
  &__right
    margin-left: auto
    &__anteprima
      background: $color-primary
    &__caricamento
      cursor: none
  button
    height: 4rem
    line-height: 1rem
  .button    
    padding: 1rem 2rem
    &:not(:last-child)
      margin-right: 1rem
  +res-sm-max
    .button
      &:not(:last-child)
        display: none

@media print
  .toolbar
    display: none
  