@import public/sass/landing-riviste/abstracts

.landing-riviste-cta
  font-family: $font-primary
  padding: 120px 0
  +res-sm-max
    padding: 30px 0 42px
  &__layout
    +res-md-min
      position: relative

  &__image-box
    +res-md-min
      position: absolute
      top: 0
      left: 0
      right: 60%
      max-width: 332px
      margin: 0 auto
      height: 380px
    +res-sm-max
      max-width: 220px
      margin: 30px auto

  &__content
    +res-md-min
      min-height: 380px
      padding-left: calc(40% + 50px)

  &__title
    +typoH1LR
    +res-md-max
      +typoH1MobileLR

  &__description
    +typoBase(24px, 36px, 500)
    margin-top: 48px
    +res-md-max
      +typoBase(14px, 19px, 500)
      margin-top: 24px

  &__cta-box
    > *
      margin-bottom: 10px
    +res-sm-min
      display: flex
      flex-wrap: wrap
      margin-top: 40px
      > *
        &:not(:last-child)
          margin-right: 13px
    +res-sm-only
      justify-content: center
    +res-xs-max
      max-width: 226px
      margin: 0 auto
      > *
        width: 100%