.raccolta-card
  font-family: $font-primary
  position: relative
  width: 21.5rem
  height: 33.8rem
  +border-radius(1.6rem)
  display: flex
  flex-direction: column
  z-index: 1
  // background: $color-grey-12 -> tramite elemento ::after
  +transition(300ms)
  &::after
    position: absolute
    content: ""
    width: 100%
    height: 31.4rem
    left: 0
    top: 2.4rem
    +border-radius(1.6rem)
    background: $color-grey-12
    z-index: -3
    max-height: 100%
  &:hover
    +translateY(-1rem)
  &--disabled
    .raccolta-card
      &__image-box,
        &:after,
        &:before
          opacity: .4
      &__content
        opacity: .4
  .ioplus
    position: absolute
    top: 0
    left: 0
    z-index: 3
  .scarica
    position: absolute
    top: 0
    left: 0
    z-index: 3
  .salva
    position: absolute
    left: 0
    z-index: 2
    &:not(:first-child)
      height: 6.1rem
      padding-top: 2.7rem
      top: 2.9rem
      +transition(all .5s)
  .modifica
    position: absolute
    left: 0
    z-index: 1
    &:not(:first-child)
      height: 6.1rem
      padding-top: 2.7rem
      top: 2.9rem
      +transition(all .5s)
  &__image-box
    max-height: 100%
    height: 19.2rem
    width: 19.2rem
    // height: 29.4rem
    // width: 29.4rem
    background-repeat: no-repeat
    background-size: cover
    background-image: url('/images/card/raccolta.jpg')
    background-position: top
    padding: 1.6rem
    text-align: right
    +border-radius(1.6rem)
    &:before
      position: absolute
      content: ""
      width: 19.2rem
      height: 19.2rem
      left: 1.2rem
      top: 1.2rem
      background: $color-brown
      +border-radius(1.6rem)
      z-index: -1
      max-height: 100%
    &:after
      position: absolute
      content: ""
      width: 19.2rem
      height: 19.2rem
      left: 2.4rem
      top: 2.4rem
      background: $color-primary-light
      +border-radius(1.6rem)
      z-index: -2
      max-height: 100%
  &__content
    padding: 4rem 1.6rem 1.7rem 1.6rem
  &__title
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-size: 1.6rem
    line-height: 2.4rem
    font-weight: $font-bold
    margin-bottom: .5rem
    word-break: break-word
  &__abstract
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-family: $font-secondary
    font-size: 1.4rem
    line-height: 2rem
  &--horizontal
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    &::after
      height: 21.6rem
      top: 0
      left: 2.6rem
      width: calc( 100% - 2.6rem )
    .raccolta-card
      &__image-box
        height: 19.8rem
        min-width: 14.8rem
        width: auto
        &:before, &:after
          width: 14.8rem
          height: 19.8rem
        &::before
          top: 1rem
        &::after
          top: 2rem
      &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        padding-left: 3.2rem
      &__title
        font-size: 2rem
        line-height: 2.6rem
  +res-sm-max
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    &::after
      height: 21.6rem
      top: 0
      left: 2.6rem
      width: calc( 100% - 2.6rem )
    .raccolta-card
      &__title, &__abstract
        -webkit-line-clamp: 3
      &__image-box
        height: 19.8rem
        min-width: 14.8rem
        width: 14.8rem
        &:before, &:after
          width: 14.8rem
          height: 19.8rem
        &::before
          top: 1rem
        &::after
          top: 2rem
      &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        padding-left: 3.2rem
        min-width: 13rem
      
  &--small
    +res-md-min
      width: 19.1rem
      height: 26.8rem
      &:after
        height: 24.4rem
      .raccolta-card
        &__image-box
          width: 17.1rem
          height: 11.8rem
          &:before
            width: 17.1rem
            height: 11.8rem
          &:after
            width: 16.6rem
            height: 11.8rem

  &--horizontal-noimage
      width: auto
      height: 12.7rem
      display: block
      text-align: left
      .ioplus
         
      .raccolta-card
        &__image-box
          display: none
        &__content
          display: flex
          flex-direction: column
          padding: .9rem 1rem 1rem 6rem
          height: 100%
        &__author
          color: inherit
          font-size: 1.4rem
          line-height: 1.6rem
          text-align: right
          margin-top: auto
     