@import public/sass/abstracts

.sticky-steps-item
  display: flex
  align-items: center
  +font(14, 20, 500)
  color: $color-secondary-dark
  +res-md-max
    flex-direction: column
    align-items: flex-start
  &__label
    +res-md-max
      margin-bottom: 10px
  .icon
    width: 16px
    height: 16px
    +fill-icon($color-secondary-dark)
    margin-right: 10px
  &--active
    font-weight: 700
    .icon
      width: 10px
      height: 10px
  &--disabled
    color: $grey-1
    .icon
      width: 6px
      height: 6px
      +fill-icon($grey-1)
  &__progress
    height: 4px
    width: 100%
    background: $color-secondary-dark
    +transition(width $transition-duration)
    +border-radius(2px)
    +res-lg-min
      display: none
    &:after
      content: ""
      position: absolute
      width: calc( 100% - (#{$wcont-padding} * 2) )
      height: 4px
      background: $grey-0
      z-index: -1
      +border-radius(2px)