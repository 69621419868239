.evidenza
  vertical-align: middle
  padding: .4rem 1.2rem
  text-align: center
  font-size: 1.6rem
  line-height: 2.4rem
  text-align: center
  font-weight: $font-bold
  color: $color-secondary
  background: $color-secondary-light
  text-decoration: none
  cursor: pointer
  @extend .overflow-ellipsis
  -webkit-line-clamp: 1
  +border-radius(.8rem)
  &:hover, &:active, &:focus, &:visited, &:link
    color: $color-secondary
    text-decoration: none
  .icon
    width: 24px
    height: 24px
    margin-left: 11px
    fill: #fff
  &--selected
    display: flex
    align-items: center
    background: $color-secondary
    color: #fff
    &:hover, &:active, &:focus, &:visited, &:link
      color: #fff
  &--disabled
    background: $grey-0
    color: #fff
    &:hover, &:active, &:focus, &:visited, &:link
      color: #fff
