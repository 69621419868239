.registrazione-success
  &__content
    text-align: center
    margin-bottom: 12rem
  &__title
    @extend .ext-boxtitle
    margin-top: 2rem
    margin-bottom: 3rem
    text-align: center
    span
      display: block
