.formazione-corsi-page
    background: $accent-blue-light
    margin-bottom: 130px
    +res-sm-max
        margin-bottom: 70px
    &__top
        position: relative
        z-index: 4
    &__header
        text-align: center
        padding-bottom: 45px
        +res-sm-max
            padding-bottom: 20px
        &__title
            @extend .ext-heading1
            color: #fff
            margin-top: 1rem
            margin-bottom: 37px
            +res-sm-max
                margin-bottom: 20px
        &__subtitle
            font-weight: 500
            font-size: 18px
            line-height: 24px
            text-align: center
            margin: auto
            max-width: 720px
            width: 100%
            color: #fff
        &__pagebuilder
            width: 60%
            +res-sm-max
                width: 100%
    &__content
        padding-top: 50px
        padding-bottom: 20px
        +res-sm-max
            padding-top: 40px
            padding-bottom: 10px
    .section
        border-radius: 0
        background: $pb-white
