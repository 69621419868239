.richiedi-rivista-box
  position: relative
  display: flex
  align-items: center
  padding: 3rem 20rem 3rem 0
  margin: 3rem 0
  +res-md-max
    padding-right: 8rem
  +res-sm-max
    flex-direction: column
    padding-right: 0
    padding-bottom: 10rem
  &::before
    content: ""
    position: absolute
    top: 0
    height: 3px
    background: $color-primary
    left: 0
    right: 2rem
  &__content    
  &__title
    font-size: 3.6rem
    font-weight: $font-bold
    line-height: 4.3rem
    margin-bottom: 4rem
    color: $color-primary
    +res-sm-max
      font-size: 2.4rem
      line-height: 2.7rem
      margin-bottom: 3rem
      padding-right: 8rem
  &__description
    font-size: 2.4rem
    line-height: 3rem
    margin-bottom: 5rem   
    +res-sm-max
      font-size: 1.6rem
      line-height: 2rem
      margin-bottom: 2rem
  &__image-box
    width: 33%
    flex: 0 0 33%
    +res-sm-max
      width: auto
      text-align: center
  &__image
    +res-sm-max
      max-height: 22rem
  &__ioplus
    position: absolute
    right: 0
    top: 0
    width: 18rem
    height: 14.4rem
    background: $color-primary
    +border-bottom-left-radius(6rem)
    +border-top-right-radius(.6rem)
    .icon
      fill: #fff
      width: 11rem
      height: 8.5rem
      margin: 2.4rem 0 0 4rem
    +res-md-max
      width: 13rem
      height: 10rem
      .icon
        width: 7rem
        height: 6rem
        margin: 2rem 0 0 4rem
    +res-sm-max
      width: 6rem
      height: 5rem
      +border-bottom-left-radius(2rem)
      .icon
        width: 3.8rem
        height: 3rem
        margin: .8rem 0 0 1rem 

  &__cta-box
    +res-sm-max
      position: absolute
      bottom: 3rem
      left: 0
      right: 0
      text-align: center

