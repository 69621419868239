@mixin typoBase($size, $lineHeight)
  font-size: $size
  line-height: $lineHeight

@mixin typoTextLg
  +typoBase(16px, 20px)

@mixin typoTextMd
  +typoBase(13px, 18px)

@mixin typoTextSm
  +typoBase(12px, 16px)

@mixin typoTextXs
  +typoBase(10px, 14px)

@mixin typoCaption
  +typoBase(10px, 14px)
  font-weight: 600
  text-transform: uppercase
  
@mixin typoCaptionLg
  +typoBase(12px, 16px)
  font-weight: 600
  text-transform: uppercase