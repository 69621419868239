@import public/sass/abstracts

.save
    .wmodal
        max-width: 335px
        padding: 20px
    &--square
        .save
            &__button
                +border-radius(6px)
    &__favourites
        +font(14, 24, 700)
        margin-top: 20px
        padding-bottom: 16px
        color: $color-secondary
        border-bottom: 1px solid $grey-2
        cursor: pointer
        .icon
            width: 24px
            height: 24px
            fill: $color-secondary
            vertical-align: middle
            margin-right: 8px
    &__add
        margin-top: 16px
        max-height: 164px
        overflow-y: scroll
        +scrollbar-style
        &__title
            +font(14, 20, 700)
            margin-bottom: 16px
        &__item
            display: flex
            margin-bottom: 18px
            cursor: pointer
            .icon--lock
                position: absolute
                right: 5px
                width: 16px
                height: 16px
                margin-left: auto
                fill: $grey-1
            input, label
                cursor: pointer
    &__create
        &__title
            +font(14, 20, 700)
            color: $color-secondary
            cursor: pointer
        &__input
            display: flex
            align-items: center
            margin-bottom: 16px
            .form-input
                flex-grow: 1
                &__input
                    line-height: 22px
        &__buttons
            display: flex
            justify-content: space-between
        .icon
            width: 24px
            height: 24px
            fill: $color-secondary
            vertical-align: middle
            margin-right: 8px