@import public/sass/abstracts

.formazione-tag
  padding: 4px 12px
  border-radius: 8px
  display: inline-flex
  align-items: center
  background: $accent-blue-light
  color: $accent-blue
  &:not(:last-child)
    margin-right: 10px
  &--selected
    background: $accent-blue
    color: #fff
  &--clickable
    cursor: pointer

  &__label
    +typoTextLg
    font-weight: $font-primary-heavy

  &__close
    svg
      +style-icon(24px, 24px, currentColor)
