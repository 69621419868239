@import public/sass/landing-riviste/abstracts

.landing-riviste-hero
  position: relative
  overflow: hidden
  color: #000
  +res-md-min
    min-height: 500px

  &--ps
    .landing-riviste-hero
      &__content
        +res-md-min
          width: 50%
      &__asset-image-box
        width: 80%
        bottom: 35%

  &__logo-bg
    position: absolute
    left: -9999px
    right: -9999px
    width: 838px
    height: 837px
    margin: 0 auto
    opacity: .19
    color: $white
    +res-md-min
      top: calc(50% - 400px)
      +translateX(-30%)
    +res-sm-max
      top: calc(50% - 256px)
      width: 512px
      height: 512px
    svg
      +style-icon(838px, 837px, currentColor)
      +res-sm-max
        +size-icon(512px, 512px)

  &__layout
    position: relative
    +res-md-min
      min-height: 500px
    +res-sm-max
      padding-bottom: 210px

  &__images-box
    position: absolute
    bottom: 0
    +translateY(20%)
    z-index: 0
    +res-md-min
      left: 55%
      width: 45%
      height: 600px
    +res-sm-max
      left: 0
      right: 0
      width: 100%
      max-width: 320px
      margin: 0 auto
      height: 300px

  &__asset-image-box
    position: absolute
    bottom: 30%
    width: 60%
    left: 0
    z-index: 1
    > img
      display: block
  
  &__cover-image-box
    position: absolute
    bottom: 0
    width: 65%
    right: 0
    z-index: 2
    max-width: 332px
    > img
      display: block

  &__content
    position: relative
    z-index: 1
    +res-md-min
      width: 50%
      max-width: 615px
      padding-top: 100px
    +res-sm-max
      padding-top: 24px

  &__logo-box
    margin-bottom: 50px
    svg
      +style-icon(405px, 126px, currentColor)
      +res-sm-max
        +size-icon(192px, 68px)
    +res-sm-max
      margin-bottom: 32px
      max-width: 200px

  &__title
    +typoBase(46px, 55px, 800, $font-primary)
    letter-spacing: -0.01em
    +res-md-max
      +typoBase(32px, 36px)