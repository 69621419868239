@import public/sass/abstracts

.cart-template
  padding-bottom: 100px

  &__empty
    padding: 50px 0 100px
    +res-md-min
      min-height: calc(100vh - 354px) // vh - header e footer (con margin e padding vari)
    +res-sm-max
      padding: 30px 0 82px

  &__layout
    +res-md-min
      display: flex
      padding-top: 50px
      min-height: calc(100vh - 354px) // vh - header e footer (con margin e padding vari)
      > * 
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      padding-top: 30px
      > *
        &:not(:last-child)
          margin-bottom: 20px

  &__main
    +res-md-min
      flex: 1

  &__wishlist
    &__items
      display: grid
      grid-template-columns: repeat(3, 1fr)
      gap: 20px
      +res-md-max
        grid-template-columns: repeat(2, 1fr)
      +res-xs-max
        grid-template-columns: repeat(1, 1fr)

  &__sidebar
    +res-md-min
      width: 30%
      min-width: 320px
    > *
      &:not(:last-child)
        margin-bottom: 16px

  &__bottom
    > *
      width: 100%
      &:not(:last-child)
        margin-bottom: 16px

  &__head
    display: flex
    align-items: center    
    margin-bottom: 20px
    > *
      &:not(:last-child)
        margin-right: 4px
  &__title
    +typoH4
    &--wishlist
      +typoH5
  &__num
    +typoTextLg
    font-weight: $font-primary-medium-2
    margin-top: 2px // per allinearlo otticamente al title

  &__notification-bar
    margin-bottom: 20px
    &--wishlist
      margin-top: 20px
      margin-bottom: 40px

  &__products-sections, &__products
    > *
      &:not(:last-child)
        margin-bottom: 20px
        +res-sm-max
          margin-bottom: 10px