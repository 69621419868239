.pills
  &__item
    display: inline-block
    padding: 4px 10px
    +font(14, 20, 700)
    color: #fff
    text-transform: uppercase
    background: $color-primary
    margin-bottom: 10px
    +border-radius(4px)
    &:not(:last-child)
      margin-right: 5px
