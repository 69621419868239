@import public/sass/abstracts

.wautore-card
  display: flex
  background: $white
  border-radius: 12px
  min-height: 145px
  &__image-box
    position: relative
    width: 33.33%
    max-width: 157px
    border-radius: 12px
    overflow: hidden
  &__image
    object-fit: cover
    width: 100%
    height: 100%
  &__content
    padding: 30px 16px 0
    flex: 1
  &__fullname
    +typoH5
    margin-bottom: 4px
  &__type
    +typoBase(1.4rem, 2rem, $font-secondary-regular, $font-secondary)
  &__redazione
    margin-top: 15px
    display: flex
    color: $color-primary
    +typoBase(1.4rem, 2rem, $font-primary-heavy)
    svg
      +style-icon(30px, 30px, currentColor)
      margin-right: 4px