.strumento-summary
  max-width: 542px
  margin-bottom: 60px
  +res-md-max
    margin-bottom: 50px
  &__title
    +font(40, 51, 700)
    font-family: $font-secondary
    color: $color-text
    margin-bottom: 16px
    +res-md-max
      +font(28, 35, 700)
  &__abstract
    +font(16, 21, 400)
    margin-bottom: 24px
  &__cta
    margin-bottom: 30px
    +res-md-max
      display: none!important
    .icon
      width: 20px
      height: 20px
      margin-right: 8px
      vertical-align: middle
  &__pills
    margin-top: 30px
  &__author
    +font(14, 19, 800)
    font-size: 1.6rem
    line-height: 3rem
    margin-top: 10px
    +res-md-max
      margin-top: 20px
