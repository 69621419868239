@import public/sass/abstracts

.area-personale-raccolte-template
  .area-personale-page
    &__head
      +res-sm-max
        display: flex
        align-items: center
        justify-content: space-between
    &__head-actions
      margin-top: 0
      margin-left: 20px
  &__add-button
    +res-sm-max
      display: none
  .area-personale
    &__listing
      display: flex
      flex-wrap: wrap
      margin-right: -1.5rem
      .raccolta-card
        flex: 0 0 25%
        margin-bottom: 2.4rem
        margin-right: 1.5rem
        max-width: 21.7rem
        +res-sm-max
          max-width: 100%
      &.horizontal
        .raccolta-card
          flex: 50%
          max-width: 44.8rem
          &:nth-child(odd)
            margin-right: 2rem