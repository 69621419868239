@import public/sass/abstracts

.widget-iopiu
  padding: 25px 20px
  text-align: center
  background: $grey-0
  border-radius: 20px
  +res-md-min
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
  &__head
    display: flex
    align-items: flex-end
    justify-content: center
    color: $color-primary
  &__title
    +typoH4
    margin-right: 16px
  &__icon
    +style-icon(64px, 50px, currentColor)
  &__description
    +typoTextLg
    margin-top: 20px