.tags
  &--margin
    margin: 4rem 0 2rem 0
  &__item
    @extend .ext-tag
    font-size: 1.5rem
  // Se è un link metto l'effetto sull'hover
  a.tags__item
    &:hover
      text-decoration: underline
      