@import public/sass/abstracts

.prodotti-cart-accordion
  background: $accent-blue-light
  border-radius: 10px

  &__trigger
    +typoTextLg
    font-weight: $font-primary-heavy
    display: flex
    align-items: center
    width: 100%
    cursor: pointer
    text-align: left
    padding: 20px
    color: $accent-blue
    svg
      margin-right: 10px
      +style-icon(24px, 24px, $accent-blue)

    
  &__arrow
    margin-left: auto
    svg
      +style-icon(24px, 24px, currentColor)

  &__content
    max-height: 0
    overflow: hidden
    +transition(max-height .3s linear)

  &__inner-content
    padding: 0 20px 20px
    > *
      &:not(:last-child)
        margin-bottom: 12px