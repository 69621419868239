@import public/sass/abstracts

.checkout-template
  padding-bottom: 100px
  +res-sm-max
    padding-bottom: 30px

  &__layout
    +res-md-min
      display: flex
      padding-top: 45px
      > * 
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      padding-top: 20px
      > *
        &:not(:last-child)
          margin-bottom: 20px

  &__main
    +res-md-min
      flex: 1

  &__sidebar
    +res-md-min
      width: 30%
      min-width: 320px
    > *
      &:not(:last-child)
        margin-bottom: 16px

  &__bottom
    > *
      width: 100%

  &__stepbar
    margin-bottom: 48px
    +res-sm-max
      margin-bottom: 40px

  &__content
    > *
      &:not(:last-child):not(.checkout-template__notification-bar)
        margin-bottom: 48px

  &__fatturazione-field
    margin-top: 20px

  &__selected-addresses
    margin-bottom: 20px
    +res-lg-min
      display: flex
      > *
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 16px
    +res-md-only
      > *
        &:not(:last-child)
          margin-bottom: 16px
    +res-sm-max
      display: none

  &__notification-bar
    margin-bottom: 20px

  &__abbonamenti-modal
    .wmodal__head
      margin-bottom: 0
    .wmodal__content
      text-align: center
