// TODO - da sistemare dopo inserimento plugin
.simpleslider
  &__items 
    // overflow-x: hidden
    display: flex
  &__item 
    flex: 0 0 45rem
    width: 45rem
    &:not(:last-child)
      margin-right: 2rem
    a
      img
        margin: auto
        border: 1px solid #ccc
  &__dots
    margin-top: 3rem
    display: flex
    justify-content: center
    align-items: center
  &__dot
    width: 1.2rem
    height: 1.2rem
    +border-radius(50%)
    background: #E5E5E5
    &:not(:last-child)      
      margin-right: 1.5rem 
    &--active
      background: $color-secondary

  .slick-list
    width: 100%

  .slick-dots
    margin-top: 3rem
    display: flex
    justify-content: center
    align-items: center
    li
      width: 1.2rem
      height: 1.2rem
      +border-radius(50%)
      background: #E5E5E5
      &:not(:last-child)      
        margin-right: 1.5rem 
      button:before, button:hover
        opacity: 0
    .slick-active
      background: $color-secondary

