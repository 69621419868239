@import public/sass/abstracts

.prodotto-cart-card
  display: flex
  flex-direction: column

  &__image-box
    margin-right: 20px
    width: 70px
    height: 88px

  &__main
    display: flex
    flex: 1

  &__content
    flex: 1

  &__intro
    +typoTextSm
    font-weight: $font-primary-heavy
    text-transform: uppercase
    color: $grey-2
    margin-bottom: 2px

  &__title
    +typoTextLg
    font-weight: $font-primary-black-2

  &__price
    margin-top: 10px
    display: flex
    align-items: center
    flex-wrap: wrap
    
  &__full-price
    +typoTextMd
    color: $grey-3
    text-decoration: line-through
    margin-right: 10px

  &__final-price
    +typoTextLg
    font-weight: $font-primary-heavy
    color: $tertiary

  &__label
    margin-left: 10px

  &__actions
    display: flex
    justify-content: space-between
    margin-top: 6px
    margin-left: auto
    &--no-quantity
      justify-content: flex-end 
      margin-top: 12px

  &__quantity
    +font(14, 24, 400)

  &__remove
    margin-left: 45px
    +typoTextMd
    font-weight: $font-primary-heavy
    cursor: pointer

  &__dates
    display: flex
    align-items: center
    margin-top: 12px
    margin-left: auto
    &__label
      +font(12, 16, 400)
      margin-right: 12px
    &__input
      min-width: 165px
      &.form-select
        min-width: 165px
        max-height: 36px
        +border-radius(40px)
      .form-input__input
        max-height: 36px
        +border-radius(40px)
        padding: 8px 12px
      .form-select__input,
      .form-select__input__control
        min-width: 165px
        max-height: 36px
        +border-radius(40px)

  &__garanzia
    margin-top: 10px

  &__discount
    +typoTextMd
    font-weight: $font-primary-heavy
    padding: 2px 8px
    border-radius: 8px
    background: $tertiary
    color: $white
    margin: 0 10px
  
  &--boxed
    padding: 16px
    border-radius: 10px
    background: $white
    
  &--bordered
    border: 1px solid $grey-1

  &--wide
    flex-direction: row
    align-items: center
    +res-xs-max
      flex-direction: column
    .prodotto-cart-card
      &__main
        display: flex
        align-items: center
      &__actions
        margin-left: auto
      &__remove
        margin-left: 30px

  &--wishlist
    flex-direction: column
    .prodotto-cart-card
      &__main
        flex-direction: column
        align-items: flex-start
      &__image-box
        margin-bottom: 12px