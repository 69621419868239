@import public/sass/abstracts

.widget-image-text
  padding: 25px 20px
  text-align: center
  background: $grey-0
  border-radius: 20px
  &__image-box
    margin-bottom: 16px
  &__image
    display: block
    max-width: 100%
    margin: 0 auto
    max-height: 110px 
  &__title
    +typoH5
    @extend .overflow-ellipsis !optional
  &__description
    margin-top: 8px
    +typoTextLg
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2