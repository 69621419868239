@import public/sass/abstracts

.form-radio-option
  &__input-wrap
    position: relative
    display: block
    cursor: pointer
    color: $black

  &__label    
    display: block

  &__input 
    position: absolute
    left: -9999px

  &__styledinput
    position: absolute
    left: 0
    svg
      display: block
      +fill-icon(currentColor)

  &--checked
    .form-radio-option
      &__styledinput
        svg
          +fill-icon($accent-blue)

  &--readonly 
    .form-radio-option
      &__styledinput
        svg
          +fill-icon($black)

  &--disabled
    .form-radio-option
      &__input-wrap
        color: $grey-1
        cursor: default
      &__styledinput
        svg
          +fill-icon($grey-1)
      &__label
        color: $grey-1

  // Status
  &--error
    .form-radio-option
      &__styledinput
        color: $accent-red

  // Size 
  &--md
    min-height: 24px
    +typoBase(1.4rem, 2rem, $font-primary-regular)
    .form-radio-option
      &__styledinput
        top: 0
        svg
          +size-icon(24px, 24px)
      &__label
        padding-left: 32px
        padding-top: 2px

.form-radio
  &__options
    display: flex
    flex-wrap: wrap
    > *
      margin-right: 40px
      margin-bottom: 16px