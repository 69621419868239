.area-personale
  @media (min-width: 1280px)
    position: relative
    &::before
      content: ""
      position: absolute
      top: 0
      bottom: 0
      left: 0
      width: calc(50% - 64rem)
      background: $color-bg
  &__side
    display: flex
    flex-direction: column
    align-items: center
    background: $color-bg
    padding-top: 3.5rem 
    padding-bottom: 6.5rem
    +res-sm-max
      display: none
    &__head
      display: flex
      flex-direction: column
      align-items: center
      width: 20rem
      margin-bottom: 3.8rem
      figure
        position: relative
        display: flex
        flex-direction: column
        align-items: center
        .avatar-box         
          position: relative 
          width: 13rem
          height: 13rem
          +border-radius(1.5rem)
          margin-left: auto
          margin-right: auto
        .avatar
          width: 13rem
          height: 13rem
          +border-radius(1.5rem)
        .icon--user
          width: 13rem
          height: 13rem
          background: $color-grey-1 
          +fill-icon($color-grey-2)
          +border-radius(1.5rem)
        .avatar-edit
          position: absolute
          top: 0
          right: -1.7rem
          width: 3.3rem
          height: 3.3rem
          background: $color-secondary
          +border-radius(50%)
          text-align: center
          padding-top: .7rem
          .icon
            width: 1.5rem
            height: 1.5rem
            +fill-icon(#fff)
        figcaption
          margin-top: 1rem
          margin-bottom: 2.5rem
          font-weight: $font-bold
          font-size: 2.4rem
          text-align: center
          .bio
            font-family: $font-secondary
            font-size: 2rem
    &__nav
      width: 100%
      ul
        li
          line-height: 5rem
          margin-left: -2rem
          margin-right: -2rem
          padding-left: 2rem
          padding-right: 2rem
          &.current
            font-weight: $font-bold
            background: $color-bg-current
      .nav-badge
        display: inline-block
        margin-left: 1.5rem
        background: $color-secondary
        +border-radius(50%)
        width: 2.7rem
        height: 2.7rem
        font-size: 1.6rem
        line-height: 3rem
        text-align: center
        color: #ffffff
      .first-level
        &__logout
          cursor: pointer
      .second-level
        li
          padding-left: 4.5rem
  &__main
    padding-bottom: 3rem    
    +res-md-min
      padding-left: 4rem
    .libreria
      margin-top: 6rem
      margin-bottom: 1.5rem
      &__title
        @extend .ext-boxtitle
        margin-bottom: 3rem
      &__subtitle
        margin-bottom: 2.4rem
        .label
          font-size: 2.4rem
          font-weight: bold
      &__description
        margin-bottom: 3rem
        .label
          font-size: 2rem
    .libreria-community
      margin-top: 6rem
      margin-bottom: 6rem
      &__content
        margin-top: 2.9rem
      &__box
        background: $color-primary
        border-radius: 5px
        .right-side
          padding-top: 3rem
          padding-bottom: 3rem
          .label-big
            padding-top: 2rem
            font-size: 4rem
            color: #FFFFFF
            font-weight: $font-bold
          .label
            padding-top: 3rem
            font-size: 2rem
            color: #FFFFFF
          .label-small
            padding-top: 3rem
            font-size: 1.8rem
            color: #FFFFFF
            .bold
              font-weight: $font-bold
            span
              cursor: pointer
          .button
            margin-top: 3rem 
            background: #fff
            color: $color-red
    .libreria-raccolta-item
      margin-bottom: 5rem
      text-align: center
    .libreria-codice-valido      
      margin-left: auto
      margin-right: auto
      background: $color-bg
      padding: 2rem
      margin-bottom: 4rem
      &__box
        display: flex 
        flex-direction:wrap
        background: $color-bg     
        max-width: 67.2rem
        margin-left: auto
        margin-right: auto        
      &__content
        flex: 1
        .label-big
          padding-top: 2rem
          padding-bottom: 1rem
          font-size: 2.4rem
          font-weight: $font-bold
        .descrizione
          padding-bottom: 3.5rem 
          line-height: 2.6rem         
        .sezione-dedicata          
      &__image
        width: 15.9rem    
        margin-right: 5.2rem
       
      
    .libreria-listing
      &__list-section
        margin-bottom: 10.8rem
        margin-top: 6rem
        background: #fff
        +border-top-left-radius($border-radius-big)
        +border-top-right-radius($border-radius-big)
        +res-sm-max
          +border-top-left-radius($border-radius-medium)
          +border-top-right-radius($border-radius-medium)
          padding-bottom: 5rem
        .link
          text-align: end
        .button
          border: none
        .label
          font-size: 2.4rem
          font-weight: bold
          margin-bottom: 4rem
        .listing
          &__options
            display: flex
            justify-content: space-between
            margin-bottom: 2rem
            margin-left: 3.125rem 
            margin-right: 3.125rem
            +res-lg-max
              margin-left: 4.166rem 
              margin-right: 4.166rem
            +res-md-max
              margin-left: 6.25rem 
              margin-right: 6.25rem
            +res-sm-max
              display: none
            > *
              display: flex
              align-items: flex-start
              &:not(:last-child)
                margin-right: 2rem
              > *
                margin-bottom: 1rem
                margin-right: 2rem
            &-left
              flex: 1
              justify-content: flex-start
              flex-wrap: wrap
            &-right
              
          &__mobile-options
            margin-bottom: 3.5rem
            display: none
            +res-sm-max
              display: block
          &__list
            display: flex
            justify-content: flex-start
            flex-wrap: wrap
            &__item
              width: 25rem
              margin-bottom: 5rem
              display: flex
              flex-direction: column
              align-items: center
              flex: 0 0 25%
              +res-lg-max
                flex: 0 0 33%
              +res-md-max
                flex: 0 0 50%
              +res-sm-max
                flex: 0 0 100%
              .heading3
                margin-bottom: .5rem
              .abstract-small
                margin-bottom: .5rem
              .author
                margin-bottom: .5rem
              &__image-box
                position: relative
              .tags
                font-size: 1.6rem
                font-weight: $font-bold
                line-height: 2.4rem
                text-transform: uppercase
                min-height: 4.8rem
                margin-bottom: .5rem
              .author
                font-weight: 100
                font-size: 1.4rem
                .icon
                  +fill-icon($color-primary)
                  width: 3rem
                  height: 3rem
                  vertical-align: bottom
              &__footer
                border-top: 1px solid $color-star
                padding: .5rem 0
                .info
                  display: flex
                  justify-content: space-between
                  time
                    font-size: 1.4rem
                    font-style: italic
                    font-weight: 100
              &__stars
                margin-top: 1rem
              .autore-card
                background: $color-bg
                margin-top: 3rem
                margin-bottom: -1rem

  &__titolo
    @extend .ext-boxtitle
    padding-top: 5rem
    margin-bottom: 2.7rem
  &__sottotitolo
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 3.2rem
    margin-bottom: 5rem
  &__intro
    font-size: 1.8rem
    line-height: 2.1rem
    margin-bottom: 2rem
  &__descrizione
    font-family: $font-secondary
    font-weight: $font-regular
    font-size: 2rem
    line-height: 3.2rem
    margin-bottom: 4rem
    &--home
      margin-bottom: 0
  &__ricerca
    margin-bottom: 4rem
  &__listing
  &__block
    padding: 3rem 0
    &:not(:last-child)
      border-bottom: 2px solid $color-rivista
    &-title
      font-size: 4rem
      font-weight: $font-bold
      line-height: 4.4rem
      color: $color-secondary-dark
      margin-bottom: 2rem
    &-description
      font-family: $font-secondary
      font-size: 2rem
      line-height: 3.2rem
    &-cta-container
      display: flex
      justify-content: flex-end
      margin-top: 2.5rem
      > *
        &:not(:last-child)
          margin-right: auto
  &__shortcuts
    margin-bottom: 5rem
    li 
      display: inline
      &:not(:last-child)
        &::after
          content: " | "
          display: inline
          color: #909090
  &__shortcut
    color: $color-secondary
    text-decoration: underline
  &__lib-main-contents
    &__intro
      font-family: $font-secondary
      font-size: 2rem
      line-height: 3.2rem
      margin-bottom: 3rem
      a
        text-decoration: underline
  &__deactivate
    font-size: 1.8rem
    line-height: 2.1rem
    color: #909090
    &-link
      text-decoration: underline
  &__vantaggi
    margin-top: 5rem

  &-adozionali
    +res-md-min
    padding-left: 2rem
    &__titolo
      @extend .ext-boxtitle
      padding-top: 3.5rem
      margin-bottom: 2.7rem
      color: $color-secondary-dark
      font-size: 4rem
      font-weight: $font-bold
      line-height : 4.8rem
      font-family: $font-primary
    &__descrizione
      margin-bottom: 4rem
      font-family: $font-secondary
      font-weight: $font-regular
      font-size: 2rem
      line-height : 3.2rem
    &__list
      margin-top: 2rem
      &-title
        margin-bottom: 2rem
      &-content
        li
          list-style-type: disc
          list-style-position: outer
          margin-left: 3.2rem


.box-codice      
  .w-form__field-wrapper
    margin-bottom: .5rem
    min-height: 0     
  &__submit-box
    +res-sm-max
      text-align: center
.raccolte-sbloccate
  &__head    
    margin-bottom: 5rem
    +res-sm-min
      display: flex
      justify-content: space-between
    .raccolte-sbloccate
      &__title
        margin-bottom: 0
        +res-xs-max
          margin-bottom: 2rem
  &__title
    font-size: 2rem
    font-weight: $font-bold
    line-height: 3.2rem
    margin-bottom: 5rem
  &__list
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    border-bottom: 2px solid $color-rivista
    margin-bottom: 5rem
    > *
      margin: 0 3.5rem 5rem 2rem

.modal
  &__content
    width: 70rem
    .title
      margin: 2rem 0
    .crea__edit-form--modal    // anche per modal fuori dalla section .crea
      margin-top: 4rem
      margin-bottom: 0
      .tooltip-trigger
        position: absolute
        width: 1rem
        height: 1rem
        text-align: center
        border: 1px solid $color-grey-4
        color: $color-grey-4
        font-size: .8rem
        line-height: 1rem
        +border-radius(1rem)
      .dati__cta-container
        text-align: center
        button
          margin-top: 3.5rem
          margin-bottom: 2rem     

.riviste-list
  &__item
    padding-bottom: 3.6rem
    margin-bottom: 4.5rem
    border-bottom: 2px solid $color-rivista
    &-preview
      margin-bottom: .8rem
    &-numero
      font-size: 2.4rem
      line-height: 4.8rem
      margin-bottom: 2.8rem
    &-abstract
      @extend .ext-paragraph2
      font-size: 2rem
      margin-bottom: 2.8rem
    &-separator
      margin-top: 3.5rem
    &-actions
      margin-top: 4.6rem
    &-action
      margin-top: 1rem
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      align-items: center
    &-info
      font-size: 1.6rem
      line-height: 3.2rem
      margin-top: 1rem
    &-cta-intro
      font-size: 1.6rem
      line-height: 3.2rem
    &-cta-box
      padding-left: 3rem
      margin-left: auto
      text-align: right


.dati
  &__section
    padding: 3rem 0
    &:not(:last-child)
      border-bottom: 2px solid $color-rivista
  &__list
    font-size: 1.6rem
    form
      margin-top: 2.2rem
  &__label
    font-size: 1.2rem
    opacity: .6
  &__value
    margin-bottom: 2rem  

.scuole
  .registrazione-page__form__scuola
    display: none
  &__list
    margin-bottom: 1.5rem
    &__item
      margin-bottom: 3.5rem
  &__button
    display: block
    padding: 0 
    font-family: $font-primary
    .icon
      width: 2rem
      height: 2rem
      vertical-align: middle
      +fill-icon($color-secondary)
      margin-right: .4rem
.pubblico
  &__option
    &:not(:last-child)
      margin-bottom: 3rem
  &__title
    font-size: 2rem
    font-weight: $font-bold
    line-height: 3.2rem
  &__disclaimer
    font-size: 2rem
    line-height: 3.2rem
  &__flag
    margin-top: 1.6rem
    font-size: 1.6rem
    line-height: 5.6rem
  &__fields
    margin-top: 2rem
.privacy
  font-size: 1.4rem
  line-height: 2rem
  &__commerciale
    margin-bottom: 1.6rem
  &__profilazione
    margin-bottom: 3.5rem
  &__link
    font-size: 1.6rem
    color: $color-secondary
    text-decoration: underline
.seguiti
  &:first-of-type
    margin-top: 4rem
  &__title
    font-size: 2.4rem
    margin-bottom: 2.5rem
  &__list
    &__item
      font-size: 1.6rem
      line-height: 2.4rem
      margin-bottom: 2rem
      .icon
        width: 2.5rem
        height: 2.5rem
        margin-right: 1rem
        vertical-align: middle
        cursor: pointer
      .w-form__checkbox-wrapper
        font-size: 1.6rem
        // line-height:
        margin-bottom: 2rem
  .separator
    margin-bottom: 3rem
.crea
  margin-top: 6rem
  &__title
    @extend .ext-boxtitle
    margin-bottom: 1rem
  &__disclaimer
    @extend .ext-paragraph2
    font-size: 2rem
    margin-bottom: 3.7rem
  &__cta
    margin-bottom: 5.5rem
  &__listing
    margin-left: -3rem   // accrocchio provvisorio
    .listing-page__list-section
      padding: 0
      .w-container
        margin: 0
        padding: 0
  &__back
    margin-bottom: 3.6rem
  &__edit-form
    .tooltip-trigger
      position: absolute
      width: 1rem
      height: 1rem
      text-align: center
      border: 1px solid $color-grey-4
      color: $color-grey-4
      font-size: .8rem
      line-height: 1rem
      +border-radius(1rem)
    &--modal
      margin-top: 4.5rem
      margin-bottom: 5.5rem
  &__delete
    margin-bottom: 4.8rem

.area-personale-home
  &__navinfo
    position: relative
    background: $color-red-light
    +border-radius(2rem)
    color: $color-red
    padding: 2rem 3rem
    font-size: 1.8rem
    line-height: 2.1rem
    margin-bottom: 3rem
    &--mobile
      +res-md-min
        display: none
    &--desktop
      width: 50%
      margin-left: auto
      +res-sm-max
        display: none
    &-arrow
      position: absolute
      top: 50%
      right: 100%
      width: 100%
      border: 1px solid $color-red
      height: 0
      &::before
        content: ""
        position: absolute
        left: 0
        top: -1rem
        width: 2rem
        height: 2rem
        border-bottom: 2px solid $color-red
        border-left: 2px solid $color-red
        +rotate(45deg)

  &__expiring
    margin: 2rem 0
    display: flex
    align-items: center
    justify-content: space-between
    &-text
      font-size: 1.4rem
      line-height: 1.7rem
      +res-sm-max
        span
          display: block


.userlib-box
  background: $color-secondary
  +border-radius(2rem)
  padding: 1.7rem 2.1rem 2.5rem
  margin-bottom: 3rem
  min-height: 25rem
  +res-sm-max
    min-height: 0
  &__arrow
    position: relative
    display: block
    width: 5.8rem
    height: 5.8rem
    background: $color-secondary-dark
    +border-radius(50%)
    margin-bottom: 1.5rem
    .icon
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      margin: auto
      width: 2.6rem
      height: 2.6rem
      fill: #fff
      +rotate(180deg)
  &__title
    font-size: 2.4rem
    line-height: 2.7rem
    color: #fff
    margin-bottom: 2.5rem
  &__description
    font-size: 1.5rem
    line-height: 1.9rem
    color: #fff
  &--adottatario
    background: $color-rivista
    .userlib-box
      &__arrow
        background: #000
      &__title
        color: #000
      &__description
        color: #000
  &--fullheight
    +res-md-min
      height: calc(100% - 3rem)



.iobanner
  position: relative
  background: $color-primary
  padding: 2.5rem 3.2rem 
  +res-sm-max
    padding: 7rem 1.7rem 2.7rem
  &__icon-box
    position: absolute
    top: 0
    right: 0
    width: 33%
    height: calc(100% - 3rem)
    +border-bottom-left-radius(5rem)
    background: $color-red
    .icon
      position: absolute
      fill: #fff
      top: 0
      left: 1rem
      right: 0
      bottom: 1rem
      width: 17rem
      height: 13rem
      margin: auto
    +res-sm-max
      width: 10rem
      height: 8.8rem
      .icon
        width: 6.6rem
        height: 5rem
  &__claim
    font-size: 4.5rem
    font-weight: $font-bold
    line-height: 1
    margin-bottom: 5rem
    &-black
      display: block
      color: #000000
    &-white
      display: block
      color: #fff
  &__cta
    +res-sm-max
      display: block
      margin-left: auto
      margin-right: auto
      max-width: 22rem
      text-align: center

.vantaggio-box
  margin-bottom: 5rem
  &__title
    font-weight: $font-bold
    font-size: 1.8rem
    line-height: 2.1rem
  &__description
    font-family: $font-secondary
    font-size: 1.8rem
    line-height: 2.1rem

.iovantaggibanner
  background: linear-gradient(180deg, #F5F0E8 0%, #F5F0E8 15%, #fff 100% )
  padding: 3rem
  margin-bottom: 5rem
  &__list
    display: flex
    justify-content: space-around
    +res-xs-max
      display: block
  &__item
    flex: 1
    margin: 0 2rem 2rem
    max-width: 14rem
    +res-xs-max
      margin: 0 auto 4rem

  &__item-image
    position: relative
    width: 13rem
    height: 13rem
    +border-radius(50%)
    background: #fff
    margin-bottom: 2rem
    margin-left: auto
    margin-right: auto
    .icon
      position: absolute
      width: 6.4rem
      height: 6.4rem
      margin: auto
      top: 0
      left: 0
      right: 0
      bottom: 0
      fill: $color-red
  &__item-description
    font-size: 1.3rem
    line-height: 1.5rem
    text-align: center
    color: #666666
  &__caption
    font-size: 2rem
    font-weight: $font-bold
    line-height: 3.5rem
    text-align: center
    margin-bottom: 2rem
  &__cta-box
    text-align: center
  &__cta
    +res-md-min
      text-transform: uppercase
      background: $color-primary!important

.richiestevalidazione
  &__filter
    margin: 1rem 0
    ::placeholder
      font-family: $font-primary
      font-size: 1.6rem
      line-height: 1.9rem
      color: $color-text
  &__query
    width: 100%
    padding: 0 1.5rem
    border: 2px solid $color-grey-7 
    +border-radius(10rem)
    min-height: 4rem
    font-family: $font-primary
    font-size: 1.6rem
    line-height: 1.9rem
  &__list
    margin: 3rem 0