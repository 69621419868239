@import public/sass/abstracts

.voce-menu-secondo-livello-mobile
  padding: 16px 20px
  overflow: hidden
  max-height: 56px
  +transition(max-height .3s linear)
  &--open
    max-height: fit-content
  &__trigger
    display: flex
    align-items: center
    +font(16, 24, 700)
    &__arrow
      +rotate(-90deg)
      margin-left: auto
      svg
        +style-icon(20px, 20px, currentColor)
