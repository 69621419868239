.numero-rivista-page
  position: relative
  &__bg
    position: absolute
    top: 0
    left: 0
    width: calc(50% - 32rem)
    bottom: 0 // per overlay footer
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
    @media (max-width: 1280px)
      width: 25%
    +res-sm-max
      width: 100%
      height: 43.3rem
    +print
      display: none
  &__container
    position: relative
    .breadcrumbs
      position: absolute
      z-index: 9


  &__side
    padding-top: 6.5rem
    +res-sm-max
      height: 43.3rem
    +print
      height: 0
      padding-top: 0
  &__image
    max-width: 25.3rem
    max-height: 35.3rem
    margin-left: auto
    margin-right: auto
    display: block
  &__content
    padding-top: 6.5rem
    padding-left: 2rem
    +print
      padding: 0
  &__vedi_tutti
    margin-bottom: 2.6rem
  &__descrizione
    font-family: $font-secondary
    font-size: 2rem
    line-height: 3.2rem
    margin-bottom: 1.4rem
  &__title
    margin-top: 0.6rem
    margin-bottom: 2rem
    &-image
      display: inline-block
      max-width: 17rem
      margin-right: 1rem
    &-h1
      font-size: 2.4rem
      font-weight: $font-bold
      line-height: 4.8rem

  &__prefooter
    padding-bottom: 6rem
    +print
      display: none
    > * > *
      &:not(:last-child)
        margin-bottom: 5rem

.elements
    margin: auto


.switch__riviste
  position: relative
  font-size: 2rem
  +print
    display: none
  &__checkbox 
    height: 0
    width: 0
    margin-left: auto
    visibility: hidden
    &:checked + .switch__riviste__label .switch__riviste__current 
      right: 100%
      transform: translateX(100%)
    &:checked + .switch__riviste__label .switch__riviste__other 
      right: 0
      transform: translateX(100%)
  &__label 
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    height: 4.8rem
    background: white
    +border-radius(10rem)
    border: 2px solid $color-secondary
    position: relative
    transition: background .2s
    padding: .4rem 1.3rem
    overflow: hidden
    // &:active
    //   .switch__riviste__current 
    //       width: 50%
    .switch__riviste__other
      display: flex
      justify-content: center
      align-items: center
      content: ''
      position: absolute
      left: 0
      width: 50%
      height: 3.5rem
      color: $color-secondary
    .switch__riviste__current
      display: flex
      justify-content: center
      align-items: center
      content: ''
      position: absolute
      right: 0
      width: 50%
      height: 4.8rem
      transition: 0.2s
      background: $color-secondary
      color: #fff
      padding: .4rem 1.3rem

.discipline-page__list.qualcosa
  margin: 5rem 0

.temi-page__list
  margin: 5rem 0
     
