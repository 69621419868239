@import public/sass/abstracts

.terzo-desktop
  display: flex
  align-items: center
  padding-left: 10px
  max-width: 210px
  +font(15, 16, 500)
  &:hover
    font-weight: 700
  +res-md-max
    max-width: 100%
    +font(16, 24, 500)
    .icon
      margin-left: 6px
  .icon
    width: 18px
    height: 18px
    fill: $color-text
    margin-left: 4px