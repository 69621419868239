@import public/sass/abstracts

.card-adozionale
  max-width: 308px
  padding: 30px 16px
  width: 100%
  +border-radius(6px)
  +res-md-min
    text-align: center
  &__image
    overflow: hidden
    +border-radius(6px)
  &__title
    +font(18, 24, 800)
    margin-bottom: 8px
    margin-top: 20px
    +res-sm-max
      font-size: 16px
  &__description
    +font(14, 20, 400)
    margin-bottom: 8px
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    +res-sm-max
      -webkit-line-clamp: 4
  &__cta
    span
      font-weight: 700
