.u-pos-relative
  position: relative

.u-hidden
  display: none

.u-align-left
  text-align: left!important
.u-align-center
  text-align: center!important
.u-align-right
  text-align: right!important

.u-col-valign-top
  align-self: flex-start!important
.u-col-valign-center
  align-self: center!important
.u-col-valign-bottom
  align-self: flex-end!important

.u-color-primary
  color: $color-primary!important
.u-color-secondary
  color: $color-secondary!important
.u-color-brown
  color: $color-brown!important
.u-color-teal
  color: $color-teal!important

.u-bg-brown
  background-color: $color-brown!important
.u-bg-teal
  background-color: $color-teal!important
.u-bg-white
  background-color: #fff
.u-bg-beige
  background-color: $color-bg

.u-before-section
  padding-bottom: 15rem!important
  +res-sm-max
    padding-bottom: 11rem!important
.u-before-section-nopadding
  padding-bottom: 9rem!important
  +res-sm-max
    padding-bottom: 3rem!important

.u-background-image
  background-size: auto 100%
  background-position: center top
