@import public/sass/abstracts

.box-bundle
  display: flex
  flex-direction: column
  +border-radius(12px)
  background: #fff
  padding: 40px
  +res-sm-max
    padding: 0 20px
  &__content
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    order: 1
    +res-sm-max
      order: 2
      margin-bottom: 20px
  &__head
    +font(14, 20, 700)
    color: $tertiary
    text-transform: uppercase
    margin-bottom: 10px
    +res-sm-max
      +font(12, 16, 700)
      margin-bottom: 4px
  &__title
    +font(24, 30, 800)
    margin-bottom: 20px
    +res-sm-max
      +font(20, 26, 800)
  &__description
    +font(16, 24, 400)
    margin-bottom: 20px
  &__cta
    +res-md-min
      display: none
    +res-sm-max
      margin-bottom: 20px 
  &__price
    +res-md-min
      margin-bottom: 20px
    +res-sm-max
      order: 3
      margin-top: 20px
  &__garanzia-tile
    +res-sm-max
      order: 2
  &__panel
    &--open
      +translateX(0)
  &__items
    display: flex
    margin-top: 20px
    order: 2
    +res-sm-max
      order: 1
      margin-top: 40px
      margin-bottom: 20px
    &__item
      position: relative
      &:not(:last-child)
        margin-right: 55px
        +res-sm-max
          margin-right: 30px
        &:after
         content: "+"
         position: absolute 
         top: calc(50% - 15px)
         right: -35px
         +font(24, 30, 800)
         +res-sm-max
          +font(16, 24, 800)
          top: calc(50% - 12px)
          right: -20px
  