@import public/sass/abstracts

.numero-rivista-risorse-template
  &__title
    +font(20, 32, 400)
    color: #fff
    text-transform: uppercase
    margin-bottom: 16px
    margin-top: 10px
  &__subtitle
    +font(40, 48, 800)
    color: #fff