.iopiu2-hero
  position: relative
  height: 450px
  overflow-x: hidden
  background: #000
  color: #FFF
  +res-sm-max
    height: 400px
  &__layer
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .3)
    +res-sm-max
      background-color: rgba(0, 0, 0, .5)
  &__image
    position: absolute
    top: 0
    height: 100%
    right: -9999px
    left: -9999px
    margin: 0 auto
    max-width: none
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none
  &__content
    position: absolute
    left: 0
    right: 0
    max-width: 1065px
    margin: 0 auto
    padding: 0 50% 0 5%
    z-index: 1
    +res-md-min
      top: 50%
      +translateY(-50%)
    @media screen and (min-width: 1065px)
      padding-right: 532px
    +res-sm-max
      padding-right: 5%
      top: 30px
      bottom: 45px
      display: flex
      flex-direction: column
  &__title
    font-size: 50px
    font-weight: 900
    line-height: 52px
    +res-sm-max
      font-size: 28px
      line-height: 29px
    span
      color: $color-primary
  &__cta-box
    margin-top: 45px
    +res-sm-max
      text-align: center
      margin-top: auto

  