@import public/sass/abstracts

.area-personale-indirizzi-template
  background: $grey-0
  &__content
    > *
      &:not(:last-child)
        margin-bottom: 30px
  &__section-head
    margin-bottom: 20px
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 20px
  &__section-title
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
  &__section-content
    +res-md-min
      display: flex
      flex-wrap: wrap
      align-items: flex-start
      > *
        width: calc(50% - 10px)
        &:nth-child(2n + 1)
          margin-right:  20px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 20px
  &__section-empty
    max-width: 480px
  &__modal
    padding: 50px
    max-height: 100vh
    overflow-y: auto
    +scrollbar-style2
    +res-sm-max
      padding: 60px 16px 16px
      border-radius: 0
    .wmodal__body
      display: flex
      flex-direction: column
  &__modal-title
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
    margin-bottom: 20px
  &__modal-actions
    margin-top: 20px
    display: flex
    align-items: center
    justify-content: flex-end
    > *
      &:not(:last-child)
        margin-right: 16px
    +res-sm-max
      justify-content: space-between

body
  &.w-toolbar
    .area-personale-indirizzi-template__modal
      max-height: calc( 100vh - #{$toolbar-height} )
      top: calc( 50% + ( #{$toolbar-height} / 2 ) )