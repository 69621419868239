.header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 999
  background: #fff
  +transition(all .5s)
  +res-lg-min
    +box-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
  &--toolbar-active
    margin-top: $toolbar-height
  &__content
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    max-width: $wcont-width
    margin: 0 auto
    z-index: 1
    @media (max-width: 1280px + 32px)
      padding: 0 $wcont-padding
    +res-md-max
      padding: 10px $wcont-padding
  &__mobile
    flex: 1 0 50%
    display: flex
    justify-content: flex-end
    align-items: center
    +res-lg-min
      display: none
  &__top
    display: flex
    align-items: center
    background: $grey-0
    +res-md-max
      display: none
    &__iopiu
      display: flex
      justify-content: flex-end
      align-items: center
      color: #fff
      width: 148px
      background: $color-primary-dark
      padding: 10px 20px
      +border-radius(0 20px 20px 0)
      +font(14, 20, 700)
      .icon
        width: 20px
        height: 16px
        margin-left: 6px
        fill: #fff
    &__menu-menu
      display: flex
      align-items: center
      &__item
        +font(14, 16, 500)
        color: $grey-3
        margin-left: 20px
        a
          display: flex
          align-items: center
        .icon
          width: 16px
          height: 16px
          fill: $grey-3
          margin-right: 4px
    .userbar
      margin-left: auto
      z-index: 999
      +res-md-max
        display: none
      &__container
        position: relative
        display: flex
        align-items: center
      &__hello
        +font(14, 16, 500)
        padding: 10px 0
        color: $grey-3
        margin-right: 26px
      &__link
        position: relative
        +font(14, 20, 700)
        +border-radius(20px 0 0 20px)
        width: 235px
        display: flex
        align-items: center
        color: #fff
        background: $color-primary
        padding: 10px 20px
        z-index: 3
        .icon
          fill: #fff
          width: 14px
          height: 10px
          margin-left: 9px
      &__logout
        position: absolute
        top: calc( 100% - 20px )
        right: 0
        display: none
        color: #fff
        background: $color-primary-dark
        padding: 30px 20px 10px 20px
        width: 235px
        cursor: pointer
        z-index: 2
        +font(14, 20, 700)
        +border-radius(0 0 0 20px)
        &--open 
          display: flex
          align-items: center
        .icon
          width: 20px
          height: 20px
          fill: #fff
          margin-right: 7px
      &__login
        +font(14, 20, 700)
        display: flex
        align-items: center
        color: $color-secondary
        padding: 10px 0
        width: 235px
        cursor: pointer
        .icon
          width: 20px
          height: 20px
          margin-left: 8px
          fill: $color-secondary
  &__logo
    margin-right: 40px
    @media (max-width: 1280px + 32px)
      margin-right: 30px
    @media (max-width: 1040px)
      margin-right: 15px
    +res-md-max
      margin-right: 0
  &__nav
    margin-right: 40px
    @media (max-width: 1280px + 32px)
      margin-right: 30px
    @media (max-width: 1040px)
      margin-right: 15px
  &__button
    +res-lg-min
      display: none
    position: relative
    .icon
      display: block
      margin: 0 auto
      width: 2.9rem
      height: 2.7rem
    &--menu
      +res-md-max
        width: 79px
      +res-sm-max
        width: 73px
      .icon
        margin: 0
        &--close
          display: none
          fill: $color-text
          width: 1.7rem
          height: 2.8rem
    &--link
    &--login
      .icon
        fill: $color-secondary
  &__right
    display: flex
      
.area-personale-menu-mobile
  display: none
  position: fixed
  top: 5.2rem
  bottom: 0
  right: 0
  z-index: 9999
  width: 100%
  max-width: 40rem
  overflow-y: auto
  background: $color-primary
  padding: 2rem 3.5rem
  +res-lg-min
    display: none!important
  &--open
    display: block
  &__header
    margin-bottom: 8rem
    text-align: center
  &__image
    max-width: 5.2rem
    max-height: 5.2rem
    display: block
    margin-left: auto
    margin-right: auto
    +border-radius(.6rem)
  &__image-box
    margin-bottom: 2rem
    .icon
      width: 5.2rem
      height: 5.2rem
      display: block
      margin-left: auto
      margin-right: auto
      margin-bottom: 1rem
      background: $color-grey-1
      fill: $color-grey-2
      +border-radius(.6rem)
  &__fullname
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 2.4rem
    color: #fff
    margin-bottom: 2rem
  &__cta-box
    text-align: center
  &__cta
    display: inline-block
  // &__list
  &__item
    a
      display: block
      font-size: 1.8rem
      font-weight: $font-bold
      line-height: 1.8rem
      padding: 1.6rem 0
      color: #fff
  &__submenu
    .area-personale-menu-mobile
      &__item
        a
          padding-left: 2.5rem

.header--toolbar-active
  ~ .area-personale-menu-mobile
    top: 10.2rem

#logo
  a
    display: block
    height: 41px
  @media (max-width: 1110px)
    a
      height: 29px
  .icon
    width: 166px
    height: 41px
    @media (max-width: 1110px)
      width: 123px
      height: 29px

.ricerca-popup
  display: none
  position: fixed
  left: 0
  right: 0
  bottom: 0
  top: $header-height
  background: #fff
  // z-index: 99999
  padding: 5rem 0 4rem
  height: fit-content
  +res-md-max
    top: $header-height-mobile
  &--open
    display: block
  &:before
    position: absolute
    content: ""
    bottom: 0
    width: 100%
    height: 100vh // sono sicuro che l'ombra superiore sia sopra l'header
    +box-shadow(.2rem 1rem 2rem rgba(0, 0, 0, 0.1))
    z-index: -1
  &__content
    overflow-y: auto
    height: auto // 100% non piace ad iOS
  &__close
    position: absolute
    top: 0
    right: 2rem
    width: 1.4rem
    height: 1.4rem
    fill: $color-text
    cursor: pointer
    &-container
      @extend .w-container-small
      position: relative
  &__top
    display: flex
    justify-content: space-between
  &__title
    font-size: 1.8rem
    line-height: 3.2rem
    font-weight: $font-bold
    margin-bottom: 2rem
  &__viewall
    
  &__results
    margin-top: 3rem
    .ais-Hits-list
      display: flex
      flex-wrap: wrap
      margin-left: -0.75rem
      margin-right: -0.75rem
    .ais-Hits-item
      flex: 48%
      margin-left: 0.75rem
      margin-right: 0.75rem
      margin-bottom: 1.6rem
      & > article
        max-height: 15rem
        .articolo,
        .lezione,
        .strumento,
        .generic,
          &-card
            &__image-box
              width: 15rem
        .raccolta,
          &-card
            &__image-box
              min-width: 13.4rem
              width: 13.4rem
              height: 13.4rem
              &:before
                width: 13.4rem
                height: 13.4rem
                top: .9rem
                left: .9rem
              &:after
                width: 13.4rem
                height: 13.4rem
                top: 1.6rem
                left: 1.6rem
                
  &__noresults
    text-align: center

.ricerca-trends
  display: none
  position: fixed
  left: 0
  right: 0
  bottom: 0
  top: $header-height
  background: #fff
  z-index: 99999
  padding: 5rem 0 12rem
  height: fit-content
  +res-md-max
    top: $header-height-mobile
  &--open
    display: block
  &--mobile
    background: $color-grey-12
    top: 6.7rem!important
    padding-top: 1rem
    height: 100%
  &:before
    position: absolute
    content: ""
    bottom: 0
    width: 100%
    height: 100vh // sono sicuro che l'ombra superiore sia sopra l'header
    +box-shadow(.2rem 1rem 2rem rgba(0, 0, 0, 0.1))
    z-index: -1
  &__content
    overflow-y: auto
    height: auto  // 100% non piace ad iOS
  &__close
    position: absolute
    top: 0
    right: 2rem
    width: 1.4rem
    height: 1.4rem
    fill: $color-text
    cursor: pointer
    &-container
      @extend .w-container-small
      position: relative
  &__title
    font-size: 1.8rem
    line-height: 3.2rem
    font-weight: $font-bold
    margin-bottom: 1.8rem
  &__item
    margin-bottom: 1.8rem
    cursor: pointer
 
.header--toolbar-active
  .ricerca-popup, .ricerca-trends
    top: calc( #{$header-height} + #{$toolbar-height} )
    +res-md-max
      top: calc( #{$header-height-mobile} + #{$toolbar-height} )
  

body
  &.w-scrolled
    .header
      +box-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
      +translateY(-100%)
    &:not(.w-scrolldown)
      .header
        +translateY(0%)
        z-index: 9999 // per sovrastare le sticky actions


@media print
  #header
    display: none
