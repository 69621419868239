.box-autori
	display: flex
	justify-content: space-between
	width: 100%
	max-width: $wcont-hub-width
	margin-left: auto
	margin-right: auto
	padding: 9rem 0 2rem 0
	+res-xxl-min
		max-width: $wcont-width
	+res-md-min
		padding-left: 2rem
		padding-right: 2rem
	+res-sm-max
		flex-direction: column
		padding: 5rem 0 0 0
	&__head
		max-width: 36.3rem
		+res-sm-max
			padding: 0 2rem
		&__title
			+font(40, 43, 800)
			margin-bottom: 1rem
			+res-sm-max
				+font(30, 40, 800)
		&__description
			+font(18, 28, 400)
			font-family: $font-secondary
			margin-bottom: 3rem
			+res-sm-max
				+font(16, 24, 400)
				margin-bottom: 2rem
		&__cta
			+res-sm-max
				margin-bottom: 4rem
	&__content
		&__list
			&--desktop
				display: flex
				flex-wrap: wrap
				justify-content: space-between
				max-width: 55.2rem
				+res-sm-max
					display: none
			&--mobile
				padding-left: 2rem
				.slick-slide
					margin-right: 4rem
					& > div
						padding-top: 2rem
				+res-md-min
					display: none
			.autore-card
				margin-bottom: 6rem