.iopiu2-help
  text-align: center
  &__content
    background: $color-primary
    padding: 38.1px 24px 310px
    +res-sm-max
      padding: 15.3px 24px 194px
  &__title
    font-size: 40px
    font-weight: 900
    line-height: 48px
    margin-bottom: 15.8px
    color: #000
    +res-sm-max
      font-size: 24px
      line-height: 28px
      margin-bottom: 7px
  &__description
    font-size: 25px
    line-height: 36px
    color: #fff
    +res-sm-max
      font-size: 16px
      line-height: 20px
  &__slider
    max-width: 1065px
    padding: 0 120px 100px
    margin: -260px auto 0
    +res-sm-max
      padding: 0 40px 33.8px
      margin-top: -180px
    .slick-prev, .slick-next
      width: 25px
      height: 50px
      &::before
        width: 25px
        height: 50px
        display: block
    .slick-prev
      left: -100px
      +res-sm-max
        left: -25px
      &::before
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOC40OTciIGhlaWdodD0iNTIuNzUxIiB2aWV3Qm94PSIwIDAgMjguNDk3IDUyLjc1MSI+DQogIDxnIGlkPSJmcmVjY2lhX3N4IiBkYXRhLW5hbWU9ImZyZWNjaWEgc3giIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05Ny4yMzcgLTM0NDMuMzA3KSI+DQogICAgPHBhdGggaWQ9IlBhdGhfMzQiIGRhdGEtbmFtZT0iUGF0aCAzNCIgZD0iTTEyNC42NzMsMzQ5NWwtMjUuMzE1LTI1LjMxNSwyNS4zMTUtMjUuMzE1IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIzIi8+DQogIDwvZz4NCjwvc3ZnPg0K")
        +res-sm-max
          content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjEyMSIgaGVpZ2h0PSIxNS40MTQiIHZpZXdCb3g9IjAgMCA5LjEyMSAxNS40MTQiPg0KICA8cGF0aCBpZD0iZnJlY2NpYV9zeCIgZGF0YS1uYW1lPSJmcmVjY2lhIHN4IiBkPSJNMTE5OC4xNjEsMjg2OS42OTRsNyw3LTcsNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIwNi41NzUgMjg4NC40MDEpIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+DQo8L3N2Zz4NCg==")
    .slick-next
      right: -100px
      +res-sm-max
        right: -25px
      &::before
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOC40OTciIGhlaWdodD0iNTIuNzUxIiB2aWV3Qm94PSIwIDAgMjguNDk3IDUyLjc1MSI+DQogIDxnIGlkPSJmcmVjY2lhX2R4IiBkYXRhLW5hbWU9ImZyZWNjaWEgZHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTU3LjM1NCAtMzQ0My4zMDcpIj4NCiAgICA8cGF0aCBpZD0iUGF0aF8zMyIgZGF0YS1uYW1lPSJQYXRoIDMzIiBkPSJNMTE1OC40MTQsMzQ0NC4zNjdsMjUuMzE1LDI1LjMxNUwxMTU4LjQxNCwzNDk1IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIzIi8+DQogIDwvZz4NCjwvc3ZnPg0K")
        +res-sm-max
          content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjEyMSIgaGVpZ2h0PSIxNS40MTQiIHZpZXdCb3g9IjAgMCA5LjEyMSAxNS40MTQiPg0KICA8cGF0aCBpZD0iZnJlY2NpYV9keCIgZGF0YS1uYW1lPSJmcmVjY2lhIGR4IiBkPSJNMTE5OC4xNjEsMjg2OS42OTRsNyw3LTcsNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOTcuNDUzIC0yODY4Ljk4NykiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz4NCjwvc3ZnPg0K")
    .slick-dots
      bottom: -47px
      text-align: center
      display: flex!important
      justify-content: center
      > *
        width: 17px
        height: 17px
        border: 2px solid #CCCCCC
        +border-radius(50%)
        &.slick-active
          background: #666666
      +res-sm-max
        display: none!important

  
  &__slide
    padding: 0 16px
    &__item
      color: #FFF
      background: $color-red
      +border-radius(39.37px)
      padding: 55px 61px 34px
      +res-sm-max
        padding: 23px 19px 29.7px
    &__icon
      opacity: .14
      margin-bottom: 30.4px
      svg
        width: 70px
        height: 70px
        margin: 0 auto
        +res-sm-max
          width: 45px
          height: 45px
      +res-sm-max
        margin-bottom: 9px
    &__title
      font-size: 40px
      line-height: 53px
      margin-bottom: 20.4px
      +res-sm-max
        font-size: 20px
        line-height: 27px
    &__description
      font-size: 24px
      line-height: 36px
      +res-sm-max
        font-size: 14px
        line-height: 19px

    