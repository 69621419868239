.section
  position: relative
  background: $color-bg
  padding-top: 5rem
  padding-bottom: 5rem
  margin-top: -9rem
  +border-top-left-radius($border-radius-big)
  +res-md-max
    padding-top: 3rem
    padding-bottom: 3rem
    +border-top-left-radius($border-radius-small)
  &--nooverflow
    margin-top: 0