.riviste-page
  background: $color-bg
  .breadcrumbs
    +res-sm-max
      display: none
  &__header
    text-align: center
  &__description
    +typoH2
    margin-bottom: 5rem
    color: #000000
    +res-sm-max 
      margin-bottom: 1rem
      margin-top: 2rem
      font-size: 36px
  &__list-section
    position: relative
   
    

      

 

      