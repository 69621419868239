.loghi
  display: flex
  flex-wrap: wrap
  &__item
    flex-basis: 25%
    margin: 2rem auto
    +res-sm-max
      flex-basis: 50%
    img
      display: block
      margin-left: auto
      margin-right: auto