.steps
  display: flex
  justify-content: center
  &__item
    position: relative
    font-weight: $font-extra-bold
    text-align: center
    color: $color-secondary
    padding: 0 6rem
    min-width: 20rem
    +res-xs-max
      width: 100%
    &:not(:first-child)
      &::before
        content: ""
        position: absolute
        width: calc(50% - 2.4rem)
        left: 0
        top: 2.4rem
        height: 0
        border: 1px solid $color-secondary
        z-index: -1
        +res-xs-max
          width: calc(50% - .4rem)
          left: -2rem
    &:not(:last-child)
      &::after
        content: ""
        position: absolute
        width: calc(50% - 2.4rem)
        right: 0
        top: 2.4rem
        height: 0
        border: 1px solid $color-secondary
        z-index: -1
        +res-xs-max
          width: calc(50% - .4rem)
          right: -2rem
    &--full
      .steps
        &__index
          background: $color-secondary
          color: #fff
          +res-xs-max          
            background: #fff
            color: $color-secondary
    &--disabled
      .steps
        &__index
          background: $color-secondary
          color: #fff
          opacity: .5
        &__label
          opacity: .5
      +res-xs-max
        display: none
  &__index
    display: block
    margin-left: auto
    margin-right: auto
    width: 4.8rem
    height: 4.8rem
    line-height: 4.8rem
    +border-radius(50%)
    border: 2px solid $color-secondary
    background: #fff
  &__label
    display: block
    margin-top: 2.5rem



