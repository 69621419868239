@import public/sass/abstracts

.rivista-card--abbonato
  .rivista-card__actions__no-abbonati
    display: none

.rivista-card--no-abbonato
  .rivista-card__actions__abbonati
    display: none

.rivista-card
  margin-bottom: 16px
  border: 1px solid rgba(187, 187, 187, 0.2)
  +border-radius(12px)
  +box-shadow(4px 8px 12px rgba(0, 0, 0, 0.05))

  &__container
    display: flex
    align-items: center
    align-content: center
  +res-sm-max
    margin-bottom: 16px
  &__image-box
    position: relative
    margin: 16px
    +res-md-min
      margin-right: 18px
      width: 90px
  &__cta
    color: #0375f8

  &__content
    position: relative
    margin: 16px
    flex: 1
    +res-md-min
      flex: 1
    +res-sm-max
      text-align: left
  &__content_title_badge
    display: flex
    flex-direction: column-reverse
    flex: 1
  &__title
    +typoBase(1.8rem, 2.4rem, $font-primary-black)
    margin-bottom: 16px
  &__durata
    +font(14, 20, 400)
    margin-bottom: 10px
    margin-top: -10px
  &__rinnova
    margin-bottom: 10px
  &__num
    +typoBase(1.6rem, 2.4rem, $font-primary-heavy)
    margin-bottom: 10px
    +res-sm-max
      margin-bottom: 32px
  &__actions
    display: flex
    align-items: center
    width: 100%
    +typoButtonMd
    +res-md-min
      margin-bottom: 25px
      > *
        &:not(:last-child)
          margin-right: 10px
    +res-sm-max
      flex-direction: column
      align-items: flex-start
      > *
        &:not(:last-child)
          margin-bottom: 16px

  &__status
    +typoTextLg
    margin-bottom: 11px
    +res-md-min
      max-width: 500px
    +res-sm-max
      max-width: 400px
      margin-left: auto
      margin-right: auto

  &__member-cta
    margin-bottom: 32px

  &__badges
    text-align: right
    margin-bottom: 15px
    +res-sm-max
      text-align: left
    .badge
      font-size: 14px
      font-weight: 550
      padding: 4px 10px
      margin-right: 8px
      margin-top: 8px
      line-height: 20px
