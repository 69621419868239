/* Colors */
$color-primary: #F78930
$color-primary-dark: #F36719
$color-primary-light: #F5C09F
$color-primary-lightest: #FEEAE0
$color-secondary: #0375F8
$color-secondary-light: #EBF5FF
$color-secondary-dark: #024598
$color-text: #2A2A2A
$color-bg: #F5F0E8
$color-bg-current: #EBE1D3
$color-facebook: #3B5998
$color-zainodigitale: #FB2E24

$color-rivista: #FFAF02
$color-rivista-text: #1A1A1A
$color-red: #FA4F00
$color-red-light: #FEEAE0
$color-red-dark: #D8213C
$color-star: #ffaf04
$color-yellow: #FFC522
$color-paris-daisy: #FFF06C

$color-teal: #63ACA5
$color-teal-lezione: #45A4AD
$color-teal-dark: #3D6C68
$color-teal-light: #CFEDEA
$color-brown: #C05E01
$color-purple: #C080CF
$color-green: #47AB46

$color-grey-1: #D8D8D7
$color-grey-2: #7f7f7f
$color-grey-3: #4a4a4a
$color-grey-4: #9B9B9B
$color-grey-5: #838383
$color-grey-6: #F6F6F6
$color-grey-7: #B4AFA6
$color-grey-8: #C5C2C2
$color-grey-9: #979797
$color-grey-10: #3a3a3a
$color-grey-11: #656565
$color-grey-12: #F5F5F5
$color-grey-13: #6F6A61
$color-grey-14: #bbbbbb
$color-grey-15: #BCBCBC
$color-grey-16: #D6D6D6
$color-grey-17: #4F4A41

$color-error: #FF0201
$color-error-bg: #ffe2e2
$color-info-bg: #e3f6ff

$color-discipline-scienze: #63B345
$color-discipline-geografia: #00AA96
$color-discipline-irc: #007274
$color-discipline-edcivica: #F6A600
$color-discipline-italiano: #E40050
$color-discipline-storia: #D84D15
$color-discipline-matematica: #009EE2
$color-discipline-italianol2: #0069B3
$color-discipline-inglese: #8E95CA
$color-discipline-tecnologia: #94569D
$color-discipline-artemusica: #003C74

$color-scheda: #F1BC43
$color-video: #3A7DAA
$color-interattivo: #050E72
$color-libro: #6D6B6A
$color-oggetto: #3A7DAA
$color-software: #311152
$color-testo: #E998BD
$color-audiolibro: #FD3327
$color-ebook: #020202
$color-evento: #EEE8E8
$color-immagine: #83A004
$color-audio: #8E65AD

$color-label-novita: #c71604

// nuovi colori 2021
$white: #FFF
$black: #2A2A2A
$accent-blue: #0375F8
$accent-blue-light: #E6F1FE
$accent-red: #FA4F00
$rust: #C05E01
$tertiary: #024598

$grey-0: #F5F5F5
$grey-1: #BBBBBB
$grey-2: #818181
$grey-3: #4F4A41

$green-0: #9DD6C0
$green-1: #83CCAE
$green-light: #C1EAD8
$green-dark: #103F2F

$text-green-light: #238B68

$menu-item: #333333

$light-blue-1: #E6F2F4

$shadow-md: 0px 4px 8px rgba(#000, .1)

/* Fonts */
$font-primary: 'Mulish', sans-serif
$font-secondary: 'Lora', serif
$font-light: 300
$font-regular: 400
$font-semi-bold: 600
$font-bold: 700
$font-extra-bold: 800

$font-primary-regular: 400 // ok 400 (roman)
$font-primary-medium: 500 // 550 - non c'è il font weight corrispondente
$font-primary-medium-2: 500 // ok 500 (medium)
$font-primary-heavy: 700 // ok 700 (heavy)
$font-primary-black: 800 // 750 - non c'è il font weight corrispondente
$font-primary-black-2: 800 // ok 800 (black)

$font-secondary-regular: 400 // ok 400
$font-secondary-heavy: 700 // ok 700

/* Resolutions */

$res-xs-max: 575px
$res-sm-min: 576px
$res-sm-max: 767px
$res-md-min: 768px
$res-md-max: 991px
$res-lg-min: 992px
$res-lg-max: 1199px
$res-xl-min: 1200px
$res-xl-max: 1399px
$res-xxl-min: 1400px

$footer-negative-margin: 30px

$wcont-width: 1280px
$wcont-inner-width: 1220px
$wcont-small-width: 1000px
$wcont-little-width: 808px
$wcont-sportelli-width: 1015px
$wcont-padding: 16px
$wcont-hub-width: 1013px
$wcont-catalogo-width: 1170px
$wcont-hug-width: 960px
$wcont-fill-width: 690px

$border-radius-big: 9rem
$border-radius-medium: 4.8rem
$border-radius-small: 2.7rem

$transition-duration: 1s

$toolbar-height: 45px
$header-height: 116px
// $header-height: 130px    
$header-height-mobile: 95px

// base64
$chevron-left: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNi42MjAxIDIuOTkwMDZDMTYuMTMwMSAyLjUwMDA2IDE1LjM0MDEgMi41MDAwNiAxNC44NTAxIDIuOTkwMDZMNi41NDAwNiAxMS4zMDAxQzYuMTUwMDYgMTEuNjkwMSA2LjE1MDA2IDEyLjMyMDEgNi41NDAwNiAxMi43MTAxTDE0Ljg1MDEgMjEuMDIwMUMxNS4zNDAxIDIxLjUxMDEgMTYuMTMwMSAyMS41MTAxIDE2LjYyMDEgMjEuMDIwMUMxNy4xMTAxIDIwLjUzMDEgMTcuMTEwMSAxOS43NDAxIDE2LjYyMDEgMTkuMjUwMUw5LjM4MDA2IDEyLjAwMDFMMTYuNjMwMSA0Ljc1MDA2QzE3LjExMDEgNC4yNzAwNiAxNy4xMTAxIDMuNDcwMDYgMTYuNjIwMSAyLjk5MDA2WiIgZmlsbD0iIzJBMkEyQSIvPg0KPC9zdmc+DQo=)
$chevron-right: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik03LjM4IDIuOTkwMDZDNy44NyAyLjUwMDA2IDguNjYgMi41MDAwNiA5LjE1IDIuOTkwMDZMMTcuNDYgMTEuMzAwMUMxNy44NSAxMS42OTAxIDE3Ljg1IDEyLjMyMDEgMTcuNDYgMTIuNzEwMUw5LjE1IDIxLjAyMDFDOC42NiAyMS41MTAxIDcuODcgMjEuNTEwMSA3LjM4IDIxLjAyMDFDNi44OSAyMC41MzAxIDYuODkgMTkuNzQwMSA3LjM4IDE5LjI1MDFMMTQuNjIgMTIuMDAwMUw3LjM3IDQuNzUwMDZDNi44OSA0LjI3MDA2IDYuODkgMy40NzAwNiA3LjM4IDIuOTkwMDZaIiBmaWxsPSIjMkEyQTJBIi8+DQo8L3N2Zz4NCg==)
