.slider-green-home2021
  position: relative
  margin-bottom: 50px
  padding: 0 16px
  +res-sm-max
    padding-bottom: 32px
    padding-left: 0
    padding-right: 0

  .slick-arrow
    width: 24px
    height: 24px
  .slick-prev
    left: 24px
    +res-md-max
      left: 10px
    &::before
      content: $chevron-left

  .slick-next    
    right: 24px
    +res-md-max
      right: 10px
    &::before
      content: $chevron-right

  .slick-dots
    z-index: 10
    li
      button
        background: #BBBBBB
        border-radius: 50%
        &::before
          display: none
      &.slick-active
        button
          background: $color-secondary
    +res-md-min
      display: none
    +res-sm-max
      bottom: -32px
      li
        width: 12px
        height: 12px
        button
          width: 12px
          height: 12px

  &__container
    +border-radius(20px)
    +res-md-min
      overflow: hidden

  &__slide
    &__container
      background-position: center center
      background-repeat: no-repeat
      background-size: cover
      border-radius: 20px
      +res-sm-min
        display: flex
        align-items: center
        min-height: 336px
        > *
          width: 50%
      +res-sm-max
        padding: 36px 14px
        min-height: 303px
      +res-xs-max
        min-height: 520px
    &__content
      display: flex
      flex-direction: column
      min-height: 336px
      padding: 45px 20px 39px 10%
      +res-sm-max
        min-height: 303px
      +res-xs-max
        min-height: 520px
        padding: 0
        margin-bottom: 20px
        text-align: center

    &__title
      font-size: 4rem
      font-weight: $font-bold
      line-height: 4.3rem
      margin-bottom: 14px
      +res-md-max
        font-size: 2.8rem
        line-height: 3.3rem
        margin-bottom: 10px

    &__description
      font-family: $font-secondary
      font-size: 1.8rem
      line-height: 2.8rem
      margin-bottom: 20px
      @extend .overflow-ellipsis
      -webkit-line-clamp: 3
      +res-md-max
        font-size: 1.6rem
        margin-bottom: 10px

    &__image-box
      img
        display: block
        margin: 0 auto

    &__cta-box
      &.bottom
        margin-top: auto

    