@import public/sass/landing-riviste/abstracts

.landing-riviste-template
  strong
    font-weight: 800
  &--blue
    .u-landing-riviste-color-dark
      color: $landing-riviste-blue-dark
    .u-landing-riviste-color-default
      color: $landing-riviste-blue-default
    .u-landing-riviste-bg-default
      background-color: $landing-riviste-blue-default
    .u-landing-riviste-bg-light
      background-color: $landing-riviste-blue-light
      &.landing-riviste-motivi__item
        background-color: $landing-riviste-blue-default // Accrocchio per fretta
  &--green
    .u-landing-riviste-color-dark
      color: $landing-riviste-green-dark
    .u-landing-riviste-color-default
      color: $landing-riviste-green-default
    .u-landing-riviste-bg-default
      background-color: $landing-riviste-green-default
    .u-landing-riviste-bg-light
      background-color: $landing-riviste-green-light
  &--yellow
    .u-landing-riviste-color-dark
      color: $landing-riviste-yellow-dark
    .u-landing-riviste-color-default
      color: $landing-riviste-yellow-default
    .u-landing-riviste-bg-default
      background-color: $landing-riviste-yellow-default
    .u-landing-riviste-bg-light
      background-color: $landing-riviste-yellow-light