@import public/sass/abstracts

.label
  display: inline-block
  +typoTextMd
  font-weight: $font-primary-medium-2
  background: $color-primary
  color: $white
  padding: 4px 10px
  border-radius: 8px
  &--tag
    padding-left: 6px
    padding-right: 6px
    font-weight: $font-primary-heavy
    text-transform: uppercase
  &--teal
    background: $color-teal