@import public/sass/abstracts

.toggle
  position: relative
  background-color: transparent
  border: 2px solid $grey-3
  transition: background-color .5s
  display: inline-block
  width: 44px
  height: 24px
  border-radius: 24px
  cursor: pointer
  &::after
    content: ""
    position: absolute
    top: 1px
    left: 2px
    width: 18px
    height: 18px
    border-radius: 50%
    background-color: $grey-2
    transition: left .3s linear, background-color .5s
  &--checked
    background-color: $accent-blue
    border-color: $accent-blue
    &::after
      left: 20px
      background-color: $white
  &--disabled
    opacity: .7
    cursor: default