.tooltip
  position: relative
  display: inline-block
  padding: .2rem 1rem
  background: #001B3D
  font-family: $font-primary
  font-size: 1.4rem
  font-weight: $font-semi-bold
  text-transform: uppercase
  text-align: center
  line-height: 2.2rem
  color: #fff
  +border-radius(1.3rem)
  &__arrow
    position: absolute
    left: -.6rem
    top: calc(50% - 1.3rem)
    width: 2.6rem
    height: 2.6rem
    +fill-icon(#001B3D)
    z-index: -1

.react-tooltip
  max-width: 17.8rem
  padding: 1rem!important
  margin-left: 2rem!important
  margin-top: .6rem!important
  +border-radius(.6rem!important)
  border: 1px solid $color-grey-15!important
  line-height: 1.6rem
  font-size: 1.4rem
  opacity: 1!important
  background: $color-bg!important
  border: 0!important
  &::after
    border-right-color: $color-bg!important
  &--pagebuilder
    margin-left: 0!important
  &--base
    padding: 4px 12px 4px 8px!important
    margin-top: -7px!important
    margin-left: 0!important
    color: #fff!important
    background: $grey-3!important
    font-size: 14px!important
    line-height: 26px!important
    opacity: .9!important
    &::after
      border-left-color: $grey-3!important
    &::before
      border-right-color: $grey-3!important

