.iopiu-caratteristiche
  background: $color-bg
  &__title-container
    background: #fff
    padding: 3.5rem 0
  &__title
    font-size: 4rem
    font-weight: $font-bold
    line-height: 4.8rem
    margin-bottom: 1rem
    +res-sm-max
      text-align: center
  &__abstract
    font-size: 2.4rem
    line-height: 2.8rem
    padding: 0 1rem
    margin-bottom: 5rem
    +res-sm-max
      text-align: center
  &__item
    position: relative
    margin-top: 5rem
    +res-md-min
      z-index: 0
      &::before
        content: ""
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        background: linear-gradient(transparent, #ffffff)
        z-index: -1        
    +res-sm-max    
      margin-bottom: 3rem
      text-align: center
    &--orange
      .iopiu-caratteristiche
        &__item-title
          color: $color-primary
    &--blue
      .iopiu-caratteristiche
        &__item-title
          color: $color-secondary-dark
    &--yellow
      .iopiu-caratteristiche
        &__item-title
          color: $color-star
    // &:nth-child(2n+1)
    //   &__item-image-content
    //     order: 1
    //   &__content
    //     order: 0
    //   +res-md-min
    //     &::before
    //       left: calc(50vw - 62rem)
    //       @media (max-width: 1280px)
    //         left: 2rem
    //     .iopiu-caratteristiche
    //       &__item-container
    //         border-left: 1rem solid $color-red
        
    &:nth-child(2n)  
      +res-md-min 
        &::before
          right: calc(50vw - 62rem)
          @media (max-width: 1280px)
            right: 2rem
        .iopiu-caratteristiche   
          &__item-container
            border-right: 1rem solid $color-red    
    &--orange
      +res-md-min 
        &:nth-child(2n+1)
          .iopiu-caratteristiche
            &__item-container
              border-left-color: $color-primary
        &:nth-child(2n)   
          .iopiu-caratteristiche   
            &__item-container
              border-right-color: $color-primary
    &--blue
      +res-md-min 
        &:nth-child(2n+1)
          .iopiu-caratteristiche
            &__item-container
              border-left-color: $color-secondary-dark
        &:nth-child(2n)   
          .iopiu-caratteristiche   
            &__item-container
              border-right-color: $color-secondary-dark
    &--yellow
      +res-md-min 
        &:nth-child(2n+1)
          .iopiu-caratteristiche
            &__item-container
              border-left-color: $color-star
        &:nth-child(2n)   
          .iopiu-caratteristiche   
            &__item-container
              border-right-color: $color-star
  &__slider
    position: relative
    margin-bottom: 6rem
    +res-sm-max
      display: none
    .slick-track
      display: flex
      & > div
        margin-right: 4rem
    .slider-container
      position: relative
      max-width: 100%
      padding: 0 1rem
      overflow: hidden
      &::before
        content: ""
        position: absolute
        top: 0
        display: block
        width: 20%
        height: 22rem
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 40%)
      &::after
        content: ""
        position: absolute
        top: 0
        display: block
        width: 20%
        margin-left: 80%
        height: 22rem
        background: linear-gradient(90deg, rgba(36,0,0,0) 20%, rgba(255,255,255,1) 80%)
      &--overlay
        &::before
          z-index: 1
        .slider-nav-item--prev
          display: block
    .slider-nav-item
      position: absolute
      top: 40%
      width: 5rem
      height: 5rem
      z-index: 2
      padding: 1rem 1.5rem
      +border-radius(5rem)
      &::after
        display: none
      svg
        width: 2.2rem
        height: 2.2rem
        fill: white
        vertical-align: top
      &--prev
        display: none
        left: 0
      &--next
        right: 2rem
        svg
          +rotate(180deg)
    .slick-list
      overflow: hidden
    &__item
      a
        position: relative
        display: block
        img
          max-height: 22rem
          &.play
            position: absolute
            bottom: 38%
            right: 40%
  &__video
    +res-md-min
      display: none
    & > div
      align-items: center
      flex-direction: column
    .iopiu-caratteristiche__slider__item
      margin-bottom: 2rem
    &__first
      display: flex
    &__others
      display: none
      &.show
        display: flex
    &__toggle
      text-align: center
      text-decoration: underline
      svg
        display: block
        margin-top: 1rem
        fill: $color-text
        width: 2rem
        height: 2rem
        margin-left: auto
        margin-right: auto
        +rotate(-90deg)
        &.hide
          margin-bottom: 1rem
          margin-top: 0
          +rotate(90deg)
  &__item-container
    +res-md-min
      display: flex
  &__item-image-content
    padding: 1rem 0 4rem 
    +res-md-min
      flex-basis: 50%
  &__item-image-box
    position: relative
    width: 25rem
    height: 25rem
    +border-radius(50%)
    background: $color-star
    margin: 0 auto
  &__item-image
    position: absolute
    max-width: 20rem
    max-height: 20rem
    margin: auto
    top: 0
    bottom: 0
    right: 0
    left: 0
  &__content
    max-width: 35rem
    padding: 0 2rem
    margin-left: auto
    margin-right: auto
    +res-md-min
      flex-basis: 50%
  &__item-title
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 2.8rem
    margin-bottom: 4rem
    color: $color-red
    +res-sm-max
      margin-bottom: 1rem

  &__ctas
    padding: 5rem 0
    text-align: center
    +res-sm-max
      padding: 2rem 0
  &__cta
    margin: .5rem .8rem 
    +res-sm-max
      width: 25rem
    
  


      