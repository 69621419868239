.tab-accordion  
  +res-sm-max
    border-bottom: 3px solid $color-bg-current
  &--opened
    .tab-accordion
      &__icon
        transform: rotate(180deg)
        
      &__inner
        max-height: 1000rem
        transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22)
        transition-duration: 0.5s
        transition-property: max-height
      
      &__content
        opacity: 1
        transform: translateY(0)
        transition-delay: 0.2s
        transition-timing-function: ease-in-out
        transition-duration: 0.2s
        transition-property: opacity, transform

  &__line
    display: flex
    justify-content: space-between
    align-items: center
    position: relative
    cursor: pointer
    +res-md-min
      display: none
  
  &__title
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 4.8rem
    color: $color-secondary-dark
    padding: 1.5rem 0

  &__icon
    transition: transform 0.3s ease-in-out
    margin-left: 2rem

    .icon
      display: block
      width: 2rem
      height: 1.2rem
      fill: $color-text
  
  &__inner
    max-height: 0
    overflow: hidden
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035)
    transition-duration: 0.5s
    transition-property: max-height
    position: relative
  
  &__content
    opacity: 0
    transform: translateY(-1rem)
    transition-timing-function: linear, ease
    transition-duration: 0.1s
    transition-property: opacity, transform
    transition-delay: 0.5s
