@import public/sass/abstracts

.primo-mobile
  display: flex
  width: 255px
  padding: 16px
  background: $grey-0
  +font(16, 24, 700)
  +border-radius(12px)
  &--iopiu
    background: $color-primary-dark
    color: #fff
  .icon
    &--io-piu
      width: 25px
      height: 20px
      fill: #fff
      margin-right: 10px
    &--chevron-right
      width: 20px
      height: 20px
      fill: $black
      margin-left: auto