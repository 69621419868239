@import public/sass/abstracts

.esplora-altro
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding-bottom: 33px
  padding-top: 33px
  padding-left: 20px
  padding-right: 20px
  background: #FFFFFF
  box-shadow: 0px 8px 16px rgb(0 0 0 / 10%)
  border-radius: 16px
  &__icon
    margin-bottom: 20px
    width: 40px
    height: 40px
  &__text
    font-weight: 800
    font-size: 20px
    line-height: 24px
    text-align: center
    color: $color-text
    margin-bottom: 20px
  &__link
    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 24px
    color: $color-secondary
