@import public/sass/abstracts

.promo-cart-accordion
  background: $grey-0
  border-radius: 10px

  &__trigger
    +typoTextLg
    font-weight: $font-primary-heavy
    display: flex
    align-items: center
    width: 100%
    cursor: pointer
    text-align: left
    padding: 20px
    
  &__icon
    margin-right: 10px
    svg
      +style-icon(24px, 24px, currentColor)

  &__arrow
    margin-left: auto
    svg
      +style-icon(24px, 24px, currentColor)

  &__content
    overflow: hidden
    +transition(max-height .3s linear)

  &__inner-content
    padding: 0 20px 20px

  &__form
    display: flex

  &__input
    flex: 1
    margin-right: 20px
    +typoTextLg
    font-weight: $font-primary-medium-2
    padding: 12px 16px
    border-radius: 12px
    max-width: calc(100% - 80px) // La larghezza non viene calcolata correttamente altrimenti

  &__submit
    width: 60px

  &__error
    margin-top: 6px
    padding-left: 4px
