.empty-message
  padding: 40px 16px
  text-align: center
  +typoTextMd
  color: $pb-grey-80
  > *
      &:not(:last-child)
          margin-bottom: 10px
  svg
      +style-icon(24px, currentColor)
      margin: 0 auto
                    

.form-buttons
    display: flex
    justify-content: flex-end
    > *
        &:not(:last-child)
            margin-right: 8px
