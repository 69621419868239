@import public/sass/abstracts

.formazione-corsi-listing-template
  &__head
    background: $accent-blue-light
    padding: 26px 0 45px
    +res-sm-max
      padding: 23px 0 20px

  &__title
    +typoH1
    text-align: center
    +res-sm-max
      +typoH2Mobile

  &__description
    +typoTextXl
    margin: 37px auto 0
    text-align: center
    max-width: 720px
    +res-sm-max
      +typoTextLg
      margin-top: 20px


  &__main
    padding: 47px 0 131px
    +res-sm-max
      padding: 30px 0 100px