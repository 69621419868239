@import public/sass/abstracts

.box-contenuti-numero-rivista
  padding: 20px
  background: #fff
  +border-radius(12px)
  &__title
    +font(24, 30, 800)
    margin-bottom: 24px
  &__item
    margin-bottom: 14px
    &--labeled
      margin-bottom: 22px