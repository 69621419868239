@import public/sass/abstracts

.listing-rivista-by-anno
  display: flex
  +res-sm-max
    flex-direction: column
    margin: 0 auto
    padding-bottom: 2rem
    border-bottom: 1px solid $grey-2
    padding-top: 3rem
  &__button
    color: #0375F8
    display: block
    cursor: pointer
    display: flex
    align-items: center
    +typoTextLg
    font-weight: 700
    svg
      +style-icon(20px, 20px, currentColor)
      margin-right: 0.6rem
  &__button-mobile
    display: none
    +res-sm-max
      display: block

  &__button-desktop
    display: flex
    +res-sm-max
      display: none
  &__container-listing
    display: flex
    flex: 1
    flex-direction: row
    flex-wrap: wrap
    +res-sm-max
      
  &__container-anno
    width: 20rem
    
  &__anno
    +typoH3
    +res-sm-max
      margin-bottom: 3rem

  &__list-item
    margin-bottom: 6.5rem
    width: 20rem
    &:not(:nth-child(5n))
      margin-right: 1rem
      +res-sm-max
        margin-right: 0
    +res-sm-max
      width: 16rem
      margin-bottom: 3rem
      margin-right: 0
      &:not(:nth-child(odd))
        margin-left: 2.3rem
      
    &.invisible
      display: none
    &__mese
      text-transform: uppercase
      +typoTextMd
      margin-top: 1.6rem
      +res-sm-max
        +typoTextSm

    &__title
      +typoTextXl
      font-weight: 700
      margin-top: 0.5rem
      display: block
      +res-sm-max
        +typoTextMd
        font-weight: 700