@import public/sass/abstracts

.badge-ore-formazione
  background: $color-secondary-dark
  width: fit-content
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
  border-radius: 4px
  display: flex
  align-items: center
  padding: 8px 10px
  &--white
    background: #FFFFFF
  &__icon
    margin-right: 10px
    color: #FFFFFF
    &--black
      color: black
    svg
      +style-icon(20px, 20px, currentColor)

  &__label
    font-weight: 500
    font-size: 14px
    line-height: 20px
    color: #FFFFFF
