@import public/sass/abstracts

.ricerca-agente
  +res-md-min
    display: flex
    align-items: flex-start
  &__select
    background-color: $grey-0
    +res-md-min
      border-radius: 20px
      padding: 35px 45px
      max-width: 397px
      margin-right: 15px
    +res-sm-max
      padding: 27px 16px 30px
      margin: 0 -2rem 25px
  &__content
    +res-sm-max
      max-height: 0
      overflow: hidden
      +transition(max-height .3s linear)
  &__title
    +typoH3
    margin-bottom: 38px
    +res-sm-max
      text-align: center
      margin-bottom: 20px
  &__required
    +font(12, 24, 400)
    margin-bottom: 20px
  &__fields
    > *
      margin-bottom: 20px
  &__wrapper
    width: 100%
    padding: 10px 10px
  &__item
    margin: 15px 0px
    justify-self: flex-start
  &__submit
    text-align: center
  &__clear
    text-align: center
    margin-top: 10px
    +res-sm-max
      margin-top: 20px
  &__open-mobile
    +res-md-min
      display: none
    +res-sm-max
      text-align: center
  &__results-num
    +typoH4
    margin-bottom: 22px
  .ais-Hits-list
    +res-md-min
      > *
        &:not(:last-child)
          border-bottom: 1px solid #BBB
          margin-bottom: 10px
          padding-bottom: 10px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 20px
  &--open
    .ricerca-agente
      &__content
        max-height: 500px
      &__open-mobile
        display: none

.ricerca-agente-scuola-secondaria
  text-align: center
  margin-top: 35px
  &__title
    +typoH4
    margin-bottom: 20px
  &__description
    +typoTextLg
    a
      font-weight: $font-primary-heavy

.ricerca-agente-no-results
  text-align: center
  flex: 1
  +res-sm-max
    display: none
  &__image-box
    margin-bottom: 15px
  &__image
    display: block
    margin: 0 auto
  &__title
    +typoTextXl
    font-weight: $font-primary-black
    margin-bottom: 20px
  &__description
    +typoTextMd
    a
      text-decoration: underline

.ricerca-agente-result
  padding: 24px 22px
  +res-sm-max
    padding: 22px 16px
    background: $grey-0
    text-align: center
    border-radius: 20px
  &--logged
    background: $grey-0
    border-radius: 20px 20px 0 0
    margin-bottom: 3rem
  &__head
    +typoTextXl
    font-weight: $font-primary-black
    color: $color-primary 
    margin-bottom: 16px
  &__layout
    +res-md-min
      display: flex
  &__content
    +res-md-min
      width: 60%
      margin-right: 16px
  &__name
    +typoTextXl
    font-weight: $font-primary-black
    margin-bottom: 16px
    +res-sm-max
      margin-bottom: 8px
  &__tags
    +res-md-min
      display: none
    +res-sm-max
      display: flex
      justify-content: center
      align-items: flex-start
      margin: 0 -7.5px 16px
      > *
        margin: 8px 7.5px 0

  &__tag
    +typoTextMd
    font-weight: $font-primary-heavy
    color: $white
    padding: 4px 6px
    border-radius: 4px
    &--orange
      background: #F36719
    &--green
      background: #63ACA5


  &__contacts
    +res-md-min
      > *
        &:not(:last-child)
          margin-bottom: 16px
    +res-sm-max
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: 0 -16px
      > *
        margin: 0 16px 16px
          
  &__contact
    display: flex
    align-items: center
    &__icon
      margin-right: 10px
      svg
        +style-icon(20px, 20px, currentColor)
    &__value
      +typoTextMd
  &__actions
    text-align: center
    > *
      &:not(:last-child)
        margin-bottom: 16px
    +res-md-min
      flex: 1
    +res-sm-max
      margin-top: 8px