.search
  z-index: 99999
  +res-md-max
    position: absolute
    top: 49px
    left: 0
    display: none
    width: 100vw
    background: #fff
    padding: 0 16px 10px 16px
    +box-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
  .ais-SearchBox-form
    position: relative
  .ais-SearchBox-input
    background: #fff
    max-width: 218px
    height: 36px
    padding: 9.5px 40px 9.5px 16px
    font-size: 1.8rem
    line-height: 2rem
    outline: none
    border: 2px solid $color-primary
    +border-radius(20px)
    +font(14, 17, 400)
    font-family: $font-primary
    color: $grey-3
    @media (max-width: 1280px + 32px)
      max-width: 190px
      +font(13, 15, 400)
    +res-md-max
      max-width: unset
      width: 100%
      padding: 5px 16px 5px 50px
      +font(16, 24, 400)
      border: 1px solid $grey-1
    &::-webkit-search-cancel-button
      display: none // removes clear X
  &__submit
    position: absolute
    right: 16px
    bottom: 8px
    width: 20px
    height: 20px
    cursor: pointer
    +res-md-max
      left: 16px
      right: unset
    .icon
      +fill-icon($color-primary)
      width: 100%
      height: 100%


body
  &:not(.w-scrolled)
    &:not(.w-scrolldown)
      .search
        +res-md-max
          display: block