.calendario-avvento-step2
  .calendario-avvento-step2-regalo-scartato
    max-width: 98.6rem
    text-align: center
    background-color: $green-light
    padding: 2.8rem 23.9rem
    text-align: center
    margin: 3rem auto 1.5rem auto
    +border-radius(1rem)
    .date
      font-size: 2.4rem
      color: $text-green-light
      padding-bottom: 3rem
    .title, .description
      color: $green-dark
      line-height: 5rem
    .title
      font-size: 2.4rem
      line-height: 4rem
    .description
      font-size: 4rem
      padding-bottom: 2rem
    .wbutton 
      border: 1px solid #ffffff
      color: $green-dark
    
    +res-md-max
      padding: 2.8rem 0


  .calendario-avvento-step2-riepilogo
    max-width: 98.6rem
    text-align: center
    margin: 0 auto
    background-color: $green-1
    +border-radius(1rem)
    .calendario-avvento-text-step2
      padding: 1.7rem 0 5.7rem 0
      text-align: center
      background-color: $green-1
      +border-radius(1rem)
      .title
        color: $green-dark
        font-size: 4rem
        line-height: 4.8rem
        padding-top: 2rem
      .name
        font-size: 2.4rem
        color: $text-green-light
        font-weight: 700
        padding: 2rem 0
      .description
        max-width: 59.8rem
        margin: auto
        +res-md-max
          padding: 0 2rem
          
    +res-md-max
      margin: 0 -2rem



    .calendario-avvento-list-step2
      display: flex
      flex-wrap: wrap
      max-width: 666px
      padding-bottom: 4rem
      margin: 0 auto
      +res-md-max
        max-width: 377.72px


  .calendario-avvento-back-step1
    padding: 5rem 0
    text-align: center