.temi-page
  background: $color-bg
  padding-bottom: 3rem // per compensare il footer

  &__header
    text-align: center
    padding-bottom: 4rem

    &__image
      display: block
      margin: -2rem auto .5rem auto

    &__title
      @extend .ext-heading1
      margin-bottom: 2rem

    &__abstract
      @extend .ext-abstract-medium

  &__all
    display: flex
    justify-content: center
    padding-bottom: 5rem

  &__list

    +print
      .row
        display: block
        > *
          display: inline-block
          width: calc(50% - 5px)

    .temi-item
      position: relative
      display: block
      margin: auto
      margin-bottom: 5rem
      height: 38rem
      width: 25rem
      +border-radius(1rem)
      overflow: hidden
      +print
        page-break-inside: avoid

      &__title
        position: absolute
        top: 50%
        +translateY(-50%)
        text-align: center
        padding: 0 1.5rem
        left: 0
        right: 0
        font-size: 2.4rem
        font-weight: $font-bold
        line-height: 3.2rem
        color: #fff
        +transition(opacity .5s)

      &__image
        +transition(all .5s)
        min-width: 100%
        min-height: 100%

      &__details
        display: flex
        justify-content: flex-start
        flex-direction: column
        padding-left: 2.2rem
        padding-right: 2.2rem
        color: white
        position: absolute
        top: 0
        left: 0
        bottom: 0
        width: 100%
        opacity: 0
        +border-radius(1rem)
        +transition(opacity .5s)

        &__title
          text-align: center
          font-size: 2.2rem
          font-weight: $font-bold
          line-height: 3.2rem
          margin-top: 4rem
          margin-bottom: 4.5rem
          text-transform: uppercase

        &__abstract
          font-family: $font-secondary
          font-size: 1.6rem
          font-weight: $font-regular
          line-height: 2.6rem
          text-align: center

      &:hover
        .temi-item
          &__title
            opacity: 0
          &__image
            +filter(blur(1rem))
            transform: scale(1.1)
          &__details
            opacity: 1






