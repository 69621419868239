@import public/sass/abstracts

.formazione-modulo-header
  display: flex
  +res-md-max
    flex-wrap: wrap
  &__left
    margin-right: 124px
    flex: 0 0 60%
    text-align: left
    +res-md-max
      flex: 0 0 100%
      margin-bottom: 20px
    &__nome-corso
      +font(20, 32, 400)
      margin-top: 13px
      margin-bottom: 24px
    &__titolo
      font-weight: 800
      font-size: 40px
      line-height: 48px
      color: $color-text
      margin-bottom: 24px
    &__descrizione
      +font(16, 24, 400)
      font-family: $font-secondary
      color: $color-text
      margin-bottom: 24px
    &__docente
      margin-bottom: 24px
    &__badge
      +res-md-max
        display: flex
        width: 100%
        justify-content: space-between
      .share
        display: none
        +res-md-max
          display: block
  &__right
    flex: 0 0 30%
    +res-md-max
      flex: 0 0 100%
    .share
      margin-bottom: 20px
      text-align: right
      +res-md-max
        display: none
