.obiettivo
  &__title
    background: $color-teal-light
    padding: 1rem 2rem
    font-size: 1.8rem
    font-weight: $font-bold
    color: $color-teal-dark
    +border-radius(3rem)
    margin-bottom: 1rem
  &__text
    font-size: 2rem
    line-height: 3.2rem
    color: $color-teal-dark
    padding-left: 2.5rem
    margin-bottom: 3rem