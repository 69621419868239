@import public/sass/abstracts

.empty-message
  padding: 30px
  text-align: center
  border: 2px dashed $grey-1
  border-radius: 16px
  text-align: center
  color: $black
  > *
    &:not(:last-child)
      margin-bottom: 20px
  &__icon
    svg
      +style-icon(50px, 50px, $color-primary)
      margin: 0 auto
  &__title
    +typoTextXl
    font-weight: $font-primary-black-2
  &__description
    +typoTextLg
  &__cta-box
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly
    max-width: 500px
    margin: auto
    &__first
      margin-bottom: 20px
    &__second
      margin-bottom: 20px
    &__third
      margin-bottom: 20px