@import public/sass/abstracts

.tab-parascolastico
  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      > *
        width: calc(50% - 7px)
        &:nth-child(2n+1)
          margin-right: 14px
    > *
      margin-bottom: 20px
  &__activate-cta
    +res-sm-max
      display: none
  &__add-cta
    +res-md-min
      display: none