@import public/sass/abstracts

.form-carta-docente-radio-option
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  color: $black
  padding: 20px
  border: 1px solid $grey-1
  border-radius: 10px
  +res-lg-min
    min-height: 90px
    > *
      &:not(:last-child)
        margin-right: 10px
  +res-md-max
    flex-wrap: wrap

  &__label    
    display: flex
    align-items: center
    +typoTextLg
    font-weight: $font-primary-heavy
    +res-md-max
      max-width: calc(100% - 40px)

  &__label-text
    +res-lg-min 
      margin-right: 4px
    +res-md-max
      margin-left: 4px
      order: 99
      
  &__helper
    svg
      +style-icon(24px, 24px, currentColor)

  &__form
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 16px
    +res-md-max
      width: 100%
      order: 99
      margin-top: 16px
    
  &__input
    +res-md-max
      flex: 1

  &__close
    cursor: pointer
    svg
      +style-icon(24px, 24px, $black)