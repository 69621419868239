@import public/sass/abstracts

.rivista-card-wide-abbonato-prova
  display: flex
  align-items: center
  justify-content: center
  flex: 1
  color: #ffffff
  background-color: $color-secondary-dark
  +border-radius(16px)
  margin-bottom: 30px
  +res-sm-max
    flex-direction: column-reverse
  &__container__content
    padding: 55px 45px
    flex: 1
    +res-sm-max
      padding: 0 20px 20px
  &__title
    +typoH4
    margin-bottom: 20px
  &__description
    +typoTextLg
    margin-bottom: 20px
  &__container__content-scadenza
    padding: 50px 
    text-align: center
    flex: 1
    +res-sm-max
      padding: 0  0 20px 0
  &__container__content-text
    background-color: $color-primary-dark
    +border-radius(12px)
    padding: 50px
    +res-sm-max
      padding: 16px
      display: flex
      flex: 1
      align-items: center
      margin: 16px
    
  &__scadenza
    +typoTextXl
    +res-sm-max
      flex: 1
      text-align: left
  &__giorni
    display: inline-block
    margin-top: 10px
    background-color: #ffffff
    padding: 6px 12px
    +border-radius(6px)
    +res-sm-max
      margin-top: 0
  &__scadenza-text
    color: #2A2A2A
    font-size: 2rem
    font-weight: 700
    line-height: 3.2rem
    +res-sm-max
      flex: 1
      



    


