@import public/sass/abstracts

.area-personale-metodo-pagamento-ordine-card
  padding: 20px
  background: $white
  border-radius: 10px
  +typoTextLg
  &__title
    margin-bottom: 16px
    font-weight: $font-primary-heavy
    text-transform: uppercase
  &__content
    display: flex
    align-items: center
  &__label
    +typoTextLg
  &__image-box
    margin-right: 10px
    padding: 2px 12px
    border-radius: 20px
    border: 1px solid $grey-1
  &__pagamento-image
    margin-right: 10px
    padding: 2px 12px
    border-radius: 20px
    border: 1px solid $grey-1
  &__image
    display: block