@import public/sass/abstracts

.corso-formazione-modulo
  &__container-moduli
    &--border
      +res-sm-max
        border-bottom: 1px solid $grey-2
    +res-md-max
      width: 90%
      margin: auto
      margin-bottom: 20px
    &__listing
      display: flex
      flex-flow: column
      align-items: center
    &__sottotitolo
      max-width: 1063px
      margin-left: auto
      margin-right: auto
      text-align: left
      margin-bottom: 20px
      +res-sm-max
        display: flex
        align-item: center
      &__text
        font-weight: 800
        width: 100%
        max-width: 1063px
        font-size: 24px
        line-height: 30px
        color: $color-text
        text-align: left
      &__trigger
        color: $color-text
        display: none
        +res-sm-max
          display: inline-block
