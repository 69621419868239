@import public/sass/abstracts

.elemento-sbloccato-card
  &__image-box
    max-width: 191px
    margin: 0 auto 16px
  &__head
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 10px
    > *
      &:not(:last-child)
        margin-right: 13px
  &__title
    +typoH5
  &__description
    +typoBase(1.4rem, 2rem, $font-primary-heavy)
    margin-bottom: 20px
  &__logo
    width: 90px
    margin: 0 auto 10px
  &__actions
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 300px 
    margin: 0 auto 20px
  &__cta
    text-align: center