$landing-riviste-blue-dark: #2597D8
$landing-riviste-blue-default: #A1DAF8
$landing-riviste-blue-light: #C9E8F9
$landing-riviste-green-dark: #478532
$landing-riviste-green-default: #AFCE67
$landing-riviste-green-light: #DDF5A6
$landing-riviste-yellow-dark: #D7751A
$landing-riviste-yellow-default: #F4C551
$landing-riviste-yellow-light: #FFE39F
$landing-riviste-orange-dark: #AF0505
