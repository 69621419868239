@import public/sass/abstracts

.quantity-handler
  display: inline-flex
  align-items: center
  background: $grey-0
  color: $black
  border-radius: 40px
  padding: 0
  &__input
    +typoTextLg
    font-weight: $font-primary-heavy
    width: 35px
    background: none
    text-align: center
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      -moz-appearance: textfield
    &:focus
      outline: none
  &__button
    display: block
    padding: 8px 10px
    &:not(:disabled)
      cursor: pointer
    &:disabled
      color: $grey-1
    svg
      +style-icon(16px, 16px, currentColor)
    &--minus
      padding-right: 5px
    &--plus
      padding-left: 5px