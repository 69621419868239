@import public/sass/abstracts

.box-elementi-rivista
  width: 100%
  max-width: $wcont-hug-width
  &__top
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 30px
    +res-sm-max
      justify-content: center
      margin-bottom: 20px
  &__right
    +res-sm-max
      display: none
  &__title
    +font(30, 40, 800)
  &__subtitle
    +font(16, 24, 400)
  &__bottom
    text-align: center
    margin-top: 50px
    +res-md-min
      display: none
  &__slider
    .slick-track
      margin-left: 0
    .slick-dots
      bottom: -52px
      font-size: 12px
      line-height: 12px
      margin-bottom: 20px
      +res-md-min
        display: none
      li 
        width: 12px 
        height: 12px
        margin: 0 6px
        button
          background: #BBBBBB
          border-radius: 50%
          width: 12px 
          height: 12px
          &::before
            display: none
        &.slick-active
          button
            background: $color-secondary
    &__slide
      padding-top: 10px
      > *
        margin-left: auto
        margin-right: auto
