@import public/sass/abstracts

.area-personale-raccolta-form
  &__pubblica-form-field
    .form-field
      &__head
        margin-bottom: 8px
      &__label
        +typoBase(1.6rem, 2.4rem, $font-primary-heavy)
  &__submit-box
    text-align: center