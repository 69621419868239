.block-strumento-embedded
  padding: 2rem 0
  &__content
    max-width: 343px

.ROOT--editing
  .block-strumento-embedded
    min-height: 20px
    &__content
      pointer-events: none
      &--empty
        &:before
          content: "Seleziona lo strumento"
          font-size: 16px
          line-height: 24px
          color: $pb-grey-80
          display: block