.popup
  text-align: center
  &__title
    font-size: 2.8rem
    line-height: 3rem
    margin-bottom: 1.8rem
  &__disclaimer
    @extend .ext-paragraph3
    margin-bottom: 2rem
    font-size: 1.8rem
    +res-xs-max
      font-size: 1.6rem
  &__image
    max-width: 30rem
    margin-bottom: 2rem
