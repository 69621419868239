.discipline-page
  background: $color-bg
  padding-bottom: 4rem

  &__header
    text-align: center
    padding-bottom: 5rem

    &__image
      display: block
      margin: -2rem auto .5rem auto

    &__title
      @extend .ext-heading1
      margin-bottom: 2rem

    &__abstract
      @extend .ext-abstract-medium

  &__all
    display: flex
    justify-content: center
    margin-bottom: 5rem

  &__list
    // display: flex
    // justify-content: space-between
    // flex-wrap: wrap
    margin: 3rem 0
    // +res-lg-max
    //   justify-content: space-around
    .row
      +print
        display: block
        > *
          display: inline-block
          width: calc(50% - 5px)
          page-break-inside: avoid

    .discipline-item
      position: relative
      display: block
      margin-bottom: 5rem
      background: #000
      height: 20rem
      // max-width: 26rem
      width: 25rem
      margin-left: auto
      margin-right: auto
      +border-radius(1rem)
      +transform(translate3d(0, 0, 0))
      overflow: hidden
      +print
        max-width: 100%
        page-break-inside: avoid

      &:hover
        +box-shadow(8px 8px 15px 0px $color-grey-8)

      &__title
        position: absolute
        top: 50%
        +translateY(-50%)
        text-align: center
        padding: 0 1.5rem
        left: 0
        right: 0
        font-size: 2.4rem
        font-weight: $font-bold
        line-height: 3.2rem
        color: #fff
        text-transform: uppercase
        +transition(opacity .5s)

      &__image
        +transition(all .5s)
        min-width: 100%
        max-width: none
        min-height: 100%

      &__details
        display: flex
        justify-content: center
        flex-direction: column
        padding: 2rem
        color: white
        position: absolute
        top: 0
        left: 0
        bottom: 0
        width: 100%
        opacity: 0
        +border-radius(1rem)
        +transition(opacity .5s)

        &__title
          text-align: center
          font-size: 2.4rem
          font-weight: $font-bold
          line-height: 3.2rem
          margin-bottom: 2rem

        &__abstract
          font-family: $font-secondary
          font-size: 1.6rem
          line-height: 2.4rem
          text-align: center
          font-weight: 100






