@import public/sass/abstracts

.area-personale-user-card
  &__image-box
    position: relative
    width: 140px
    height: 140px
    overflow: hidden
    border-radius: 12px
    background: none
    margin-right: 14px
    +res-xs-max
      margin: 0 auto 20px
    > svg
      +style-icon(104px, 104px, $white)
      margin: 18px

  &__edit-icon
    position: absolute
    top: 0
    left: 0
    padding: 8px
    border-radius: 12px 0 12px 0
    background: $accent-blue
    svg
      +style-icon(29px, 29px, $white)

  &__content
    > *
      &:not(:last-child)
        margin-bottom: 8px
  &__name
    +typoBase(1.8rem, 2.4rem, 750)
  &__type
    +typoTextMd
    font-weight: $font-primary-regular
    +res-sm-max
      margin-top: 8px
  &__member
    +typoTextMd
    font-weight: $font-primary-heavy
    color: $color-primary
    > *
      &:nth-child(2)
        color: $black
        font-weight: $font-primary-regular
  &__profile-link
    display: none     

  &--md
    +res-sm-min
      display: flex
    +res-xs-max
      text-align: center
    .area-personale-user-card
      &__content
        +res-sm-min
          flex: 1
      &__member
        margin-top: 20px

  &--sm
    display: flex
    .area-personale-user-card
      &__image-box
        width: 70px
        height: 70px
        +res-lg-min
          margin-right: 10px
        +res-md-max
          margin-right: 20px
        svg
          +size-icon(52px, 52px)
          margin: 9px
      &__content
        flex: 1
        > *
          &:not(:last-child)
            +res-md-max
              margin-bottom: 10px
      &__member
        +res-md-max
          display: none
      &__profile-link
        +res-md-max
          display: inline-flex