@import public/sass/abstracts

.checkout-section
  &__head
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    margin-bottom: 20px

  &__title
    +typoH4

  &__action-desktop
    margin-left: auto
    > *
      display: flex // L'inline-flex lascia uno spazio sotto, andrebbe sistemato probabilmente a livello di bottone ma è rischioso
    +res-sm-max
      display: none

  &__action-mobile
    margin-top: 20px
    +res-md-min
      display: none