.iopiu2-video
  max-width: 1065px
  margin: 0 auto
  padding: 38.7px 24px 108.3px
  +res-sm-max
    padding-bottom: 19.6px 
    padding-bottom: 26.9px 
  &__title
    font-size: 40px
    font-weight: 900
    line-height: 48px
    text-align: center
    margin-bottom: 8.5px
    +res-sm-max
      font-size: 24px
      line-height: 24px
      margin-bottom: 17.4px
  &__description
    font-size: 24px
    line-height: 28.8px
    text-align: center
    margin-bottom: 89.4px
    +res-sm-max
      font-size: 14px
      line-height: 19px
      margin-bottom: 33px
  &__slider
    position: relative
    +res-sm-max
      display: none
    .slick-track
      display: flex
      & > div
        margin-right: 4rem
    .slider-container
      position: relative
      max-width: 100%
      padding: 0 1rem
      overflow: hidden
      &::before
        content: ""
        position: absolute
        top: 0
        display: block
        width: 20%
        height: 22rem
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 40%)
      &::after
        content: ""
        position: absolute
        top: 0
        display: block
        width: 20%
        margin-left: 80%
        height: 100%
        background: linear-gradient(90deg, rgba(36,0,0,0) 20%, rgba(255,255,255,1) 80%)
      &--overlay
        &::before
          z-index: 1
        .slider-nav-item--prev
          display: block
    .slider-nav-item
      position: absolute
      top: 40%
      width: 5rem
      height: 5rem
      z-index: 2
      padding: 1rem 1.5rem
      +border-radius(5rem)
      &::after
        display: none
      svg
        width: 2.2rem
        height: 2.2rem
        fill: white
        vertical-align: top
      &--prev
        display: none
        left: 0
      &--next
        right: 2rem
        svg
          +rotate(180deg)
    .slick-list
      overflow: hidden
    &__item
      a
        position: relative
        display: block
        img
          max-height: 22rem
          &.play
            position: absolute
            bottom: 38%
            right: 40%
  &__video
    +res-md-min
      display: none
    & > div
      align-items: center
      flex-direction: column
    .iopiu-caratteristiche__slider__item
      margin-bottom: 2rem
    &__first
      display: flex
    &__others
      display: none
      &.show
        display: flex
    &__toggle
      text-align: center
      text-decoration: underline
      margin-top: 20.8px
      svg
        display: block
        margin-top: 1rem
        fill: $color-text
        width: 2rem
        height: 2rem
        margin-left: auto
        margin-right: auto
        +rotate(-90deg)
        &.hide
          margin-bottom: 1rem
          margin-top: 0
          +rotate(90deg)
  