.modal
  &__overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, .5)
    z-index: 9999
  &__content
    position: absolute
    top: 50%
    left: 50%
    right: auto
    bottom: auto
    display: flex
    align-items: center
    flex-direction: column
    margin-right: -50%
    +border-radius(.5rem)
    padding: 2rem 2rem 4rem 2rem
    width: 80rem
    max-width: 90%
    transform: translate(-50%, -50%)
    background: #fff
    outline: none
    max-height: 90%
    overflow-y: auto
    .close
      align-self: flex-end
      margin-bottom: 1rem
      cursor: pointer
      .icon
        width: 1.4rem
        height: 1.4rem
        fill: $color-grey-3
    .title
      margin-bottom: 2rem
    .disclaimer
      margin-bottom: 3rem
      text-align: center
    .cta
      margin-bottom: 3rem
    &--wide
      width: 80rem!important
    &__vimeo
      width: 100%
      iframe
        width: 100%
        height: 35rem
        +res-xs-max
          height: auto
