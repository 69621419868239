@import public/sass/abstracts

.form-checkbox
  position: relative
  display: block
  color: $black

  &__input-wrap
    cursor: pointer

  &__label    
    display: block

  &__input
    position: absolute
    left: -9999px

  &__styledinput
    position: absolute
    left: 0
    svg
      display: block
      +fill-icon($black)

  &--checked
    .form-checkbox
      &__styledinput
        svg
          +fill-icon($accent-blue)

  &--readonly
    .form-checkbox
      &__styledinput
        svg
          +fill-icon($black)

  &--disabled
    .form-checkbox
      &__input-wrap
        cursor: default
      &__styledinput
        svg
          +fill-icon($grey-1)
      &__label
        color: $grey-1
  
  // Status
  &--error
    .form-checkbox
      &__input
        &+ .form-checkbox__styledinput
          color: $accent-red

  // Size 
  &--md
    min-height: 24px
    +typoBase(1.4rem, 2rem, $font-primary-regular)
    .form-checkbox
      &__input
        &+ .form-checkbox__styledinput
          top: 0
          svg
            +size-icon(24px, 24px)
      &__label
        padding-left: 32px
        padding-top: 2px