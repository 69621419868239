.target-box
  font-family: $font-primary
  padding: 2.5rem 2rem
  background: $color-teal-light
  color: $color-teal-dark
  margin: 4rem 0
  +border-radius(.6rem)
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0
  
  &__text
    font-size: 2rem
    line-height: 3.2rem
    padding-left: 1rem
    margin-top: 0
    margin-bottom: 0

  .icontitle
    color: $color-teal-dark!important
    &::before
      content: url(/images/svg/target--teal-dark.svg)!important
    &--pencil    
      &::before
        content: url(/images/svg/pencil--teal-dark.svg)!important
    &--paper    
      &::before
        content: url(/images/svg/paper--teal-dark.svg)!important
    &--clock    
      &::before
        content: url(/images/svg/clock--teal-dark.svg)!important
    &--headphones    
      &::before
        content: url(/images/svg/headphones--teal-dark.svg)!important

  &--white
    background: #fff
    color: $color-teal-lezione
    .icontitle
      color: $color-teal-lezione!important
      &::before
        content: url(/images/svg/target--teal-lezione.svg)!important
      &--pencil    
        &::before
          content: url(/images/svg/pencil--teal-lezione.svg)!important
      &--paper    
        &::before
          content: url(/images/svg/paper--teal-lezione.svg)!important
      &--clock    
        &::before
          content: url(/images/svg/clock--teal-lezione.svg)!important
      &--headphones    
        &::before
          content: url(/images/svg/headphones--teal-lezione.svg)!important
