@import public/sass/abstracts

.area-personale-attiva-template
  &--success
    .area-personale-page
      &__main
        padding-bottom: 0
  &__form
    +res-sm-min
      display: flex
      align-items: flex-start
      > *
        &:first-child
          width: 50%
          margin-right: 16px
    > *
      &:last-child
        margin-top: 20px
        +res-sm-max
          margin-top: 8px
    .form-input__input
      border-color: $grey-2
      &:active, &:focus
        border-color: $accent-blue
  &__success
    +typoBase(1.2rem, 1.6rem, $font-primary-heavy, $font-primary, $color-discipline-scienze)
    margin: 10px 0 0 16px
  &__contenuti
    background: $grey-0
    margin: 20px -16px 0
    padding: 30px 16px 200px
    z-index: -1
    @media screen and (min-width: $wcont-width)
      margin-right: calc(#{$wcont-width / 2} - 50vw)
      padding-right: calc(50vw - #{$wcont-width / 2} + 16px)
    &__title
      +typoH4
      margin-bottom: 20px
    &__description
      +typoBase(1.8rem, 2.4rem, $font-primary-medium)
      a
        color: $accent-blue
        font-weight: $font-primary-heavy
  &__results
    margin-top: 20px
  &__result
    +res-md-min
      display: flex
      align-items: flex-start
    &:not(:last-child)
      margin-bottom: 50px
    &__sidebar
      background: $white
      padding: 30px 16px 20px
      border-radius: 20px
      +res-md-min
        width: 40%
        margin-right: 18px
      +res-sm-max
        margin-bottom: 30px
    &__list
      +res-md-min
        flex: 1
        display: flex
        flex-wrap: wrap
        +custom-row(14px)
        > *
          width: 50%
    &__item
      margin-bottom: 30px
      > *
        margin-left: auto
        margin-right: auto