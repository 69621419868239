@import public/sass/abstracts

.corso-formazione
  &__container-moduli
    max-width: 1280px
    margin-left: auto
    margin-right: auto
    padding-left: 16px
    padding-right: 16px
    padding-top: 70px
    &__titolo
      font-weight: 800
      font-size: 30px
      line-height: 40px
      color: $color-text
      margin-bottom: 10px
    &__sottotitolo
      font-weight: 400
      font-size: 16px
      line-height: 24px
      color: $color-text
      margin-bottom: 20px
    &__spacing
      margin-bottom: 20px
    &__listing
      flex-wrap: wrap
      gap: 10px
      +res-sm-min
        display: flex
        column-gap: 15px
      .formazione-modulo-card
        +res-md-min
          flex: 0 0 49%
        +res-xl-min
          flex: 0 0 32%
