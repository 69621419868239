.modifica
  display: block
  background: #fff
  padding: 1.2rem 0 0 1.2rem
  +border-top-left-radius(1.3rem)
  +border-bottom-right-radius(1.6rem)
  width: 4.5rem
  height: 4.5rem
  cursor: pointer
  .icon
    width: 2rem
    height: 2rem
    fill: $color-secondary!important
