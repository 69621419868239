@import public/sass/abstracts

.tab-group
  .tab-trigger
    margin-left: 15px
    +res-sm-max
      margin-left: 8px
    &--white
      margin-left: 0
      margin-right: 15px
      +res-sm-max
        margin-left: 0
        margin-right: 8px
  &--no-slider
    .tab-group
      &__triggers
        display: flex
        > *
          flex: 1 0 0