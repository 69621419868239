@import public/sass/abstracts

.form-field

  &__head
    display: flex
    align-items: center
    margin-bottom: 4px

  &__label
    display: block
    color: $black

  &__children
    +res-md-min
      display: flex
      > * 
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 16px

  &__helper
    margin-top: 4px
    +typoBase(1.2rem, 1.6rem, $font-primary-regular)

  // Inline (label affiancata a campo)
  &--inline
    +res-md-min
      display: flex
      .form-field
        &__head
          margin-bottom: 0
          margin-top: 4px
          margin-right: 40px
          align-self: flex-start



  // Status
  &--error
    .form-field
      &__label
        color: $accent-red

  // Size
  &--md
    .form-field
      &__label
        +typoBase(1.2rem, 1.6rem, $font-primary-heavy)