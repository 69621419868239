.autore-page
  &--redazionale
    .autore-page
      &__header
        position: relative
        &::before
          content: ""
          position: absolute
          top: 0
          left: 0
          width: calc(50% - 10.66rem) // 
          bottom: 0 // per overlay footer
          background-size: 60rem auto
          background-repeat: no-repeat
          background-position: center right
          background-color: $color-primary
          background-image: url(/images/svg/logosymbol-overlay.svg)
          @media (max-width: 1280px)
            width: 41.66%
          +res-sm-max
            display: none
      &__side
        +res-sm-max
          background-size: 60rem auto
          background-repeat: no-repeat
          background-position: center center
          background-color: $color-primary
          background-image: url(/images/svg/logosymbol-overlay.svg)
        
        
.autore-redazionale-biografia
  margin: 3rem
  &__titolo
    color: $color-secondary-dark
    font-family: $font-primary
    font-size: 4rem
    font-weight: $font-bold
    line-height: 4.8rem
    margin-bottom: 1rem
  &__tags
    margin-bottom: 1rem
  &__contenuto
    @extend .ext-abstract-medium
  &__attributi
    margin-top: 3rem
    margin-right: 3rem
    margin-bottom: 3rem
    p
      display: inline-block
      margin-right: 1.5rem
      margin-bottom: 1.5rem
      background: grey