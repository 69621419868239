@import public/sass/abstracts

.contenuto-numero-rivista
  +font(16, 24, 400)
  &__label
    +font(12, 16, 800)
    color: $tertiary
    margin-bottom: 6px
    text-transform: uppercase
  &__title
    font-weight: 900
    color: $accent-blue
    margin-right: 4px
    text-decoration: underline