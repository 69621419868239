#navigation
  position: relative
  +res-md-max
    display: none
  .nav
    display: flex
    justify-content: space-between
    &__item
      cursor: pointer
      padding: 30px 0 28px 0
      // padding: 37px 0 35px 0
      &:not(:last-child)
        margin-right: 30px
        @media (max-width: 1280px + 32px)
          margin-right: 20px
      & > a
        display: block
        +font(15, 18, 700)
        color: $menu-item
        text-decoration: none
        white-space: nowrap
        // @media (max-width: 1280px + 32px)
        //   +font(15, 18, 700)
        .icon--ioplus
          margin-left: .7rem
          width: 2.5rem
          height: 1.96rem
          fill: white
          vertical-align: top
      &--active, &:hover
        border-bottom: 3px solid $color-primary
        padding-bottom: 25px // per assorbire il bordo
        // padding-bottom: 32px // per assorbire il bordo
        & > a
          color: $color-primary
                
#nav-mobile
  display: block
  position: fixed
  top: 0
  left: 0
  width: 325px
  max-width: 40rem
  bottom: 0
  z-index: 9999
  background: #fff
  overflow-y: auto
  overflow-x: hidden
  +transition(transform .3s ease-in-out)
  +translateX(-100%)
  +box-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
  +res-lg-min
    display: none!important
  +print
    display: none!important
  &.nav-mobile--open
    +translateX(0)
.nav-mobile  
  &__panel
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow-y: auto
    +transition(all .5s)
    +translateX(-100%)
    background: #fff
    &--open, &--main
      +translateX(0)
    &:not(.nav-mobile__panel--main)
      padding: 26px 20px
  &__list
    &--main
      padding: 70px 35px 0 35px
      .nav-mobile__item
        margin-bottom: 16px
    &--utilities
      padding: 0 35px
      .nav-mobile__item
        padding: 16px 0
        +font(16, 19, 500)
        .icon
          display: inline-block
          margin-right: 12px
          width: 16px
          height: 16px
          fill: $black
          vertical-align: middle
  &__back
    display: flex
    align-items: center
    margin-bottom: 20px
  &__back-link, &__close
    display: flex
    align-items: center
    +font(14, 20, 700)
    color: $grey-3
    .icon
      width: 24px
      height: 24px
      margin-right: 10px
  &__main-close
    position: absolute
    top: 26px
    right: 16px
    .icon
      width: 24px
      height: 24px
  &__close
      margin-left: auto
  &__backdrop
      position: absolute
      top: 0
      left: 0
      content: ""
      width: 100vw
      height: 100vh
      background: rgba(0, 0, 0, .5)
      z-index: 999

.header--toolbar-active
  ~ #nav-mobile
    top: $toolbar-height

body
  &:not(.w-scrolled)
    .header--toolbar-active
      #navigation
        .submenu
          top: calc( #{$header-height} + #{$toolbar-height} )