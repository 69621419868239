.box-sportello
  display: flex
  justify-content: space-between
  background: $color-bg
  max-width: $wcont-sportelli-width
  margin-left: auto
  margin-right: auto
  padding: 6rem 3rem 6rem 5rem
  margin-bottom: 5.5rem
  margin-top: 7.5rem
  +border-radius(.6rem)
  +res-sm-max
    flex-direction: column
    padding: 4rem 2rem
  &--faq
    .box-sportello
      &__intro
        +res-sm-max
          text-align: left
          margin-left: 0
      &__actions
        +res-sm-max
          align-items: flex-start
  &__intro
    max-width: 56rem
    +res-md-max
      max-width: 40rem
    +res-sm-max
      text-align: center
      margin-left: auto
      margin-right: auto
      margin-bottom: 3rem
    &__biografia
      font-family: $font-secondary
      font-size: 1.6rem
      line-height: 2.4rem
  &__title
    font-size: 2.4rem
    line-height: 3rem
    margin-bottom: 2.4rem
  &__disclaimer
    font-family: $font-secondary
    font-size: 1.6rem
    line-height: 2.4rem
  &__actions
    display: flex
    flex-direction: column
    align-items: flex-start
    +res-sm-max
      align-items: center
    a, button
      margin-bottom: 2rem