#nprogress
  pointer-events: none

.nprogress
  &__bar
    background: $color-primary
    position: fixed
    z-index: 10031
    top: 0
    left: 0
    width: 100%
    height: 2px
