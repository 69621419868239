@import public/sass/abstracts

.garanzia_tile
  &__container
    display: flex
    justify-content: space-between
    background: $grey-0
    flex-flow: column
    padding: 12px 16px
    border-radius: 10px
    max-width: 520px
    width: 100%
    position: relative
    &__title
      font-weight: 800
      font-size: 14px
      cursor: pointer

    &__arrow
      position: absolute
      right: 10px
      width: 25px
      cursor: pointer

    &__info
      overflow: hidden
      +transition(max-height .3s linear)
      display: none
      +res-xl-min
        display: block

      &__inner
        display: flex
        font-size: 14px
        padding-top: 10px
        overflow: hidden

        &__name
          margin-right: 5px

        &__prezzo
          font-weight: 800
          margin-left: auto

    &--hide
      +res-md-min
        .garanzia_tile
          &__container
            &__title
              display: none

            &__arrow
              display: none
                
            &__info
              &__inner
                padding-top: 0
                &__prezzo
                  margin-left: 40px

  &__inverted_mobile
    display: none
    overflow: hidden
    +transition(max-height .3s linear)
    +res-lg-max
      display: block

  &__container--inverted
    flex-direction: row-reverse
    display: flex
    justify-content: space-between
    background: $grey-0
    padding: 10px 20px
    border-radius: 10px
    max-width: 500px
    width: 100%
    margin-top: 10px

    &__info
      &__inner
        &__inverted
          display: flex
        &__prezzo
          font-weight: 800
          margin-right: 5px