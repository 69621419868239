*,
*::after,
*::before
  margin: 0
  padding: 0
  box-sizing: inherit

ul, ul > li
  list-style: none

button
  background: none
  border: none
  outline: none

img
  max-width: 100%
  +print
    page-break-inside: avoid

html
  font-size: 62.5%
  // scroll-behavior: smooth
  scroll-padding-top: $header-height
  +res-md-max
    scroll-padding-top: $header-height-mobile

main
  display: block
  padding-top: $header-height
  +res-md-max
    padding-top: $header-height-mobile
  +print
    padding-top: 0
.header--toolbar-active ~ main
  padding-top: calc( #{$header-height} + #{$toolbar-height} )
  +res-md-max
    padding-top: calc( #{$header-height-mobile} + #{$toolbar-height} )
  +print
    padding-top: 0

video
  width: 100%
  display: block
  outline: none

table
  width: 100%

address
  font-style: normal

a
  outline: none
  text-decoration: none
  color: inherit
  &:hover,
  &:active
    text-decoration: none

body
  overflow-x: hidden
  box-sizing: border-box
  position: relative
  +print
    color-adjust: exact
    -webkit-print-color-adjust: exact
    background: #FFF
    color: #000

  &.w-noscroll
    overflow: hidden
    margin-right: 17px // scrollbar width

  &.navigation-open, &.dialog-open
    overflow: hidden

  .pb-content
    iframe
      height: calc( 100vh - #{$toolbar-height} * 2 - #{$header-height})
      width: 100%
      margin-bottom: 30px

  .cookie-policy
    padding-bottom: 80px
    &__title
      +font(48, 40, 700)
      margin-bottom: 40px

  // .custom-content
  //   position: relative
  //   &.active
  //     height: 80vh
  //     margin-bottom: 3rem
  //     > .pagebuilder-placeholder
  //       position: absolute
  //       top: 0
  //       left: 0
  //       width: 100%
  //       height: 100%
  // @media (min-width: $wcont-width + 400px)
  //   .w-container, 
  //   .w-container-inner
  //     .custom-content
  //       // &.active
  //       > .pagebuilder-placeholder
  //         width: calc(100% + 210px)  // side width

.w-container
  margin-left: auto
  margin-right: auto
  padding-left: 2rem
  padding-right: 2rem
  max-width: $wcont-width

.w-container-inner
  margin-left: auto
  margin-right: auto
  padding-left: 2rem
  padding-right: 2rem
  max-width: $wcont-inner-width

.w-container-small
  margin-left: auto
  margin-right: auto
  padding-left: 2rem
  padding-right: 2rem
  max-width: $wcont-small-width

.w-container-hub
  margin-left: auto
  margin-right: auto
  padding-left: 2rem
  padding-right: 2rem
  max-width: $wcont-hub-width
  +res-xxl-min
    max-width: $wcont-width

.w-container-sportelli
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  max-width: calc(#{$wcont-sportelli-width} + #{$wcont-padding * 2})

.w-container-fluid
  max-width: 100%
  padding-left: 2rem
  padding-right: 2rem

.w-container-fluidleft
  margin-right: calc(50% - 640px) //50% - $wcont-width/2
  padding-right: 2rem
  @media (max-width: $wcont-width)
    margin-right: 0

.w-container-fluidright
  margin-left: calc(50% - 640px) //50% - $wcont-width/2
  padding-left: 2rem
  @media (max-width: $wcont-width)
    margin-left: 0

.w-padding
  padding-left: 2rem
  padding-right: 2rem

h1, h2, h3, h4, h5, h6
  margin-bottom: 0

@page
  size: auto
  margin-top: 20mm

.cms-page
  min-height: 50rem
  &--default
    padding-bottom: $footer-negative-margin

.grecaptcha-badge
  visibility: hidden

#cc-notification
  +print
    display: none!important

.CybotCookiebotBannerCloseButton svg
  width: 7px
  height: 7px
  opacity: 0.5
