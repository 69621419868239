@import public/sass/abstracts

.area-personale-indirizzo-ordine-card
  padding: 20px
  background: $white
  border-radius: 10px
  +typoTextLg
  &__title
    margin-bottom: 16px
    font-weight: $font-primary-heavy
    text-transform: uppercase
  &__content
    > *
      &:not(:last-child)
        margin-bottom: 4px