@import public/sass/abstracts

.widget-link
  display: block
  text-align: center
  padding: 25px 28px
  background: $grey-0
  border-radius: 20px
  display: flex
  align-items: center
  justify-content: space-between
  height: 100%
  &__label
    +typoH5