@import public/sass/abstracts

.area-personale-data-field-layout
  display: flex
  flex-wrap: wrap
  +custom-row(20px)
  > .area-personale-data-field
    &--space-md
      margin-bottom: 24px
    &--space-none
      margin-bottom: 0

  +res-md-min
    > .area-personale-data-field
      &--layout-md
        width: 33.33%
      &--layout-full
        width: 100%
  +res-sm-max
    > .area-personale-data-field
      &--layout-mobile-md
        width: 50%
      &--layout-mobile-full
        width: 100%