@import public/sass/abstracts

.tab-autori
  &__actions
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 24px
    > *
      &:not(:last-child)
        margin-right: 24px
  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(16px)
      > *
        width: 50%
  &__item
    position: relative
    margin-bottom: 24px
    +res-sm-max
      margin-bottom: 8px