.registrazione-typ
  &__content
    text-align: center
    margin-bottom: 5rem
  &__title
    @extend .ext-boxtitle
    margin-top: 2rem
    margin-bottom: 3rem
    text-align: center
    span
      display: block
  &__description
    +typoTextLgSerif
  &__actions
    +res-md-min
      display: flex
      margin-bottom: 100px
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 18px
    +res-sm-max
      margin-bottom: 50px
      > *
        &:not(:last-child)
          margin-bottom: 20px
    &__right
      +res-md-min
        flex: 0 0 40%
        > *
          &:not(:last-child)
            margin-bottom: 16px
      +res-sm-max
        > *
          &:not(:last-child)
            margin-bottom: 20px
  .typ-contenuti-box
    margin-bottom: 10px