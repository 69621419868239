.credits
  margin: 4rem 0
  background: $grey-0
  +border-radius(12px)
  +print 
    margin-bottom: 2rem
    background: none
    border: 1px solid $grey-0
    page-break-inside: avoid
  &__trigger
    display: flex
    cursor: pointer
    padding: $wcont-padding
    +font(18, 26, 900)
    .icon
      margin-top: 7px
      transition: $transition-duration / 3
      +transform(rotate(-90deg))
      margin-left: auto
      height: 1.5rem
      width: 1.5rem
      +print
        display: none
      &--arrow-down
        margin-top: 0
        transition: $transition-duration / 3
        +transform(rotate(0))
        height: 24px
        width: 24px
        fill: $grey-3
  &__content
    display: none
    padding: $wcont-padding
    padding-top: 0
    line-height: 2.8rem
  &--open
    .credits
      &__trigger
        .icon
          +transform(rotate(90deg))
          &--arrow-down
            +transform(rotate(180deg))
      &__content
        display: block
  &--white
    background: #fff
  &--catalogo
    margin: 0 0 $wcont-padding 0
    &:not(.credits--open)
      background: $accent-blue-light
      color: $color-secondary
      .icon
        &--arrow-down
          fill: $color-secondary

@media print
  .credits
    &__content
      display: block

