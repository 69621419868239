@import public/sass/abstracts


.tab-trigger
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 13px 16px
  +border-radius(20px 20px 0 0)
  background-color: $accent-blue-light
  color: $accent-blue
  cursor: pointer
  &__label
    +typoBase(1.8rem, 2.4rem, $font-primary-heavy)
    +res-sm-max
      +typoBase(1.6rem, 2.4rem)
  &--active
    background-color: $grey-0
    color: $black
  &--white
    &.tab-trigger--active
      background: #fff

.tab
  position: relative // per z-index
  display: none
  background-color: $grey-0
  padding: 30px 16px
  +res-sm-max
    padding: 20px $wcont-padding
  &--white
    background: #fff
    padding-left: 0
    padding-right: 0
  &--active
    display: block