.articolo-card
  font-family: $font-primary
  position: relative
  width: 21.5rem
  height: 33.8rem
  +border-radius(1.6rem)
  display: flex
  flex-direction: column
  z-index: 1
  background: $color-grey-12
  +transition(300ms)
  &:hover
    +translateY(-1rem)
  &--disabled
    .articolo-card
      &__image-box,
      &__content
        opacity: .4
  .ioplus
    position: absolute
    top: 0
    left: 0
    z-index: 3
  .scarica
    position: absolute
    top: 0
    left: 0
    z-index: 3
  .salva
    position: absolute
    left: 0
    z-index: 2
    &:not(:first-child)
      height: 6.1rem
      padding-top: 2.7rem
      top: 2.9rem
      +transition(all .5s)
  &__image-box
    max-height: 100%
    height: 21.5rem
    width: 21.5rem
    background-repeat: no-repeat
    background-size: cover
    background-image: url('/images/card/articolo.jpg')
    background-position: top
    padding: 1.6rem
    padding-left: 4.8rem
    text-align: right
    +border-radius(1.6rem)
  &__content
    padding: 1.6rem 1.2rem 1.8rem 1.2rem
  &__title
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-size: 1.6rem
    line-height: 2.4rem
    font-weight: $font-bold
    margin-bottom: .5rem
    word-break: break-word
  &__abstract
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-family: $font-secondary
    font-size: 1.4rem
    line-height: 2rem
  &__author
    font-size: 1.6rem
    line-height: 2.4rem
    color: #fff
    font-weight: $font-bold
  &--horizontal
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    .articolo-card
      &__image-box
        height: 21.6rem
        min-width: 16.4rem
        display: flex
        padding-left: 1.2rem
        padding-bottom: 1rem
      &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
      &__title
        font-size: 2rem
        line-height: 2.6rem
      &__author
        margin-top: auto
        text-align: left
  &--small
    width: 19.1rem
    height: 26.8rem
    .articolo-card
      &__image-box
        width: 19.1rem
        height: 14.2rem

  &--horizontal-noimage
    width: auto
    height: 12.7rem
    display: block
    text-align: left
    .articolo-card
      &__image-box
        display: none
      &__content
        display: flex
        flex-direction: column
        padding: .9rem 1rem 1rem 6rem
        height: 100%
      &__author
        color: inherit
        font-size: 1.4rem
        line-height: 1.6rem
        text-align: right
        margin-top: auto

  +res-sm-max
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    .articolo-card
      &__title, &__abstract
        -webkit-line-clamp: 3
      &__image-box
        height: 21.6rem
        min-width: 16.4rem
        display: flex
      &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
      &__author
        margin-top: auto
        text-align: left
