.iopiu2-options
  +res-md-min
    background: $color-bg
    display: flex
    padding-bottom: 50px
    > *
      flex: 1 0 0
  &__register
    position: relative
    +res-md-min
      padding: 57px 47px 180px
    @media screen and (min-width: 1065px)
      padding-left: calc(50vw - #{1065px/2 - 16px})
    +res-sm-max
      background: $color-bg
      padding: 36px 24px 42.3px
    &__title
      font-size: 40px
      font-weight: 900
      line-height: 48px
      color: $color-primary
      margin-bottom: 30px
      +res-sm-max
        font-size: 28px
        line-height: 48px
        text-align: center
    &__description
      font-size: 24px
      line-height: 36px
      color: #000
      margin-bottom: 43px
      +res-sm-max
        font-size: 16px
        line-height: 23px
        margin-bottom: 39.6px
    &__cta-box
      +res-sm-max
        text-align: center
    &__icons
      display: flex
      justify-content: space-between
      margin-left: auto
      margin-right: auto
      > *
        max-width: 170px
        height: 50px
        &:not(:last-child)
          margin-right: 30px
      +res-md-min
        position: absolute
        bottom: 74px
        right: 47px
        left: 47px
      @media screen and (min-width: 1065px)
        left: calc(50vw - #{1065px/2 - 16px})
        right: 140px
      +res-sm-max
        margin-bottom: 34.6px
        max-width: 300px
        > *
          max-width: 120px
          height: 30px
          &:not(:last-child)
            margin-right: 36px
  &__trial
    position: relative
    background: $color-primary
    +border-bottom-left-radius(100px)
    +res-md-min
      padding: 57px 47px 180px
    @media screen and (min-width: 1065px)
      padding-right: calc(50vw - #{1065px/2 - 16px})
    +res-sm-max
      padding: 36px 24px 60px
    &__title
      font-size: 40px
      font-weight: 900
      line-height: 48px
      color: #fff
      margin-bottom: 30px
      +res-sm-max
        font-size: 28px
        line-height: 48px
        text-align: center
    &__description
      font-size: 24px
      line-height: 36px
      color: #000
      margin-bottom: 43px
      +res-md-min
        span
          display: block
      +res-sm-max
        font-size: 16px
        line-height: 23px
        margin-bottom: 21.3px
    &__cta-box
      +res-sm-max
        text-align: center
    &__icons
      svg
        display: block
        fill: #FFF
      +res-md-min
        position: absolute
        bottom: 74px
        left: 47px
        svg
          width: 84.3px
          height: 65.6px
      +res-sm-max
        margin-bottom: 29px
        svg
          width: 62px
          height: 48px
          margin: 0 auto

  
  