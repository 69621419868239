@import public/sass/abstracts

.numero-rivista-template
  background: $grey-0
  &__container
    display: flex
    max-width: $wcont-catalogo-width
    margin-top: 10px
  &__content
    max-width: $wcont-fill-width
    +res-sm-max
      display: flex
      flex-direction: column
  &__image
    filter: drop-shadow(6px 6px 20px rgba(0, 0, 0, 0.1))
    &--mobile
      display: flex
      justify-content: center
      width: 100%
      margin-bottom: 16px
      +res-md-min
        display: none
    &--desktop
      margin-right: 65px
      +res-sm-max
        display: none
  &__rivista
    +font(16, 24, 400)
    margin-bottom: 20px
    text-transform: uppercase
    +res-sm-max
      margin-bottom: 10px
      text-align: center
  &__inserto
    &--desktop
      margin-top: 40px
      margin-bottom: 40px
    &--mobile
      margin-bottom: 16px
      +res-md-min
        display: none
  &__title
    +font(30, 45, 750)
    margin-bottom: 20px
    +res-sm-max
      margin-bottom: 16px
      text-align: center
  &__description
    +font(16, 24, 400)
    margin-bottom: 20px
    +res-sm-max
      margin-bottom: 16px
      text-align: center
    &--mobile
      +res-md-min
        display: none
    &--desktop
      +res-sm-max
        display: none
  &__cta-sfogliabile
    margin-bottom: 30px
    +res-sm-max
      align-self: center
      margin-bottom: 16px
  &__abbonati-top
    display: flex
    align-items: center
    width: fit-content
    background: #fff
    +border-radius(12px)
    padding: 10px
    padding-left: 20px
    margin-bottom: 30px
    +res-sm-max
      flex-direction: column
      width: 100%
    &__label
      +font(16, 24, 700)
      color: $tertiary
      +res-sm-max
        margin-bottom: 10px
      +res-md-min
        margin-right: 30px
    &__cta
  &__articoli, &__lezioni, &__tassonomie
    margin-bottom: 30px
    +res-sm-max
      margin-bottom: 16px
  &__tassonomie
    display: flex
    flex-direction: column
    align-items: center
    padding: 20px
    background: #fff
    +border-radius(12px)
    +res-sm-max
      margin-bottom: 50px
    &__title
      +font(24, 30, 800)
      margin-bottom: 24px
      text-align: center
    &__items
      display: flex
      flex-wrap: wrap
      justify-content: center
      gap: 8px 10px
      margin-bottom: 24px
  &__bottom
    width: 100%
    background: #fff
    padding: 50px 0
  &__archivio
    display: flex
    justify-content: center
    margin-bottom: 80px
    +res-sm-max
      padding-left: $wcont-padding
  &__abbonamenti
    width: 100%
    margin-left: auto
    margin-right: auto
    max-width: $wcont-width
    margin-bottom: 100px
    +res-sm-max
      margin-bottom: 80px
  &__abbonati
    +font(30, 36, 800)
    margin-bottom: 24px
    text-align: center
    padding: 0 $wcont-padding
    +res-sm-max
      margin-bottom: 20px
  &__box-abbonamenti
    display: flex
    flex-wrap: wrap
    justify-content: center
    +res-sm-max
      padding: 0 $wcont-padding
    &__item
      &:not(:last-child)
        +res-md-max
          margin-bottom: 16px
        +res-lg-min
          margin-right: 16px
