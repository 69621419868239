.filterbar
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 100
  // height: 11.7rem
  display: none
  flex-direction: column
  justify-content: flex-end
  +box-shadow(0px -2px 8px rgba(0, 0, 0, 0.15))
  +res-sm-max
    display: flex
  &--open
    position: static // accrocchio 
  &__top
    background: #fff
    padding: 1.5rem 0 0 1.7rem
    height: 8.9rem
    margin-bottom: -2.7rem
    .evidenza, .chip
      margin-right: 1.2rem
  &__bar
    background: $color-secondary
    padding: 1.5rem 1.2rem 1.6rem
    height: 5.5rem
    +border-top-left-radius($border-radius-small)
    &__cta
      display: flex
      font-size: 1.6rem
      line-height: 2.4rem
      font-weight: bold
      color: #fff
      .icon
        width: 2.4rem
        height: 2.4rem
        fill: #fff
        margin-right: .6rem
  &__filters
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 1.6rem
    // padding-bottom: 8.2rem
    padding-bottom: 0
    +translateY(100%)
    +transition(all .3s)
    background: #fff
    z-index: 99999
    +border-top-left-radius($border-radius-medium)
    +border-top-right-radius($border-radius-medium)
    +box-shadow(0px -2px 8px rgba(0, 0, 0, 0.15))
    &--open
      +translateY(0)
    &__head
      position: relative
      background: #fff
      // padding: 2rem 5rem 1.6rem 1.4rem
    &__title
      font-size: 1.8rem
      font-weight: $font-bold
      line-height: 3.2rem
      padding-top: 4rem
      margin-bottom: 1.6rem
    &__close
      position: absolute
      top: .4rem 
      right: .4rem
      width: 1.4rem
      height: 1.4rem
    &__list
      height: calc(100% - 8.8rem)
      overflow-y: auto
    &__cta
      position: fixed
      left: 50%
      transform: translateX(-50%)
      bottom: 1.6rem
  &--area-personale
    .filterbar
      &__filters
        &__list
          .ricerca__listing__filters__filter
            padding: 0 .1rem // accrocchio per mostrare il bordo della dropdown


@media print
  .filterbar
    display: none
