fieldset, input, textarea
  border: none
  outline: none
  -webkit-appearance: none

.w-form

  input[type=text], input[type=email], input[type=number], input[type=password],input[type=tel], textarea
    width: 100%
    border-bottom: 1px solid rgba(#000, .38)
    +border-top-left-radius(.4rem)
    +border-top-right-radius(.4rem)
    background: $color-grey-12
    font-family: $font-primary
    font-size: 1.6rem
    line-height: 2.4rem
    color: $color-text
    padding: 2.2rem 1.3rem 1rem 1.3rem
    &:hover, &:active, &:focus
      border-bottom-width: .2rem
      padding-bottom: .9rem
    &:active, &:focus
      border-bottom-color: $color-secondary
    &.error
      border-bottom-color: $color-error

  &__checkbox-wrapper, &__radio-wrapper
    position: relative
    padding-left: 3.4rem
    cursor: pointer
    font-weight: $font-semi-bold 
    margin-bottom: 2rem
    display: block
    font-size: 1.4rem
    font-weight: $font-regular
    line-height: 2rem
    input
      position: absolute
      left: -9999px
      margin-left: -20px
      &:disabled
        & + .w-form__styled-input
          background: $color-secondary

      & + .w-form__styled-input
        +transition(background .5s linear)
        position: absolute
        top: 1px
        left: 0
        display: block
        border: 2px solid $color-grey-11

  &__checkbox-wrapper
    .w-form__label
      cursor: pointer
    input
      & + .w-form__styled-input
        +border-radius(3px)
        width: 1.5rem
        height: 1.5rem
        .icon
          +transition(opacity .5s)
          opacity: 0
          position: absolute
          top: 0
          left: 0
          bottom: 0
          right: 0
          width: 100%
          height: 100%
          margin: auto
          +fill-icon(#fff)
          z-index: -1
      &:checked
        & + .w-form__styled-input
          border-color: $color-secondary
          background: $color-secondary
          .icon
            opacity: 1
  &__radio-wrapper
    .w-form__label
      font-size: 1.6rem
      vertical-align: sub 
      margin-right: .3rem
    input
      & + .w-form__styled-input
        +border-radius(50%)
        width: 2.4rem
        height: 2.4rem
        &::after
          content: ""
          position: absolute
          +transition(opacity .3s)
          +border-radius(50%)
          top: 0
          left: 0
          right: 0
          bottom: 0
          margin: auto
          width: 1.2rem
          height: 1.2rem
          background: $color-secondary
          opacity: 0
      &:checked
        & + .w-form__styled-input
          border: 2px solid $color-secondary
          &:after
            opacity: 1

  &__select
    width: 100%
    border: none
    border-bottom: 1px solid rgba(#000, .38)
    +border-top-left-radius(.4rem)
    +border-top-right-radius(.4rem)
    background: $color-grey-12
    font-family: $font-primary
    font-size: 1.6rem
    line-height: 2.4rem
    color: $color-text
    padding: 0 0 .1rem 0 !important
    &:hover, &:active, &:focus
      border-bottom-width: .2rem !important
      padding-bottom: 0 !important
    &.valid
      border-bottom: solid .2rem $color-secondary
    &.error
      .w-form__select__control
        border-bottom-color: $color-error
    &.searchable
      .w-form__select__control
        &--menu-is-open
          .w-form__select__value-container
            margin-top: 2.5rem !important
      .w-form__select__value-container
        margin-top: 1rem !important
    &__control
      background: none !important
      border: none !important
      +box-shadow(none !important)
      padding: .9rem 1.3rem .8rem 1.3rem
      cursor: pointer !important
      z-index: 2
      &--menu-is-open
        .w-form__select
          &__indicator
            .icon
              +rotate(180deg)
    &__indicator-separator
      display: none !important
    &__indicator
      .icon
        height: .7rem
        width: .7rem
    &__value-container
      padding: 0 !important
      margin-top: 1.4rem !important        
    &__menu
      background: #fff !important
      +border-radius(.4rem !important)
      border: none !important
      +box-shadow(0 .5rem .5rem rgba(#000, .2) !important)
      z-index: 3 !important
      &-list
        padding: 0 !important
    &__option       
      padding: 1rem 1.6rem 1.4rem 1.6rem !important
      &--is-selected
        background: none !important
        color: $color-text !important
      &--is-focused
        background: $color-grey-12 !important
        color: $color-text !important
      &:active
        background: none !important
    &__multi-value    
      max-height: 2.5rem
      &__label
        font-size: 80% !important
        padding: .2rem 0 .2rem .6rem !important

        
  &__field-wrapper
    position: relative
    margin: 0
    padding: 0
    min-height: 9rem
    margin-bottom: 1rem

    label
      position: absolute
      font-size: 1.6rem
      line-height: 1.6rem
      top: 2.2rem      
      +transition(all .5s)
      left: 1.3rem
      color: rgba(#000, .6)
      z-index: 1

    input, textarea, .w-form__select
      &:focus, &.error, &.valid, &.full, &.active, &:not([value=""])
        ~ label
          font-size: 1.2rem
          line-height: 1.2rem
          top: .8rem
      &:focus, &.active
        ~ label
          color: $color-secondary
      &:disabled
        ~ label
          color: rgba(#000, .3)
      &.error
        ~ label
          color: $color-error

    // Non sull'active se è una Select di react-select
    input, textarea
      &:active
        ~ label
          font-size: 1.2rem
          line-height: 1.2rem
          top: .8rem
          color: $color-secondary
  &__privacy-link
    color: $color-secondary
    &:hover
      text-decoration: underline
    


  &__info, &__error
    display: block
    padding: 0 1.3rem
    margin-top: .5rem
    font-family: $font-primary
    font-size: 1.2rem
    line-height: 1.6rem

  &__error
    color: $color-error
