.generic-page-tab
  .heading2
    color: $color-secondary-dark
  &__listing
    display: flex
    flex-wrap: wrap
    margin: 2rem 0
    margin-left: -5rem
    &__item
      margin-left: 5rem
      margin-bottom: 5rem
  &__eventi
    &__slider
      +res-sm-max
        display: none
    &__list
      .evento-card
        margin-bottom: 3rem
      +res-md-min
        display: none
  &__content
    padding-top: 20px
    +res-sm-max
      padding-top: 0
    +transition(all 300ms)
    &.speciali
      +res-sm-max
        display: none
  &.open
    .trigger
      .icon
        +rotate(-180deg)
    .generic-page-tab__content
      +res-sm-max
        display: block    
        padding-bottom: 1rem

