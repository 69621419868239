@import public/sass/abstracts

.tab-temi
  &__actions
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 24px
    > *
      &:not(:last-child)
        margin-right: 24px
  &__list
    display: flex
    flex-wrap: wrap
    +custom-row(16px)
    > *
      width: 50%
    +res-sm-min
      > *
        width: 33.33%
    +res-md-min
      > *
        width: 25%
    +res-sm-max
      +custom-row(8px)
  &__item
    position: relative
    margin-bottom: 24px