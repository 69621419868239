@import public/sass/abstracts

.header-cart
  position: relative
  margin-right: 26px
  cursor: pointer
  +res-sm-max
    margin-right: 20px
  svg
    +style-icon(24px, 24px, currentColor)
  &--mobile
    +res-lg-min
      display: none
  &__badge
    position: absolute
    top: -6px
    right: -6px
    background: $color-primary
    color: #FFF
    +typoBase(1rem, 1rem, $font-bold)
    text-align: center
    padding: 3px 0
    width: 16px
    border-radius: 50%