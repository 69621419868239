.autore-card
  display: block
  position: relative
  background: $color-bg
  width: 25rem
  height: 13.6rem
  padding: 3rem 2rem
  +border-radius(.6rem)
  &::after
    opacity: 0
    content: ""
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    +box-shadow(1.1rem 1.3rem 2.4rem #cccac6)
    +transition(opacity .3s)
    mix-blend-mode: multiply
  &:hover
    &::after
      opacity: 1
  &--sportello
    margin-top: -6rem
    margin-bottom: 2rem
    padding: 0
    padding-top: 3rem
    height: auto
    &:after
      display: none
    +res-sm-max
      margin-top: -4rem
  &__image
    max-width: 6.5rem
    +border-radius(1.5rem)
    &-wrapper
      width: 6.5rem
      height: 6.5rem
  &__default-image
    width: 6.5rem
    height: 6.5rem
    background: $color-grey-1 
    +fill-icon($color-grey-2)
    +border-radius(1.5rem)
  &__header
    margin-top: -5rem
    margin-bottom: 1rem
    display: flex
    align-items: baseline
    .icon
      &--community
        +fill-icon($color-primary)
        width: 30px
        height: 30px
        margin-left: 2rem
      &--logosymbol
        +fill-icon($color-primary)
        width: 30px
        height: 30px
        margin-left: 2rem
  &__fullname
    font-size: 2rem
    font-weight: $font-bold
    line-height: 2.4rem
  &__type
    font-family: $font-secondary
    font-size: 1.6rem
    line-height: 2.4rem

.u-bg-beige
  .autore-card
    background: #fff

