// Utilities

@mixin placeholder-style
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::placeholder
    @content

@mixin color-icon($value)
  fill: $value
  &.icon--strokes
    stroke: $value

@mixin size-icon($size)
  width: $size
  height: $size

@mixin style-icon($size, $color)
  display: block
  +size-icon($size)
  +color-icon($color)

@mixin scrollbar-style
  &::-webkit-scrollbar
    background: $pb-grey-20
    width: 6px

  &::-webkit-scrollbar-thumb
    background: $pb-grey-80
    border-radius: 3px
