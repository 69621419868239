
.code-editor
    width: 100%
    min-height: 100px
    font-size: 13px


// override CodeMirror css
.CodeMirror
    height: 100% !important
    &-scroll
        min-height: 100px
    