@import public/sass/abstracts

.contatti-template
  &__container
    display: flex
    +res-md-max
      flex-direction: column
      padding-bottom: 9rem
    &__left
      flex: 50%
    &__right
      flex: 50%
      text-align: center
  &__form-box
    background: #fff
    margin-bottom: 3rem
    padding: 3rem 0
    +res-md-max
      border-bottom: 1px solid $grey-2
    +res-lg-min
      padding: 4rem
      margin-top: 5rem
      margin-bottom: 8rem
      box-shadow: 11px 13px 24px 0px #00000033
      +border-radius(1rem)
    &__title
      +font(32, 40, 800)
      text-align: center
      margin-bottom: 1rem
      color: $color-secondary-dark
    &__description
      +font(16, 24, 400)
      font-family: $font-secondary
      text-align: center
      margin-bottom: 2rem
    &__submit
      text-align: center
    .form-checkbox
      margin-bottom: 2rem
  &__faq-mobile
    display: none
    flex-direction: column
    align-items: center
    text-align: center
    +res-md-max
      display: flex
      padding-top: 3rem
      padding-bottom: 1rem
      border-bottom: 1px solid $grey-2
    &__title
      +font(32, 40, 800)
      color: $color-secondary-dark
      margin-bottom: 1rem
    &__description
      +font(16, 24, 400)
      font-family: $font-secondary
      margin-bottom: 3rem
      max-width: 41rem
      margin-left: auto
      margin-right: auto
  &__faq
    position: relative
    padding-top: 9rem
    padding-bottom: 4rem
    color: #fff
    +res-md-max
      display: none
    &::before
      position: absolute
      content: ""
      background: $color-secondary-dark
      top: 0
      height: 100%
      right: calc(#{$wcont-width / 2} - 50vw - #{$wcont-padding})
      width: 100vw
      z-index: -1
      @media screen and (max-width: $wcont-width)
        right: #{$wcont-padding * -1}
    &__title
      +font(32, 40, 800)
      margin-bottom: 1rem
    &__description
      +font(16, 24, 400)
      font-family: $font-secondary
      margin-bottom: 2rem
      max-width: 41rem
      margin-left: auto
      margin-right: auto
    &__item
      +font(16, 24, 700)
      margin-bottom: 4rem
  &__contatti
    max-width: 41rem
    margin-left: auto
    margin-right: auto
    &__title
      +font(32, 40, 800)
      margin-bottom: 2rem
      color: $color-secondary-dark
      +res-lg-min
        margin-top: 9rem
    &__box
      padding: 2rem 1rem
      background: $color-bg
      margin-bottom: 2rem
      +border-radius(1rem)
      &__title
        +font(18, 24, 800)
        margin-bottom: 1rem
      &__content
        +font(16, 24, 400)
        font-family: $font-secondary
        margin-bottom: 1rem
        &__mailto
          text-decoration: underline
          color: $color-secondary
        span
          color: $color-primary
          font-weight: bold