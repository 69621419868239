.chip
    display: flex
    align-items: center
    background: $color-primary
    color: #fff
    padding: .6rem .8rem
    width: fit-content
    height: 3.2rem
    font-size: 1.4rem
    line-height: 1.6rem
    font-weight: 700
    cursor: pointer
    +border-radius(.4rem)
    &__close
      right: .4rem
      width: 1rem
      height: 1rem
      fill: #fff
      margin-left: .4rem