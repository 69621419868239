@import public/sass/abstracts

.sticky-actions
    position: sticky
    top: 20px
    align-self: flex-start // for flex container
    margin-left: auto // push to right in flex container
    width: 40px
    +res-md-max
        display: none
    > div
        &:not(:last-child)
            margin-bottom: 10px
    .sticky-actions
        &__cta
            +border-radius(6px)
    .wbutton--only-icon
        padding: 8px

@media print
  .sticky-actions
    display: none