@import public/sass/abstracts

.actions
    display: flex
    justify-content: flex-end
    padding: 30px 0
    margin-bottom: 30px
    & > *
        &:not(:last-child)
            margin-right: 10px
    .wbutton--only-icon
        padding: 8px

@media print
  .actions
    display: none