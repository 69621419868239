.autori-page
    padding-bottom: 20rem
    &__title
        font-size: 7.2rem
        line-height: 8rem
        margin: 3rem 0 1rem 0
        +res-sm-max
            font-size: 4.4rem
            line-height: 4.8rem
    .listing-autori
        &__tassonomia
            +font(24, 30, 800)
            margin-bottom: 5rem
            +res-sm-max
                +font(20, 26, 800)
                margin-bottom: 2rem
        &__listing
            display: flex
            flex-wrap: wrap
            margin-right: -8rem
            +res-xs-max
                justify-content: center
                margin-right: 0
            .autore-card
                margin-bottom: 6rem
                margin-right: 8rem
                +res-xs-max
                    margin-right: 0
        &__top
            display: flex
            align-items: center
            margin-bottom: 4rem
            justify-content: space-between
            +res-sm-max
                align-items: revert
                flex-direction: column
                margin-bottom: 6rem
        &__help
            font-size: 1.4rem
            line-height: 2rem
            font-weight: 700
            margin-bottom: 1.4rem
        &__stats
            font-size: 2.4rem
            line-height: 3rem
            font-weight: 800
            margin-bottom: 4rem
            +res-sm-max
                display: none
        .pagination
            text-align: left
            padding-top: 0
            &--mobile
                margin-bottom: 2rem
                +res-md-min
                    display: none
            &--desktop
                padding-bottom: 1.5rem
                +res-sm-max
                    display: none
            &__item
                +res-md-min
                    margin-bottom: .5rem
            &__nav
                &--disabled
                    color: #818181
                    background: $color-grey-12