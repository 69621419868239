@import public/sass/abstracts

.rivista-detail-template
  display: flex
  flex-direction: column
  align-items: center
  &__inserto
    margin: auto
    margin-bottom: 50px
    text-align: center
    width: 100%
    display: flex
    justify-content: center
    &--medium
      width: 70%
      +res-sm-max
        width: 100%
  &__image-container
    margin-bottom: 10px
  &__column-display
    display: flex
    flex-direction: column
    align-items: center
  &__breadcrumbs
    width: 100%
  &__title
    position: absolute
    color: transparent
  &__subtitle
    +font(20, 26, 800)
    margin-bottom: 10px
    text-align: center
    +res-sm-max
      margin-bottom: 30px
  &__description
    +font(16, 24, 500)
    margin-bottom: 30px
    text-align: center
    max-width: 748px
  &__anchors
    margin-bottom: 50px
    +res-sm-max
      display: none
  &__anchor
    padding: 0 30px!important
    border-radius: 0!important
    &:not(:last-child)
      border-right: 1px solid $color-secondary
  &__tema
    display: flex
    justify-content: center
    +border-radius(16px)
    background: $grey-0
    width: 100%
    max-width: $wcont-sportelli-width
    margin: 0 auto 54px auto
    padding: 35px 20px
    +res-sm-max
      flex-direction: column
      align-items: center
      margin-bottom: 30px
      padding: 20px
    &__image
      +border-radius(16px)
    &__content
      flex: 1
      margin-left: 30px
      +res-sm-max
        text-align: center
        margin: 0
    &__label
      +font(16, 24, 400)
      margin-bottom: 8px
      text-transform: uppercase
      +res-sm-max
        margin-top: 22px
    &__title
      +font(30, 40, 800)
      margin-bottom: 22px
    &__text
      +font(16, 24, 400)
      margin-bottom: 22px
  &__risorse
    display: flex
    justify-content: center
    width: 100%
    margin-bottom: 50px
    padding: 0 $wcont-padding
    +res-sm-max
      margin-bottom: 30px
  &__archivio
    display: flex
    justify-content: center
    width: 100%
    background: $grey-0
    padding: 30px 0
    margin-bottom: 50px
    +res-sm-max
      background: #fff
      margin-bottom: 60px
      padding-left: $wcont-padding
  &__abbonamenti
    width: 100%
    max-width: $wcont-width
    margin-bottom: 100px
    +res-sm-max
      margin-bottom: 80px
  &__abbonati
    +font(30, 36, 800)
    margin-bottom: 24px
    text-align: center
    padding: 0 $wcont-padding
    +res-sm-max
      margin-bottom: 20px
  &__box-abbonamenti
    display: flex
    flex-wrap: wrap
    justify-content: center
    +res-sm-max
      padding: 0 $wcont-padding
    &__item
      &:not(:last-child)
        +res-md-max
          margin-bottom: 16px
        +res-lg-min
          margin-right: 16px
