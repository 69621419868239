@import public/sass/abstracts

.area-personale-edit-card
  padding: 30px
  border-radius: 12px
  background-color: $white
  +res-sm-max
    padding: 20px
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 24px
  &__title
    +typoBase(2rem, 2.4rem, $font-primary-black)
  &__description
    +typoBase(1.4rem, 2rem, $font-primary-medium-2)
    margin-bottom: 24px
    p
      margin-bottom: 20px
    a
      color: $accent-blue
      text-decoration: underline
  &__actions
    margin-top: 20px
    display: flex
    align-items: center
    +res-md-min
      flex-direction: column
      > *
        &:not(:last-child)
          margin-bottom: 20px
    +res-sm-max
      justify-content: space-between 
      > *
        &:not(:last-child)
          order: 2