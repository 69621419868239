.custom-toolbox
    &__tools
        &__item
            display: inline-flex
            align-items: center
            width: 160px
            cursor: grab
            +typoTextSm
            font-weight: 600
            background: $pb-grey-20
            color: $pb-grey-100
            padding: 6px 6px
            margin-right: auto
            &:not(:last-child)
                margin-bottom: 4px
            > *
                &:not(:last-child)
                    margin-right: 6px
            span
                flex: 1
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
            svg
                +style-icon(20px, currentColor)

            


    &__custom-blocks
        padding: 10px 16px
        &__item
            display: flex
            align-items: center
            > *
                &:not(:first-child)
                    margin-left: 8px
