@import public/sass/abstracts

.inserto-box
  position: relative
  background: $color-paris-daisy
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)
  border-radius: 8px
  width: 100%
  height: 100%

  &--icon-version
    background: $color-primary-light
    padding: 16px
  &--button-version
    max-width: 1064px
    @include res-sm-max
      width: 100%
  &--pdp-version,
  &--pdp-vita-scolastica
    background: $color-primary-light
    height: auto
    padding: 20px
    @include res-sm-max
      padding: 10px

  &--pdp-vita-scolastica
    background: $color-paris-daisy
    color: $tertiary

  &-container
    display: flex
    justify-content: center
    place-items: center
    flex-direction: column

    @include res-sm-max
      flex-direction: row-reverse
      justify-content: flex-end
      padding: 2rem

    &--landing-rivista
      flex-direction: row-reverse
      text-align: left
      padding: 2rem
      justify-content: space-evenly

      @include res-sm-max
        place-items: center
        flex-direction: column-reverse

    &--icon-version
      padding: 0.5rem
      justify-content: left
      @include res-sm-max
        flex-direction: row-reverse
        place-items: flex-start

    &--pdp-vita-scolastica
      flex-flow: row-reverse
      @include res-sm-max
        padding: 0
        justify-content: space-around

    &-content
      flex: 1

    &-title
      margin: 4px 12px 12px
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: $color-text

      &--landing-rivista
        font-style: normal
        font-weight: 700
        font-size: 16px
        line-height: 24px
        color: $color-secondary-dark
        @include res-sm-max
          margin-top: 15px

      &--icon-version
        font-style: normal
        font-weight: 700
        font-size: 16px
        line-height: 24px
        color: $color-text
        margin: 0px 12px 0px

      &--button-version
        color: $color-text
        font-size: 30px
        font-weight: 800
        line-height: 40px
        margin-right: 40px

        @include res-sm-max
          display: none

      &--button-version-mobile
        display: none

        @include res-sm-max
          display: block
          color: $color-text
          font-size: 24px
          font-weight: 800
          line-height: 30px
          margin-left: 20px

      &--pdp-version
        color: $color-text
        font-size: 18px
        font-weight: 700
        line-height: 24px
      &--pdp-vita-scolastica
        color: $tertiary
        font-size: 16px
        font-weight: 700
        line-height: 24px
        @include res-sm-max
          margin-top: 8px

    &-text
      margin: 12px
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: $color-text

      &--landing-rivista
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 20px
        color: $color-secondary-dark

      &--icon-version
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 20px
        color: $color-text
        margin: 5px 12px 0px
        a, a:visited, a:hover, a:active
          font-weight: 700
          text-decoration: underline
          cursor: pointer

      &--button-version
        color: $color-text
        font-size: 16px
        line-height: 24px
        margin-right: 40px
        @include res-sm-max
          text-align: center
          margin-right: 0
      &--pdp-version
        color: $color-text
        font-size: 16px
        line-height: 24px
    &-image-pdp-vita-scolastica
      max-width: 100px
    &-icon
      flex-shrink: 0
      width: 20px
      height: 20px

    &-image-container
      @include res-sm-max
        display: flex

      &--pdp-vita-scolastica
        @include res-sm-max
          display: flex
          flex-flow: column
