@import public/sass/abstracts

.wobserve-box
  margin: 3rem 0
  &__head
    position: relative
    margin-bottom: 2rem
    padding-left: 3.4rem
  &__icon
    position: absolute
    top: .4rem
    left: 0
    svg
      +style-icon(24px, 24px, $tertiary)
  &__title
    @extend .ext-heading3-secondary
    margin-bottom: 0
  &__text
    @extend .ext-custom-text