.block-image  
  +print
    page-break-inside: avoid
  &--left
    text-align: left
    img
      margin-left: 0px
      margin-right: auto
  &--center
    text-align: center
    img
      margin-left: auto
      margin-right: auto
  &--right
    text-align: right
    img
      margin-left: auto
      margin-right: 0px
  img
    max-width: 100%
    margin-top: 30px
    margin-bottom: 30px
    display: block