.box-tag-cloud
    margin-bottom: 6.2rem
    +border-radius(1.2rem)
    +res-sm-max
        margin-bottom: 7.2rem
        +border-radius(0)
    &--white
        background: #fff
    &--beige
        background: $color-bg
        padding-top: 9rem
        padding-bottom: 9rem
        +res-sm-max
            padding-top: 5rem
            padding-bottom: 5rem
    &__content
        display: flex
        flex-direction: column
        align-items: center
        max-width: $res-md-min
        margin-left: auto
        margin-right: auto
        text-align: center
    &__title
        +font(30, 40, 800)
        margin-bottom: 1rem
        +res-sm-max
            +font(24, 30, 800)
    &__description
        +font(16, 24, 400)
        margin-bottom: 2rem
        font-family: $font-secondary
    &__cloud
        display: flex
        flex-wrap: wrap
        justify-content: center
        max-width: 60rem
        &__item
            +font(16, 24, 800)
            padding: .6rem 1.6rem
            background: $color-primary
            color: #fff
            margin-bottom: .8rem
            margin-right: .8rem
            +border-radius(.8rem)