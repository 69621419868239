@import public/sass/abstracts

.form-layout
  display: flex
  flex-wrap: wrap
  +custom-row(20px)
  > .form-field
    &--space-md
      margin-bottom: 24px
    &--space-none
      margin-bottom: 0

  +res-md-min
    > .form-field
      &--layout-md
        width: 33.33%
      &--layout-half
        width: 50%
      &--layout-lg
        width: 66.66%
      &--layout-full
        width: 100%

  +res-sm-max
    > .form-field
      &--layout-mobile-md
        width: 50%
      &--layout-mobile-full
        width: 100%