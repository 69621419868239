@import public/sass/abstracts

.widget-toggle
  padding: 20px
  border-radius: 20px
  background: $grey-0
  display: flex
  align-items: center
  min-height: 88px
  &:not(:last-child)
    margin-bottom: 10px
  &__content
    flex: 1
    margin-right: 20px
  &__title
    +typoBase(1.8rem, 2.4rem, $font-primary-black)
  &__description
    +typoTextMd
    margin-top: 4px
  &__toggle
    margin-left: auto