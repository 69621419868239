.temi-home2021
  padding: 70px 0 80px
  +res-lg-min
    display: flex
    justify-content: space-between

  &__content
    +res-lg-min
      width: calc(100% - 620px)
    +res-md-max
      margin-bottom: 30px
      

  &__intro
    font-size: 1.6rem
    line-height: 2.4rem
    font-weight: 400
    text-transform: uppercase
    margin-bottom: 20px

  &__title
    @extend .ext-heading2
    margin-bottom: 16px
    +res-sm-max
      line-height: 4.8rem
      margin-bottom: 19px

  &__description
    font-family: $font-secondary
    font-size: 1.6rem
    line-height: 2.4rem

  &__cta-box
    margin-top: 38px
    +res-sm-max
      margin-top: 30px

  &__slider
    position: relative
    +res-lg-min
      width: 600px
    +res-sm-max
      max-width: 280px
      margin: 0 auto

  &__slider-nav
    position: absolute
    top: calc(50% - 12px)
    left: 0
    right: 0
    display: flex
    justify-content: space-between
    z-index: 1

    +res-md-min
      display: none
  
  &__slider-nav-item
    cursor: pointer
    svg
      +style-icon(24px, 24px, #000)
      

  &__item
    padding-top: 10px
    > *
      margin: 0 auto