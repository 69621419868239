@import public/sass/abstracts

.dispositivi-template
  &__header
    margin-top: 4.2rem
    margin-bottom: 6.3rem        
    &__titolo
      margin-bottom: 2.7rem
      color: $color-secondary-dark  
      font-size: 4rem
      font-family: $font-primary
      line-height: 4.8rem
    &__descrizione
      margin-bottom: 6.3rem
      font-size: 2rem
      font-family: $font-secondary
      line-height: 3.2rem
  &__devices
    border-bottom: 1px solid #BBBBBB
    margin-top: 3rem
    margin-bottom: 3rem
  &__dissocia-box
    text-align: left
    padding: 2rem 0
  &__empty
    +typoBase(1.6rem, 2.4rem)
    padding: 24px 0