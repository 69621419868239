.ioplus
  background: $color-primary
  padding: 1rem 0 0 .8rem
  +border-top-left-radius(13px)
  +border-bottom-right-radius(16px)
  width: 4.5rem
  height: 4.5rem
  .icon
    width: 3rem
    height: 2.4rem
    fill: #fff!important

.ioplusbar
  display: flex
  align-items: center
  margin-bottom: 10px
  color: $color-primary
  &--white
    background: #fff
    width: fit-content
    padding-right: 12px
    +border-top-left-radius(13px)
    +border-bottom-right-radius(16px)
  &__text
    +font(16, 24, 700)
    @media (max-width: 385px)
      font-size: 14px
  .ioplus
    margin-right: 10px