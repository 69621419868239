@import public/sass/abstracts

.area-personale-ordini-detail-template
  background: $grey-0
  &__layout
    +res-md-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 20px
  &__main
    flex: 1
    > *
      &:not(:last-child)
        margin-bottom: 20px
  &__sidebar
    +res-md-min
      min-width: 300px
      flex-basis: 42%
    +res-sm-max
      margin-top: 20px