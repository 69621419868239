.listing-page
  background: $color-bg
  &__header
    text-align: center
    // padding-bottom: 5rem
  &__image
    display: block
    margin: -2rem auto .5rem auto
    +res-sm-max
      margin: 0 auto 2rem auto
  &__title
    @extend .ext-heading1
    color: white
    margin-top: 2rem
    margin-bottom: -1rem
    &--black
      color: $color-text
  &__abstract
    @extend .ext-abstract-medium
  &__list-section
    background: #fff
    +border-top-left-radius($border-radius-big)
    +border-top-right-radius($border-radius-big)
    // padding: 4.2rem 0 11rem 0
    +res-sm-max
      +border-top-left-radius($border-radius-medium)
      +border-top-right-radius($border-radius-medium)
      padding-bottom: 5rem
    .listing_riviste_element
      &__image
      &__main_content        
        &__titolo
          margin-bottom: 1.7rem
        &__descrizione
          margin-bottom: 1.6rem
        &__link
          color: $color-secondary

.rivista-list-head
  margin-bottom: 5rem
  max-width: 79rem
  margin-left: auto
  margin-right: auto
  &__image
    margin-left: auto
    margin-right: auto
    display: block
    +box-shadow(0 .6rem 1.4rem rgba(#000, .18))
    +res-xs-max
      max-width: 20rem
      margin-bottom: 3rem
      margin-top: -7rem
    +res-sm-min
      position: absolute
      bottom: 0
      left: 2rem
      right: 2rem
      max-width: calc(100% - 4rem)
      max-height: calc(100% + 7rem)
  &__title
    font-size: 3.2rem
    font-weight: $font-bold
    line-height: 4rem
    margin-bottom: 1rem
  &__description    
    font-family: $font-secondary
    font-size: 2rem
    font-weight: $font-regular
    line-height: 3.2rem
    margin-bottom: 1.5rem
  &__link


