.lezione-round
  position: relative
  display: flex
  align-items: center
  max-height: 5.6rem
  margin-top: 1rem
  padding: 2rem 2.5rem 1.8rem 2.5rem
  color: $color-teal-lezione
  background: #fff
  +border-radius(10rem)
  +res-sm-max
    display: block
    max-height: none
    +border-radius(2rem)
  &__tipologia
    @extend .ext-heading3
    margin-right: 2rem
  &__classe
    position: absolute
    top: 1.5rem
    right: 1.5rem
    @extend .ext-heading3
    font-weight: $font-regular
    margin-left: auto
  .tags
    &__item
      font-size: 1.8rem