@import public/sass/landing-riviste/abstracts

.landing-riviste-box
  color: #000
  padding: 30px
  border-radius: 50px
  font-family: $font-primary
  +res-sm-max
    padding: 25px
  &--orange-dark
    background: $landing-riviste-orange-dark
    color: #fff
  &--white
    background: #fff
  &--primary
    background: $color-primary
  &--yellow-default
    background: $landing-riviste-yellow-default
  &--blue-dark
    background: $landing-riviste-blue-dark
    color: #FFF
  &--blue-default
    background: $landing-riviste-blue-default
  &--green-default
    background: $landing-riviste-green-default

  &--image
    overflow: hidden
    padding: 0
    height: 342px
    +res-sm-max
      height: 250px
    > img
      display: block
      margin: 0 auto
      object-fit: cover
      width: 100%
      height: 100%

  &__title
    +typoBase(28px, 36px, 900)
    letter-spacing: -0.01em
    margin-bottom: 20px

  &__description
    +typoBase(20px, 28px, 500)
    p
      &:not(:last-child)
        margin-bottom: 10px
    a
      text-decoration: underline