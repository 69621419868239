@import public/sass/abstracts

.card-parascolastico
  display: flex
  padding: 20px
  background: $grey-0
  min-width: 310px
  max-width: 600px
  +border-radius(12px)
  +res-md-max
    flex-direction: column
    align-items: center
    background: #fff
    padding: 30px 16px
  &--white
    background: #fff  
  &__image-box
    max-width: 120px
    +res-lg-min
      margin-right: 30px
    +res-md-max
      margin: 0 auto 30px
  &__content
    flex: 1
    +res-lg-min
      > *
        &:not(:last-child)
          margin-bottom: 16px
    +res-md-max
      margin-top: 30px
      text-align: center
  &__title
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
    margin-bottom: 16px
    +res-md-max
      +typoTextXl
      font-weight: $font-primary-heavy
      margin-bottom: 10px
  &__description
    +font(16, 24, 400)
    margin-bottom: 16px
    +res-sm-max
      margin-bottom: 10px
  &__price
    display: flex
    align-items: center
    +res-md-max
      justify-content: center
      margin-bottom: 20px
    > *
      &:not(:last-child)
        margin-right: 8px
  &__fullprice
    +typoTextLg
    text-decoration: line-through
  &__finalprice
    +typoBase(2rem, 2.4rem, $font-primary-heavy)
    color: $tertiary
  &__discount
    +typoTextMd
    font-weight: $font-primary-heavy
    padding: 2px 8px
    border-radius: 8px
    background: $tertiary
    color: $white
  &__acquista
    +res-xs-max
      width: 100%
  &__cta-box
    &--first
      +res-lg-min
        display: flex
        align-items: center
        justify-content: space-between
      +res-md-max
        margin-bottom: 20px
    &--second
      display: flex
      align-items: center
      justify-content: space-between
      +res-lg-min
        padding-right: 20px
      +res-md-max
        padding-right: 10px
  &__dettagli
    +res-lg-min
      margin-left: auto