@import public/sass/abstracts

.popup-conferma-scuola
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: white
  padding: 50px 50px
  border-radius: 6px
  margin: 15px 0px
  h1
    text-align: center
    line-height: 35px
  &__spacer
    height: 150px
    border: 1px solid #818181
    @media (max-width: 768px)
      height: 0px
      width: 150px
  &__container-scuola
    margin: 30px 0px
    width: fit-content
    padding: 12px 24px
    border-radius: 6px
    background-color: #F5F5F5
  &__container-cta
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%
    margin-bottom: 30px
    @media (max-width: 768px)
      flex-direction: column
    &__wrapper
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      text-align: center
      margin: 0px 30px
      p
        text-align: center
        margin-bottom: 12px
      @media (max-width: 768px)
        margin: 20px 0px