.category-box
  padding: 5rem 8rem
  margin-top: 3rem
  margin-bottom: 3rem
  @extend .ext-custom-text
  background: $color-teal-light
  color: $color-teal-dark
  +border-radius(.6rem)
  +res-sm-max
    padding: 6rem 3.5rem
    margin-left: -1.5rem
    margin-right:  -1.5rem