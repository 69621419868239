.registrazione-page
  &__header
    margin-bottom: 3.5rem
  &__title
    @extend .ext-boxtitle
    margin-top: 4.3rem
    margin-bottom: 3rem
    text-align: center
    span
      display: block
  &__description
    @extend .ext-p
    text-align: center
  &__steps
    margin-bottom: 5rem
  &__form
    max-width: 76.7rem
    margin-left: auto
    margin-right: auto
    margin-bottom: 4.3rem
    fieldset
      margin-bottom: 2rem
      min-height: 10rem
    &__scuola
      @extend .ext-heading3
      margin-top: 3.7rem
      margin-bottom: 7rem
      text-align: center
  &__submit-box
    text-align: center
    margin-top: 3.5rem
  &__submit-info
    margin-top: 0
    margin-bottom: 1.2rem
  &__submit-button
    min-width: 19rem
    &--final
      margin-bottom: 6rem
  &__other
    text-align: center
    margin-bottom: 10.8rem
    &-label
      @extend .ext-p
      margin-bottom: 4.6rem
      display: block
    &-buttons
      display: flex
      justify-content: center
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 1.8rem
  &__scuole-add
    margin-top: 6.6rem
    margin-bottom: 6.6rem
  &__scuole-text
    @extend .ext-p
    text-align: center
    margin-bottom: 1.2rem
  &__scuole-button
    display: block
    margin-left: auto
    margin-right: auto
    font-family: $font-primary
    .icon
      width: 2rem
      height: 2rem
      vertical-align: middle
      +fill-icon($color-secondary)
      margin-right: .4rem
  &__privacy-text
    font-size: 1.6rem
    font-weight: $font-bold
    line-height: 1.9rem
    margin-bottom: 2.2rem
    margin-top: 2.4rem
  &__vantaggi
    padding-top: 8.5rem
    padding-bottom: 7.7rem
  &__vantaggi-cards
    margin-bottom: 10.4rem
  &__loghi
    text-align: center
    &__title
      @extend .ext-boxtitle
      margin-bottom: 3rem
      span
        display: block
    &__description
      margin-bottom: 4.3rem
    &__list
      margin-bottom: 4.5rem
  &__cta
    min-width: 19rem
  &--recupera
    header
      margin-top: 7rem
    section
      margin-bottom: 13rem
      .registrazione-page__submit-box
        margin-top: 0
  

    

