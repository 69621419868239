.switch
  position: relative
  &__checkbox 
    height: 0
    width: 0
    margin-left: auto
    visibility: hidden
    &:checked + .switch__label .switch__button
      right: 101%
      transform: translateX(100%)
  &__label 
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    width: 9.5rem
    height: 3.5rem
    background: white
    +border-radius(10rem)
    border: 2px solid $color-secondary
    position: relative
    transition: background .2s
    padding: .4rem 1.3rem
    &:active
      .switch__button
          width: 5rem
    .switch__image
      width: 2rem
    .switch__button 
      content: ''
      position: absolute
      right: 0
      width: 4.75rem
      height: 3.5rem
      +border-radius(4.5rem)
      transition: 0.2s
      background: $color-secondary
      padding: .4rem 1.3rem
    .icon
      width: 2rem
      height: 2rem
      vertical-align: middle
      +fill-icon(#fff)

