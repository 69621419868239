.dropdown
  min-width: 17.4rem
  position: relative
  div[class$="control"]
    border: 2px solid $color-grey-7 
    +border-radius(10rem)
    min-height: 4rem!important
  & * 
    cursor: pointer
    font-size: 1.6rem
    line-height: 1.9rem
    text-align: left
  &--is-disabled
    opacity: .54
    .dropdown
      &__control
        background: rgba(222,222,222,0.36)
        border-color: $color-grey-14
      &__placeholder, &__single-value
        color: $color-grey-13!important
      &__indicators
        .icon
          +fill-icon($color-grey-13)
  &--order
    margin-left: auto
  .icon
    height: .7rem
    width: .7rem
  &__indicators
    .icon
      +fill-icon($color-grey-3)
  &__indicator, &__dropdown-indicator
    padding: 1.2rem!important
  &__indicator-separator
    display: none!important
  &__menu
    z-index: 99999!important
  &__option
    &:hover
      background: $color-bg
  &__value-container
    padding: 0 1.5rem!important
    margin-top: 1px
  &__single-value, &__placeholder
    color: $color-grey-10!important
    left: 1.3rem
    right: 0
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  // &__trigger
  //   background-color: $color-grey-6
  //   border: 1px solid $color-grey-7
  //   +border-radius(.6rem)
  //   padding: 1rem 4rem 1rem 1.5rem
  //   position: relative
  //   font-size: 1.6rem
  //   line-height: 1.9rem
  //   min-width: 17.4rem
  //   text-align: left
  //   cursor: pointer
  //   &::after
  //     content: url(/images/svg/dropdown-arrow.svg)
  //     position: absolute
  //     top: calc(50% - 1.25rem)
  //     right: 2rem
  // &__list
  //   display: none
  //   position: absolute
  //   top: calc(100% - .5rem)
  //   left: 0
  //   right: 0
  //   background: $color-grey-6
  //   z-index: 10
  //   padding: .5rem 0
  //   border: 1px solid $color-grey-7
  //   border-top-color: transparent
  //   +border-bottom-left-radius(.6rem)
  //   +border-bottom-right-radius(.6rem)
  // &__list-item
  //   cursor: pointer
  //   padding: .3rem 1.5rem
  //   font-size: 1.6rem
  //   line-height: 1.9rem
  //   &:hover
  //     background: #fff
