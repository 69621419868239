@import public/sass/abstracts

.area-personale-on-boarding-modal
  max-width: 807px
  padding-bottom: 50px
  &__slider
    max-width: 670px
    margin: 0 auto
    .slick-dots
      bottom: -62px
      font-size: 12px
      line-height: 12px
      +res-sm-max
        bottom: -43px
      li 
        width: 12px 
        height: 12px
        margin: 0 6px
        button
          background: #BBBBBB
          border-radius: 50%
          width: 12px 
          height: 12px
          &::before
            display: none
        &.slick-active
          button
            background: $accent-blue
  &__slide
    text-align: center
    &__image-box
      margin: 0 auto 40px
      position: relative
      max-width: 320px
      +res-sm-max
        max-width: 190px
    &__image
      margin: 0 auto
    &__title
      max-width: 500px
      +typoH3
      margin: 0 auto 20px
      +res-sm-max
        +typoH4
    &__description
      +typoBase(2rem, 2.8rem, $font-secondary-regular, $font-secondary)
      +res-sm-max
        +typoBase(1.6rem, 2.4rem)
  &__nav
    margin-top: 32px
    display: flex
    align-items: center
    justify-content: space-between
    +res-sm-max
      margin-top: 74px
  &__nav-item
    &--disabled
      visibility: hidden