.strumento-card
  font-family: $font-primary
  position: relative
  width: 21.5rem
  height: 33.8rem
  +border-radius(1.6rem)
  display: flex
  flex-direction: column
  z-index: 1
  +transition(300ms)
  &:hover
    +translateY(-1rem)
  &--disabled
    .strumento-card
      &__image-box,
      &__content
        opacity: .4
  .ioplus
    position: absolute
    top: 0
    left: 0
    z-index: 3
  .scarica
    position: absolute
    top: 0
    left: 0
    z-index: 3
  .salva
    position: absolute
    left: 0
    z-index: 2
    &:not(:first-child)
      height: 6.1rem
      padding-top: 2.7rem
      top: 2.9rem
      +transition(all .5s)
  &__image-box
    max-height: 100%
    height: 21.5rem
    width: 21.5rem
    background-repeat: no-repeat
    background-size: cover
    background-position: top
    +border-radius(1.6rem)
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 12.6rem
    padding: 1.6rem 1.2rem 1.8rem 1.2rem
  &__title
    @extend .overflow-ellipsis
    -webkit-line-clamp: 2
    font-size: 1.6rem
    line-height: 2.4rem
    font-weight: $font-bold
    margin-bottom: 1.2rem
    word-break: break-word
  &__category
    font-size: 1.4rem
    line-height: 2.4rem
    font-weight: $font-bold
    text-transform: uppercase
    .icon
      display: inline-block
      width: 2.4rem
      height: 2.4rem
      margin-right: 1rem
      vertical-align: middle
      +fill-icon($color-text)
    span
      vertical-align: bottom
  &--horizontal
    flex-direction: row
    max-width: 100%
    width: 100%
    height: 21.6rem
    .strumento-card
      &__image-box
        height: 21.6rem
        min-width: 16.4rem
      &__content
        height: 100%
      &__title
        font-size: 2rem
        line-height: 2.6rem
        -webkit-line-clamp: 3
  +res-sm-max
    &:not(.strumento-card--small)
      flex-direction: row
      max-width: 100%
      width: 100%
      height: 21.6rem
      .strumento-card
        &__title, &__abstract
          -webkit-line-clamp: 3
        &__image-box
          height: 21.6rem
          min-width: 16.4rem
        &__content
          height: 100%
      
  // Per sovrascrivere i link standard del custom-content
  a 
    &.strumento-card
      &__title
        color: #fff
        text-decoration: none

  // SIZE
  &--small
    width: 19.1rem
    height: 26.8rem
    width: 19.1rem
    height: 26.8rem
    .strumento-card
      &__image-box
        width: 19.1rem
        height: 14.2rem
  
  

  //TIPI STRUMENTO
  &--oggetti
    background: $color-oggetto
    color: #fff
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-oggetti.jpg')
      &__category
        .icon
          +fill-icon(#fff)
  &--risorsa
    background: $color-interattivo
    color: #fff
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-interattivo.jpg')
      &__category
        .icon
          +fill-icon(#fff)
  &--audio
    background: $color-audio
    color: #fff
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-audio.jpg')
      &__category
        .icon
          +fill-icon(#fff)
  &--audiolibro
    background: $color-audiolibro
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-audiolibro.jpg')
      &__category
        .icon
          width: 3.4rem
  &--ebook
    background: $color-ebook
    color: #fff
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-ebook.jpg')
      &__category
        .icon
          +fill-icon(#fff)
  &--evento
    background: $color-evento
    color: $color-text
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-evento.jpg')
    .icon
      +fill-icon($color-text)
    a.strumento-card__title
      color: $color-text
  &--immagine
    background: $color-immagine
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-immagine.jpg')
  &--libro
    background: $color-libro
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-libro.jpg')
  &--scheda
    background: $color-scheda
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-scheda.jpg')
    color: $color-text
    .icon
      +fill-icon($color-text)
    a.strumento-card__title
      color: $color-text
  &--software
    background: $color-software
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-software.jpg')
  &--testo
    background: $color-testo
    .strumento-card
      &__image-box
        background-image: url('/images/demo/bg-testo.jpg')
    color: $color-text
    .icon
      +fill-icon($color-text)
    a.strumento-card__title
      color: $color-text
  &--video
    background: $color-video
    color: #fff
    .strumento-card
      &__image-box
        background-color: $color-video
        background-image: url('/images/demo/bg-video.jpg')
        background-position: center
      &__category
        .icon
          +fill-icon(#fff)
    &.strumento-card--thumbed
      .strumento-card
        &__image-box
          background-color: $color-grey-12

  &--small
    .strumento-card
      &__category
        +font(12, 20, 700)


  &--horizontal-noimage
    width: auto
    height: 12.7rem
    display: block
    text-align: left
    .strumento-card
      &__image-box
        display: none
      &__content
        display: flex
        flex-direction: column
        padding: .9rem 1rem 1rem 6rem
        height: 100%
      &__author
        color: inherit
        font-size: 1.4rem
        line-height: 1.6rem
        text-align: right
        margin-top: auto


@media print 
  .strumento-card 
    color-adjust: exact
    -webkit-print-color-adjust: exact