@import public/sass/abstracts

.garanzia-modal
  max-width: 807px
  padding: 40px 80px
  +res-md-max
    padding: 40px 20px
  &__container
    // padding: 20px 65px
    .wmodal__head
      display: flex
      margin-bottom: 20px
      align-items: center
    .wmodal__icon
      margin-bottom: 0
      margin-right: 20px
      svg
        height: 40px
        width: 40px

    &__infos
      background: $grey-0
      padding: 20px
      border-radius: 8px
      display: flex
      flex-flow: column

      &__titolo
        font-weight: 800
        margin-bottom: 10px
        font-size: 14px

      &__prezzo
        font-weight: 800
        margin-bottom: 10px

      &__descrizione
        margin-bottom: 20px
        font-size: 14px
        li
          position: relative
          margin-bottom: 22px
          padding-left: 44px
          &:before
            content: "L"
            +font(20, 24, 700)
            color: $color-primary
            position: absolute
            left: 20px
            transform: scale(1, -1) rotate(135deg)

    .wmodal__buttons
      +res-md-max
        display: block
      .wbutton
        +res-md-max
          margin-bottom: 10px
          width: 100%
