.bottombar
    height: 35px
    position: relative
    z-index: 5
    background-color: white
    border-top: 1px solid $pb-grey-50

    &-placeholder
        +typoTextXs
        color: $pb-grey-80
        height: 100%
        display: flex
        align-items: center
        padding: 5px 16px

    &-main
        height: 100%

    .block-breadcrumbs
        display: flex
        height: 100%
        +typoTextXs
    
    .block-breadcrumbs-step
        position: relative
        padding: 0 12px 0 28px
        display: flex
        align-items: center
        background: $pb-white
        cursor: default
        &:first-child
            padding-left: 10px
        &:not(:last-child)
            cursor: pointer
            &:hover
                background: $pb-grey-20
        
        &__title
            z-index: 2
        &__arrow
            position: absolute
            top: 5px
            right: -12px
            transform: rotate(45deg)
            height: 24px
            width: 24px
            border-top: 1px solid $pb-grey-20
            border-right: 1px solid $pb-grey-20
            background: inherit
            z-index: 1

        
            