.box-catalogo
    display: flex
    flex-direction: column
    background: $color-bg
    padding: 7rem 5rem
    +border-radius(2rem)
    +res-md-max
        padding: 5rem 3rem
    +res-sm-max
        padding: 3rem 2rem
    &__head
        margin-bottom: 4.3rem
        &__title
            +font(40, 48, 800)
            margin-bottom: 2rem
        &__description
            +font(18, 28, 400)
            font-family: $font-secondary
            margin-bottom: 2rem
        &__cta-box
            // display: flex
            .button
                &:first-child
                    margin-right: .8rem
                    margin-bottom: 2rem
    &__image
        align-self: center
        max-width: 33.5rem
        +res-sm-max
            max-width: 100%