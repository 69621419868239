@import public/sass/abstracts

.wvideo
  position: relative
  padding-bottom: 56.25%
  padding-top: 30px
  height: 0
  overflow: hidden
  margin-bottom: 2rem
  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%