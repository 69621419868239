@import public/sass/abstracts

.share
    .wmodal
        max-width: 345px
        &__head
            margin-bottom: 20px
        &__title
            +font(16, 24, 700)
            text-align: center
            margin-top: -30px
    &--square
        .share
            &__button
                +border-radius(6px)
    &__social
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        gap: 10px
        margin-bottom: 10px
        &__button
            height: 44px
            background-color: $grey-0!important
            +border-radius(22px)
            padding-top: 5px!important
            padding-bottom: 5px!important
            flex: 22%
            +res-md-max
                flex: 48%
            svg
                // width: 24px
                // height: 24px
                vertical-align: middle
    &__links
        .wbutton
            height: 44px
            background: $grey-0
            color: $black
            width: 100%
            margin-bottom: 10px
            &:last-child
                margin-bottom: 0
            .icon
                margin-right: 5px
                width: 20px
                height: 20px
                fill: $black

.copied-to-clipboard
  position: fixed
  bottom: 1.7rem
  right: 3rem
  padding: .8rem
  background: #fff
  font-size: 1.2rem
  line-height: 1.6rem
  font-weight: $font-semi-bold
  border: 1px solid $color-grey-15
  z-index: 9999
  +border-radius(.6rem)