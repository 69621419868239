@import public/sass/abstracts

.widget-col
  +res-md-min
    height: 268px
  &--stacked
    +res-md-min
      display: flex
      flex-direction: column
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-bottom: 14px
  &--size-1 
    +res-md-min
      width: calc(33.33% - 14px)
  &--size-2 
    +res-md-min
      width: calc(66.66% - 14px)