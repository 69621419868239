@import public/sass/landing-riviste/abstracts
.landing-riviste-novita
  padding: 52px 0 61px 
  background: #EAEAEA
  font-family: $font-primary
  +res-sm-max
    padding: 31px 0 48px
  &__title
    +typoH2LR
    color: $color-primary
    text-align: center
    +res-md-max
      +typoH1MobileLR

  &__list
    text-align: center
    +res-md-min
      display: flex
      flex-wrap: wrap
      margin: 53px -8px 0
      > *
        margin: 0 8px 15px
        width: calc(33.33% - 16px)
        display: flex
        flex-direction: column
        justify-content: space-evenly
        align-items: center
        &:nth-child(5)
          width: calc(66.66% - 16px)
        &:nth-child(4)
          order: 999
    +res-sm-max
      > * 
        &:not(:last-child)
          margin-bottom: 10px

    +res-sm-max
      margin-top: 46px