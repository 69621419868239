/** UI KIT **/

.ext-heading1
  font-family: $font-primary
  font-size: 7rem
  font-weight: $font-bold
  line-height: 8rem
  +res-sm-max
    font-size: 4.4rem
    line-height: 4.8rem

.ext-heading2
  font-family: $font-primary
  font-size: 4rem
  font-weight: $font-bold
  line-height: 4.8rem
  +res-sm-max
    font-size: 3.6rem
    line-height: 4rem

.ext-heading2-secondary
  font-family: $font-secondary
  font-size: 4rem
  font-weight: $font-bold
  line-height: 4.8rem
  +res-sm-max
    font-size: 3.6rem
    line-height: 4rem

.ext-heading3 // H4 su Figma
  font-family: $font-primary
  font-size: 2.4rem
  font-weight: $font-bold
  line-height: 3.2rem

.ext-heading3-secondary
  font-family: $font-secondary
  font-size: 2.4rem
  font-weight: $font-bold
  line-height: 3.2rem
  color: $tertiary

.ext-heading4
  font-family: $font-primary
  font-size: 2rem
  font-weight: $font-bold
  line-height: 2.4rem

.ext-heading4-secondary
  font-family: $font-secondary
  font-size: 2rem
  font-weight: $font-bold
  line-height: 2.4rem
  color: $tertiary

.ext-heading5
  font-family: $font-primary
  font-size: 1.6rem
  font-weight: $font-bold
  line-height: 2.4rem



.ext-paragraph1
  font-family: $font-secondary
  font-size: 3rem
  font-weight: $font-regular
  line-height: 4rem

.ext-paragraph2
  font-family: $font-secondary
  font-size: 2.4rem
  font-weight: $font-regular
  line-height: 3.2rem

.ext-paragraph3
  font-family: $font-secondary
  font-size: 2rem
  font-weight: $font-regular
  line-height: 2.4rem

.ext-paragraph4
  font-family: $font-primary
  font-size: 1.8rem
  font-weight: $font-bold
  line-height: 2.4rem

.ext-paragraph5
  font-family: $font-primary
  font-size: 1.6rem
  font-weight: $font-bold
  line-height: 1.6rem

.ext-paragraph6
  font-family: $font-secondary
  font-size: 1.6rem
  font-weight: $font-bold
  line-height: 1.6rem

.ext-paragraph7
  font-size: 1.4rem
  font-weight: $font-regular
  line-height: 2rem

.ext-info1
  font-family: $font-primary
  font-size: 1.4rem
  font-weight: $font-bold
  line-height: 1.6rem

.ext-info2
  font-family: $font-primary
  font-size: 1.4rem
  font-weight: $font-regular
  line-height: 1.6rem

/** END UI KIT **/

.ext-default
  font-family: $font-primary
  font-size: 1.8rem
  font-weight: $font-regular
  line-height: 2.4rem

.ext-tag
  @extend .ext-paragraph5
  line-height: 2.4rem
  text-transform: uppercase

.ext-boxtitle
  @extend .ext-heading2
  color: $color-secondary-dark

.ext-abstract-big
  @extend .ext-paragraph1
  +res-sm-max
    font-size: 2.4rem
    line-height: 3.2rem

.ext-abstract-medium
  @extend .ext-paragraph3
  line-height: 3.2rem

.ext-abstract-small
  @extend .ext-paragraph6
  line-height: 2.4rem

.ext-author
  @extend .ext-paragraph5
  line-height: 3rem

.ext-caption
  font-family: $font-primary
  font-size: 1.4rem
  font-weight: $font-regular
  line-height: 2rem

.ext-focus
  @extend .ext-paragraph2
  line-height: 4rem

.ext-quote
  font-family: $font-secondary
  font-size: 2.4rem
  font-style: italic
  font-weight: $font-regular
  line-height: 4rem

.ext-category
  font-family: $font-primary
  font-size: 1.6rem
  font-weight: $font-primary-medium
  line-height: 2.4rem
  text-transform: uppercase
  margin-bottom: 10px

.ext-icontitle
  position: relative
  font-family: $font-primary
  font-size: 2rem
  font-weight: $font-bold
  line-height: 3.2rem
  text-transform: uppercase
  margin-bottom: 1rem
  padding-left: 3rem
  color: $color-teal-lezione
  &::before
    position: absolute
    content: url(/images/svg/target--teal-lezione.svg)
    top: .2rem
    left: 0

.ext-p
  @extend .ext-paragraph3
  line-height: 3.2rem

.ext-ul, .ext-ol
  li
    position: relative
    margin-bottom: .5rem

.ext-ul
  li
    padding-left: 4rem
    &::before
      content: "•"
      position: absolute
      left: 0
      width: 3rem
      text-align: center
    +res-sm-max
      padding-left: 3rem

.ext-ol
  padding-left: 2.5rem
  li
    padding-left: 1.5rem

.ext-custom-text
  font-family: $font-primary
  font-size: 1.6rem
  line-height: 2.4rem
  p
    margin-bottom: 1rem
  .heading1
    @extend .ext-heading1
  .heading2
    @extend .ext-heading2
  .heading2-secondary
    @extend .ext-heading2-secondary
  .heading3
    @extend .ext-heading3
  .heading3-secondary
    @extend .ext-heading3-secondary
  .heading4
    @extend .ext-heading4
  .heading4-secondary
    @extend .ext-heading4-secondary
  .heading5
    @extend .ext-heading5
  .category
    @extend .ext-category
  .paragraph4
    @extend .ext-paragraph4
    margin-bottom: 1rem
  .icontitle
    @extend .ext-icontitle
    &--pencil
      &::before
        content: url(/images/svg/pencil--teal-lezione.svg)
        top: .4rem
    &--paper
      &::before
        content: url(/images/svg/paper--teal-lezione.svg)
        top: .2rem
    &--clock
      &::before
        content: url(/images/svg/clock--teal-lezione.svg)
        top: .5rem
    &--headphones
      &::before
        content: url(/images/svg/headphones--teal-lezione.svg)
        top: .4rem
  &--secondary-dark
    color: $color-secondary-dark
  a:not(.button):not(.strumento-card__content):not(.wbutton)
    color: $color-secondary
    text-decoration: underline
  .button
    margin-top: 2rem
    margin-bottom: 2rem
  ul
    @extend .ext-ul
  ol
    @extend .ext-ol

  blockquote
    background: url(/images/svg/quotes.svg) no-repeat top left
    @extend .ext-quote
    margin: 3rem 0
    +print
      font-size: 1.8rem
      line-height: 2.8rem
      background: none

body
  @extend .ext-default
  font-family: $font-primary
  color: $color-text

.heading1
  @extend .ext-heading1
.heading2
  @extend .ext-heading2
.heading2-secondary
  @extend .ext-heading2-secondary
.heading3
  @extend .ext-heading3
  &--secondary-dark
    color: $color-secondary-dark
.heading4
  @extend .ext-heading4
.heading5
  @extend .ext-heading5

.abstract-big
  @extend .ext-abstract-big
.abstract-medium
  @extend .ext-abstract-medium
.abstract-small
  @extend .ext-abstract-small

.overflow-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
