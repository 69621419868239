.ricerca-home2021
  position: relative
  padding: 73px 0
  background: $color-bg
  +res-sm-max
    padding: 49px 0
  &__title
    @extend .ext-heading2
    text-align: center
    margin-bottom: 30px
    +res-sm-max
      font-size: 2rem
      line-height: 2.4rem
      margin-bottom: 24px
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none
  &__container
    max-width: 600px
    margin: 0 auto

  &__search-form
    position: relative
    margin-bottom: 30px
    +res-sm-max
      margin-bottom: 24px

  &__input
    padding: 12px 22px 12px 54px
    font-family: $font-primary
    font-size: 1.6rem
    line-height: 2.4rem
    border-radius: 24px
    border: 1px solid #bbb
    width: 100%
  
  &__submit
    position: absolute
    top: calc(50% - 10px)
    left: 22px
    svg
      +style-icon(20px, 20px, $color-primary)

  &__tags
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center

  &__tag
    margin: 0 4px 19px
    font-size: 1.6rem
    font-weight: $font-bold
    line-height: 2.4rem
    color: #fff
    background: $color-primary
    padding: 6px 16px
    border-radius: 8px
    cursor: pointer
    text-align: center

  &__illustration
    position: absolute
    left: 0
    right: calc(50% + 300px)
    bottom: 0
    +translateY(50%)
    +res-md-max
      display: none
    img
      max-height: 300px
      +res-lg-max
        max-height: 200px

