@import public/sass/abstracts

.area-personale-newsletter-preferiti-edit-card
  &__list
    display: flex
    flex-wrap: wrap
    > *
      width: 294px
      max-width: 100%
      margin-bottom: 8px
      +res-xs-max
        margin-bottom: 24px