@import public/sass/abstracts

.form-textarea
  &__input
    width: 100%
    border: 1px solid $grey-0
    background: $grey-0
    color: $black
    outline: none
    border-radius: 12px
    min-height: 84px
    resize: vertical
    +placeholder-style
      color: $grey-2
    
    &:active, &:focus
      border-color: $accent-blue

  // Status
  &--error
    .form-textarea
      &__input
        border-color: $accent-red

  // Size 
  &--md
    .form-textarea
      &__input
        +typoBase(1.6rem, 2.4rem, $font-primary-medium-2, $font-primary)
        padding: 12px 16px