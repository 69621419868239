.dialog-backdrop
    background-color: $pb-black
    opacity: 0.5
    position: fixed
    top: 0
    left: 0
    z-index: 999
    width: 100vw
    height: 100vh

.dialog
        position: fixed
        z-index: 1000
        left: 30%
        top: 25%
        width: 40%
        height: 50%
        background-color: $pb-white

.dialog-content
    display: flex
    flex-direction: column
    height: 100%

.dialog-header
    display: flex
    justify-content: flex-end

.dialog-body
    display: flex
    justify-content: center
    align-items: center
    flex-grow: 1