@import public/sass/abstracts

.area-personale-contenuti-template
  .area-personale-page
    &__head
      +res-sm-max
        display: flex
        align-items: center
        justify-content: space-between
    &__head-actions
      margin-top: 0
      margin-left: 20px