@import public/sass/abstracts

.area-personale-dettagli-ordine-card
  padding: 20px
  background: $white
  border-radius: 10px
  &__details
    +typoTextLg
    +res-sm-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 20px
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 20px
  &__detail
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 8px
  &__detail-label
    color: $grey-2
    font-weight: $font-primary-medium
  &__detail-value
    font-weight: $font-primary-heavy
  &__actions
    margin-top: 20px
    display: flex
    +res-sm-min
      align-items: center
      > * 
        &:not(:last-child)
          margin-right: 20px
    +res-xs-max
      flex-direction: column
      align-items: flex-start
      > * 
        &:not(:last-child)
          margin-bottom: 20px