@import public/sass/abstracts

.bar
    display: none
    position: fixed
    bottom: 0
    border-radius: 20px 20px 0px 0px
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1)
    padding: 10px $wcont-padding
    z-index: 999
    width: 100%
    background: #fff
    +res-md-max
        display: flex
    +print
        display: none!important
    &__actions
        display: flex
        > div
            margin-right: 10px
            &:last-child
                margin-right: 25px
    &__cta
        flex-grow: 1
    .icon
        &--download
            width: 20px
            height: 20px
            margin-right: 8px
            vertical-align: middle

.modal__content  
    &__download
        display: flex
        flex-direction: column
        align-items: center
        width: 100%
        .disclaimer
            @extend .ext-paragraph3
        .content
            width: 100%
            font-size: 1.8rem
            text-align: center
            &__item
                display: flex
                flex-direction: column
                justify-content: space-between
                align-items: center
                min-width: 20rem
                &:not(:last-child)
                    +res-md-min
                        border-right: 2px solid $color-rivista
                    +res-md-max
                        margin-bottom: 3rem
                p
                    margin-bottom: 2.7rem
                    opacity: .6
                    +res-md-max
                        margin-bottom: 1rem
    &__comunicazione
        display: flex
        flex-direction: column
        align-items: center
        width: 100%
        .title
            font-size: 3rem
            line-height: 4rem
            text-align: center
        .disclaimer
            @extend .ext-paragraph3
            line-height: 3rem
        .login
            text-decoration: underline
            color: $color-secondary
            cursor: pointer

@media print
  .bar
    display: none