@import public/sass/abstracts

.filtri-catalogo
  display: flex
  align-items: center
  +res-sm-max
    align-items: flex-start
    flex-direction: column
  &__label
    +font(16, 24, 700)
    min-width: 150px
    margin-right: 10px
    +res-sm-max
      margin-right: 0
      margin-bottom: 10px
  &__slider
    max-width: 100%
    overflow: hidden
    .slick-slide
      &:not(:last-child)
        margin-right: 10px