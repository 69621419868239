@import public/sass/abstracts

.w-container-calendario
  max-width: 1259px
  margin-left: auto
  margin-right: auto
  padding-left: 2rem
  padding-right: 2rem
  
.calendario-avvento-template
  background-color: $green-0
 
  header
    background-color: #ffffff
  .header__content_small
    display: flex
    justify-content: space-between
    height: 12rem
    margin-left: auto
    margin-right: auto
    max-width: 1280px
    border-bottom: none
    max-width: 1240px
    .wbutton--primary
      +res-md-max
        padding: 7px 14px
    +res-md-max
      height: 7rem
      padding-left: 2rem
    
    .header__logo
      display: flex
      align-items: center
      padding: 0


  .header__side
    align-self: center
    padding: 0 1.5rem

    +res-md-min
      padding: 0 1.5rem