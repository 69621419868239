
// Colors

$pb-white: #ffffff
$pb-black: #131518

$pb-grey-10: #F0F2F6
$pb-grey-20: #e0e2e6
$pb-grey-50: #bdc2cb
$pb-grey-80: #8891a1
$pb-grey-85: #595e68
$pb-grey-90: #555a63
$pb-grey-100: #484b52

$pb-selected: #2f72d6
$pb-hovered: #214475
$pb-global: #c509ac

$pb-accent-light: #bbcae4
$pb-accent-default: #2c4871
$pb-accent-dark: #172d4e

$pb-error-light: #F39D97
$pb-error-default: #DE635B
$pb-error-dark: #A83932

$pb-warning-light: #FFE691
$pb-warning-default: #FFBB38
$pb-warning-dark: #D3952D

$pb-success-light: #9CECB7
$pb-success-default: #74CF93
$pb-success-dark: #54A06E


// Fonts

$pb-font-primary: 'Open Sans', Arial, sans-serif


// Resolutions

$pb-res-xs-max: 575px
$pb-res-sm-min: 576px
$pb-res-sm-max: 767px
$pb-res-md-min: 768px
$pb-res-md-max: 991px
$pb-res-lg-min: 992px
$pb-res-lg-max: 1199px
$pb-res-xl-min: 1200px
