.info
  display: flex
  align-items: flex-end
  > *
    &:not(:last-child)
      margin-right: 1.7rem
  &__trigger
    display: flex
    align-items: flex-end
    cursor: pointer
  // &__stars
  //   .icon
  //     display: inline-block
  //     +fill-icon($color-star)
  //     width: 1rem
  //     height: 1rem
  //     &:not(:last-child)
  //       margin-right: .5rem
  &__rating
    display: flex
    align-items: center
    font-weight: $font-bold
    font-size: 1.4rem
    margin-right: 1.4rem
    span
      padding-top: .5rem
    .icon
      display: inline-block
      +fill-icon($color-green)
      width: 1.9rem
      height: 1.9rem
      margin-right: .4rem
  &__comments
    .icon
      display: inline-block
      +fill-icon($color-secondary)
      vertical-align: middle
      width: 2rem
      height: 2rem
  &__comments-count
    font-size: 1.6rem
    margin: 0 .5rem
    color: $color-secondary
  &__readtime
    font-size: 1.4rem
    +res-sm-max
      margin-left: auto