@import public/sass/abstracts

.typ-libri-box
  padding: 70px 35px
  background: $color-bg
  border-radius: 20px
  +res-md-min
    display: flex
  +res-sm-max
    padding: 40px 14px
  &__content
    +res-md-min
      width: 60%
      margin-right: 10px
  &__image-box
    +res-md-min
      flex: 1
  &__image
    display: block
    margin: 0 auto
  &__title
    +typoH2
    margin-bottom: 20px
  &__description
    +typoBase(1.8rem, 2.8rem, $font-secondary-regular, $font-secondary)
    margin-bottom: 30px