.sidebar
    .toolbox
        width: 100%
        height: 100%
        background-color: white
        border-right-width: 1px
        display: flex
        flex-direction: column
        justify-content: space-between

        .disabled
            opacity: 0.5
            cursor: not-allowed

        &__tools

            &__wrapper
                display: flex
                align-items: flex-start
                flex-wrap: wrap
                padding: 5px
            
            &__item
                background-color: $pb-grey-50
                padding: 1em 2em
                margin: 5px
                cursor: grab
                
                > *
                    display: block

                span
                    +typoTextXs
                svg
                    width: 24px
                    height: 24px
                    fill: $pb-white

        &__custom-blocks

            &__item
                display: flex
                justify-content: space-between
                a
                    cursor: pointer
