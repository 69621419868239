@import public/sass/abstracts

.sesamo-template
  display: flex
  flex-direction: column
  align-items: center
  &__image-container
    margin-bottom: 10px
  &__column-display
    display: flex
    flex-direction: column
    align-items: center
  &__breadcrumbs
    width: 100%
  &__title
    position: absolute
    color: transparent
  &__subtitle
    +font(20, 26, 800)
    margin-bottom: 10px
    text-align: center
    +res-sm-max
      margin-bottom: 30px
  &__description
    +font(16, 24, 500)
    margin-bottom: 30px
    text-align: center
    max-width: 748px
  &__anchors
    margin-bottom: 50px
    +res-sm-max
      display: none
  &__anchor
    padding: 0 30px!important
    border-radius: 0!important
    &:not(:last-child)
      border-right: 1px solid $color-secondary
  &__tema
    display: flex
    justify-content: center
    flex-wrap: wrap
    +border-radius(16px)
    background: $color-bg
    width: 100%
    max-width: $wcont-sportelli-width
    margin: 0 auto 54px auto
    padding: 35px 20px
    +res-sm-max
      flex-direction: column
      align-items: center
      margin-bottom: 30px
      padding: 20px
      padding-bottom: 50px
    &__image
      +border-radius(16px)
    &__content
      flex: 1
      margin-left: 30px
      +res-sm-max
        text-align: center
        margin: 0
    &__label
      +font(16, 24, 400)
      margin-bottom: 8px
      text-transform: uppercase
      +res-sm-max
        margin-top: 22px
    &__title
      +font(30, 40, 800)
      margin-bottom: 22px
    &__text
      +font(16, 24, 400)
      margin-bottom: 22px
    &__risorse
      margin-top: 30px
  &__abbonati
    max-width: $wcont-sportelli-width
    margin-bottom: 80px
