@import public/sass/abstracts

.wmodal-calendario-avvento
  .wmodal__head
    margin-bottom: 0

  .w-modal-title
    font-size: 3rem
    font-weight: 500
    line-height: 3rem
    margin-bottom: 2.5rem
    padding-top: 3rem

  .w-modal-disclaimer
    font-size: 2.4rem
    line-height: 4rem
    color: #000000

  .w-modal-gift
    font-size: 4rem
    font-weight: 500
    line-height: 5rem
    margin-bottom: 2.5rem
    color: #000000

  .wbutton__label
    font-weight: 400

  .w-modal-subdescription
    font-size: 1.6rem
    padding-bottom: 2.5rem
    color: #7E7E7E
    margin-top: -1rem

  .w-modal-variant-yellow
    margin-top: -2rem
    .w-modal-title
      color: $color-yellow
    .wbutton__label
      color: #000000

  .w-modal-variant-red
    margin-top: -2rem
    .w-modal-title
      color: $color-red-dark
    .wbutton__label
      color: #ffffff

  .w-modal-variant-green
    margin-top: -2rem
    .w-modal-title
      color: $text-green-light
    .wbutton__label
      color: #ffffff