.temi-all
  padding-bottom: 4rem

  &__header
    display: flex
    flex-wrap: wrap
    align-items: baseline
    margin-bottom: 4rem

    &__title
      margin-right: 3rem

    &__cta
      +res-sm-max
        display: block
        padding-left: 0
        padding-right: 0
      &:hover
        color: $color-secondary

  &__list
    display: flex
    flex-direction: column
    flex-wrap: wrap
    justify-content: space-between
    max-height: 90rem
    @media ( max-width: 1150px )
      max-height: 150rem
    @media ( max-width: 700px )
      max-height: 250rem

  &__item
    max-width: 35rem
    margin-bottom: 2rem

    &::before
      content: attr(aria-label)
      font-size: 2.4rem
      font-weight: $font-bold
      display: block
      background: $color-bg
      color: $color-secondary-dark
      padding: .5rem 2rem 0 2rem
      margin-bottom: .5rem
      +border-radius(3rem)

    li
      padding: .5rem 0 0 2rem
      font-size: 1.6rem
      font-weight: 100
      line-height: 3.2rem
    
    &-arrow
      display: none
      margin-left: 1.6rem
      width: 1.6rem
      height: .8rem
      fill: $color-text

    &--disabled
      &::before
        color: #acbdd2

    a
      &:hover
        text-shadow: 0px 0px 1px $black
        .temi-all__item
          &-arrow
            display: inline-block

