@import public/sass/abstracts

.area-personale-indirizzo-card
  padding: 20px
  background: $white
  border-radius: 10px
  margin-bottom: 20px
  &__predefinito-label
    margin-bottom: 16px
  &__content
    +typoTextLg
    > *
      &:not(:last-child)
        margin-bottom: 4px
  &__intro
    font-weight: $font-primary-heavy
    text-transform: uppercase
    color: $grey-2
  &__title
    font-weight: $font-primary-heavy
  &__bottom
    margin-top: 16px
    padding-top: 16px
    border-top: 1px solid $grey-2
    +res-sm-min
      display: flex
      justify-content: flex-end
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 16px
  &__predefinito-wrap
    +res-sm-min
      order: -1
      margin-right: auto
  &__actions-wrap
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 16px