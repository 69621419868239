@import public/sass/abstracts

.area-personale-data-field
  &__label
    +typoBase(1.2rem, 1.6rem, $font-primary-heavy)
    color: $grey-2
    margin-bottom: 4px
  &__value
    +typoBase(1.6rem, 2.4rem, $font-primary-medium-2)
    padding: 2px 0
    text-overflow: ellipsis
    overflow-x: hidden