@import public/sass/abstracts

.wmodal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 10000
  background-color: rgba($black, .6)
.wmodal
  position: fixed
  top: 50%
  +translateY(-50%)
  left: 0
  right: 0
  z-index: 10001
  max-width: 653px
  margin: 0 auto
  padding: 54px 16px 20px
  +border-radius(20px)
  background: $white
  +res-sm-max
    padding: 56px 16px 20px
  &__close // Non previsto dalla grafica - fatto a intuito
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    +res-sm-max
      top: 16px
      right: 16px
    svg
      width: 24px
      height: 24px
      +fill-icon($black)
  // &__body // Tolgo - altrimenti le tendine delle select non si vedono
    //max-height: calc(100vh - 112px) // 112 = 16*2 (margin) + 40*2 (padding)
    //overflow: hidden auto
    //+scrollbar-style
  &__head
    margin-bottom: 40px
  &__icon
    margin-bottom: 40px
    svg
      display: block
      width: 90px
      height: 90px
      margin: 0 auto
  &__title
    +typoH3
    +res-sm-max
      +typoH4
  &__description
    +typoTextLg
    margin-top: 20px
    margin-bottom: 20px
  &__buttons
    display: flex
    justify-content: center
    margin-top: 20px
    > *
      &:not(:last-child)
        margin-right: 20px
  &--lightbox
    height: calc( 100vh - 20px )
    background: transparent
    .wmodal
      &__close
        display: flex
        padding: 2px
        background: $color-secondary
        .icon
          fill: #fff

body
  &.w-toolbar
    .wmodal
      &--lightbox
        height: calc( 100vh - #{$toolbar-height} - 20px )
        &__body
          display: flex
          justify-content: center
          max-height: 100%
          flex-flow: column
          img
            object-fit: contain
        &__content
          width: 100%
