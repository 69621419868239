.wslider
  margin: 4rem 0
  .block-image
    img
      margin: 0
  .slick-slider
    max-width: 92%
    .slick-prev
      &::before
        content: url(/images/svg/chevron-sharp-left.svg)  // da cambiare
    .slick-next
      transform: rotate(180deg) translateY(50%)
      &::before
        content: url(/images/svg/chevron-sharp-right.svg)  // da cambiare
    .slick-list
      & > div
        margin-left: 0
      .slick-track
        .slick-slide
          display: inline-block
          max-width: 22rem
          &:not(:last-child)
            margin-right: 2rem
.wslider-edit
  display: flex
  flex-wrap: wrap
  align-items: center
  min-height: 10rem
  margin: 0 4rem
  position: relative
  &::before, &::after
    position: absolute
    top: calc(50% - 1.2rem)
  &::before
    content: url(/images/svg/chevron-sharp-left.svg)  // da cambiare
    left: -2.4rem
  &::after
    content: url(/images/svg/chevron-sharp-right.svg)  // da cambiare
    right: -2.4rem
    transform: rotate(180deg)
  .block-image 
    margin: 1rem 1.5rem
    img
      max-width: 25rem
      max-height: 25rem
      margin: 0