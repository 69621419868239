.figure
  &__image
    display: block
  &__caption
    @extend .ext-caption
    color: $color-grey-5
    padding: 1rem 0
    text-align: center
  &--overflow-left
    .figure__image
      margin-left: -5rem
      max-width: calc(100% + 5rem)
  &--overflow-right
    .figure__image
      margin-right: -5rem
      margin-left: auto
      max-width: calc(100% + 5rem)
