.hub-page
  background: black
  &__header
    text-align: center
    &__title
      @extend .ext-heading1
      color: white
      margin-top: 2rem
      margin-bottom: -1rem
  &__content
    padding-top: $border-radius-big
    margin-top: -9rem
    +border-top-left-radius($border-radius-big)
    +border-top-right-radius($border-radius-big)
    +res-md-max
      padding-top: $border-radius-small
      +border-top-left-radius($border-radius-small)
      +border-top-right-radius($border-radius-small)
    &__cataloghi
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      margin-bottom: 7rem
      +res-sm-max
        flex-direction: column
        margin-bottom: 8rem
      .box-catalogo
        flex-basis: 49%
        +res-sm-max
          flex-basis: 100%
          margin-bottom: 1.6rem
  &--tema
    .hub-page
      &__content
        +res-sm-max
          padding-top: 0
        .box-tema-del-momento
          padding-top: 3rem
          +res-sm-max
            +border-top-left-radius($border-radius-small)
            +border-top-right-radius($border-radius-small)
