@import public/sass/abstracts

.area-personale-sidebar
  padding: 32px 16px 
  background-color: $white
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1)
  width: 326px
  z-index: 1 // per far venire l'ombra sul main
  @media screen and (min-width: $wcont-width)
    padding-left: calc(50vw - #{$wcont-width / 2} + 16px)
    width: calc(50vw - #{$wcont-width / 2 - 272px})
  > *
    &:not(:last-child)
      margin-bottom: 20px
  +res-md-max
    position: fixed
    top: 0
    bottom: 0
    right: 0
    width: 100%
    max-width: 375px
    z-index: 8000
    +translateX(100%)
    +transition(transform .5s)
    overflow: auto
    +scrollbar-style
  &__close
    position: absolute
    top: 20px
    right: 24px
    .icon
      width: 24px
      height: 24px
  &__backdrop
    position: absolute
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: rgba(0, 0, 0, .5)
    z-index: 999
    display: none
    
.header--toolbar-active ~ main
  .area-personale-sidebar
    top: $toolbar-height
    
.w-menuareapersonaleopen
    .area-personale-sidebar
      &__backdrop
        display: block
      +res-md-max
        +translateX(0)