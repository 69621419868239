@import public/sass/abstracts

.widget-slider
  +res-md-min
    height: 100%
  +res-xs-max
    padding-bottom: 32px
  
  &__slider
    position: relative
    +border-radius(20px)
    +res-md-min
      overflow: hidden
    +res-sm-max
      min-height: 303px
    +res-xs-max
      min-height: 520px

    .slick-dots
      z-index: 10
      bottom: -32px
      li
        width: 12px
        height: 12px
        button
          background: $grey-1
          border-radius: 50%
          width: 12px
          height: 12px
          
          &::before
            display: none
        &.slick-active
          button
            background: $accent-blue
    
  .slick-arrow
    width: 24px
    height: 24px
    z-index: 2

  .slick-prev
    left: 10px
    &::before
      content: $chevron-left

  .slick-next    
    right: 10px
    &::before
      content: $chevron-right

  &__slide
    position: relative
    width: 200px
    +res-md-min
      height: 268px
    +res-xs-max
      text-align: center

    &__background
      background-repeat: no-repeat
      background-size: cover
      padding: 30px 50px
      width: 100%
      height: 100%
      +res-sm-max
        background-position: center
        background-size: cover
        min-height: 303px
      +res-xs-max
        min-height: 520px
        padding: 36px 14px

    &__container
      +res-sm-min
        display: flex
        align-items: center
        height: 100%
        > *
          width: 50%
    
    &__content
      display: flex
      flex-direction: column
      height: 100%

    &__title
      +typoH4
      margin-bottom: 10px
      +res-xs-max
        +typoH3

    &__description      
      +typoBase(1.6rem, 2.4rem, $font-secondary-regular, $font-secondary)
      margin-bottom: 20px
      +res-xs-max
        margin-bottom: 10px

    &__cta-box
      &.bottom
        position: absolute
        bottom: 30px
        +res-xs-max
          left: 50%
          transform: translateX(-50%)