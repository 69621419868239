.box-risorse
	display: flex
	align-items: center
	flex-direction: column
	width: 100%
	padding: 5rem 0 3.4rem 0
	background: $color-bg
	&__head
		text-align: center
		&__title
			+font(30, 40, 800)
			margin-bottom: 1.2rem
			+res-sm-max
				+font(24, 30, 800)
				margin-bottom: 1.6rem
		&__description
			+font(16, 24, 400)
			font-family: $font-secondary
			margin-bottom: 3rem
			+res-sm-max
				margin-bottom: 1.6rem
	&__content
		display: flex
		justify-content: space-between
		flex-wrap: wrap
		+res-sm-max
			flex-direction: column
		&__item
			display: flex
			flex-basis: 49%
			padding: 3rem 5rem
			+border-radius(1.2rem)
			background: #fff
			margin-bottom: 1.6rem
			+res-sm-max
				padding: 2.4rem
			&__left
				display: flex
				align-items: center
				padding-right: 5rem
				.icon
					width: 5.5rem
					max-height: 6rem
					+fill-icon($color-grey-17)
			&__title
				+font(30, 40, 800)
				margin-bottom: 1.2rem
				+res-sm-max
					+font(24, 30, 800)
					margin-bottom: .4rem
			&__description
				+font(16, 24, 400)
				font-family: $font-secondary
				margin-bottom: 1.2rem
				+res-sm-max
					margin-bottom: 1.6rem