.richiestavalidazione-card
  padding: 3rem 0
  &:not(:last-child)
    border-bottom: 2px solid $color-rivista
  &__item
    margin-bottom: 2rem
  &__label
    font-size: 1.2rem
    opacity: .6
  &__value
    margin-bottom: 2rem  
  &__value-alert
    color: $color-red
  &__buttons-col
    align-self: flex-end
  &__buttons
    text-align: right
    > *
      margin-top: 2rem
      &:not(:last-child)
        margin-right: 1rem
    +res-xs-max
      > * 
        display: block
        width: 23rem
        margin-left: auto
        margin-right: auto
        &:not(:last-child)
          margin-right: auto