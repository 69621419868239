@import public/sass/abstracts

.libro-card
  position: relative
  padding: 20px 16px
  border-radius: 20px
  background: $white
  min-height: 300px
  +res-sm-min
    display: flex
  +res-xs-max
    text-align: center
    padding-bottom: 82px
  &__image-box
    width: 200px 
    overflow: hidden
    +border-radius(10px)
    +res-sm-min
      margin-right: 16px
    +res-xs-max
      margin: 0 auto 24px
  &__content
    +res-sm-min
      display: flex
      flex-direction: column
      align-items: flex-start
      flex: 1
  &__head
    display: flex
    align-items: center
    margin-bottom: 10px
    +res-xs-max
      justify-content: center
    > *
      &:not(:last-child)
        margin-right: 13px
  &__title
    +typoH5
  &__description
    +typoBase(1.4rem, 2rem, $font-primary-heavy)
    margin-bottom: 24px
  &__logo
    margin-bottom: 10px
    +res-xs-max
      display: none
    svg
      width: 90px
  &__actions
    display: flex
    flex-direction: column
    align-items: flex-start
    +res-xs-max
      align-items: center
    > *
      &:not(:last-child)
        margin-bottom: 12px
  &__cta
    margin-top: auto
  &__cta-guide
    &--desktop
      +res-xs-max
        display: none
    &--mobile
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      border-radius: 0 0 20px 20px
      +res-sm-min
        display: none
    &--contenuti
      margin-top: 10px
      +res-sm-max
        margin-left: 10px
      +res-xs-max
        bottom: 48px
        background: #fff
        color: $color-secondary
  &--parascolastico,
  &--libri,
    +res-xs-max
      padding-bottom: 110px