@import public/sass/abstracts

.thumbed-slider
  max-width: 100%
  &__thumbs
    position: static!important
    margin-top: 20px!important
    padding: 10px!important
    li
      width: auto!important
      height: 50px!important
      margin: 0!important
      &:not(:last-child)
        margin-right: 7px!important
      &.slick-active
        border: 1px solid $black
        
  .slick-arrow
    width: 24px
    height: 24px
    z-index: 2

  .slick-prev
    left: -50px
    &::before
      content: $chevron-left

  .slick-next    
    right: -50px
    &::before
      content: $chevron-right