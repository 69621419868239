@import public/sass/abstracts

.attiva-contenuto-card
  min-height: 300px
  padding: 60px
  border-radius: 20px
  background: $white
  text-align: center
  &__title
    +typoH5
    margin-bottom: 30px
  &__description
    +typoTextLg
    margin-bottom: 30px