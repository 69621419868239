.news-home2021
  padding: 50px 0
  text-align: center
  +res-md-max
    padding-bottom: 94px
  &__intro
    font-size: 1.6rem
    line-height: 2.4rem
    text-transform: uppercase
    margin-bottom: 9px
    +res-sm-max 
      margin-bottom: 3px
  &__title
    font-size: 4rem
    font-weight: $font-bold
    line-height: 4.8rem
    margin-bottom: 23px
    +res-sm-max
      font-size: 3.6rem
      margin-bottom: 32px
  &__list
    max-width: 800px
    margin: 0 auto
    +res-md-max
      display: none
    +res-lg-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 15px
      &__item
        display: flex
        justify-content: space-between
        &:first-child
          > *
            &:not(:last-child)
              margin-right: 15px
        &:last-child
          flex-direction: column
          > *
            &:not(:last-child)
              margin-bottom: 14px
  &__slider
    +res-lg-min
      display: none
    .slick-dots
      bottom: -44px
      font-size: 12px
      line-height: 12px
      li 
        width: 12px 
        height: 12px
        margin: 0 6px
        button
          background: #BBBBBB
          border-radius: 50%
          width: 12px 
          height: 12px
          &::before
            display: none
        &.slick-active
          button
            background: $color-secondary

  &__slide
    padding-top: 10px
    > *
      margin-left: auto
      margin-right: auto