@import public/sass/abstracts

.checkout-error-template
  background: $grey-0
  padding: 52px 0
  &__card
    max-width: 469px
    margin: 0 auto
    padding: 30px
    border-radius: 24px
    background: $white
    text-align: center
    +res-sm-max
      padding: 20px
    > *
      &:not(:last-child)
        margin-bottom: 29px
  &__image-box
    +res-sm-max
      max-width: 256px
      margin-left: auto
      margin-right: auto
  &__intro
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
    color: $color-primary
  &__title
    +typoBase(3.2rem, 3.8rem, $font-primary-black-2)
    +res-sm-max
      +typoBase(2.6rem, 3.1rem)
  &__subtitle
    +typoTextXl
    font-weight: $font-primary-heavy
  &__description
    +typoTextLg
    a
      color: $color-secondary
      font-weight: $font-primary-heavy
      text-decoration: underline