@import public/sass/abstracts

.number_cell,.number_cell_corrente
    position: absolute
    font-size: 30px
    padding: 1.4rem
    +res-md-max
        font-size: 1.6rem
        padding: 1.4rem 0.4rem

.number_cell_corrente    
    cursor: pointer
    color: $color-red-dark
    font-size: 6rem
    padding: 2.8rem 1.4rem
    +res-md-max
        font-size: 2.6rem
        padding: 1.8rem 0.4rem


.wcella-card__image-box
    max-height: 200px