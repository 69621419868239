@import public/sass/abstracts

.scalapay-banner
  font-size: 14px

  &--pdp
    width: fit-content
    border: 1px solid $grey-1
    border-radius: 8px
    padding: 12px 16px
    margin-bottom: 20px
