.proposte-home2021
  padding: 50px 0
  &__content
    max-width: 608px
    margin: 0 auto
    text-align: center
  &__title
    @extend .ext-heading2
    margin-bottom: 13px
  &__description
    font-family: $font-secondary
    font-size: 1.6rem
    line-height: 2.4rem
    margin-bottom: 40px
  &__slider
    +res-md-min
      padding-top: 65px
    +res-lg-max
      padding-left: 50px
      padding-right: 50px

    .slick-dots
      +res-md-min
        bottom: auto
        top: -65px
        li
          width: auto
          height: auto
          button
            width: auto
            height: auto
            font-size: 1.6rem
            font-weight: $font-bold
            line-height: 1.92rem
            color: #000
            padding-bottom: 4px
            &::before
              display: none
          &.slick-active
            button
              border-bottom: 2px solid #000


    .slick-arrow
      width: 24px
      height: 24px
    .slick-prev
      left: -50px
      &::before
        content: $chevron-left

    .slick-next
      right: -50px
      &::before
        content: $chevron-right

  &__item
    background: #FF9F00
    border-radius: 20px
    padding: 35px 19px
    +res-md-min
      display: flex
      align-items: center
      padding: 38px 50px
    +res-sm-max
      text-align: center

    &__image-box
      min-width: 270px
      +res-md-min
        margin-right: 40px
      +res-sm-max
        display: none
    &__content

    &__title
      font-size: 3rem
      font-weight: $font-bold
      line-height: 4rem
      margin-bottom: 13px
      color: #FFF
      +res-sm-max 
        font-size: 2.4rem
        line-height: 2.88rem
        margin-bottom: 20px
    &__description
      font-family: $font-secondary
      font-size: 1.6rem
      line-height: 2.4rem
      margin-bottom: 20px
    &__cta-box
      +res-sm-max
        text-align: center