.community
  margin-top: 5rem
  &__content
    background: $color-bg
    padding: 3.5rem 3.8rem 2rem
  &__title
    font-size: 3.6rem
    line-height: 4.3rem
    margin-bottom: 2.8rem
  &--side
    position: relative
    padding: 4rem
    background: $color-bg
    +border-bottom-right-radius(14rem)
    +res-xs-max
      padding: 4rem 2rem 2rem 0
    &::before
      content: ""
      position: absolute
      top: 0
      right: 100%
      height: 100%
      width: calc(50vw - 60rem)
      background: $color-bg
      @media (max-width: 1280px)
        width: 2rem
    .community
      &__title
        font-size: 2.4rem
        line-height: 3.2rem
        margin-bottom: 0
        text-align: center
  .community-box
    border-top: 2px solid #E6E6E6
    &--side
      border-top: none
  
.community-box
  padding: 2.8rem 0
  display: flex
  +res-md-max
    display: block
  &__image-box
    flex: 0 0 33%
    margin-right: 4.6rem
    +res-md-max
      margin-right: 0
      margin-bottom: 2rem
  &__image
    display: block
    +border-radius(50%)
    +res-md-max
      margin-left: auto
      margin-right: auto
      max-width: 20rem
  &__content
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
  &__title
    font-size: 2rem
    font-weight: $font-bold
    line-height: 2.1rem
    margin-bottom: 1rem
  &__description
    font-size: 1.6rem
    line-height: 2.1rem
  &__cta
    margin-top: auto
    display: inline-block
    margin-top: 3rem

  &--horizontal
    border-top: none
    .community-box
      &__image-box
        flex-basis: 46%
        margin-right: 0
        padding: 0 3.4rem
      &__image
        margin-left: auto
        margin-right: auto
      &__content
        padding: 3rem 0
        justify-content: center
      &__title
        font-size: 4rem
        line-height: 4.8rem
        margin-bottom: 2rem
      &__description
        font-size: 2rem
        line-height: 2.8rem
      &__cta
        margin-top: 3rem

  &--side
    display: block
    padding-top: 0
    .community-box
      &__title
        font-size: 2.4rem
        line-height: 3.2rem
        text-align: center
      &__image
        max-width: 24rem
        width: 100%
        margin: 4rem auto
      &__description
        text-align: left

@media print
  .community-box
    display: none

