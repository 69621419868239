@import public/sass/abstracts

.infinite-loader
  position: absolute
  top: 50%
  +translateY(-50%)
  left: 0
  right: 0
  margin: 0 auto
  +style-icon(40px, 40px, $color-primary)
  animation: rotation 1s infinite linear