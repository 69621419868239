.iopiu2-actions
  background: $color-bg
  padding: 35.9px 24px 85.4px
  text-align: center   
  +res-sm-max
    padding: 28.9px 24px 95.4px
  &__title
    font-size: 40px
    font-weight: 900
    line-height: 48px
    margin-bottom: 12.5px
    +res-sm-max
      font-size: 28px
      line-height: 36px
      margin-bottom: 23.8px
  &__subtitle
    font-size: 24px
    font-weight: 900
    line-height: 36px
    margin-top: 63.7px
    margin-bottom: 24px
    +res-sm-max
      font-size: 20px
      line-height: 28px
      margin-top: 47.1px
      margin-top: 22.7px
  &__cta-box
  &__cta

  