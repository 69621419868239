.pb-form-select
  &__input
    &__control
      cursor: pointer
      border: 1px solid $pb-grey-50!important
      background: $pb-white!important
      color: $pb-grey-100
      width: 100%
      outline: none!important
      box-shadow: none!important
      min-height: 0!important
      border-radius: 2px!important
      font-size: 12px!important
      line-height: 16px!important
      &--is-focused
        border-color: $pb-grey-50!important

    &__placeholder
      margin-top: 2px
      margin-bottom: 2px
      padding-top: 2px
      padding-bottom: 2px

    &__dropdown-indicator
      padding: 5px!important
      svg
        width: 16px!important
        height: 16px!important
        fill: $pb-grey-100!important
        color: $pb-grey-100!important

    &__indicator-separator
      display: none!important

    &__value-container
      padding: 0!important

    &__single-value
      margin: 0!important
      padding: 0 2px!important
      color: $pb-grey-100!important
      
    &__menu
      margin: 0!important
      +border-radius(2px!important)
      +box-shadow(none!important)
      overflow: hidden
      border: 1px solid $pb-grey-50!important
      background: $pb-white!important

    &__menu-list
      padding: 0!important
      +scrollbar-style

    &__option
      font-size: 12px!important
      line-height: 16px!important
      padding: 2px 4px!important
      color: $pb-grey-100!important
      cursor: pointer!important
      background: $pb-white!important
      &:hover
        background: $pb-grey-10!important
        color: $pb-grey-100!important
      &--is-focused
        background: $pb-white!important
        color: $pb-grey-100!important
        outline: none!important
      &--is-selected
        background: $pb-grey-20!important
        color: $pb-grey-100!important

    // Disabled
    &--is-disabled
      opacity: .4
      .form-select
        &__input
          &__single-value
            color: $pb-grey-100!important
          &__dropdown-indicator
            svg
              +fill-icon($pb-grey-100)

    &--searchable
      .form-select
        &__input
          &__value-container
            height: 2.8rem
          &__single-value
            top: 50%!important

  // Status
  &--error
    .form-select
      &__input
        &__control
          border-color: $pb-error-default!important
