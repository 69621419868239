@import public/sass/abstracts

.follow
    &--square
        .follow
            &__button
                +border-radius(6px)
                +box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))
    &--followed
        .follow
            &__button
                background-color: #fff
                .icon
                    fill: $color-secondary