@import public/sass/abstracts

.area-personale-raccolta-contenuti-edit-form
  border-top: 1px solid $grey-2
  padding-top: 24px
  +res-sm-max
    padding-top: 40px
  &__head
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
    +res-sm-max
      margin-bottom: 8px
  &__title
    +typoH3
    +res-sm-max
      +typoH4
      margin-bottom: 15px
  &__actions
    min-height: 48px // per compensare la visualizzazione del bottone alto
    display: flex
    align-items: center
    +res-sm-max
      justify-content: space-between
    +res-md-min
      > *
        margin-left: 28px

  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(14px)
      > *
        width: 50%
        +res-lg-min
          width: 33.33%

  &__item
    position: relative
    margin-top: 30px
    +res-sm-max
      margin-top: 8px
    .selection-button
      +res-md-min
        left: 7px

  &__item__card
    position: relative
    z-index: 0 // le card hanno degli z-index che sovrastano il checkbox

  &--selection-active
    .area-personale-raccolta-contenuti-edit-form
      &__item__card
        .articolo-card
          &:hover
            +translateY(0)