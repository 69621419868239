.breadcrumbs
  position: relative
  vertical-align: middle
  margin-top: 1.5rem
  margin-bottom: 1.5rem
  background: #fff
  display: inline-block
  padding: 0 1.25rem 0 .3rem
  max-width: 100%
  +border-top-left-radius(.5rem)
  +border-bottom-left-radius(.5rem)
  &__container
    +res-sm-max
      padding-top: 15px
  > .icon
    display: block
    position: absolute
    top: 0
    width: 1.5rem
    height: 2.5rem
    right: -1.4rem
    // z-index: 999
    +fill-icon(#fff)
  li
    display: inline-block
    vertical-align: middle
  &-item
    color: $color-grey-4
    font-size: 1.4rem
    line-height: 2.6rem
    a
      padding: 0 .5rem
      text-decoration: none
      color: $color-grey-4
      display: block
    &:first-child
      .icon
        width: 1.3rem
        height: 1.1rem
        +fill-icon($color-primary)
        display: block

  &-item--home
    padding: .6rem 0

  &-separator
    padding: 0 .5rem
    &::before
      content: ">"
      display: inline-block
      color: $color-grey-4
      font-size: 1.4rem
  
  +res-sm-max
    display: flex
    flex-wrap: nowrap
    max-width: calc(100% - 2.5rem)
    align-items: center
    margin-top: 0
    width: fit-content
    &-item--active, &-item > a
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

  .slick-list
    overflow: hidden

  .slick-slider
    width: 100%

  .slick-track
    display: flex
    max-width: max-content
    margin-left: 0
    margin-right: 0

    & > div
      // width: 100%
      // max-width: 32rem!important
      // margin-right: 0!important

      & > div
        display: flex
        width: 100%

@media print
  .breadcrumbs
    display: none