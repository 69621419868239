@import public/sass/abstracts

.area-personale-scuole-template
  background: $grey-0!important
  &__head
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 30px
    +res-sm-max
      margin-bottom: 20px
  &__title
    +typoH4
    +res-sm-max
      margin-bottom: 24px
  &__list
    > *
      &:not(:last-child)
        margin-bottom: 30px