@import public/sass/abstracts

.bundle-panel-item
  display: flex
  flex-direction: column
  padding: 10px
  border: 1px solid $grey-1
  +border-radius(12px)
  margin-bottom: 20px
  &__bottom
    display: none
  &--open
    .bundle-panel-item
      &__bottom
        display: block
        margin-top: 10px
  &__top
    display: flex
    align-items: center
    svg
      margin-left: auto
      +style-icon(24px, 24px, $black)
  &__title
    +font(16, 24, 700)
  &__description
    +font(16, 24, 400)
