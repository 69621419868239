.slider
  overflow-x: hidden
  padding: 2rem 0 // per far vedere la shadow dei box
  div
    outline: none!important
  &__header
    @extend .w-container-fluidright
    padding-right: 1.5rem
    margin-bottom: 1rem
    +res-md-min
      display: flex
      align-items: flex-end
      margin-bottom: 1.5rem
  &__viewall
    margin-left: auto
    margin-right: 3rem
  .slick-list
    overflow: visible
    & > div
      margin-left: 0
    .slick-track
      display: flex
      padding: 2rem 0 2rem 1rem
      & > div
        display: inline-block
        max-width: 25rem
        &:not(:last-child)
          margin-right: 6rem
  &__title
    @extend .ext-boxtitle
    +res-md-max
      font-size: 3.2rem
      line-height: 4.4rem
  &__nav
    display: inline-block
    margin-left: 3rem
    margin-bottom: 1rem
    +res-sm-max
      display: none
  &__nav-item
    padding: .25rem .65rem
    cursor: pointer
    &:not(:last-child)
      margin-right: 1rem
    .icon
      width: 1.1rem
      height: 1.9rem
      +fill-icon(#000)
    &--next
      .icon
        +rotate(180deg)
    &--disabled
      cursor: default
      .icon
        opacity: .5
    &:not(.slider__nav-item--disabled)
      &:hover
        .icon 
          +filter(drop-shadow(1px 2px 3px #cfc9c1))

.eventi
  .slider
    .slick-track
      & > div
        max-width: 100%
        &:not(:last-child)
          margin-right: 1.5rem

.in-tab
  .slider
    &__title
      color: $color-text
      font-family: $font-secondary
      font-size: 3.6rem
      line-height: 4.8rem
      +res-md-max
        font-size: 3rem
        line-height: 4rem
    &__nav
      margin-bottom: .5rem

.moduli
  .slider
    .slick-track
      +res-md-max
        max-width: none!important
      .formazione-modulo-card
        &__cta-box
          display: flex
          flex-direction: column
          gap: 2rem

      & > div
        width: 45rem
        max-width: none
        +res-md-max
          width: auto
          max-width: 50%
          &:not(:last-child)
            margin-right: 2rem
        // +res-md-max
        //   &:not(:last-child)
        //     margin-right: 1.5rem

@media print
  .slider__section
    display: none