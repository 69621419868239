.vantaggi
  display: flex
  justify-content: center
  +res-md-max
    display: block
  &__item
    width: calc(33.33% - 4rem)
    padding: 3.8rem 3rem
    +border-radius(.6rem)
    background: url(/images/svg/logosymbol-overlay.svg) no-repeat 4.2rem 12.7rem $color-primary
    background-size: 100% 100%
    color: #fff
    text-align: center
    +res-md-max
      width: 100%
      margin-bottom: 3rem
    &:not(:nth-child(3n+1))  
      margin-left: 3rem
      +res-md-max
        margin-left: 0
    &:not(:nth-child(3n))  
      margin-right: 3rem
      +res-md-max
        margin-right: 0
    .icon
      width: 5rem
      height: 5rem
      margin-bottom: .8rem
      +fill-icon(currentColor)
  &__title
    font-size: 4rem
    line-height: 4.8rem
    font-weight: $font-extra-bold
    margin-bottom: 3.7rem
    +res-lg-max
      font-size: 3rem
      line-height: 3.6rem
      margin-bottom: 2.4rem
  &__description
    @extend .ext-p