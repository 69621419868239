@import public/sass/abstracts

.form-indirizzo-radio-option
  &__input-wrap
    position: relative
    display: flex
    align-items: center
    cursor: pointer
    color: $black
    padding: 20px
    border: 1px solid $grey-1
    border-radius: 10px
    > *
      &:not(:last-child)
        margin-right: 10px

  &__label    
    display: block
    +typoTextLg
    > *
      display: block

  &__title
    font-weight: $font-primary-heavy

  &__input 
    position: absolute
    left: -9999px

  &__styledinput
    svg
      +style-icon(24px, 24px, currentColot)
  
  &--editable
    .form-indirizzo-radio-option
      &__edit-button
        position: relative
        align-self: stretch
        width: 54px
        cursor: pointer
        margin: -20px -20px -20px auto
        svg
          position: absolute
          top: calc(50% - 12px)
          right: 20px
          +style-icon(24px, 24px, $grey-1)

  &--checked
    .form-indirizzo-radio-option
      &__input-wrap
        border-color: $color-secondary
      &__styledinput
        svg
          +fill-icon($accent-blue)

  &--readonly 
    .form-indirizzo-radio-option
      &__styledinput
        svg
          +fill-icon($black)

  &--disabled
    .form-indirizzo-radio-option
      &__input-wrap
        color: $grey-1
        cursor: default
      &__styledinput
        svg
          +fill-icon($grey-1)
      &__label
        color: $grey-1

  // Status
  &--error
    .form-indirizzo-radio-option
      &__styledinput
        color: $accent-red

.form-indirizzo-radio
  &__options
    > *
      &:not(:last-child)
      margin-bottom: 20px