.servizi-home2021
  padding: 50px 0
  &__title
    font-size: 3rem
    font-weight: $font-bold
    line-height: 4rem
    margin-bottom: 20px
    text-align: center
  &__list
    +res-sm-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 14px
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 16px
  &__item
    position: relative
    border-radius: 20px
    background: $color-bg
    text-align: center
    +res-md-min
      padding: 100px 16px 40px
      overflow: hidden
    +res-sm-max
      padding: 14px 16px 22px
    &__image-box
      +res-md-min
        position: absolute
        top: calc(50% - 130px)
        left: 0
        right: 0
        margin: 0 auto
        +transition(all .2s linear)
      +res-sm-max
        margin-bottom: 6px
    &__image
      +res-md-min
        height: 200px
      +res-sm-max
        height: 92px
    &__title
      font-size: 3rem
      font-weight: $font-bold
      line-height: 4rem
      +res-md-min
        position: absolute
        left: 16px
        right: 16px
        top: 235px
        +transition(top .3s linear)
      +res-sm-max
        font-size: 2rem
        line-height: 2.4rem
        margin-bottom: 26px

    &__menu
      display: flex
      flex-direction: column
      align-items: center
      > *
        &:not(:last-child)
          margin-bottom: 15px
      +res-md-min
        opacity: 0
        +translateY(100%)
        +transition(all .3s linear)

    &:hover
      .servizi-home2021__item
        &__image-box
          +res-md-min
            opacity: 0
        &__title
          +res-md-min
            top: 45px
        &__menu
          +res-md-min
            opacity: 1
            +translateY(0)