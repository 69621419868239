.actionbar
  display: flex
  justify-content: space-between
  align-items: center
  background: $color-secondary
  color: #fff
  padding: 1rem 3rem
  padding-bottom: 40px
  +border-top-left-radius($border-radius-medium)
  +res-md-max
    +border-top-left-radius($border-radius-small)
    padding-left: 1.5rem
    padding-right: 1.5rem
  &__buttons
    +res-md-max
      display: none
    > *
      display: inline-block
      &:not(:last-child)
        margin-right: 1.5rem
  &__comments
    margin-right: 2rem
    .icon
      height: 2.5rem
      width: 2.5rem
      vertical-align: middle
  &__description
    font-size: 1.6rem
    line-height: 2.4rem
    color: #fff
    +res-lg-max
      display: none
    a
      font-weight: $font-bold
      color: #fff
      cursor: pointer
    span
      font-weight: $font-regular
  &__icons
    text-align: right
    +res-md-max
      display: none
    > *
      display: inline-block
      vertical-align: middle
      &:not(:last-child):not(.actionbar__icons__save)
        margin-right: 1.5rem
    &__save
      margin-right: 4.5rem
  &__mobile
    display: flex
    justify-content: space-between
    flex: 1
    > *
      flex: 1
    +res-lg-min
      display: none

  &--fixed
    position: fixed
    bottom: 0
    left: 0
    right: 0
    height: 6.8rem
    z-index: 100
    padding-bottom: 0

  + .section
    margin-top: 0
    position: relative
    &::before
      content: ""
      position: absolute
      left: 0
      right: 0
      top: 0
      height: 9rem
      background: $color-secondary
      z-index: -1

.modal__content
  width: 60rem !important
  &__saved
    display: flex
    flex-direction: column
    align-items: center
    .icon
      height: 6.2rem
      width: 6.2rem
      margin-bottom: 1.9rem
      &--check
        background: $color-green
        padding: 1rem
        +fill-icon(#fff)
        +border-radius(3rem)
    .title
      font-size: 3rem
      line-height: 3.6rem
      margin-bottom: 2.7rem
    .disclaimer
      @extend .ext-paragraph3
      text-align: center
      max-width: 48rem
  &__add
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    .title
      font-size: 3rem
      line-height: 3.6rem
      margin-bottom: 2.7rem
    .dropdown
      width: 29rem
      margin-bottom: 2.4rem
    .cta
      margin-bottom: 3.3rem
    .disclaimer
      @extend .ext-paragraph3
      text-align: center
  &__share
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    .title
      font-size: 3rem
      line-height: 3.6rem
      margin-bottom: 2.7rem
  &__comunicazione
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    .title
      font-size: 3rem
      line-height: 4rem
      text-align: center
    .disclaimer
      @extend .ext-paragraph3
      line-height: 3rem
    .login
      text-decoration: underline
      color: $color-secondary
      cursor: pointer
  &__contatta
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    padding: 3rem 3rem 1rem 3rem
    .title
      font-size: 3rem
      line-height: 4rem
      text-align: center
    .disclaimer
      @extend .ext-paragraph3
      line-height: 3rem
    .bottom
      font-size: 1.6rem
      line-height: 2.4rem
      margin-top: 3.5rem
    .login
      color: $color-secondary
      cursor: pointer
      font-weight: 700
    .title-form
      font-size: 2.4rem
      line-height: 3rem
      text-align: center
      margin-bottom: 2rem
    .disclaimer-form
      @extend .ext-paragraph3
      font-size: 1.6rem
      line-height: 2.4rem
      margin-bottom: 2rem
      text-align: center
    .privacy-richiesta
      margin-top: 3rem

@media print
  .actionbar
    display: none
