@import public/sass/abstracts

.tema-card
  position: relative
  display: block
  height: 120px
  border-radius: 12px
  overflow: hidden

  &__title
    position: absolute
    top: 50%
    +translateY(-50%)
    text-align: center
    padding: 0 1.5rem
    left: 0
    right: 0
    +typoBase(1.6rem, 2.4rem, $font-primary-black)
    color: #fff
    +transition(opacity .5s)

  &__image-box
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden
    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: rgba(#000, .2)
      z-index: 0
  &__image
    object-fit: cover
    width: 100%
    height: 100%
    z-index: -1