// INIZIO PAGINA FAQ AUTORE (anche normali)
.faq-page__autore
  &__section
    max-width: $wcont-sportelli-width
    margin-left: auto
    margin-right: auto
    margin-top: -2.5rem
    @media (min-width: 1131px)
      padding-bottom: 8rem
  &__header
    text-align: center
    padding-bottom: 5rem    
    &__title
      @extend .ext-heading2
      margin-bottom: 1rem
      color: $color-secondary-dark

    &__abstract
      @extend .ext-abstract-medium

  &__content
    display: flex

  &__menu
    width: calc( 100% / 3 )
    padding-left: 0
    padding-right: 2rem
    +res-sm-max
      display: none

    &__item
      display: flex
      justify-content: center
      flex-direction: column
      height: 6.6rem
      padding-right: 2rem
      padding-left: 2rem
      color: $color-secondary-dark

    .active
      background: $color-bg-current
      +border-radius(.6rem)

  &__faqs
    width: calc( 100% / 3 * 2)
    +res-sm-max
      width: 100%
    ul
      li        
        padding-bottom: 3.2rem
        &:not(:first-child)
          padding-top: 3.2rem
        &:not(:last-child)
          border-bottom: 1px solid #2a2a2a

  &__category
    +res-md-min
      display: none
    +res-sm-max
      padding: 4rem 2rem 0 2rem
    &--active
      +res-md-min
        display: block
    &--odd
      +res-sm-max
        background: $color-bg
    &-title-mobile
      font-size: 2.8rem
      font-weight: $font-bold
      line-height: 3.2rem
      color: $color-secondary-dark
      margin-bottom: 2rem
      +res-md-min
        display: none

.faq-page

  .sticky-actions
    z-index: 999
    right: calc(((100vw - #{$wcont-sportelli-width}) / 2) - 60px)
    +res-lg-max
      right: $wcont-padding
    @media (max-width: 1130px)
      display: none
      
  .actions
    @media (min-width: 1131px)
      display: none

  &__header
    padding-bottom: 5rem
    +res-sm-max
      padding-bottom: 3rem

  &__title
    @extend .ext-heading2
    text-align: center
    margin-bottom: 1.6rem
    color: $color-secondary-dark
    margin-top: 3.5rem
    max-width: $wcont-sportelli-width
    margin-left: auto
    margin-right: auto

  &__description
    @extend .ext-paragraph4
    text-align: center
    margin-bottom: 8rem
    font-weight: 500
    color: $color-secondary-dark
    max-width: $wcont-sportelli-width
    margin-left: auto
    margin-right: auto
    +res-sm-max
      margin-bottom: 5rem

  &--detail
    .faq-page__autore
      &__content
        +res-sm-max
          flex-direction: column
      &__summary
        display: none
        flex-direction: column
        align-items: flex-start
        +res-sm-max
          display: flex
        margin-bottom: 1rem
        &__anchor
          +font(18, 24, 800)
          text-decoration: underline
          margin-bottom: 2rem
          color: $color-secondary
      &__menu
        &__item
          &.active
            background: $color-grey-12
            font-weight: 800
      &__faqs
        ul
          li        
            padding: 2.4rem
            border: 1px solid $color-grey-14
            +border-radius(1.6rem)
            &:not(:last-child)
              margin-bottom: 2rem
            .accordion-item 
              &__title
                +font(18, 24, 800)
                font-family: $font-primary
              &__paragraph
                +font(18, 24, 400)
                font-family: $font-primary
                margin-top: 2rem
                +res-sm-max
                  padding: 0
      &__category
        +res-sm-max
          padding: 4rem 0 2rem 0
        &-title-mobile
          +font(22, 24, 800)
         

body
    &.w-toolbar
      .faq-page
        .sticky-actions
          top: calc( 20px + #{$toolbar-height} )
    &.w-menu-open
      .faq-page
        .sticky-actions
          z-index: 9 // per il menu
