@import public/sass/abstracts

.form-toggle-autori
  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(16px)
      > *
        width: 50%
    +res-sm-max
      +custom-row(8px)
  &__item
    position: relative
    margin-bottom: 24px
    +res-sm-max
      margin-bottom: 8px
    .selection-button
      left: 8px
      +res-sm-max
        left: 4px
    &--more
      display: flex
      justify-content: center
      align-items: center
      background: $white
      border-radius: 12px
      min-height: 145px
      color: $color-secondary-dark
      +font(16, 24, 700)
      .icon
        &--add-circle
          width: 2rem
          height: 2rem
          margin-right: .6rem
          +fill-icon($color-secondary-dark)
    &__link
      display: flex
      justify-content: center
      align-items: center
  &__actions
    display: flex
    flex-direction: column
    align-items: center
    +res-sm-max
      margin-top: 16px
    > *
      &:not(:last-child)
        margin-bottom: 20px
