@import public/sass/abstracts

.area-personale-libri-template
  .area-personale-page
    &__main
      padding-bottom: 0
  &__tabgroup
    margin: 0 -16px
    @media screen and (min-width: 1280px)
      margin-right: calc(640px - 50vw)
    .tab
      min-height: 100vh
      padding-bottom: 200px
      +res-sm-max
        padding-bottom: 80px
      @media screen and (min-width: 1280px)
        padding-right: calc(50vw - 624px)