.immagine-header
  &__top
    padding-bottom: 50px
  &__header
    text-align: center
  &__title
    @extend .ext-heading1
    color: white
    margin-top: 2rem
    margin-bottom: -1rem
    &--black
      color: $color-text
  &__subtitle
    @extend .ext-heading2
    color: white
    margin-top: 1.7rem
  &__content
    @extend .w-container
    padding-top: 30px
