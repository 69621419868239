.pb-form-input  
  &__input
    width: 100%
    border: 1px solid $pb-grey-50
    background: $pb-white
    color: $pb-grey-100
    outline: none
    +typoTextSm
    font-family: $pb-font-primary
    padding: 4px 
    border-radius: 2px
    +placeholder-style
      color: $pb-grey-80

  // Status
  &--error
    .pb-form-input
      &__input
        border-color: $pb-error-default
