@import public/sass/abstracts

.menu-tile
  background: $grey-0
  width: 308px
  padding: 20px
  +border-radius(6px)
  +res-md-max
    width: 255px
  &__image
    margin-bottom: 16px
  &__title
    +font(18, 24, 800)
    margin-bottom: 12px
  &__description
    +font(14, 16, 500)
    margin-bottom: 12px
  &--header
    padding: 0
    overflow: hidden
    .menu-tile
      &__image
        width: 100%
        max-height: 100px
      &__title, &__description, &__cta
        padding: 0 16px
      &__cta
        margin-bottom: 16px
  &--shop
    display: flex
    width: 416px
    .menu-tile
      &__image
        margin-right: 20px