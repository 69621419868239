.ricerca
  background: $color-bg
  +res-sm-max
    background: #fff
  &__head
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 3rem
    padding-bottom: 3rem
    +res-sm-max
      padding-bottom: 0
      justify-content: center
  &__summary
    font-size: 2rem
    line-height: 2.4rem
  &-related
    &--desktop
      max-width: 50%
      +res-sm-max
        display: none
      .ais-Hits-list
        display: flex
    &__title
      font-size: 1.8rem
      line-height: 3.2rem
      font-weight: $font-bold
      margin-bottom: 1.2rem
    &__tags     
      &--popup 
        .ais-Hits-list
          display: flex
          flex-wrap: wrap
        .ais-Hits-item
          display: inline-block
          margin-bottom: 1rem
          &:not(:last-child)
            margin-right: 1.2rem
      .evidenza
        margin-right: 1.2rem
  &__content
    background: #fff
    +border-top-left-radius($border-radius-big)
    +border-top-right-radius($border-radius-big)
    padding: 6.1rem 0 11rem 0
    +res-sm-max
      +border-top-left-radius($border-radius-medium)
      +border-top-right-radius($border-radius-medium)
    &--area-personale
      padding-top: 0!important // brutto ma necessario
      background: none
  &__listing
    display: flex
    padding: 0 1rem
    &__filters
      width: 25%
      +res-sm-max
        display: none
      &__title
        font-size: 2rem
        line-height: 2.4rem
        font-weight: 700
        margin-bottom: 2rem
        &--listing
          margin-top: 1.6rem
      &__chips
        display: flex
        flex-wrap: wrap
        margin-bottom: 1rem
        &:empty
          margin-bottom: 0
        .chip
          margin-bottom: .8rem
          &:not(:last-child)
            margin-right: .8rem
      &__filter
        margin-bottom: 2rem
        padding-right: 1.6rem
        .box
          position: relative
          background: $color-grey-12
          padding: 1.2rem 1.6rem
          +border-radius(1.2rem)
          &--collapsed
            .box
              &__placeholder
                &::after
                  top: 1.2rem
                  +rotate(180deg)
              &__choices
                max-height: 0
          &--disabled
            opacity: .4
            .box
              &__placeholder
                cursor: not-allowed
          &__placeholder
            font-size: 1.6rem
            line-height: 2.4rem
            font-weight: 700
            cursor: pointer
            &::after
              content: url('/images/demo/dropdown_arrow.png')
              position: absolute
              top: 1rem
              right: 1.6rem
              +transition(all .3s)
          &__choices
            height: auto
            max-height: 100rem  // casuale
            overflow: hidden
            +transition(all .3s)
          &__choice
            font-size: 1.4rem
            line-height: 1.6rem
            margin-bottom: 1.5rem
            +res-sm-max
              line-height: 2rem
            &--disabled
              opacity: .4
            &:first-child
              margin-top: 1.2rem
              +res-sm-max
                margin-top: 2.2rem
            &__flag
              position: absolute
              top: 1px
              left: 0
              display: block
              border: 2px solid $color-grey-11
              +border-radius(.3rem)
              width: 1.5rem
              height: 1.5rem
              .icon
                opacity: 0
                width: 100%
                height: 100%
                position: absolute
                top: 0
                left: 0
                bottom: 0
                right: 0
                +fill-icon($color-secondary)
              &--checked
                // background: $color-secondary
                border-color: $color-secondary
                .icon
                  opacity: 1
    &__list
      width: 75%
      display: flex
      flex-direction: column
      +res-sm-max
        width: 100%
      &.horizontal
        .ais-Hits-list
          // justify-content: space-evenly
        .ais-Hits-item
          flex: 50%
          max-width: 44.8rem
          &:nth-child(odd)
            margin-right: 3rem
      &__loading
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        +res-sm-max
          justify-content: center
      &__top
        display: flex
        align-items: center
        margin-left: auto
        margin-bottom: 1.2rem
        &--loading
          +res-sm-max
            display: none
        &__title
          font-size: 1.4rem
          line-height: 2rem
          font-weight: 700
          margin-right: 1.2rem
          +res-sm-max
            display: none
        .dropdown-algolia
          margin-right: 2.4rem
          +res-sm-max
            display: none
        .switch
          +res-sm-max
            display: none
          &__checkbox
            position: absolute
      .ais-Hits-list
        display: flex
        flex-wrap: wrap
        margin-right: -1.5rem
        +res-sm-max
          justify-content: center
      .ais-Hits-item
        flex: 0 0 25%
        margin-bottom: 2.4rem
        padding-right: 1.5rem
        max-width: 100%
        .ricerca-result
          display: flex
          justify-content: center

    &--area-personale
      flex-direction: column
      .ricerca__listing
        &__filters
          display: flex
          width: 100%
          +res-sm-max
            display: none
          &__filter
            margin-bottom: 3rem
            .dropdown-algolia
              min-width: 20.4rem
        &__list
          width: 100%
          .ais-Hits-item
            padding-right: 1rem
          &.horizontal
            .ais-Hits-item
              &:nth-child(odd)
                margin-right: 2rem
          &__top
            margin-left: unset
            margin-bottom: 3rem
            &__title
              +font(24, 30, 800)
              +res-sm-max
                display: block
            .switch
              margin-left: auto

  &__empty
    font-size: 2.4rem
    margin-top: 5rem
    margin-bottom: 30rem
    text-align: center

  .actionbar
    &__mobile
      display: none

.ais-Pagination-list
    display: flex
    justify-content: center

.ais-Pagination-item
    margin: 5px

.ais-MenuSelect
  &-select
    max-width: 20rem
    position: relative
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
    outline: none
    border: 2px solid #BBBBBB
    +border-radius(2rem)
    height: 4rem
    font-family: $font-primary
    font-size: 1.6rem
    line-height: 4rem
    color: $color-text
    padding: 0 3.5rem 0 1.5rem
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    background: url(/images/svg/dropdown-arrow.svg) no-repeat 90% center
    &:not(:disabled)
      cursor: pointer
     
.ais-Pagination
  display: block
  text-align: center
  padding: 2rem 0
  &-list
    flex-wrap: wrap
  &-item
    display: inline-block
    vertical-align: middle
    padding: 0 .8rem
    text-align: center
    margin: 0 .5rem
    font-size: 1.4rem
    line-height: 2.4rem
    text-align: center
    font-weight: $font-bold
    color: $color-secondary
    background: $color-secondary-light
    text-decoration: none
    cursor: pointer
    +border-radius(.8rem)
    +res-sm-max
      margin: 0 .3rem .5rem .3rem
    &:hover, &:active, &:focus, &:visited, &:link
      color: $color-secondary
      text-decoration: none
    &--disabled
      opacity: .6
      cursor: default
    &--selected, &:active
      color: #fff
      background: $color-secondary
      cursor: default
      &:hover
        color: #fff

  &--firstPage, &--previousPage, &--nextPage, &--lastPage
    display: inline-block
    vertical-align: middle
    padding: .2rem .5rem
    font-size: 1.6rem
    font-weight: $font-bold
    line-height: 2.4rem
    color: $color-secondary
    cursor: pointer
    &:hover, &:active, &:focus, &:visited, &:link
      color: $color-secondary
    +res-sm-max
      padding: .2rem 0

  &--firstPage    
    +res-xs-max
      margin-right: .5rem
  &--lastPage
    +res-xs-max
      margin-left: .5rem

