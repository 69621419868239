.left-sidebar
    .toolbox
        width: 100%
        height: 100%
        background-color: white
        border-right-width: 1px
        display: flex
        flex-direction: column
        justify-content: flex-start

        .disabled
            opacity: 0.5
            cursor: not-allowed

        &__tabs
            display: flex
            justify-content: center
            padding: 0 10px
            border-bottom: 1px solid $pb-grey-50
            > *
                flex: 1 0 0
        
        &__tab-item
            position: relative
            cursor: pointer
            padding: 10px 
            text-align: center
            background: $pb-grey-50
            color: $pb-grey-100
            border: 1px solid $pb-grey-50
            border-bottom-width: 0
            +typoCaption
            &:last-child
                border-right: 1px solid $pb-grey-50
            &--active
                background-color: $pb-white
                color: $pb-accent-default
                &::after
                    content: ""
                    position: absolute
                    bottom: -1px
                    height: 1px
                    left: 0
                    right: 0
                    background: $pb-white
            

        &__tools
            
            &__wrapper
                display: flex
                flex-wrap: wrap
                margin: 0 -3px

            &__item
                width: calc(33.33% - 4px)
                background-color: $pb-grey-20
                text-align: center
                padding: 9px 5px
                margin: 0 2px 4px
                cursor: grab
                color: $pb-grey-100
                border-radius: 2px
                
                > *
                    display: block

                span
                    +typoTextXs
                svg
                    +style-icon(32px, currentColor)
                    margin: 0 auto

        &__custom-blocks

            &__item
                display: flex
                justify-content: space-between
                a
                    cursor: pointer