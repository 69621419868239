.ROOT
  .col
    > * 
      &:not(:last-child)
        margin-bottom: 4rem
  
.ROOT--editing
  .col
    min-height: 20px
    padding-top: 10px
    padding-bottom: 10px