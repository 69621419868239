@import public/sass/abstracts

.area-formazione-template
  .area-personale-page
    &__main
      padding-bottom: 0
  &__tabgroup
    .tab-group
      &__contents
        margin: 0 -16px
        @media screen and (min-width: 1280px)
          margin-right: calc(640px - 50vw)
    .tab
      @media screen and (min-width: 1280px)
        padding-right: calc(50vw - 624px)
    .tab
      min-height: 100vh
      padding-bottom: 200px
      +res-sm-max
        padding-bottom: 80px
