.calendario-avvento-text-step1
  display: flex
  padding: 5.7rem 0 0 0
  +res-md-max
    flex-direction: column
    padding: 2.7rem 0 0
  .calendario-avvento-title-step1
    font-size: 4rem 
    text-align: left
    color: $green-dark
    line-height: 1.15
    display: flex
    max-width: 30%
    +res-md-max
      font-size: 2.4rem
      text-align: center
      max-width: 100%
      margin: 0 auto    
    
  .calendario-avvento-description-step1
    text-align: left
    color: $green-dark
    font-size: 2rem 
    margin-left: auto
    width: 50%
    max-width: 54.4rem
    line-height: 2.8rem
    +res-md-max
      font-size: 1.6rem
      text-align: center
      width: 100%
      line-height: 2.1rem
      padding-top: 2.5rem
      margin: 0 auto

.calendario-avvento-list-step1
  display: flex
  flex-wrap: wrap
  padding: 6.7rem 0 12rem 0
  +res-md-max
    padding: 2.7rem 0 
  .box
    border: 1px solid #ffffff
    +res-md-max
      width: 25%
      line-height: 0
    .wcella-card__image-box
        img 
          width: 100%
          max-width: 200px
          cursor: pointer!important