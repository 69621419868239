@import public/sass/abstracts

.step-bar
  display: flex
  +res-md-min
    padding: 12px 0
    +res-md-min
      > *
        &:not(:last-child)
          margin-right: 25px

.step-bar-item
  position: relative
  display: flex
  +typoTextSm
  font-weight: $font-primary-heavy
  +res-sm-max
    flex: 1 0 0
  
  &:not(:first-child)::before, &:not(:last-child)::after
    content: ""
    height: 2px
    margin: 16px 0 0
    +res-md-min
      display: none
    +res-sm-max
      position: absolute

  &:not(:first-child)::before
    margin-right: 10px
    +res-sm-max
      left: 0
      right: calc(50% + 16px)

  &:not(:last-child)::after
    margin-left: 10px
    +res-sm-max
      right: 0
      left: calc(50% + 16px)

  &__content
    margin: 0 auto
    +res-md-min
      display: inline-flex
      align-items: center

  &__dot
    position: relative
    display: block
    margin: 0 auto
    padding: 4px 0
    width: 30px
    height: 30px
    border: 2px solid transparent
    line-height: 20px
    text-align: center
    border-radius: 50%

    +res-md-min
      margin: 0 8px 0 0

  &__dot-check
    +style-icon(20px, 20px, currentColor)
    margin: 0 auto

  &__label
    display: block
    margin: 8px auto 0
    text-align: center
    
    +res-md-min
      margin: 0
      max-width: 120px

  &--past
    cursor: pointer
    color: $color-secondary
    &:not(:first-child)::before
      background: $color-secondary
    &:not(:last-child)::after
      background: $color-secondary
    .step-bar-item
      &__dot
        border-color: $color-secondary
      
  &--active
    color: $color-secondary-dark
    &:not(:first-child)::before
      background: $color-secondary
    &:not(:last-child)::after
      background: $grey-1
    .step-bar-item
      &__dot
        color: $white
        background: $color-secondary-dark
        border-color: $color-secondary-dark

  &--future
    color: $grey-1
    &:not(:first-child)::before
      background: $grey-1
    &:not(:last-child)::after
      background: $grey-1
    .step-bar-item
      &__dot
        border-color: $grey-1