@import public/sass/abstracts

.tag
  padding: 2px 7px
  background: $tertiary
  color: $white
  font-size: 14px
  line-height: 24px
  font-weight: $font-primary-black
  display: inline-block
  .tag__content
    p
      margin: 0