@import public/sass/abstracts

.disciplina-card
  position: relative
  display: block
  background: $black
  height: 120px
  +border-radius(12px)
  overflow: hidden
  &:hover
    +box-shadow(8px 8px 15px 0px $color-grey-8)

  &__title
    position: absolute
    top: 50%
    +translateY(-50%)
    text-align: center
    padding: 0 16px
    left: 0
    right: 0
    +typoBase(1.8rem, 2.4rem, $font-primary-black)
    color: #fff