.catalogo-page
  +res-lg-min
    background: $grey-0
  .breadcrumbs
    margin: 30px 0
  &__sticky-container
    position: sticky
    top: 20px
    z-index: 999 // per le modali
    .sticky-actions
      position: absolute
      top: 0
      right: 0
  &__header-container
    max-width: $wcont-catalogo-width
    margin-right: auto
    @media (max-width: 1280px + 32px)
      max-width: calc( 100% - ( 40px + #{$wcont-padding} ) )
    +res-md-max
      max-width: 100%
  &__header
    display: flex
    background: #fff
    margin-bottom: 90px // 40px + 50px negativi dei tab
    padding: 40px
    +border-radius(12px)
    +res-md-max
      flex-direction: column
      padding: 0
      margin-bottom: 60px
  &__image-box
    flex: 1
    max-width: 285px
    +res-md-max
      display: flex
      justify-content: center
      align-self: center
  &__summary
    flex: 1
    +res-lg-min
      margin-left: 50px
    +res-md-max
      margin-top: 30px
  &__intro
    +font(12, 16, 700)
    margin-bottom: 10px
    text-transform: uppercase
    color: $grey-3
    +res-md-max
      margin-bottom: 20px
  &__back
    display: flex
    align-items: center
    color: $color-secondary
    +font(16, 24, 700)
    margin-bottom: 20px
    +res-sm-max
      margin-bottom: 30px
    &--desktop
      +res-md-max
        display: none
    &--mobile
      +res-lg-min
        display: none
    .icon
      width: 24px
      height: 24px
      margin-right: 4px
      fill: $color-secondary
  &__title
    +font(30, 40, 800)
    margin-bottom: 20px
    +res-md-max
      font-size: 28px
  &__tags
    margin-bottom: 20px
  &__abstract, &__isbn
    +font(14, 20, 400)
    margin-bottom: 20px
  &__abstract
    &--dropshipping
      +font(16, 24, 400)
  &__editore, &__pagine, &__classe
    +font(14, 20, 400)
    margin-bottom: 20px
    display: inline-block
    background: $grey-0
    padding: 6px 10px
    +border-radius(6px)
  &__box-libro
    display: flex
    gap: 20px
    +res-sm-max
      flex-direction: column
  &__box-contenuti-libro
    &:not(:only-child)
      flex: 1
      flex-direction: column
      +res-md-min
        align-items: flex-start
      .box-contenuti-libro__content
        margin-right: 0
      .box-contenuti-libro__description
        margin-bottom: 20px
  &__box-attiva-libro
    margin-top: 20px
    &.desktop
      +res-md-max
        display: none
    &.mobile
      margin-bottom: 60px
      +res-lg-min
        display: none
    &--volume
      &:nth-child(2)
        flex: 1
        flex-direction: column
        margin-top: 0
        +res-md-min
          align-items: flex-start
        .box-attiva-libro__content
          margin-right: 0
        .box-attiva-libro__description
          margin-bottom: 20px
  &__cta-box
    display: flex
    align-items: center
    flex-wrap: wrap
    &__cta
      &:not(:last-child)
        margin-right: 10px
      +res-md-max
        margin-bottom: 20px
      &--sfogliabile
        +res-lg-min
          margin-left: auto
  &__price
    +font(20, 24, 700)
    color: $tertiary
    margin-right: 40px
    +res-md-max
      display: block
      margin-bottom: 20px
    &--full
      color: $grey-3
      margin-right: 8px
      font-weight: 400
      text-decoration: line-through
    &--shipping
      display: block
      +font(14, 20, 400)
      margin-bottom: 20px
      span
        font-weight: 800
  &__vat
    color: $color-text
    margin-right: 20px
    +font(12, 16, 400)
    +res-md-max
      margin-bottom: 20px
  &__discount
    +typoTextMd
    font-weight: $font-primary-heavy
    padding: 2px 8px
    border-radius: 8px
    background: $tertiary
    color: $white
    margin-left: -32px // vaccata ma serve
    margin-right: 30px
  &__tabs
    background: #fff
    +res-md-max
      margin-bottom: 60px
  &__tabgroup
    position: relative
    top: -50px
    max-width: $wcont-catalogo-width
    +res-md-max
      display: none
  &__credits
    +res-lg-min
      display: none
  &__box-configurazioni
    background: $grey-0
    padding: 60px 0 50px 0
    +res-sm-max
      padding-top: 30px
    &__title
      +font(24, 30, 800)
      margin-bottom: $wcont-padding
    &__content
      display: flex
      flex-wrap: wrap
      max-width: $wcont-catalogo-width
      +res-sm-max
        flex-direction: column
        align-items: center
    &__card
      max-width: 575px
      margin-bottom: $wcont-padding
      &:nth-child(odd)
        +res-sm-min
          margin-right: 20px
  &__parascolastici
    display: flex
    flex-wrap: wrap
    max-width: $wcont-catalogo-width
    +res-sm-max
      flex-direction: column
      align-items: center
    &__card
      width: calc(50% - #{$wcont-padding})
      margin-bottom: $wcont-padding
      &:nth-child(odd)
        +res-sm-min
          margin-right: 20px
  &__bundle-container
    background: $grey-0
    margin-left: -16px
    margin-right: -16px
    padding: 30px 16px
    .box-bundle
      &:not(:last-child)
        margin-bottom: 20px
    &__volume-page
      margin-bottom: 30px

  &--volume
    .catalogo-page
      &__header-container
        padding-bottom: 110px
      &__header
        margin-bottom: 0
      &__forzatura
        display: inline-block
        color: $color-red
        width: 100%
        margin-bottom: 20px
        font-size: 17px
        line-height: 24px
        font-weight: 700
        +res-sm-max
          margin-bottom: 10px
      &__garanzia-tile
        margin-bottom: 30px
      &__editore
        display: inline-block
        margin-right: 10px
        +res-sm-max
          margin-bottom: 10px
      &__pagine
        display: inline-block
        margin-right: 10px
        +res-sm-max
          margin-bottom: 10px
      &__classe
        display: inline-block
        +res-sm-max
          margin-bottom: 20px
      &__cta-box
        margin-bottom: 20px
  &--no-tabs
    .catalogo-page
      &__header-container
        padding-bottom: 110px
      &__header
        margin-bottom: 0
  &--prodotto
    padding-bottom: 120px
    .catalogo-page
      &__header
        margin-bottom: 40px

body
  &.w-toolbar
    .catalogo-page
      &__sticky-container
        top: calc( 20px + #{$toolbar-height} )
    &.w-menu-open
      .catalogo-page
        &__sticky-container
          z-index: 9 // per il menu
