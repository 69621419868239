@import public/sass/abstracts

.correlati
    padding: 65px $wcont-padding
    background: $grey-0
    +res-md-max
        padding: 30px $wcont-padding
    &__container
        max-width: $wcont-sportelli-width
        margin-left: auto
        margin-right: auto
    &__title
        +font(20, 26, 800)
        margin-bottom: 30px
    &__content
        display: flex
        +res-md-max
            flex-direction: column

@media print
  .correlati
    display: none