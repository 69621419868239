@import public/sass/abstracts

.wbutton
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  position: relative
  +transition(#{"background .2s linear, color .2s linear, border-color .2s linear, box-shadow .2s linear"})
  z-index: 0
  border-radius: 24px
  +typoButtonMd

  &:not(:disabled)
    cursor: pointer

  &:disabled
    opacity: .7

  &.disabled
    opacity: .5

  &__label
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none

  &__icon, &__loader
    svg
      display: block
      +style-icon(24px, 24px, currentColor)

  &__loader
    svg
      animation: rotation 1s infinite linear

  &__icon
    margin-right: 4px

  &__loader
    position: absolute
    left: 0
    right: 0
    top: calc(50% - 12px)
    width: 24px
    margin: 0 auto
    // svg
    //   animation: rotation 1s infinite linear

  // Loading
  &--loading
    .wbutton
      &__label, &__icon
        opacity: 0

  // Variant
  &--primary
    background-color: $accent-blue
    color: $white
    padding: 12px 35px
    &.wbutton--only-icon
      padding: 12px

    &.wbutton--inverted
      background-color: $white
      color: $accent-blue

  &--secondary
    border: 2px solid currentColor
    color: $accent-blue
    padding: 10px 14px
    &.wbutton--only-icon
      padding: 10px

  &--squared
    border: 1px solid currentColor
    color: $color-text
    padding: 10px 14px
    border-radius: 5px

  &--ghost
    position: relative
    color: $accent-blue
    padding: 0
    padding: 0

  &--checked
    background-color: $color-grey-12
    color: $accent-blue
    padding: 12px 35px

  &:not(.wbutton--ghost)
    &:not(:disabled)
      &:hover
        +box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))

  // Icon
  &--icon-right
    .wbutton
      &__icon
        margin-right: 0
        margin-left: 4px
      &__label
        order: -1

  &--only-icon
    .wbutton
      &__icon
        margin-right: 0
        margin-left: 0

  // size
  &--sm
    +typoButtonSm
    .wbutton
      &--secondary
        padding: 10px 12px

  // color
  &--color-darkblue
    &.wbutton
      &--primary
        background-color: $tertiary
        &.wbutton--inverted
          background-color: $white
          color: $tertiary
      &--secondary
        color: $tertiary
      &--ghost
        color: $tertiary

  &--color-orange
    &.wbutton
      &--primary
        background-color: $color-primary
        &.wbutton--inverted
          background-color: $white
          color: $color-primary
      &--secondary
        color: $color-primary
      &--ghost
        color: $color-primary

  &--color-white
    &.wbutton
      &--secondary
        color: #fff
      &--ghost
        color: #fff

  &--color-red
    &.wbutton
      &--primary
        background-color: $color-red-dark
        &.wbutton--inverted
          background-color: $white
          color: $color-red-dark
      &--secondary
        color: $color-red-dark
      &--ghost
        color: $color-red-dark

  &--color-red-light
    &.wbutton
      &--primary
        background-color: $color-red
        &.wbutton--inverted
          background-color: $white
          color: $color-red
      &--secondary
        color: $color-red
      &--ghost
        color: $color-red

  &--color-yellow
    &.wbutton
      &--primary
        background-color: $color-yellow
        &.wbutton--inverted
          background-color: $white
          color: $color-yellow
      &--secondary
        color: $color-yellow
      &--ghost
        color: $color-yellow

  &--color-green
    &.wbutton
      &--primary
        background-color: $green-dark
        &.wbutton--inverted
          background-color: $white
          color: $green-dark
      &--secondary
        color: $green-dark
      &--ghost
        color: $green-dark

  &--color-grey
    &.wbutton
      &--primary
        background-color: $grey-3
        &.wbutton--inverted
          background-color: $white
          color: $grey-3
      &--secondary
        color: $grey-3
      &--ghost
        color: $grey-3

  &--color-black
    &.wbutton
      &--primary
        background-color: $black
        &.wbutton--inverted
          background-color: $white
          color: $black
      &--secondary
        color: $black
      &--ghost
        color: $black
