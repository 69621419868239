.custom-content
  @extend .ext-custom-text
  word-break: break-word
  .custom_text
    margin-bottom: 2rem
  .strumento-card
    margin-top: 4rem
    margin-bottom: 4rem
    +res-sm-max
      margin-left: auto
      margin-right: auto
    &__content
      a
        // color: $color-text
        text-decoration: none
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11
    +res-sm-max
      &:not(:last-child)
        margin-bottom: 4rem
  .row
    &:not(:last-child)
      margin-bottom: 4rem
  .map
    iframe
      width: 100%
      height: 70vh // ipotetica
