@import public/sass/abstracts

.formazione-corso-concluso-card
  border-radius: 8px
  background: #fff
  color: $color-text
  padding: 20px
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
  +res-sm-min
    display: flex
  > *
    &:not(:last-child)
      +res-sm-min
        margin-right: 20px
      +res-xs-max
        margin-bottom: 20px

  &__image-box
    height: 120px
    border-radius: 12px
    overflow: hidden
    +res-sm-min
      width: 120px
    &--desktop
      +res-xs-max
        display: none
    &--mobile
      +res-sm-min
        display: none
    img
      width: 100%
      height: 100%
      display: block
      object-fit: cover

  &__content
    +res-sm-min
      flex: 1 

  &__intro
    +typoTextLg
    text-transform: uppercase
    margin-bottom: 8px

  &__title
    +typoH4

  &__cta-box
    margin-top: 20px
    +res-xs-max
      text-align: center