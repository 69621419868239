@import public/sass/abstracts

.checkout-indirizzo-card
  background: $grey-0
  border-radius: 10px
  padding: 16px
  +typoTextSm
  &__title
    font-weight: $font-primary-heavy
    text-transform: uppercase
    margin-bottom: 10px
  &__content
    > *
      &:not(:last-child)
        margin-bottom: 4px