.page404
  padding-bottom: 3rem
  &__image-box
    padding: 2rem 0
  &__image
    display: block 
    margin: 0 auto
    +res-sm-max
      max-height: 20rem
  &__content
    padding: 9rem 2rem 
    +res-sm-max
      text-align: center
      padding: 3rem 0
  &__title
    font-size: 4.8rem
    font-weight: $font-bold
    line-height: 5.6rem
    margin-bottom: 2.8rem
    +res-sm-max
      font-size: 3.2rem
      line-height: 3.6rem
  &__description
    font-family: $font-secondary
    font-size: 3rem
    line-height: 4rem
    margin-bottom: 1rem
    +res-sm-max
      font-size: 2rem
      line-height: 2.4rem
  &__errorcode
    font-size: 2.4rem
    font-weight: $font-bold
    line-height: 2.9rem
    +res-sm-max
      font-size: 1.8rem
      line-height: 2.4rem
  &__cta-box
    margin-top: 4.2rem