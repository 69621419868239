@import public/sass/abstracts

.w-container-fluid, .w-container, .w-container-sm
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding

.w-container
  max-width: $wcont-width
.w-container-sm
  max-width: $wcont-small-width