@import public/sass/abstracts

.area-personale-ordine-card
  background: $white
  padding: 30px
  border-radius: 12px
  &__title
    +typoBase(2rem, 2.4rem, $font-primary-black-2)
    margin-bottom: 20px
  &__main
    +res-md-min
      display: flex
      > * 
        &:not(:last-child)
          margin-right: 5%

  &__content
    +res-md-min
      flex: 1

  &__actions
    display: flex
    justify-content: flex-end
    +res-md-min
      flex-direction: column
      align-items: flex-end
    +res-sm-max
      margin-top: 20px

  &__track
    +res-md-min
      margin-bottom: auto
    +res-sm-max
      margin-right: auto

  &__details
    +typoTextMd
    +res-sm-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 20px
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 20px

  &__detail
    +res-sm-min
      > *
        display: block
    +res-xs-max
      display: flex
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 8px
  &__detail-label
    color: $grey-2
    font-weight: $font-primary-medium
    +res-md-min
      margin-bottom: 6px
  &__detail-value
    font-weight: $font-primary-heavy

  &__images
    margin-top: 20px
    display: flex
    &__more
      position: absolute
      top: 50%
      color: $accent-blue
      +translateY(-50%)
      left: 0
      right: 0
      text-align: center
      +font(12, 16, 400)
    &__quantity
      position: absolute
      top: -5px
      right: -5px
      display: flex
      align-items: center
      justify-content: center
      background: $tertiary
      color: #fff
      width: 26px
      height: 26px
      +font(12, 24, 400)
      +border-radius(40px)
    > *
      position: relative
      &:not(:last-child)
        margin-right: 20px