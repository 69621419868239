@import public/sass/abstracts

.box-bundle-item
  position: relative
  width: 230px
  text-align: center
  padding: 12px 16px
  border: 1px solid $grey-1
  +border-radius(12px)
  +res-sm-max
    width: 60px
    border: none
    padding: 0
  &__title
    +font(14, 20, 700)
    margin-top: 12px
    +res-sm-max
      display: none
  &__helper
    position: absolute
    top: 12px
    right: 12px
    cursor: pointer
    +res-sm-max
      display: none
    svg
      +style-icon(20px, 20px, $color-secondary)
  .wmodal
    max-width: 848px
    padding: 40px
    max-height: 85%
    overflow: hidden

  &__modal
    display: flex
    text-align: left
    &__image
      margin-right: 20px
      flex: 0 0 30%
    &__content
      display: flex
      flex-flow: column
      flex: 0 0 70%
    &__title
      +font(24, 30, 800)
      margin-bottom: 28px
    &__description
      +font(16, 24, 400)
      overflow: auto
      max-height: 50vh
