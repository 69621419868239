@import public/sass/abstracts

.form-input-password
  
  &__input-wrap
    position: relative

  &__input
    width: 100%
    border: 1px solid $grey-0
    background: $grey-0
    color: $black
    outline: none
    border-radius: 12px!important
    +placeholder-style
      color: $grey-2
      
    &:active, &:focus
      border-color: $accent-blue

  // Status
  &--error
    .form-input-password
      &__input
        border-color: $accent-red

  // Size 
  &--md
    .form-input-password
      &__input
        +typoBase(1.6rem, 2.4rem, $font-primary-medium-2, $font-primary)
        padding: 12px 16px