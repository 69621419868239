@import public/sass/landing-riviste/abstracts

.landing-riviste-motivi
  font-family: $font-primary
  color: #000
  padding: 86px 0
  +res-sm-max
    padding: 24px 0 11px
  &__title
    +typoBase(46px, 55px, 800)
    letter-spacing: -0.01em
    text-align: center
    span
      color: $color-primary
    +res-md-max
      +typoH1MobileLR

  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      margin-top: 55px
      > *
        width: calc(50% - 10px)
        &:nth-child(2n + 1)
          margin-right: 20px
    +res-sm-max
      margin-top: 20px
      
  &__item
    padding: 18px 24px
    border-radius: 10px
    background: #F7F7F7
    margin-bottom: 22px
    +typoBase(24px, 36px, 500)
    +res-md-max
      +typoBase(14px, 19px, 500)
    +res-sm-max
      margin-bottom: 11px