=typoH1LR
  +typoBase(46px, 55px, 800, $font-primary)
  letter-spacing: -0.01em

=typoH1MobileLR
  +typoBase(32px, 36px, 800, $font-primary)
  letter-spacing: -0.01em

=typoH2LR
  +typoBase(40px, 55px, 800, $font-primary)
  letter-spacing: -0.01em

=typoH4MaiuscoloLR
  +typoBase(28px, 38px, 800, $font-primary)
  letter-spacing: 0.05em
  text-transform: uppercase

